import Papa from "papaparse";
import { Input } from "@mui/material";

export interface IParticipantCsvFormat {
    email: string;
    name?: string;
    type?: string;
}

interface IParticipantCsvReaderProps {
    onSelect: (path: string, data: IParticipantCsvFormat[]) => void;
    onError?: (path: string, error: any) => boolean; // if return true, continue, Otherwise stop.
}

const EXPECTED_COLUMNS = ["email"];

const readFile = (
    e: any,
    cb: (a: string, b: IParticipantCsvFormat[]) => void,
    cbErr?: (path: string, error: any) => boolean
) => {
    var file = e.target.files[0];

    Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        delimiter: ";",
        complete: function (results) {
            const column_names = results.meta.fields || [];
            const allColsPresent = EXPECTED_COLUMNS.map((k) => column_names.includes(k)).every((e) => e);
            if (!allColsPresent) {
                if (!cbErr) return;
                else if (!cbErr(file.name, `Missing columns. Got ${column_names}. Expected ${EXPECTED_COLUMNS}`))
                    return;
            }

            // TODO: validate not empty col in row

            cb(file.name, results.data as IParticipantCsvFormat[]);
        },
    });
};
export const ParticipantCsvReader = (props: IParticipantCsvReaderProps) => {
    return (
        <div>
            <Input type="file" onChange={(e) => readFile(e, props.onSelect, props.onError)} />
        </div>
    );
};
