import {
    CommandFactory,
    CommandResultError,
    CommandResultMyTokens,
    ECommandError,
    UITokenBase,
    CommandGetMyTokens,
    ExtraArgsCommandGetMyTokens,
    CommandCreateUserSurveyToken,
    ExtraArgsCommandCreateUserSurveyToken,
    CommandResultSuccess,
    ETokens,
    EServices,
    ESupportedLanguage,
} from "4common-ts";
import CommandController, { EEndPointSecure } from "Common/command-controller";
import { UserData } from "Common/user-data";
import ControllerAbstract from "../../Common/Controllers/controller-abstract";

export default class DashboardInviteCollaboratorController extends ControllerAbstract {
    static myName: string = "dashboard-invite-collaborator-controller";

    constructor(commandMediator: CommandController, userData: UserData, currentLang: ESupportedLanguage) {
        super(commandMediator, userData, DashboardInviteCollaboratorController.myName, currentLang);
    }

    async getTokenAsync(email: string): Promise<Array<UITokenBase>> {
        return new Promise<Array<UITokenBase>>(async (resolve, reject) => {
            // FIXME: Should only query STANDARD survey. Not all then filter.
            const command = CommandFactory.create<ExtraArgsCommandGetMyTokens>(CommandGetMyTokens.type, this.token, {
                tokenType: [ETokens.ALL],
                tokens: [""], //TODO: A way to get all token and another to have specific tokens list
                email, //email of the user who is log-in
                service: EServices.USER,
                keySeach: "", //TODO: A way to search a token by a keySearch like email or surveyId
            });

            const result = await this.sendCommandAsync(command, EEndPointSecure.PROTECTED);
            if (result === undefined) throw Error("Something went wrong while request Token to dashboard.");
            if (result.type === CommandResultError.type) {
                reject((result as CommandResultError).error);
                return;
            } else if (result.type !== CommandResultMyTokens.type) {
                reject(ECommandError.UNKNOWN);
                return;
            }

            const tokens = (result as CommandResultMyTokens).tokens;

            resolve(tokens);
        });
    }

    async createSurveyTokenAsync(
        buyTokenId: string,
        emails: string[],
        parent_survey_token_id: string | undefined,
        expiration_date: string | undefined
    ): Promise<string[]> {
        return new Promise<string[]>(async (resolve, reject) => {
            resolve(
                (
                    await Promise.all(
                        emails.map(async (email) => {
                            const command = CommandFactory.create<ExtraArgsCommandCreateUserSurveyToken>(
                                CommandCreateUserSurveyToken.type,
                                this.token,
                                {
                                    buyTokenId,
                                    email,
                                    expiration_date,
                                    parent_survey_token_id,
                                }
                            );

                            const result = await this.sendCommandAsync(command, EEndPointSecure.PROTECTED);
                            if (result === undefined) return email;

                            if (result.type !== CommandResultSuccess.type) {
                                return email;
                            }

                            return "";
                        })
                    )
                ).filter((r) => r !== "")
            );
        });
    }
}
