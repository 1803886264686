import { ICommandResult } from "../ICommandResult";
import { ECommandResultType } from "../../utils/enum-constants";
import { ICommand } from "src/command/command-model/ICommand";

export interface ExtraArgsCommandResultSurveyRetrieved {
    participant: IParticipant;
}

export interface IParticipant {
    firstName?: string;
    lastName?: string;
    email?: string;
    company?: string;
}

export class CommandResultSurveyRetrieved implements ICommandResult {
    static readonly type = ECommandResultType.COMMAND_RESULT_SURVEY_RETRIEVED;

    readonly type: ECommandResultType = CommandResultSurveyRetrieved.type;
    readonly id: string;
    readonly timestamp: string;
    readonly command: ICommand;
    readonly status: number = 200;

    readonly participant: IParticipant;

    cookies: { [key: string]: string } = {};

    constructor(id: string, timestamp: string, command: ICommand, participant: IParticipant) {
        this.id = id;
        this.timestamp = timestamp;
        this.command = command;
        this.participant = participant;
    }
}
