import { ESupportedLanguage } from "4common-ts";
import CommandController, { EEndPointSecure } from "Common/command-controller";
import { UserData } from "Common/user-data";
import ControllerAbstract from "../../Common/Controllers/controller-abstract";

export default class DashboardAttendanceListController extends ControllerAbstract {
    static myName: string = "dashboard-attendance-list-controller";

    constructor(commandMediator: CommandController, userData: UserData, currentLang: ESupportedLanguage) {
        super(commandMediator, userData, DashboardAttendanceListController.myName, currentLang);
    }
}
