import { AttributeFactoryResult } from "./attribute-factory";

export interface IAttribute {
    key: EAkey;
    // Constant defined in attribute implementation use by the FrontEnd to filter attribute
    metaType: EMetaType;
    display: string;
    validate: (arg: AttributeFactoryResult) => void;
}

export enum EAkey {
    A_QUESTION_CHOICE_DISPLAY = "question-choice-display",
    A_QUESTION_MULTIPLE_CHOICE_DISPLAY = "question-multiple-choice-display",
    A_QUESTION_TEXT_DISPLAY = "question-text-display",
}

export enum EMetaType {
    FORMAT = "format",
    DISPLAY = "display",
    MULTIPLE_CHOICE_TYPE = "multiple-choice-type",
}

export enum EFormats {
    USER_EMAIL = "user-email", //use for the user who answer the survey
    EMAIL = "email", //use for texfield to enter email observers
    EMAIL_OBSERVER = "obs-email",
    DEFAULT = "default",
    DYNAMIC_EMAILS = "dynamic-emails",
}
