import React from "react";

import Container from "@material-ui/core/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Trans, withTranslation } from "react-i18next";

const ActuFooter: React.FunctionComponent = () => {
    return (
        <Container
            maxWidth="md"
            style={{
                backgroundColor: "#354189",
                color: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "50px",
                height: "50px",
            }}
        >
            <Box>
                <Typography align="center">
                    <Trans i18nKey="footer.actualisation_idh" />
                </Typography>
            </Box>
        </Container>
    );
};

export default withTranslation()(ActuFooter);
