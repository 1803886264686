import { UITokenBuyToken, ETokenType } from "4common-ts";
import * as EmailValidator from "email-validator";
import { TextField } from "@material-ui/core";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Trans } from "react-i18next";
import Typography from "@mui/material/Typography";
import BaseTabAbstract, { IBaseTabProps, IBaseTabState } from "Common/Components/tabs/base-tab-abstract";
import { EPrivillegeLevels } from "Common/privileges";
import DashboardAssignTokenToAnUserController, {
    BuyTokenWithParentId,
} from "User/Controllers/dashboard-assign-token-to-an-user-controller";
import { isDateFormatValid } from "Common/date";
import { IParticipantCsvFormat, ParticipantCsvReader } from "./Parts/participant-csv-reader";
import { EMAIL_SPLIT_CHAR, validateMultipleEmail } from "Common/email";

const SURVEY_SELECT_COLOR = "#CCFFE5";
const MISSING_SURVEY_SELECT_COLOR = "#ff4d4d";

interface IDashboardAssignTokenToAnUserProps extends IBaseTabProps {
    controller: DashboardAssignTokenToAnUserController;
}
interface IDashboardAssignTokenToAnUserState extends IBaseTabState {
    buyTokens: BuyTokenWithParentId[];
    selectedToken: BuyTokenWithParentId | undefined;
    emailUser: string;
    expirationDate: string;
    loadingToken: boolean;
    tokenFirstLoaded: boolean;
    isSurveyTokenCreated: boolean;
    hasUserEmailError: boolean;
}
export default class DashboardAssignTokenToAnUser extends BaseTabAbstract<
    DashboardAssignTokenToAnUserController,
    IDashboardAssignTokenToAnUserProps,
    IDashboardAssignTokenToAnUserState
> {
    static privillegeLevel = EPrivillegeLevels.ADMIN;
    static controllerType = DashboardAssignTokenToAnUserController;
    static title = "create-tokens";
    title = DashboardAssignTokenToAnUser.title;
    constructor(props: IDashboardAssignTokenToAnUserProps) {
        super(props);
        this.state = {
            ...this.state,
            buyTokens: [],
            selectedToken: undefined,
            emailUser: "",
            expirationDate: "",
            loadingToken: true,
            tokenFirstLoaded: false,
            isSurveyTokenCreated: false,
            hasUserEmailError: false,
        };
    }
    private fetchTokenData() {
        this.setState({ loadingToken: true, tokenFirstLoaded: true });
        return new Promise<void>(async (resolve) => {
            const uiTokens = (await this.controller.getTokenAsync(
                this.controller.userData.email!
            )) as BuyTokenWithParentId[];
            this.setState({ buyTokens: uiTokens, loadingToken: false, tokenFirstLoaded: true });
            resolve();
        });
    }

    private createSurveyToken() {
        if (this.state.selectedToken === undefined) return;

        return new Promise<void>(async (resolve) => {
            const expiration_date = this.state.expirationDate === "" ? undefined : this.state.expirationDate;
            const result = await this.controller.createSurveyTokenAsync(
                this.state.selectedToken!.id,
                this.state.emailUser.split(EMAIL_SPLIT_CHAR),
                expiration_date,
                this.state.selectedToken!.parentTokenId
            );
            if (result) {
                const index = this.state.buyTokens.findIndex(
                    (buyToken) => buyToken.id === this.state.selectedToken!.id
                );
                const uiTokens = this.state.buyTokens;
                // uiTokens[index].nbTokenUsed += 1;
                this.setState({ isSurveyTokenCreated: true, buyTokens: uiTokens });
                this.clearTextFields();
            }
            resolve();
        });
    }

    private getuiTokenSelectedTitle(): string {
        const buyToken = this.state.buyTokens.find((buyToken) => buyToken.id === this.state.selectedToken?.id);
        if (buyToken === undefined) return this.props.t("dashboard.create_token.no_buy_token_selected");
        else {
            return this.state.selectedToken!.id;
        }
    }
    private setSelectedToken = (tokenId: string) => {
        this.setState({
            selectedToken: this.state.buyTokens.find((bt) => bt.id === tokenId)!,
            isSurveyTokenCreated: false,
        });
    };
    private setEmailUser(emailUser: string) {
        this.setState({ emailUser });
    }

    private setDate(date: string) {
        this.setState({ expirationDate: date });
    }

    private showNbTokenUsed(buyToken: UITokenBuyToken): string {
        if (buyToken.type === ETokenType.STANDARD) return `${buyToken.nbTokenUsed}/${buyToken.nbTokenAllowed}`;
        else if (buyToken.type === ETokenType.MULTI) return "";
        return ""; // TODO: display how many tokens have been assign to observor
    }

    private showActions(token: UITokenBuyToken) {
        const isDisabled = token.type === ETokenType.STANDARD && token.nbTokenAllowed === token.nbTokenUsed;
        const text = isDisabled
            ? this.props.t("dashboard.create_token.no_more_token_allowed_available")
            : this.props.t("dashboard.button_names.select");
        return (
            <Button
                className="btn btn-primary"
                variant="contained"
                onClick={() => this.setSelectedToken(token.id)}
                disabled={isDisabled}
            >
                {text}
            </Button>
        );
    }

    private clearTextFields() {
        this.setState({
            selectedToken: undefined,
            emailUser: "",
            expirationDate: "",
        });
    }
    private setRowBackgroundColor(tokenId: string | undefined) {
        if (this.state.selectedToken && this.state.selectedToken.id === tokenId) return SURVEY_SELECT_COLOR;
        return "#E4E4F1";
    }

    private onCsvRead(_: string, emails: IParticipantCsvFormat[]) {
        const emailUser = emails.map((e) => e.email).join(EMAIL_SPLIT_CHAR);
        this.setState({ emailUser });
    }

    private isCreateTokenInfoValid(): [boolean, string, boolean, string, boolean, boolean] {
        var hasEmailError = false;
        var helperText = "";
        var hasDateFormatError = false;
        var dateHelperText = "";

        const noRowSelected = !this.state.selectedToken;

        if (this.state.emailUser !== "" && !validateMultipleEmail(this.state.emailUser)) {
            hasEmailError = true;
            helperText = this.props.t("dashboard.create_token.invalid_email");
        }
        if (!isDateFormatValid(this.state.expirationDate)) {
            hasDateFormatError = true;
            dateHelperText = this.props.t("dashboard.create_token.invalid_date_format");
        }

        const isButtonDisable = this.state.emailUser === "" || hasEmailError || hasDateFormatError || noRowSelected;

        return [hasEmailError, helperText, hasDateFormatError, dateHelperText, noRowSelected, isButtonDisable];
    }
    render() {
        if (!this.state.tokenFirstLoaded) this.fetchTokenData();

        const [hasEmailError, helperText, hasDateFormatError, dateHelperText, noRowSelected, isButtonDisable] =
            this.isCreateTokenInfoValid();

        return (
            <Box>
                <br />
                <br />
                <Typography variant="h5">
                    <Trans i18nKey="dashboard.create_token.buy_token_table_title" />
                </Typography>
                <TableContainer component={Paper} style={{ backgroundColor: "#E4E4F1" }}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                {tableColumNames.uiTokens.map((column_name) => {
                                    return (
                                        <TableCell align="center">
                                            <Typography fontWeight={700} fontSize="15px" fontStyle={"bold"}>
                                                <Trans i18nKey={column_name} />
                                            </Typography>
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.buyTokens.map((bt) => (
                                <TableRow
                                    key={bt.id}
                                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                    style={{ backgroundColor: this.setRowBackgroundColor(bt.id) }}
                                >
                                    <TableCell align="center" component="th" scope="row">
                                        {bt.id}
                                    </TableCell>
                                    <TableCell align="center">{bt.type}</TableCell>
                                    <TableCell align="center">{bt.description}</TableCell>
                                    <TableCell align="center">{bt.surveyInternalName}</TableCell>
                                    <TableCell align="center">{this.showNbTokenUsed(bt)}</TableCell>
                                    <TableCell align="center">{this.showActions(bt)}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <br />
                <br />
                <Typography variant="h5">{this.props.t("dashboard.create_token.buyToken_selected")}</Typography>
                <Box
                    component={Paper}
                    style={{ backgroundColor: noRowSelected ? MISSING_SURVEY_SELECT_COLOR : SURVEY_SELECT_COLOR }}
                >
                    <Typography variant="h5">{this.getuiTokenSelectedTitle()}</Typography>
                </Box>
                <br />
                <Typography variant="h5">{this.props.t("dashboard.create_token.asign_a_token_to_an_user")}</Typography>
                <br />
                <TextField
                    fullWidth
                    error={hasEmailError}
                    helperText={helperText}
                    label={this.props.t("dashboard.create_token.email_user")}
                    variant="outlined"
                    size="medium"
                    onChange={(event) => this.setEmailUser(event.target.value)}
                    value={this.state.emailUser}
                />
                <ParticipantCsvReader onSelect={(name, info) => this.onCsvRead(name, info)} />
                <br />
                <br />
                <Typography variant="h5">{this.props.t("dashboard.create_token.select_a_date")}</Typography>
                <TextField
                    fullWidth
                    error={hasDateFormatError}
                    helperText={dateHelperText}
                    label={this.props.t("dashboard.create_token.date_exemple")}
                    variant="outlined"
                    size="medium"
                    onChange={(event) => this.setDate(event.target.value)}
                    value={this.state.expirationDate}
                />
                <br />
                <br />
                <Button
                    className="btn btn-primary"
                    variant="contained"
                    onClick={() => this.createSurveyToken()}
                    disabled={isButtonDisable}
                >
                    {this.props.t("dashboard.button_names.send")}
                </Button>
                <br />
                <br />
                <Box>
                    <Typography variant="h5">
                        {this.state.isSurveyTokenCreated
                            ? this.props.t("dashboard.create_token.token_created_successfully")
                            : ""}
                        {this.state.hasUserEmailError ? this.props.t("dashboard.create_token.email_error") : ""}
                    </Typography>
                </Box>
            </Box>
        );
    }
}

const tableColumNames = {
    uiTokens: [
        "dashboard.buy_token_table_column_names.id",
        "dashboard.buy_token_table_column_names.survey_type",
        "dashboard.survey_token_table_column_names.description",
        "dashboard.buy_token_table_column_names.survey_internal_name",
        "dashboard.buy_token_table_column_names.NbTokenAllowed",
        "dashboard.survey_token_table_column_names.actions",
    ],
};
