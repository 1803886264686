import { EMetaType, IAttribute } from "4common-ts";

export const findAttribute = <attrType extends IAttribute>(
    attributes: Array<IAttribute>,
    metaType: EMetaType
): attrType | undefined => {
    const attr = attributes.find((attr) => attr.metaType === metaType);
    if (attr === undefined) return undefined;
    return attr as attrType;
};
