import { EServices, ICommand } from "../ICommand";
import { ECommandType } from "../../utils/enum-constants";

export interface ExtraArgsCommandGetQuestions {
    questionIds: Array<string>;
    lang: string;
}

export class CommandGetQuestions implements ICommand {
    static readonly type = ECommandType.COMMAND_GET_QUESTIONS;
    static readonly service: EServices = EServices.SURVEY;

    readonly id: string;
    readonly timestamp: string;
    readonly type: ECommandType = CommandGetQuestions.type;
    readonly service: EServices = CommandGetQuestions.service;
    readonly token: string;

    readonly questionIds: Array<string>;
    readonly lang: string;

    constructor(id: string, timestamp: string, token: string, questionIds: Array<string>, lang: string) {
        this.id = id;
        this.timestamp = timestamp;
        this.token = token;
        this.questionIds = questionIds;
        this.lang = lang;
    }
}
