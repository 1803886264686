import ControllerAbstract from "../../Common/Controllers/controller-abstract";

import CommandController from "../../Common/command-controller";
import { UserData } from "Common/user-data";
import { ESupportedLanguage } from "4common-ts";

export default class AfterRegistrationController extends ControllerAbstract {
    static myName: string = "after-registration-controller";

    constructor(commandMediator: CommandController, userData: UserData, currentLang: ESupportedLanguage) {
        super(commandMediator, userData, AfterRegistrationController.myName, currentLang);
    }
}
