import { LDescriptionAbstract, EDescriptionType, LHtmlDescription, LSimpleDescription } from "4common-ts";
import Box from "@mui/material/Box";

export const displayDescription = (description: LDescriptionAbstract, isMandatory: boolean = false): JSX.Element => {
    const descriptionType = description.type;
    switch (descriptionType) {
        case EDescriptionType.L_SIMPLE_DESCRIPTION: {
            return (
                <p>
                    {(description as LSimpleDescription).text}
                    {isMandatory ? "*" : ""}
                </p>
            );
        }
        case EDescriptionType.L_HTML_DESCRIPTION: {
            return (
                <Box
                    marginTop={0}
                    style={{
                        textAlign: "justify",
                    }}
                    dangerouslySetInnerHTML={{ __html: (description as LHtmlDescription).text }}
                />
            );
        }
        default: {
            throw Error(`Unknown type ofdescription [${descriptionType}]`);
        }
    }
};
