import { EServices, ICommand } from "../ICommand";
import { ECommandType } from "../../utils/enum-constants";

export interface ExtraArgsCommandGetUserProfile {
    email: string;
}

export class CommandGetUserProfile implements ICommand {
    static readonly type = ECommandType.COMMAND_GET_USER_PROFILE;
    static readonly service: EServices = EServices.USER;

    readonly id: string;
    readonly timestamp: string;
    readonly type: ECommandType = CommandGetUserProfile.type;
    readonly service: EServices = CommandGetUserProfile.service;
    readonly token: string; //for now dont usefull for 4BeUser

    readonly email: string;

    constructor(id: string, timestamp: string, email: string) {
        this.id = id;
        this.timestamp = timestamp;
        this.token = "";
        this.email = email.toLowerCase();
    }
}
