import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { TextField } from "@material-ui/core";
import * as EmailValidator from "email-validator";
import { Trans } from "react-i18next";

const NONE = "none";
const EMPTYFIELD = "";
const SEPARATOR = ";";

enum ETextColor {
    DEFAULT = "black",
    ERROR = "red",
}
enum EButtonColor {
    ADD = "green",
    DELETE = "red",
    CONFIRM = "blue",
    MODIFY = "blue",
}

enum EButtonAction {
    ADD = "+",
    DELETE = "-",
}
export interface IGenerateDynamicEmailsTextFieldsProps {
    emails: string[];
    textfielsHaveLimitedNumber: boolean;
    minTextfieldNumber: number;
    maxTexfieldsNumber: number;
    onChangeEmail: (newString: string) => void;
}

interface IGenerateDynamicEmailsTextFieldsState {
    emails: string[];
    textfielsHaveLimitedNumber: boolean;
    minTextfieldNumber: number;
    maxTexfieldsNumber: number;
    emailsAreValid: boolean;
}

class Textfields extends React.Component<IGenerateDynamicEmailsTextFieldsProps, IGenerateDynamicEmailsTextFieldsState> {
    constructor(props: IGenerateDynamicEmailsTextFieldsProps) {
        super(props);
        this.state = {
            emails: this.props.emails,
            textfielsHaveLimitedNumber: this.props.textfielsHaveLimitedNumber,
            emailsAreValid: false,
            minTextfieldNumber: this.props.minTextfieldNumber,
            maxTexfieldsNumber: this.props.maxTexfieldsNumber,
        };
    }

    private initializeMinDynamicEmailsTextFieldsNumber() {
        const emails = this.state.emails;

        while (this.state.emails.length !== this.state.minTextfieldNumber) {
            emails.push(EMPTYFIELD);
            this.setState({ emails });
        }
    }

    private validateEmails(emailsAreValid: boolean) {
        this.setState({ emailsAreValid });
    }

    private addEmail(email: string) {
        const emails = this.state.emails;
        if (this.state.textfielsHaveLimitedNumber) {
            if (this.state.emails.length <= this.state.maxTexfieldsNumber) {
                emails.push(email);
                this.setState({ emails });
            }
        } else {
            emails.push(email);
            this.setState({ emails });
        }
    }

    private retrieveLastEmail() {
        const emails = this.state.emails;
        emails.pop();
        this.setState({ emails });
    }

    private setEmail(email: string, index: number) {
        var emails = this.state.emails;
        emails[index] = email;
        this.setState({ emails });
    }

    private canAddDynamicEmailsTextFields() {
        return this.state.textfielsHaveLimitedNumber ? this.state.emails.length < this.state.maxTexfieldsNumber : true;
    }
    private canRetrieveDynamicEmailsTextFields() {
        return this.state.emails.length > this.state.minTextfieldNumber;
    }

    private getEmailsToOneString() {
        var str = EMPTYFIELD;

        this.state.emails.forEach((email) => {
            if (EmailValidator.validate(email)) str = str + SEPARATOR + email;
        });

        return str;
    }

    private allEmailsAreValid() {
        if (this.state.emails.includes(EMPTYFIELD)) return false;
        else if (this.findDuplicateEmail() !== NONE) return false;
        else return !this.state.emails.find((email) => !EmailValidator.validate(email));
    }

    private findDuplicateEmail() {
        var duplicate = NONE;

        this.state.emails.forEach((email) => {
            if (email !== EMPTYFIELD) {
                if (this.state.emails.filter((duplicate) => duplicate === email).length > 1) duplicate = email;
            }

            if (duplicate !== NONE) return;
        });

        return duplicate;
    }

    render() {
        if (this.state.emails.length < this.state.minTextfieldNumber) this.initializeMinDynamicEmailsTextFieldsNumber();

        return this.state.emailsAreValid ? (
            <Box>
                {this.state.emails.map((email, index) => {
                    return (
                        <Box sx={{ color: EmailValidator.validate(email) ? ETextColor.DEFAULT : ETextColor.ERROR }}>
                            <Typography align="center" variant="h6">
                                <Trans i18nKey="dynamic_textfields.email" /> {` ${index + 1}: ${email}`}
                            </Typography>
                            <br />
                        </Box>
                    );
                })}
                <Button
                    className="btn btn-primary"
                    variant="contained"
                    sx={{ backgroundColor: EButtonColor.MODIFY }}
                    onClick={() => {
                        this.validateEmails(false);
                    }}
                >
                    <Trans i18nKey="dynamic_textfields.modify" />
                </Button>
            </Box>
        ) : (
            <Box>
                {this.state.emails.map((email, index) => {
                    return (
                        <Box>
                            <TextField
                                fullWidth
                                error={!EmailValidator.validate(email)}
                                helperText={
                                    !EmailValidator.validate(email) ? (
                                        <Trans i18nKey="dynamic_textfields.email_is_not_valid" />
                                    ) : (
                                        EMPTYFIELD
                                    )
                                }
                                label={<Trans i18nKey="dynamic_textfields.email" />}
                                variant="outlined"
                                size="medium"
                                onChange={(event) => {
                                    this.setEmail(event.target.value, index);
                                    this.props.onChangeEmail(this.getEmailsToOneString());
                                }}
                                value={email}
                            />
                            <br />
                            <br />
                        </Box>
                    );
                })}
                <Typography align="center" variant="h6">
                    <Trans i18nKey="dynamic_textfields.minimum_fields" /> {this.state.minTextfieldNumber}.
                </Typography>
                <br />
                {this.state.textfielsHaveLimitedNumber === false ? (
                    EMPTYFIELD
                ) : (
                    <Typography align="center" variant="h6">
                        <Trans i18nKey="dynamic_textfields.maximum_fields" /> {this.state.maxTexfieldsNumber}.
                    </Typography>
                )}
                <br />
                <Button
                    className="btn btn-primary"
                    variant="contained"
                    sx={{ backgroundColor: EButtonColor.ADD }}
                    onClick={() => {
                        this.addEmail(EMPTYFIELD);
                        this.props.onChangeEmail(this.getEmailsToOneString());
                    }}
                    disabled={!this.canAddDynamicEmailsTextFields() || !this.allEmailsAreValid()}
                >
                    {EButtonAction.ADD}
                </Button>
                <Button
                    className="btn btn-primary"
                    variant="contained"
                    sx={{ backgroundColor: EButtonColor.DELETE }}
                    onClick={() => {
                        this.retrieveLastEmail();
                        this.props.onChangeEmail(this.getEmailsToOneString());
                    }}
                    disabled={!this.canRetrieveDynamicEmailsTextFields()}
                >
                    {EButtonAction.DELETE}
                </Button>
                <Button
                    className="btn btn-primary"
                    variant="contained"
                    sx={{ backgroundColor: EButtonColor.CONFIRM }}
                    onClick={() => {
                        this.props.onChangeEmail(this.getEmailsToOneString());
                        this.validateEmails(true);
                    }}
                    disabled={!this.allEmailsAreValid()}
                >
                    <Trans i18nKey="dynamic_textfields.confirm" />
                </Button>
                <br />
                <Box sx={{ color: "red" }}>
                    {this.findDuplicateEmail() === NONE ? (
                        EMPTYFIELD
                    ) : (
                        <Typography align="center" variant="h6">
                            <Trans i18nKey="dynamic_textfields.duplicate" /> {this.findDuplicateEmail()}
                        </Typography>
                    )}

                    <br />
                </Box>
            </Box>
        );
    }
}

export default Textfields;
