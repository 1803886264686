import Cookies from "universal-cookie";

import {
    CommandFactory,
    CommandGenerateDynamicToken,
    CommandGetDescriptionSurvey,
    CommandGetSurveyAvailableLanguages,
    CommandResultDynamicToken,
    CommandResultError,
    CommandResultGetDescriptionSurvey,
    CommandResultGetSurveyAvailableLanguages,
    ESupportedLanguage,
    ExtraArgsCommandGenerateDynamicToken,
    ExtraArgsCommandGetDescriptionSurvey,
    ExtraArgsCommandGetSurveyAvailableLanguages,
    LDescriptionSurvey,
} from "4common-ts";

import ControllerAbstract from "../../Common/Controllers/controller-abstract";
import CommandController, { EEndPointSecure } from "../../Common/command-controller";
import { UserData } from "Common/user-data";

const cookies: Cookies = new Cookies();

export default class InstructionController extends ControllerAbstract {
    static myName: string = "instruction-controller";

    private surveyStatic: boolean;
    private surveyName: string = "";

    constructor(commandMediator: CommandController, userData: UserData, currentLang: ESupportedLanguage, surveyStatic: boolean = false) {
        super(commandMediator, userData, InstructionController.myName, currentLang);
        this.surveyStatic = surveyStatic;
    }

    /**
     *
     * @param lang
     * @returns
     */
    requestDescriptionSurveyAsync(lang: ESupportedLanguage): Promise<LDescriptionSurvey> {
        return new Promise<LDescriptionSurvey>(async (resolve, reject) => {

            if(this.surveyStatic) {
                const ckie_token = cookies.get(`token_${this.surveyName}`);
                if(ckie_token) {
                    this.setSurveyToken(ckie_token);
                }
                else{
                    const commandGenerateToken = CommandFactory.create<ExtraArgsCommandGenerateDynamicToken>(CommandGenerateDynamicToken.type, "", {
                        surveyName: this.surveyName
                    });

                    const resultGenerateToken = await this.sendCommandAsync(commandGenerateToken, EEndPointSecure.PUBLIC);

                    if (resultGenerateToken === undefined) {
                        throw Error("Something went wrong while requesting survey.");
                    } else if (resultGenerateToken.type === CommandResultError.type) {
                        throw Error((resultGenerateToken as CommandResultError).error.toString());
                    }

                    this.setSurveyToken((resultGenerateToken as CommandResultDynamicToken).token);
                    cookies.set(`token_${this.surveyName}`, this.token, { path: "/" });
                }
            }

            try {
                const command = CommandFactory.create<ExtraArgsCommandGetDescriptionSurvey>(
                    CommandGetDescriptionSurvey.type,
                    this.token,
                    {
                        surveyStatic: this.surveyStatic,
                        lang,
                    }
                );

                const result = await this.sendCommandAsync(command, EEndPointSecure.PUBLIC);

                if (result === undefined) {
                    throw Error("Something went wrong while requesting survey.");
                } else if (result.type === CommandResultError.type) {
                    throw Error((result as CommandResultError).error.toString());
                }

                if (!this.isValideRequestResult(result, lang)) {
                    reject();
                    return;
                }

                const descriptionSurvey = (result as CommandResultGetDescriptionSurvey).descriptionSurvey;
                resolve(descriptionSurvey);
            } catch (e: any) {
                this.logError(e);
                reject();
            }
        });
    }

    requestAvailableLanguageAsync(lang: ESupportedLanguage): Promise<Array<ESupportedLanguage>> {
        return new Promise<Array<ESupportedLanguage>>(async (resolve, reject) => {
            try {
                const command = CommandFactory.create<ExtraArgsCommandGetSurveyAvailableLanguages>(
                    CommandGetSurveyAvailableLanguages.type,
                    this.token
                );

                const result = await this.sendCommandAsync(command, EEndPointSecure.PUBLIC);
                if (result === undefined) {
                    throw Error("Something went wrong while requesting available languages.");
                } else if (result.type === CommandResultError.type) {
                    throw Error((result as CommandResultError).error.toString());
                }

                if (!this.isValideRequestResult(result, lang)) {
                    reject();
                    return;
                }

                const availableLanguages = (result as CommandResultGetSurveyAvailableLanguages).availableLanguages;
                resolve(availableLanguages.map((l) => ESupportedLanguage[l]));
            } catch (e: any) {
                this.logError(e);
                reject();
            }
        });
    }

    setSurveyName(surveyName: string) {
        this.surveyName = surveyName;
    }

    toSurvey(lang: ESupportedLanguage) {
        if(this.surveyStatic) {
            this.changeLocation(`survey/static/${this.surveyName}`, lang, false);
        } else {
            this.changeLocation("survey", lang, true);
        }
    }
}
