import {
    CommandFactory,
    CommandGetMyTokens,
    CommandResultError,
    CommandResultMyTokens,
    ECommandError,
    EServices,
    ESupportedLanguage,
    ETokens,
    ExtraArgsCommandGetMyTokens,
    UITokenBase,
} from "4common-ts";
import CommandController, { EEndPointSecure } from "Common/command-controller";
import { UserData } from "Common/user-data";
import ControllerAbstract from "../../Common/Controllers/controller-abstract";

export default class DashboardTokenManagerController extends ControllerAbstract {
    static myName: string = "dashboard-token-manager-controller";

    constructor(commandMediator: CommandController, userData: UserData, currentLang: ESupportedLanguage) {
        super(commandMediator, userData, DashboardTokenManagerController.myName, currentLang);
    }

    async getTokenAsync(email: string): Promise<Array<UITokenBase>> {
        return new Promise<Array<UITokenBase>>(async (resolve, reject) => {
            const command = CommandFactory.create<ExtraArgsCommandGetMyTokens>(CommandGetMyTokens.type, this.token, {
                tokenType: [ETokens.ALL],
                tokens: [""], //TODO: A way to get all token and another to have specific tokens list
                email, //email of the user who is log-in
                service: EServices.USER,
                keySeach: "", //TODO: A way to search a token by a keySearch like email or surveyId
            });

            const result = await this.sendCommandAsync(command, EEndPointSecure.PROTECTED);
            if (result === undefined) throw Error("Something went wrong while request Token to dashboard.");
            if (result.type === CommandResultError.type) {
                reject((result as CommandResultError).error);
                return;
            } else if (result.type !== CommandResultMyTokens.type) {
                reject(ECommandError.UNKNOWN);
                return;
            }

            const tokens = (result as CommandResultMyTokens).tokens;

            resolve(tokens);
        });
    }
}
