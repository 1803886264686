import { QuestionAnswerAbstractModel, allQuestionAnswerTypes } from "./question-answer-abstract";
import { ESurveyModelType } from "./utils/enum-constants";

export class QuestionAnswerMultipleChoiceModel extends QuestionAnswerAbstractModel {
    static type: ESurveyModelType = ESurveyModelType.QUESTION_ANSWER_MULTIPLE_CHOICE;

    choice_ids: Array<string>;

    constructor(id: string, question_type: string, question_id: string, survey_token_id: string, choice_ids: Array<string>, extra?: string | undefined | null) {
        super(id, QuestionAnswerMultipleChoiceModel.type, question_type, question_id, survey_token_id, extra);

        this.choice_ids = choice_ids;
    }
}

allQuestionAnswerTypes.push(QuestionAnswerMultipleChoiceModel.type);
