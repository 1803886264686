export enum ESexes {
    MALE = "male",
    FEMALE = "female",
    X = "x",
}
export enum EPrivileges {
    EMPLOYEE = "employee",
    MANAGER = "manager",
    FORMER = "former",
    ADMIN = "admin",
}

export enum ELogInErrors {
    WRONG_EMAIL = "wrong-email",
    WRONG_PASSWORD = "wrong-password",
}

export enum ESignInErrors {
    WRONG_EMAIL = "wrong-email",
    EXISTING_EMAIL = "existing-email",
    INVALID_PASSWORD = "invalid-password",
}

export enum EConnectionResults {
    SUCCESSFUL_AUTHENTIFICATION = "successful-authentication",
    FAILED_AUTHENTIFICATION = "failed-authentication",
}

export enum EUserModelType {
    BUY_TOKEN = "buy-token",
    CONNECTION_ATTEMPED = "connection-attempted",
    SURVEY_TOKEN_USER = "survey-token-user",
    USER_INFORMATION = "user-information",
    USER = "user",
    SESSION = "session",
}
export const findEUserModelTypeMember = (modelType: string): EUserModelType | undefined => {
    return Object.values(EUserModelType).find((e) => e === modelType);
};
