import { ICommand } from "../../command-model/ICommand";
import { ICommandResult } from "../ICommandResult";
import { ECommandResultType } from "../../utils/enum-constants";

export interface ExtraArgsCommandResultTokenNoMoreValid {}

export class CommandResultTokenNoMoreValid implements ICommandResult {
    static readonly type = ECommandResultType.COMMAND_RESULT_TOKEN_NO_MORE_VALID;

    readonly type: ECommandResultType = CommandResultTokenNoMoreValid.type;
    readonly id: string;
    readonly timestamp: string;
    readonly command: ICommand;
    readonly status: number = 200;

    cookies: { [key: string]: string } = {};

    constructor(id: string, timestamp: string, command: ICommand) {
        this.id = id;
        this.timestamp = timestamp;
        this.command = command;
    }
}
