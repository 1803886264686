import { ESupportedLanguage } from "4common-ts";
import { Component } from "react";

export interface IBaseProps<E = any> {
    controller: E;
    onselectlanguage: (lang: ESupportedLanguage | string) => void;
    selectlang: ESupportedLanguage; // FIXME: Maybe this information should be in the controller
    t: (key: string) => string;
}

export interface IBaseState {
    hasError: boolean;
}

export default class ActuComponentBase<E, P extends IBaseProps<E>, S extends IBaseState, C = false> extends Component<
    P,
    S
> {
    protected controller: E;
    constructor(props: P) {
        super(props);
        this.controller = props.controller;
    }

    onError(message: string) {
        console.error(message);
    }
}
