import { EServices, ICommand } from "../ICommand";
import { ECommandType } from "../../utils/enum-constants";

export interface ExtraArgsCommandCreateSurveyToken {
    surveyId: string;
    expiration_date?: string;
    parent_survey_token_id?: string;
}

export class CommandCreateSurveyToken implements ICommand {
    static readonly type = ECommandType.COMMAND_CREATE_SURVEY_TOKENS;
    static readonly service: EServices = EServices.SURVEY;

    readonly id: string;
    readonly timestamp: string;
    readonly type: ECommandType = CommandCreateSurveyToken.type;
    readonly service: EServices = CommandCreateSurveyToken.service;
    readonly token: string;

    readonly surveyId: string;
    readonly expiration_date?: string;
    readonly parent_survey_token_id?: string;

    constructor(id: string, timestamp: string, token: string, surveyId: string, expiration_date?: string, parent_survey_token_id?: string) {
        this.id = id;
        this.timestamp = timestamp;
        this.token = token;
        this.expiration_date = expiration_date;
        this.parent_survey_token_id = parent_survey_token_id;
        this.surveyId = surveyId;
    }
}
