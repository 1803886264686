export class QuestionIdentifier {
    readonly questonId: string;
    readonly exposeName?: string;
    readonly favoreMethod: EQIFavorMethod;

    constructor(favorMethod: EQIFavorMethod, questonId: string, exposeName?: string) {
        this.favoreMethod = favorMethod;
        this.questonId = questonId;
        this.exposeName = exposeName;
    }
}

export enum EQIFavorMethod {
    QUESTION_ID = "question-id",
    EXPOSE_NAME = "expose-name",
}
