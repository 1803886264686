import { ESupportedLanguage } from "../../language";

export const findELangMember = (language: string): ESupportedLanguage | undefined => {
    return Object.values(ESupportedLanguage).find((e) => e === language);
};

export enum ECommandType {
    EMPTY = "empty",
    COMMAND_ANSWER_CHOICE_QUESTION = "answer-choice-question",
    COMMAND_ANSWER_MULTIPLE_CHOICE_QUESTION = "answer-multiple-choice-question",
    COMMAND_ANSWER_TEXT_QUESTION = "answer-text-question",
    COMMAND_CREATE_TOKENS = "create-tokens",
    COMMAND_END_OF_SURVEY = "end-of-survey",
    COMMAND_FILL_PARTICIPANTS_INFO = "fill-participants-info",
    COMMAND_GET_ANSWERS = "get-answers",
    COMMAND_GET_DESCRIPTION_SURVEY = "get-description-survey",
    COMMAND_GET_PAGE = "get-page",
    COMMAND_GET_QUESTIONS = "get-questions",
    COMMAND_GET_SURVEY = "get-survey",
    COMMAND_GET_SURVEY_AVAILABLE_LANGUAGES = "get-survey-available-languages",
    COMMAND_NEXT_QUESTION = "next-question",
    COMMAND_MISSING_ANSWER = "missing-answer",
    COMMAND_RETRIEVE_SURVEY_RESULT = "retrieve-survey-result",
    COMMAND_LOG_IN = "log-in",
    COMMAND_SIGN_IN = "sign-in",
    COMMAND_BUY_TOKEN = "buy-token",
    COMMAND_ACTIVE_BUY_TOKEN = "active-buy-token",
    COMMAND_GET_USER_PROFILE = "get-user-profile",
    COMMAND_GET_MY_TOKENS = "get-my-tokens",
    COMMAND_GET_SURVEY_INFO = "get-survey-info",
    COMMAND_GET_ALL_SURVEY_NAMES = "get-all-survey-names",
    COMMAND_GET_USER_SURVEY_TOKEN = "get-user-survey-token",
    COMMAND_GET_MY_SURVEY_TOKENS = "get-my-survey-tokens",
    COMMAND_GET_MY_BUY_TOKENS = "get-my-buy-tokens",
    COMMAND_GET_SURVEY_STATIC_ID_BY_NAME = "get-survey-static-id-by-name",
    COMMAND_CREATE_USER_SURVEY_TOKEN = "create-user-survey-token",
    COMMAND_CREATE_SURVEY_TOKENS = "create-survey-tokens",
    COMMAND_CREATE_OBSERVER_SURVEY_TOKENS = "create-oberser-survey-tokens",
    COMMAND_SEND_EMAIL_WITH_TOKEN_TO_OBSERVER = "send-email-with-token-to-observer",
    COMMAND_SEND_EMAIL_CONFIRMATION_TO_AUTO = "send-email-confirmation-to-auto",
    COMMAND_MODIFY_EXPIRATION_DATE = "modify-expiration-date",
    COMMAND_CREATE_USER = "create-user",
    COMMAND_GENERATE_REPORT = "generate-report",
    COMMAND_GENERATE_DYNAMIC_TOKEN = "generate-dynamic-token",
    COMMAND_GET_INDICATORS = "get-indicators",
    COMMAND_CREATE_SESSION = "create-session",
    COMMAND_ASSIGN_A_BUY_TOKEN_TO_A_SESSION = "assign-a-buy-token-to-a-session",
    COMMAND_GET_MY_SESSIONS = "get-my-sessions",
    COMMAND_GET_TOKEN_INFOS = "get-token-infos",
}
export const findECommandTypeMember = (commandType: string): ECommandType | undefined => {
    return Object.values(ECommandType).find((e) => e === commandType);
};

export enum ECommandResultType {
    COMMAND_RESULT_CREATE_OBSERVER_SURVEY_TOKENS = "result-create-observer-survey-tokens",
    COMMAND_RESULT_ACCESS_DENIED = "result-access-denied",
    COMMAND_RESULT_END_OF_SURVEY = "result-end-of-survey",
    COMMAND_RESULT_ERROR = "result-error",
    COMMAND_RESULT_GET_ANSWERS = "result-get-answers",
    COMMAND_RESULT_GET_DESCRIPTION_SURVEY = "result-get-description-survey",
    COMMAND_RESULT_GET_PAGE = "result-get-page",
    COMMAND_RESULT_GET_QUESTIONS = "result-get-questions",
    COMMAND_RESULT_GET_SURVEY = "result-get-survey",
    COMMAND_RESULT_GET_SURVEY_AVAILABLE_LANGUAGES = "result-get-survey-available-languages",
    COMMAND_RESULT_SUCCESS = "result-success",
    COMMAND_RESULT_SURVEY_NOT_COMPLETED = "result-survey-not-completed",
    COMMAND_RESULT_SURVEY_RETRIEVED = "result-survey-retrieved",
    COMMAND_RESUTL_TOKEN_GENERATED = "result-token-generated",
    COMMAND_RESULT_TOKEN_NO_MORE_VALID = "result-token-no-more-valid",
    COMMAND_RESULT_OBSERVERS_TOKENS_STILL_VALID = "result-observers-tokens-still-valid",
    COMMAND_RESULT_FIELDS_ERRORS = "result-fields-errors",
    COMMAND_RESULT_USER_PROFILE = "result-user-profile",
    COMMAND_RESULT_MY_TOKENS = "result-my-tokens",
    COMMAND_RESULT_SURVEY_INFOS = "result-survey-infos",
    COMMAND_RESULT_SURVEY_NAMES = "result-survey-names",
    COMMAND_RESULT_MY_SURVEY_TOKENS = "result-get-my-survey-tokens",
    COMMAND_RESULT_DYNAMIC_TOKEN = "result-get-dynamic-token",
    COMMAND_RESULT_SURVEY_ID = "result-survey-id",
    COMMAND_GET_INDICATORS = "get-indicators",
    COMMAND_RESULT_CREATE_SESSION = "result-create-session",
    COMMAND_RESULT_GET_SURVEY_TOKEN_INFOS = "result-get-token-infos",
}
export const findECommandResultTypeMember = (commandResultType: string): ECommandResultType | undefined => {
    return Object.values(ECommandResultType).find((e) => e === commandResultType);
};
