import { LQuestionAbstract, QuestionAbstract } from "4common-ts";

export const getQuestionFromPageById = (
    questionId: string,
    questions?: Array<LQuestionAbstract<QuestionAbstract>>
): LQuestionAbstract<QuestionAbstract> | undefined => {
    if (questions === undefined) return undefined;
    return questions.find((question) => question.question.id === questionId);
};

export const getQuestionLayoutFromPageById = (
    questionId: string,
    questions?: Array<LQuestionAbstract<QuestionAbstract>>
): LQuestionAbstract<QuestionAbstract> | undefined => {
    if (questions === undefined) return undefined;
    return questions.find((question) => question.question.id === questionId);
};
