import { ContainerAbstract } from "../container-abstract";
import { LQuestionAbstract } from "./question/question-abstract";
import { IAttribute } from "../attribute/attribute";
import { ELPageType } from "../utils/enum-constants";
import { QuestionAbstract } from "src/model/survey/question-abstract";
import { HookAbstract } from "../hook/hook-abstract";
import { LDescriptionAbstract } from "../description/description-abstract";
import { LPageTitle } from "./page-title";

// TODO: if a abstract choice is added, set it in LQuestionAbstract template
export abstract class LPageAbstract extends ContainerAbstract<LQuestionAbstract<QuestionAbstract>, ELPageType> {
    static type: ELPageType = ELPageType.L_ABSTRACT;
    id: string;
    pageTitle?: LPageTitle;
    areTheQuestionsRandom?: boolean;

    constructor(
        type: ELPageType,
        attributes: Array<IAttribute>,
        hooks: Array<HookAbstract>,
        descriptions: Array<LDescriptionAbstract>,
        containers: Array<LQuestionAbstract<QuestionAbstract>>,
        id: string,
        areTheQuestionsRandom?: boolean,
        pageTitle?: LPageTitle
    ) {
        super(type, attributes, hooks, descriptions, containers);
        this.id = id;
        this.pageTitle = pageTitle;
        this.areTheQuestionsRandom = areTheQuestionsRandom;
    }
}
