import { ICommandResult } from "../ICommandResult";
import { ECommandResultType } from "../../utils/enum-constants";
import { ICommand } from "src/command/command-model/ICommand";
import { IndicatorModel } from "src/model/survey/indicator";

export interface ExtraArgsCommandResultGetIndicators {
    indicators: IndicatorModel[];
}

export class CommandResultGetIndicators implements ICommandResult {
    static readonly type = ECommandResultType.COMMAND_GET_INDICATORS;

    readonly type: ECommandResultType = CommandResultGetIndicators.type;
    readonly id: string;
    readonly timestamp: string;
    readonly command: ICommand;
    readonly status: number = 200;

    readonly indicators: IndicatorModel[];

    cookies: { [key: string]: string } = {};

    constructor(id: string, timestamp: string, command: ICommand, indicators: IndicatorModel[]) {
        this.id = id;
        this.timestamp = timestamp;
        this.command = command;
        this.indicators = indicators;
    }
}
