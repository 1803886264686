import {
    CommandFactory,
    CommandResultError,
    CommandResultMyTokens,
    ECommandError,
    UITokenBase,
    CommandGetMyTokens,
    ExtraArgsCommandGetMyTokens,
    CommandCreateUserSurveyToken,
    ExtraArgsCommandCreateUserSurveyToken,
    CommandResultSuccess,
    ETokens,
    EServices,
    ESupportedLanguage,
    ExtraArgsCommandCreateSession,
    CommandGetAllSurveyNames,
    CommandResultSurveyNames,
    ESurveyType,
    ExtraArgsCommandBuyToken,
    ExtraArgsCommandGetAllSurveyNames,
    ISurveyInfos,
    CommandCreateSession,
} from "4common-ts";
import CommandController, { EEndPointSecure } from "Common/command-controller";
import { UserData } from "Common/user-data";
import ControllerAbstract from "../../Common/Controllers/controller-abstract";

export default class DashboardTrainingSessionController extends ControllerAbstract {
    static myName: string = "dashboard-training-session-controller";

    constructor(commandMediator: CommandController, userData: UserData, currentLang: ESupportedLanguage) {
        super(commandMediator, userData, DashboardTrainingSessionController.myName, currentLang);
    }

    fetchAllSurveysDetailsAsync = async (): Promise<ISurveyInfos[]> => {
        return new Promise<ISurveyInfos[]>(async (resolve, reject) => {
            const commandGetAllSurveyInfo = CommandFactory.create<ExtraArgsCommandGetAllSurveyNames>(
                CommandGetAllSurveyNames.type,
                "",
                {
                    select: [ESurveyType.AUTO, ESurveyType.STANDARD, ESurveyType.MULTI],
                    lang: this.currentLang,
                }
            );

            const result = await this.sendCommandAsync(commandGetAllSurveyInfo, EEndPointSecure.PUBLIC);
            if (result === undefined) throw Error("Something went wrong while requesting surveys details.");
            if (result.type === CommandResultError.type) {
                reject((result as CommandResultError).error);
                return;
            } else if (result.type !== CommandResultSurveyNames.type) {
                reject(ECommandError.UNKNOWN);
                return;
            }

            resolve((result as CommandResultSurveyNames).surveyInfos.sort());
        });
    };

    async getCreateSession(
        start_date: string,
        end_date: string,
        code_session: string,
        survey_id: string
    ): Promise<boolean> {
        return new Promise<boolean>(async (resolve, reject) => {
            const command = CommandFactory.create<ExtraArgsCommandCreateSession>(
                CommandCreateSession.type,
                this.token,
                {
                    survey_id,
                    email: this.userData.email!,
                    start_date,
                    end_date,
                    code_session,
                }
            );

            const result = await this.sendCommandAsync(command, EEndPointSecure.PROTECTED);
            if (result === undefined) throw Error("Something went wrong while request Token to dashboard.");
            if (result.type === CommandResultError.type) {
                resolve(false);
                return;
            } else if (result.type !== CommandResultSuccess.type) {
                resolve(false);
                return;
            }

            resolve(true);
        });
    }
}
