import { EServices, ICommand } from "../ICommand";
import { ECommandType } from "../../utils/enum-constants";

export interface ExtraArgsCommandGetAnswers {
    questionIds: Array<string>;
}

export class CommandGetAnswers implements ICommand {
    static readonly type = ECommandType.COMMAND_GET_ANSWERS;
    static readonly service: EServices = EServices.SURVEY;

    readonly id: string;
    readonly timestamp: string;
    readonly type: ECommandType = CommandGetAnswers.type;
    readonly service: EServices = CommandGetAnswers.service;
    readonly token: string;

    readonly questionIds: Array<string>;

    constructor(id: string, timestamp: string, token: string, questionIds: Array<string>) {
        this.id = id;
        this.timestamp = timestamp;
        this.token = token;
        this.questionIds = questionIds;
    }
}
