import { LQuestionAbstract } from "./question-abstract";
import { IAttribute } from "../../attribute/attribute";
import { QuestionChoiceModel } from "../../../model/survey/question-choice";
import { ELQuestionType } from "../../utils/enum-constants";
import { HookAbstract } from "src";
import { LDescriptionAbstract } from "../../description/description-abstract";
import { LChoiceAbstract } from "./choice/choice-abstract";

export class LMultipleChoiceQuestion extends LQuestionAbstract<QuestionChoiceModel, LChoiceAbstract<any>> {
    static type = ELQuestionType.L_QUESTION_MULTIPLE_CHOICE;

    constructor(
        attributes: Array<IAttribute>,
        hooks: Array<HookAbstract>,
        question: QuestionChoiceModel,
        descriptions: Array<LDescriptionAbstract>,
        choices: Array<LChoiceAbstract<any>>
    ) {
        super(LMultipleChoiceQuestion.type, attributes, hooks, question, descriptions, choices);
    }
}
