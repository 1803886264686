import { ICommandResult } from "../ICommandResult";
import { QuestionAnswerAbstractModel } from "../../../model/survey/question-answer-abstract";
import { ECommandResultType } from "../../utils/enum-constants";
import { ICommand } from "src/command/command-model/ICommand";

export interface ExtraArgsCommandResultGetAnswers {
    questionAnswerModels: Array<QuestionAnswerAbstractModel>;
}

export class CommandResultGetAnswers implements ICommandResult {
    static readonly type = ECommandResultType.COMMAND_RESULT_GET_ANSWERS;

    readonly type: ECommandResultType = CommandResultGetAnswers.type;
    readonly id: string;
    readonly timestamp: string;
    readonly command: ICommand;
    readonly status: number = 200;

    readonly questionAnswerModels: Array<QuestionAnswerAbstractModel>;

    cookies: { [key: string]: string } = {};

    constructor(id: string, timestamp: string, command: ICommand, questionAnswerAbstractModels: Array<QuestionAnswerAbstractModel>) {
        this.id = id;
        this.timestamp = timestamp;
        this.command = command;
        this.questionAnswerModels = questionAnswerAbstractModels;
    }
}
