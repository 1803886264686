import { Trans } from "react-i18next";
import * as EmailValidator from "email-validator";

import ActuComponentBase, { IBaseProps, IBaseState } from "Common/Components/actu-component-base";
import CommonPageFrame from "Common/Components/page-frame";
import { ComponentWithPredefinedLang } from "Survey/langugae/interface";
import LoginController from "../Controllers/login-controller";

import Container from "@material-ui/core/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Button, TextField } from "@mui/material";
import { ECommandError } from "4common-ts";

enum EPasswordError {
    NONE,
    MISSING_PASSWORD,
}

enum EEmailError {
    NONE,
    MISSING_EMAIL,
    INVALID_EMAIL,
}

interface ILoginProps extends IBaseProps, ComponentWithPredefinedLang {}
interface ILoginState extends IBaseState {
    email: string;
    password: string;
    genErrorMsg?: string; // FIXME:
    emailError: EEmailError;
    passwordError: EPasswordError;
}

export default class Login extends ActuComponentBase<LoginController, ILoginProps, ILoginState> {
    constructor(props: ILoginProps) {
        super(props);

        this.controller.redirectIfConnect(this.props.selectlang);

        this.state = {
            ...this.state,
            passwordError: EPasswordError.NONE,
            emailError: EEmailError.NONE,
            password: "",
            email: "",
        };
    }
    private validateLoginInfo(context: Login): boolean {
        let emailValid = false;
        let passwordValid = false;
        // Validate email
        if (!context.state.email) {
            this.setState({ emailError: EEmailError.MISSING_EMAIL });
        } else if (!EmailValidator.validate(context.state.email)) {
            this.setState({ emailError: EEmailError.INVALID_EMAIL });
        } else {
            emailValid = true;
            if (context.state.emailError !== EEmailError.NONE) this.setState({ emailError: EEmailError.NONE }); // Reset error
        }
        // Validate password
        if (!context.state.password) {
            this.setState({ passwordError: EPasswordError.MISSING_PASSWORD });
        } else {
            passwordValid = true;
            if (context.state.passwordError !== EPasswordError.NONE)
                this.setState({ passwordError: EPasswordError.NONE }); // Reset error
        }
        return emailValid && passwordValid;
    }

    private onLogin(context: Login) {
        return new Promise<void>(async (resolve) => {
            if (!this.validateLoginInfo(context)) {
                resolve();
                return;
            }

            try {
                await context.controller.onLoginAsync(
                    context.state.email,
                    context.state.password,
                    this.props.selectlang
                );
            } catch (errorCode) {
                // FIXME: pas un errorCode si on a pas catch l'Exception
                switch (errorCode) {
                    case ECommandError.INVALID_EMAIL: {
                        context.setState({ genErrorMsg: this.props.t("login.unknow_email") });
                        break;
                    }
                    case ECommandError.INVALID_PASSWORD: {
                        context.setState({ genErrorMsg: this.props.t("login.unknow_password") });
                        break;
                    }
                    default:
                        context.setState({ genErrorMsg: this.props.t("login.unknow_error") });
                }
            }
            resolve();
        });
    }

    render() {
        let emailErrorMsg: string | undefined = undefined;
        if (this.state.emailError === EEmailError.INVALID_EMAIL) emailErrorMsg = this.props.t("login.invalid_email");
        else if (this.state.emailError === EEmailError.MISSING_EMAIL)
            emailErrorMsg = this.props.t("login.missing_email");

        let passwordErrorMsg: string | undefined = undefined;
        if (this.state.passwordError === EPasswordError.MISSING_PASSWORD)
            passwordErrorMsg = this.props.t("login.missing_password");

        return (
            <Container maxWidth="md">
                <CommonPageFrame
                    availableLanguages={this.props.availableLanguages}
                    onselectlanguage={(lang) => this.props.onselectlanguage(lang)}
                    disconnectUser={() => this.controller.disconnectUser(this.props.selectlang)}
                    selectlang={this.props.selectlang}
                    userData={this.controller.userData}
                >
                    <Grid container direction="column" display="flex" justifyContent="center" alignItems="center">
                        <Typography
                            align="center"
                            variant="h2"
                            sx={{
                                marginTop: "10px",
                                marginBottom: "10px",
                            }}
                        >
                            <Trans i18nKey="login.welcome" />
                        </Typography>
                        {this.state.genErrorMsg && (
                            <Typography align="center" color="red">
                                *{this.state.genErrorMsg}
                            </Typography>
                        )}
                        <form
                            style={{ width: "100%" }}
                            onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                    this.onLogin(this);
                                }
                            }}
                        >
                            <TextField
                                fullWidth
                                error={this.state.emailError !== EEmailError.NONE}
                                helperText={emailErrorMsg}
                                id={"email"}
                                label={`*${this.props.t("login.email")}`}
                                variant="outlined"
                                onChange={(e) => this.setState({ email: e.target.value })}
                                value={this.state.email}
                                size="medium"
                                autoComplete="username"
                                sx={{
                                    marginTop: "10px",
                                }}
                            />
                            <TextField
                                fullWidth
                                error={this.state.passwordError !== EPasswordError.NONE}
                                helperText={passwordErrorMsg}
                                id={"password"}
                                label={`*${this.props.t("login.password")}`}
                                variant="outlined"
                                onChange={(e) => this.setState({ password: e.target.value })}
                                value={this.state.password}
                                size="medium"
                                type="password"
                                autoComplete="current-password"
                                sx={{
                                    marginTop: "10px",
                                }}
                            />
                        </form>
                        <Button
                            fullWidth
                            onClick={() => this.onLogin(this)}
                            className="btn btn-primary"
                            variant="contained"
                            sx={{
                                marginTop: "20px",
                            }}
                        >
                            <Trans i18nKey="login.login" />
                        </Button>
                    </Grid>
                </CommonPageFrame>
            </Container>
        );
    }
}
