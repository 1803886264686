import { EServices, ICommand } from "../ICommand";
import { ECommandType } from "../../utils/enum-constants";
import { ESupportedLanguage } from "src/language";

export interface ExtraArgsCommandSendEmailWithTokenToObserver {
    childTokenId: string;
    lang: ESupportedLanguage;
}

export class CommandSendEmailWithTokenToObserver implements ICommand {
    static readonly type = ECommandType.COMMAND_SEND_EMAIL_WITH_TOKEN_TO_OBSERVER;
    static readonly service: EServices = EServices.SURVEY;

    readonly id: string;
    readonly timestamp: string;
    readonly type: ECommandType = CommandSendEmailWithTokenToObserver.type;
    readonly service: EServices = CommandSendEmailWithTokenToObserver.service;
    readonly token: string;
    readonly childTokenId: string;
    readonly lang: ESupportedLanguage;

    constructor(id: string, timestamp: string, token: string, childTokenId: string, lang: ESupportedLanguage) {
        this.id = id;
        this.timestamp = timestamp;
        this.token = token;
        this.childTokenId = childTokenId;
        this.lang = lang;
    }
}
