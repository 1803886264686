import { EServices, ICommand } from "../ICommand";
import { ECommandType } from "../../utils/enum-constants";
import { QuestionIdentifier } from "../../utils/question-identifier";

export interface ExtraArgsCommandAnswerChoiceQuestion {
    questionIdentifier: QuestionIdentifier;
    answerId: string;
}

export class CommandAnswerChoiceQuestion implements ICommand {
    static readonly type = ECommandType.COMMAND_ANSWER_CHOICE_QUESTION;
    static readonly service: EServices = EServices.SURVEY;

    readonly id: string;
    readonly timestamp: string;
    readonly type: ECommandType = CommandAnswerChoiceQuestion.type;
    readonly service: EServices = CommandAnswerChoiceQuestion.service;
    readonly token: string;
    readonly questionIdentifier: QuestionIdentifier;
    readonly answerId: string;

    constructor(id: string, timestamp: string, token: string, questionIdentifier: QuestionIdentifier, answerId: string) {
        this.id = id;
        this.timestamp = timestamp;
        this.token = token;
        this.questionIdentifier = questionIdentifier;
        this.answerId = answerId;
    }
}
