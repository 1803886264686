import { EServices, ICommand } from "../ICommand";
import { ECommandType } from "../../utils/enum-constants";
import { ESupportedLanguage } from "src/language";

export interface ExtraArgsCommandGetIndicators {
    survey_token_id: string;
    lang: ESupportedLanguage;
}

export class CommandGetIndicators implements ICommand {
    static readonly type = ECommandType.COMMAND_GET_INDICATORS;
    static readonly service: EServices = EServices.SURVEY;

    readonly id: string;
    readonly timestamp: string;
    readonly type: ECommandType = CommandGetIndicators.type;
    readonly service: EServices = CommandGetIndicators.service;
    readonly token: string = "";
    readonly survey_token_id: string;
    readonly lang: ESupportedLanguage;

    constructor(id: string, timestamp: string, survey_token_id: string, lang: ESupportedLanguage) {
        this.id = id;
        this.timestamp = timestamp;
        this.survey_token_id = survey_token_id;
        this.lang = lang;
    }
}
