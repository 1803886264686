import { EServices, ICommand } from "../ICommand";
import { ECommandType } from "../../utils/enum-constants";
import { ESupportedLanguage } from "src/language";

export enum ESurveyInfoType {
    TYPE = "type",
    INTERNAL_NAME = "internal-name",
    SURVEY_NAME = "survey-name",
    SURVEY_DESCRIPTION = "survey-description",
    PARENT_ID = "parent-id",
    CHILD_ID = "child-id",
    CHILD_SURVEY_NAME = "child-survey-name",
    CHILD_TYPE = "child-type",
    HAVE_INDICATORS = "have-indicators",
    DURATION = "duration",
    OBJECTIVES = "objectives",
}

export interface ExtraArgsCommandGetSurveyInfos {
    surveyId: string;
    tokenId?: string;
    infoTypes: ESurveyInfoType[];
    lang?: ESupportedLanguage;
}

export class CommandGetSurveyInfos implements ICommand {
    static readonly type = ECommandType.COMMAND_GET_SURVEY_INFO;
    static readonly service: EServices = EServices.SURVEY;

    readonly id: string;
    readonly timestamp: string;
    readonly type: ECommandType = CommandGetSurveyInfos.type;
    readonly service: EServices = CommandGetSurveyInfos.service;
    readonly token: string = "";

    readonly surveyId: string;
    readonly infoTypes: ESurveyInfoType[];
    readonly lang?: ESupportedLanguage;
    readonly tokenId?: string;

    constructor(id: string, timestamp: string, surveyId: string, infoTypes: ESurveyInfoType[], lang?: ESupportedLanguage, tokenId?: string) {
        this.id = id;
        this.timestamp = timestamp;
        this.surveyId = surveyId;
        this.infoTypes = infoTypes;
        this.lang = lang;
        this.tokenId = tokenId;
    }
}
