import { LPageAbstract } from "../page/page-abstract";
import { LQuestionAbstract } from "../page/question/question-abstract";
import { IAttribute } from "../attribute/attribute";
import { QuestionAbstract } from "src/model/survey/question-abstract";
import { ELPageType } from "../utils/enum-constants";
import { HookAbstract } from "../hook/hook-abstract";
import { LDescriptionAbstract } from "../description/description-abstract";
import { LPageTitle } from "./page-title";

export class LStaticPage extends LPageAbstract {
    static type: ELPageType = ELPageType.L_STATIC_PAGE;

    constructor(
        attributes: Array<IAttribute>,
        hooks: Array<HookAbstract>,
        descriptions: Array<LDescriptionAbstract>,
        containers: Array<LQuestionAbstract<QuestionAbstract>>,
        id: string,
        areTheQuestionsRandom: boolean,
        pageTitle?: LPageTitle
    ) {
        super(LStaticPage.type, attributes, hooks, descriptions, containers, id, areTheQuestionsRandom, pageTitle);
    }
}
