import { Component } from "react";

import { LPageAbstract, ELPageType, ESupportedLanguage } from "4common-ts";
import { StaticPageComponent } from "./pages";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { displayDescription } from "../display-description";

interface IPropsPageBuilder {
    lang: ESupportedLanguage;
    page: LPageAbstract;
}

export class PageBuilder extends Component<IPropsPageBuilder, {}> {
    private _render(): any {
        if (this.props.page.type === ELPageType.L_STATIC_PAGE) {
            return (
                <StaticPageComponent page={this.props.page}>{this.props.children /* questions */}</StaticPageComponent>
            );
        } else {
            throw Error(`Unknow type of layout [${this.props.page.type}]`);
        }
    }

    render(): any {
        const description = this.props.page.pageTitle?.descriptions.find(
            (description) => description.lang === this.props.lang
        );
        return (
            <Container maxWidth="md" style={{}}>
                {this.props.page.pageTitle ? (
                    <Box marginTop={5} boxShadow={"1px 1px 4px #1D1D35"}>
                        <Typography
                            fontWeight={400}
                            align="justify"
                            color="white"
                            sx={{
                                fontFamily: "Segoe UI",
                                padding: "10px 20px",
                                width: "100%",
                                backgroundColor: "#1D1D35",
                                textAlign: "center",
                                fontSize: {
                                    md: "1.5rem",
                                    sm: "1.15rem",
                                    xs: "1rem",
                                },
                            }}
                        >
                            {description ? displayDescription(description) : ""}
                        </Typography>
                        <br />
                    </Box>
                ) : (
                    ""
                )}

                <div>{this._render()}</div>
            </Container>
        );
    }
}
