import { IAttribute, EAkey, EMetaType } from "./attribute";
import { AttributeFactoryResult } from "./attribute-factory";

export class QuestionMultipleChoiceDisplayAttr implements IAttribute {
    readonly key: EAkey = EAkey.A_QUESTION_MULTIPLE_CHOICE_DISPLAY;
    readonly metaType: EMetaType = EMetaType.DISPLAY;
    readonly display: string;
    readonly nbChoice: number; // TODO: maybe we should change it for nbChoiceMin and nbMaxChoice?
    readonly choicesExposeNamesChecked: Array<string>;
    readonly choicesExposeNamesDisabled: Array<string>;

    constructor(args: {}) {
        //-1 allow to have number of answer equal of numbers of choices avaibles
        if (args["nb-choice"] === undefined) this.nbChoice = -1;
        else this.nbChoice = parseInt(args["nb-choice"]);
        this.display = args["display"] === undefined ? EAQuestionMultipleChoiceDisplay.VERTICAL_CHECK_BOXES : args["display"];
        //TODO: add the key in the layout
        this.choicesExposeNamesChecked = new Array<string>();
        this.choicesExposeNamesDisabled = new Array<string>();
    }

    validate = (r: AttributeFactoryResult) => {
        if (this.nbChoice === Number.NaN) r.error = `Invalide value [${this.nbChoice}] for argument 'nb-choice'`;
        if (findEADisplayMultipleChoiceMember(this.display) === undefined)
            r.error = `Invalid value [${this.display}] for argument 'display' for attribute [QuestionMultipleChoiceDisplayAttr]`;
    };
}

export enum EAQuestionMultipleChoiceDisplay {
    VERTICAL_CHECK_BOXES = "vertical-check-boxes",
    HORIZONTAL_CHECK_BOXES = "horizontal-check-boxes",
}
const findEADisplayMultipleChoiceMember = (display: string): EAQuestionMultipleChoiceDisplay | undefined => {
    return Object.values(EAQuestionMultipleChoiceDisplay).find((e) => e === display);
};
