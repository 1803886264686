import { Trans } from "react-i18next";
import Typography from "@mui/material/Typography";

import { ITabHeaderAbsProps, ITabHeaderAbsState, TabHeaderAbstract } from "Common/Components/tabs/tab-header-abstract";
import { List, ListItem } from "@mui/material";

interface IBaseHeaderProps extends ITabHeaderAbsProps {}
interface IBaseHeaderState extends ITabHeaderAbsState {}

export default class TabHeader extends TabHeaderAbstract<IBaseHeaderProps, IBaseHeaderState> {
    constructor(props: IBaseHeaderProps) {
        super(props);

        this.tabs = this.props.tabs;

        this.state = {
            ...this.state,
            activeTab: this.tabs.find((t) => t.title === this.props.activeTabTitle)!,
        };
    }

    render() {
        return (
            <Typography align="center" fontSize={15}>
                <List
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        p: 1,
                        m: 1,
                    }}
                >
                    {this.tabs.map((t) => {
                        if (this.state.activeTab.title === t.title)
                            return (
                                <ListItem
                                    sx={{
                                        display: "inline-block",
                                        borderRadius: 1,
                                        backgroundColor: "#555",
                                        color: "#fff",
                                        textAlign: "center",
                                        margin: "0px 5px",
                                    }}
                                >
                                    <Trans i18nKey={`dashboard.tabs-title.${t.title}`} />
                                </ListItem>
                            );
                        else
                            return (
                                <ListItem
                                    onClick={() => this.setActiveTab(t.title)}
                                    sx={{
                                        display: "inline-block",
                                        cursor: "pointer",
                                        borderRadius: 1,
                                        border: "1px solid #000",
                                        textAlign: "center",
                                    }}
                                >
                                    <Trans i18nKey={`dashboard.tabs-title.${t.title}`} />
                                </ListItem>
                            );
                    })}
                </List>
            </Typography>
        );
    }
}
