import { EServices, ICommand } from "../ICommand";
import { ECommandType } from "../../utils/enum-constants";

export interface ExtraArgsCommandGetSurvey {
    surveyId: string;
    surveyStatic: boolean;
    lang: string;
}

export class CommandGetSurvey implements ICommand {
    static readonly type = ECommandType.COMMAND_GET_SURVEY;
    static readonly service: EServices = EServices.SURVEY;

    readonly id: string;
    readonly timestamp: string;
    readonly type: ECommandType = CommandGetSurvey.type;
    readonly service: EServices = CommandGetSurvey.service;
    readonly token: string;
    readonly surveyId: string;
    readonly surveyStatic: boolean;
    readonly lang: string;

    constructor(id: string, timestamp: string, token: string, surveyId: string, surveyStatic: boolean, lang: string) {
        this.id = id;
        this.timestamp = timestamp;
        this.token = token;
        this.surveyId = surveyId;
        this.surveyStatic = surveyStatic
        this.lang = lang;
    }
}
