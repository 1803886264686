import { UITokenBuyToken, ETokenType, ISurveyInfos } from "4common-ts";
import * as EmailValidator from "email-validator";
import { TextField, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Trans } from "react-i18next";
import Typography from "@mui/material/Typography";
import BaseTabAbstract, { IBaseTabProps, IBaseTabState } from "Common/Components/tabs/base-tab-abstract";
import { EPrivillegeLevels } from "Common/privileges";

import { isDateFormatValid } from "Common/date";
import { IParticipantCsvFormat, ParticipantCsvReader } from "./Parts/participant-csv-reader";
import { EMAIL_SPLIT_CHAR, validateMultipleEmail } from "Common/email";
import Dashboard from "./dashboard";
import DashboardTrainingSessionController from "User/Controllers/dashboard-training-session-controller";

const SURVEY_SELECT_COLOR = "#CCFFE5";
const MISSING_SURVEY_SELECT_COLOR = "#ff4d4d";

interface IDashboardTrainingSessionProps extends IBaseTabProps {
    controller: DashboardTrainingSessionController;
}
interface IDashboardTrainingSessionState extends IBaseTabState {
    startDate: string;
    endDate: string;
    code_session: string;
    isStartDateValid: boolean;
    isEndDateValid: boolean;
    isStartDateSelected: boolean;
    isEndDateSelected: boolean;
    surveysDetails: ISurveyInfos[];
    selectSurveyDetails: ISurveyInfos | undefined;
    surveyDetailsLoaded: boolean;
    isCodeSessionValid: boolean;
    isCodeSessionSelected: boolean;
    isSessionCreated: boolean;
}
export default class DashboardTrainingSession extends BaseTabAbstract<
    DashboardTrainingSessionController,
    IDashboardTrainingSessionProps,
    IDashboardTrainingSessionState
> {
    static privillegeLevel = EPrivillegeLevels.ADMIN;
    static controllerType = DashboardTrainingSessionController;
    static title = "training-session";
    title = DashboardTrainingSession.title;
    constructor(props: IDashboardTrainingSessionProps) {
        super(props);
        this.state = {
            ...this.state,
            startDate: "",
            endDate: "",
            code_session: "",
            isEndDateValid: false,
            isStartDateValid: false,
            isStartDateSelected: false,
            isEndDateSelected: false,
            surveysDetails: [],
            selectSurveyDetails: undefined,
            surveyDetailsLoaded: false,
            isCodeSessionValid: false,
            isCodeSessionSelected: false,
            isSessionCreated: false,
        };
    }

    private fetchSurveyDetails() {
        this.setState({ surveyDetailsLoaded: true });
        return new Promise<void>(async (_) => {
            const surveysDetails = await this.controller.fetchAllSurveysDetailsAsync();
            this.setState({ surveysDetails });
        });
    }

    private async createTrainingSession() {
        return new Promise<boolean>(async (_) => {
            const surveyId = this.state.selectSurveyDetails ? this.state.selectSurveyDetails.id : "";
            const isSessionCreated = await this.controller.getCreateSession(
                this.state.startDate,
                this.state.endDate,
                this.state.code_session,
                surveyId
            );
            this.setState({ isSessionCreated });
        });
    }

    private setStartDate(startDate: string) {
        this.setState({ startDate });
        this.setState({ isStartDateValid: this.validateDateFormat(startDate) });
    }

    private setEndDate(endDate: string) {
        this.setState({ endDate });
        this.setState({ isEndDateValid: this.validateDateFormat(endDate) });
    }

    private setCodeSession(code_session: string) {
        this.setState({ code_session });
        this.setState({ isCodeSessionValid: this.state.code_session === "" ? false : true });
    }

    private validateDateFormat(dateString: string): boolean {
        const dateRegex = /^\d{4}-(0[1-9]|1[0-2])-([0-2]\d|3[01])$/;
        return dateRegex.test(dateString);
    }

    private valdidatePeriod(): boolean {
        const startDate = new Date(this.state.startDate);
        const endDate = new Date(this.state.endDate);
        return startDate < endDate;
    }

    private confirmDateSelection(period: string, isConfirm: boolean, idCodeSessionValidate?: boolean) {
        if (period === "startDate") {
            this.setState({ isStartDateSelected: isConfirm });
        } else if (period === "endDate") {
            this.setState({ isEndDateSelected: isConfirm });
        } else if (period === "codeSession") {
            this.setState({ isCodeSessionValid: isConfirm });
            this.setState({ isCodeSessionSelected: idCodeSessionValidate ? idCodeSessionValidate : false });
        }
    }

    private getSelectedValue(): string {
        if (!this.state.selectSurveyDetails) return "";
        return this.state.selectSurveyDetails?.id;
    }

    private onChangeSelect(surveyId: string) {
        this.setState({ selectSurveyDetails: this.state.surveysDetails.find((sd) => sd.id === surveyId) });
    }

    render() {
        if (!this.state.surveyDetailsLoaded) {
            this.fetchSurveyDetails();
        }

        const invalidPeriod = this.valdidatePeriod() ? "" : " ou la date de fin est antérieure à la date de début";
        const dateTextHelper = "Le format de la date est invalide" + invalidPeriod;
        const codeSessionTextHelper = "Le code de session est invalide";
        const validSessionCreation =
            this.state.isStartDateSelected && this.state.isEndDateSelected && this.state.isCodeSessionSelected;

        return (
            <Box>
                <Typography variant="h5">Sélectionner une formation</Typography>
                <FormControl fullWidth>
                    <InputLabel id="survey-select-label">{this.props.t("dashboard.buy-token.label-select")}</InputLabel>
                    <Select
                        labelId="survey-select-label"
                        id="survey-select"
                        value={this.getSelectedValue()}
                        onChange={(e) => this.onChangeSelect(e.target.value as string)}
                    >
                        <MenuItem value="" key={`select-survey-default`} disabled>
                            -----
                        </MenuItem>
                        {this.state.surveysDetails.map((sd) => (
                            <MenuItem value={sd.id} key={`select-survey-${sd.internal_name}`}>
                                {sd.name} ({sd.type})
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <br />
                <br />
                <Typography variant="h5">Créer une session de formation</Typography>
                <br />
                <Typography variant="h5">Date de début format aaaa-mm-jj</Typography>
                <br />
                <TextField
                    fullWidth
                    label={"date de départ"}
                    error={!this.state.isStartDateValid}
                    helperText={this.state.isStartDateValid ? "" : dateTextHelper}
                    variant="outlined"
                    size="medium"
                    onChange={(event) => this.setStartDate(event.target.value)}
                    value={this.state.startDate}
                    disabled={this.state.isStartDateSelected}
                />

                <Button
                    className="btn btn-primary"
                    variant="contained"
                    onClick={() => this.confirmDateSelection("startDate", true)}
                    disabled={!this.state.isStartDateValid || this.state.isStartDateSelected}
                >
                    {"Confirmer"}
                </Button>

                <Button
                    className="btn btn-primary"
                    variant="contained"
                    onClick={() => this.confirmDateSelection("startDate", false)}
                    disabled={!this.state.isStartDateSelected}
                >
                    {"Modifier"}
                </Button>

                <Typography variant="h5">date de fin aaaa-mm-jj</Typography>
                <br />
                <TextField
                    fullWidth
                    label={"date de fin"}
                    error={!this.state.isEndDateValid}
                    helperText={this.state.isEndDateValid ? "" : dateTextHelper}
                    variant="outlined"
                    size="medium"
                    onChange={(event) => this.setEndDate(event.target.value)}
                    value={this.state.endDate}
                    disabled={this.state.isEndDateSelected}
                />
                <Button
                    className="btn btn-primary"
                    variant="contained"
                    onClick={() => this.confirmDateSelection("endDate", true)}
                    disabled={!this.state.isEndDateValid || this.state.isEndDateSelected}
                >
                    {"Confirmer"}
                </Button>

                <Button
                    className="btn btn-primary"
                    variant="contained"
                    onClick={() => this.confirmDateSelection("endDate", false)}
                    disabled={!this.state.isEndDateSelected}
                >
                    {"Modifier"}
                </Button>
                <br />
                <Typography variant="h5">Code de session</Typography>
                <br />
                <TextField
                    fullWidth
                    label={"Code de session"}
                    error={this.state.code_session === ""}
                    helperText={this.state.code_session === "" ? codeSessionTextHelper : ""}
                    variant="outlined"
                    size="medium"
                    onChange={(event) => this.setCodeSession(event.target.value)}
                    value={this.state.code_session}
                    disabled={this.state.isCodeSessionSelected}
                />
                <Button
                    className="btn btn-primary"
                    variant="contained"
                    onClick={() => this.confirmDateSelection("codeSession", true, true)}
                    disabled={!this.state.isCodeSessionValid || this.state.isCodeSessionSelected}
                >
                    {"Confirmer"}
                </Button>

                <Button
                    className="btn btn-primary"
                    variant="contained"
                    onClick={() => this.confirmDateSelection("codeSession", true, false)}
                    disabled={!this.state.isCodeSessionValid || !this.state.isCodeSessionSelected}
                >
                    {"Modifier"}
                </Button>
                <br />
                <br />
                <Button
                    className="btn btn-primary"
                    variant="contained"
                    onClick={() => this.createTrainingSession()}
                    disabled={!validSessionCreation}
                >
                    {"Créer un nouvelle session"}
                </Button>
                {this.state.isSessionCreated ? "Session créée" : ""}
            </Box>
        );
    }
}
