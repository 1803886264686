import { EServices, ICommand } from "../ICommand";
import { ECommandType } from "../../utils/enum-constants";

export interface ExtraArgsCommandModifyExpirationDate {
    expiration_date: string;
    parent_survey_token_id?: string;
    service: EServices;
}

export class CommandModifyExpirationDate implements ICommand {
    static readonly type = ECommandType.COMMAND_MODIFY_EXPIRATION_DATE;

    readonly id: string;
    readonly timestamp: string;
    readonly type: ECommandType = CommandModifyExpirationDate.type;
    readonly service: EServices;
    readonly token: string;
    readonly expiration_date: string;
    readonly parent_survey_token_id?: string;

    constructor(id: string, timestamp: string, token: string, expiration_date: string, service: EServices, parent_survey_token_id?: string) {
        this.id = id;
        this.timestamp = timestamp;
        this.token = token;
        this.expiration_date = expiration_date;
        this.parent_survey_token_id = parent_survey_token_id;
        this.service = service;
    }
}
