import { EServices, ICommand } from "../ICommand";
import { ECommandType } from "../../utils/enum-constants";

export enum ETokens {
    MULTI = "multi",
    BUY_TOKEN = "buy-token",
    SURVEY_TOKEN = "survey-token", // Generic for any type of survey token
    OBSERVER = "observateur",
    STANDARD = "standard",
    AUTO = "auto",
    ALL = "all",
}
export interface ExtraArgsCommandGetMyTokens {
    tokens: string[];
    tokenType: ETokens[];
    email: string;
    service: EServices;
    keySeach: string;
}

export class CommandGetMyTokens implements ICommand {
    static readonly type = ECommandType.COMMAND_GET_MY_TOKENS;

    readonly id: string;
    readonly timestamp: string;
    readonly type: ECommandType = CommandGetMyTokens.type;
    readonly service: EServices;
    readonly token: string; //for now dont usefull for 4BeUser
    readonly tokens: string[]; // Should only be set when send to 4Be
    readonly tokenType: ETokens[];
    readonly email: string;
    readonly keySeach?: string;

    constructor(id: string, timestamp: string, email: string, tokenType: ETokens[], tokens: string[], service: EServices, keySearch?: string) {
        this.id = id;
        this.timestamp = timestamp;
        this.token = "";
        this.tokenType = tokenType;
        this.email = email.toLowerCase();
        this.tokens = tokens;
        this.service = service;
        this.keySeach = keySearch;
    }
}
