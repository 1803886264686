import { EServices, ICommand } from "../ICommand";
import { ECommandType } from "../../utils/enum-constants";

export interface ExtraArgsCommandBuyToken {
    email: string;
    survey_id: string;
    nb_token_allowed: number;
    description?: string;
}

export class CommandBuyToken implements ICommand {
    static readonly type = ECommandType.COMMAND_BUY_TOKEN;
    static readonly service: EServices = EServices.USER;

    readonly id: string;
    readonly timestamp: string;
    readonly type: ECommandType = CommandBuyToken.type;
    readonly service: EServices = CommandBuyToken.service;
    readonly token: string; //for now dont usefull for 4BeUser
    jwtToken: string = "";
    readonly email: string;
    readonly survey_id: string;
    readonly nb_token_allowed: number;
    readonly description?: string;

    constructor(id: string, timestamp: string, email: string, survey_id: string, nb_token_allowed: number, description?: string) {
        this.id = id;
        this.timestamp = timestamp;
        this.token = "";
        this.email = email.toLowerCase();
        this.survey_id = survey_id;
        this.nb_token_allowed = nb_token_allowed;
        this.description = description;
    }
}
