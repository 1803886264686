import { EHookType } from "../utils/enum-constants";
import { HookAbstract } from "./hook-abstract";

export class HookQuestion extends HookAbstract<EHQListenner> {
    static type: EHookType = EHookType.H_QUESTION;
}

export enum EHQListenner {
    H_None = "None",
    ON_UPDATE_ANSWER = "ON_UPDATE_ANSWER",
}
