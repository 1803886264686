import { ModelAbstract } from "../model-abstract";
import { EUserModelType } from "./utils/enum-constants";

export class SessionModel extends ModelAbstract {
    static type: EUserModelType = EUserModelType.SESSION;

    // Columns
    readonly startDate: Date;
    readonly endDate: Date;
    readonly code_session: string;

    // relations
    readonly email: string;
    readonly buy_token_ids: string[];

    constructor(id: string, email: string, startDate: Date, endDate: Date, code_session: string, buy_token_ids: string[]) {
        super(id, SessionModel.type);

        this.email = email;
        this.startDate = startDate;
        this.endDate = endDate;
        this.code_session = code_session;
        this.buy_token_ids = buy_token_ids;
    }
}
