import BaseTabAbstract, { IBaseTabProps, IBaseTabState } from "Common/Components/tabs/base-tab-abstract";
import { EPrivillegeLevels } from "Common/privileges";

import DashboardCertificatesController from "User/Controllers/dashboard-certicicates-controller";

import { TextField } from "@material-ui/core";
import Grid from "@mui/material/Grid";
import Container from "@material-ui/core/Container";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import certificate_en from "../../image/certificate_en.png";
import certificate_fr from "../../image/certificate_fr.png";
import {
    CertificateCsvReader,
    IParticipantCsvFormat,
    IObjectiveCsvFormat,
    PARTICIPANT_COLUMNS,
    OBJECTIVE_COLUMNS,
} from "./Parts/certificate-csv-reader";
import { ESupportedLanguage } from "4common-ts";

interface IDashboardCertificatesProps extends IBaseTabProps {}

export interface ICertificateSurveyInfos {
    survey_name: string;
    duration: string;
    objectives: string[];
}

interface IDashboardCertificatesState extends IBaseTabState {
    tokenId: string;
    surveyInfos: ICertificateSurveyInfos | undefined;
    full_name: string;
    survey_name: string;
    duration: string;
    sessions_date: string;
    objectives: string[];
    objectivesCollapsed: boolean;
    datasCollapsed: boolean;
    hasError: boolean;
    participantCsvFormat: IParticipantCsvFormat[];
    objectiveCsvFormat: IObjectiveCsvFormat[];
    maxObjectivesNumber: number;
    certificateLanguage: ESupportedLanguage;
    selectedParticipant: IParticipantCsvFormat | undefined;
}

export default class DashboardCertificates extends BaseTabAbstract<
    DashboardCertificatesController,
    IDashboardCertificatesProps,
    IDashboardCertificatesState
> {
    static privillegeLevel = EPrivillegeLevels.ADMIN;
    static controllerType = DashboardCertificatesController;
    static title = "certificates";
    title = DashboardCertificates.title;

    constructor(props: IDashboardCertificatesProps) {
        super(props);
        this.state = {
            ...this.state,
            tokenId: "",
            surveyInfos: undefined,
            hasError: false,
            full_name: "Full Name",
            survey_name: "Training title",
            duration: "hours",
            sessions_date: "dates",
            objectives: [
                "Objective 1",
                "Objective 2",
                "Objective 3",
                "Objective 4",
                "Objective 5",
                "Objective 6",
                "Objective 7",
                "Objective 8",
            ],
            participantCsvFormat: [],
            objectiveCsvFormat: [],
            objectivesCollapsed: true,
            datasCollapsed: true,
            maxObjectivesNumber: 8,
            certificateLanguage: this.controller.currentLang,
            selectedParticipant: undefined,
        };
    }

    /**
     * The function convert the div to pdf, all the component inside the div certificate will be converted
     */
    downloadCertificate() {
        let input = window.document.getElementsByClassName("certificate")[0] as HTMLElement;
        html2canvas(input, { scale: 2 }).then((canvas) => {
            const img = canvas.toDataURL("image/png");
            const pdf = new jsPDF("landscape", "pt", "letter", true);
            pdf.addImage(img, "png", 10, 10, 775, 590);
            pdf.save(this.getCertficateNameFormat());
        });
    }

    private getCertficateNameFormat() {
        var nameFormat = this.state.full_name
            .normalize("NFD") // Décomposer les caractères accentués
            .replace(/[\u0300-\u036f]/g, "") // Supprimer les diacritiques
            .replace(/\s+/g, ""); // Supprimer les espaces

        nameFormat = nameFormat.charAt(0).toUpperCase() + nameFormat.slice(1).toLowerCase(); // Convertir en format camel case

        var surveyNameFormat = this.state.survey_name
            .normalize("NFD") // Décomposer les caractères accentués
            .replace(/[\u0300-\u036f]/g, "") // Supprimer les diacritiques
            .replace(/\s+/g, ""); // Supprimer les espaces

        surveyNameFormat = surveyNameFormat.charAt(0).toUpperCase() + surveyNameFormat.slice(1).toLowerCase(); // Convertir en format camel casevar surveyNameFormat = "";

        const certificate = this.state.certificateLanguage === ESupportedLanguage.fr ? "Attestation" : "Certificate";
        const language = this.state.certificateLanguage === ESupportedLanguage.fr ? "Fr" : "En";

        return certificate + "_" + nameFormat + "_" + surveyNameFormat + "_" + language + ".pdf";
    }

    private onCsvRead(_: string, participantCsvFormat: IParticipantCsvFormat[]) {
        this.setState({ participantCsvFormat });
        this.setState({ selectedParticipant: participantCsvFormat[0] });
        this.selectParticipant(participantCsvFormat[0]);
    }

    private loadObjectives(_: string, objectiveCsvFormat: IObjectiveCsvFormat[]) {
        this.setState({ objectiveCsvFormat });

        const objectives = this.state.objectiveCsvFormat.map((objective) => {
            return objective.description;
        });
        this.setState({ objectives });
    }

    private selectParticipant(participant: IParticipantCsvFormat) {
        this.setState({
            full_name: participant.firstname + " " + participant.name,
            survey_name: participant.training,
            duration: participant.hours.toString(),
            sessions_date: participant.dates,
        });
    }

    private setFullName(full_name: string) {
        this.setState({ full_name });
    }
    private setSurveyName(survey_name: string) {
        this.setState({ survey_name });
    }

    private setDuration(duration: string) {
        this.setState({ duration });
    }

    private setSessionsDate(sessions_date: string) {
        this.setState({ sessions_date });
    }

    private getDefaultObjectives() {
        const objectives: string[] = [];
        for (let i = 0; i < this.state.maxObjectivesNumber; i++) {
            const objective =
                this.state.certificateLanguage === ESupportedLanguage.fr
                    ? "Objectif " + (i + 1)
                    : "Objective " + (i + 1);
            objectives.push(objective);
        }
        return objectives;
    }

    private setNextPartipant() {
        if (this.state.selectedParticipant) {
            const index = this.state.participantCsvFormat.indexOf(this.state.selectedParticipant);
            if (index < this.state.participantCsvFormat.length - 1) {
                this.setState({ selectedParticipant: this.state.participantCsvFormat[index + 1] });
                this.selectParticipant(this.state.participantCsvFormat[index + 1]);
            }
        }
    }

    private setPreviousPartipant() {
        if (this.state.selectedParticipant) {
            const index = this.state.participantCsvFormat.indexOf(this.state.selectedParticipant);
            if (index > 0) {
                this.setState({ selectedParticipant: this.state.participantCsvFormat[index - 1] });
                this.selectParticipant(this.state.participantCsvFormat[index - 1]);
            }
        }
    }

    private getParticipantIndex() {
        if (this.state.selectedParticipant) {
            const index = this.state.participantCsvFormat.indexOf(this.state.selectedParticipant);
            return index;
        }
        return -1;
    }

    private setDefaultDatas() {
        const objectives = this.getDefaultObjectives();

        this.setState({
            full_name: "Full Name",
            survey_name: this.state.surveyInfos?.survey_name || "Training title",
            duration: this.state.surveyInfos ? this.state.surveyInfos.duration : "7",
            sessions_date: "10 , 11 , 12 Juin 2023",
            objectives,
        });
    }

    private setObjective(objective: string, index: number) {
        const objectives = [...this.state.objectives];
        objectives[index] = objective;
        this.setState({ objectives });
    }

    private addObjective() {
        const objectives = [...this.state.objectives];
        objectives.push("");

        this.setState({ objectives });
    }

    private removeObjective(index: number) {
        const objectives = [...this.state.objectives];
        objectives.splice(index, 1);

        this.setState({ objectives });
    }
    private toggleCollapseAllObjectives() {
        const objectivesCollapsed = !this.state.objectivesCollapsed;
        this.setState({ objectivesCollapsed });
    }

    private toggleAllDatas() {
        const datasCollapsed = !this.state.datasCollapsed;
        this.setState({ datasCollapsed });
    }

    render() {
        return (
            <Box>
                <br />
                <div className="certificate" style={{ boxShadow: "1px 1px 4px #1D1D35" }}>
                    <Box
                        sx={{
                            flexGrow: 1,
                            mt: 2,
                            borderRadius: 4,
                            p: 0,
                            marginRight: 0,
                        }}
                    >
                        <Box sx={{ position: "relative" }}>
                            <Box
                                marginTop={4}
                                component="img"
                                sx={{
                                    height: "100%",
                                    width: "100%",
                                }}
                                alt="certificate"
                                src={
                                    this.state.certificateLanguage === ESupportedLanguage.fr
                                        ? certificate_fr
                                        : certificate_en
                                }
                            />
                            <Grid container spacing={3} sx={{ position: "absolute", top: 0, left: 0, zIndex: 1 }}>
                                <Grid item xs={4} textAlign="center">
                                    <Typography variant="body1"></Typography>
                                </Grid>
                                <Grid item xs={4} textAlign="center">
                                    <Typography variant="body1"></Typography>
                                </Grid>
                                <Grid item xs={4} textAlign="center" marginTop={73}>
                                    <Typography variant="body1">{this.state.sessions_date}</Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={3} sx={{ position: "absolute", top: 0, left: 0, zIndex: 1 }}>
                                <Grid
                                    item
                                    xs={12}
                                    container
                                    justifyContent="center"
                                    alignItems="center"
                                    color="black"
                                    marginTop={15}
                                >
                                    <Typography variant="h6">
                                        {this.props.t("certificate.actualisation_attest")}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    container
                                    justifyContent="center"
                                    alignItems="center"
                                    color="black"
                                    marginTop={-2}
                                >
                                    <Typography variant="h3" fontWeight={"bold"}>
                                        {this.state.full_name}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    container
                                    justifyContent="center"
                                    alignItems="center"
                                    color="black"
                                    marginTop={0}
                                >
                                    <Typography variant="h6">{this.props.t("certificate.has_participated")}</Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    container
                                    justifyContent="center"
                                    alignItems="center"
                                    color="black"
                                    marginTop={-2}
                                >
                                    <Typography variant="h4" fontWeight={"bold"}>
                                        {this.state.survey_name}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} container justifyContent="center" alignItems="center" color="black">
                                    <Typography variant="h6" fontWeight={"bold"}>
                                        {this.props
                                            .t("certificate.duration_text")
                                            .replace("{}", this.state.duration + "")}
                                    </Typography>
                                </Grid>

                                {this.state.objectives.map((objective) => {
                                    return (
                                        <Grid item xs={12} textAlign="center">
                                            <Typography variant="body1" style={{ marginTop: "-20px" }}>
                                                {objective}
                                            </Typography>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </Box>
                    </Box>
                </div>
                <br />
                <Typography variant="h4" align="center">
                    {this.getParticipantIndex() + 1 + "/" + this.state.participantCsvFormat.length}
                </Typography>
                <br />
                <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Button
                        className="btn btn-primary"
                        variant="contained"
                        color="success"
                        onClick={(e) => this.setPreviousPartipant()}
                        disabled={this.getParticipantIndex() === -1 || this.getParticipantIndex() === 0}
                    >
                        {this.props.t("certificate.previous")}
                    </Button>
                    <Button
                        className="btn btn-primary"
                        variant="contained"
                        color="success"
                        onClick={(e) => this.setNextPartipant()}
                        disabled={
                            this.getParticipantIndex() === this.state.participantCsvFormat.length - 1 ||
                            this.getParticipantIndex() === -1
                        }
                    >
                        {this.props.t("certificate.next")}
                    </Button>
                </Box>
                <br />
                <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Button
                        className="btn btn-primary"
                        variant="contained"
                        color="success"
                        onClick={(e) => this.downloadCertificate()}
                    >
                        {this.props.t("certificate.download_pdf")}
                    </Button>
                </Box>
                <br />
                <Box marginTop={2} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Button variant="contained" color="primary" onClick={() => this.toggleAllDatas()}>
                        {this.state.datasCollapsed
                            ? this.props.t("certificate.show")
                            : this.props.t("certificate.hide")}
                    </Button>
                </Box>

                <br />
                {this.state.datasCollapsed ? null : (
                    <Box>
                        <Typography variant="h4" fontWeight={"bold"} align="center">
                            {this.props.t("certificate.import_participants")}
                        </Typography>

                        <Typography fontWeight={"bold"} align="center">
                            {this.props.t("certificate.helper_text_participants")}
                        </Typography>
                        <br />
                        <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <CertificateCsvReader onSelect={(name, info) => this.onCsvRead(name, info)} />
                        </Box>

                        <br />
                        <Typography variant="h3" fontWeight={"bold"} align="center">
                            {this.props.t("certificate.participant_table_title")}
                        </Typography>
                        <br />
                        <Box>
                            <TableContainer component={Paper} style={{ backgroundColor: "#E4E4F1" }}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            {PARTICIPANT_COLUMNS.map((column_name) => {
                                                return (
                                                    <TableCell align="center">
                                                        <Typography fontWeight={700} fontSize="15px" fontStyle={"bold"}>
                                                            {column_name}
                                                        </Typography>
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.participantCsvFormat.map((c) => (
                                            <TableRow
                                                key={c.email}
                                                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                            >
                                                <TableCell align="center">{c.name}</TableCell>
                                                <TableCell align="center">{c.firstname}</TableCell>
                                                <TableCell align="center">{c.email}</TableCell>
                                                <TableCell align="center">{c.language}</TableCell>
                                                <TableCell align="center">{c.hours}</TableCell>
                                                <TableCell align="center">{c.dates}</TableCell>
                                                <TableCell align="center">{c.training}</TableCell>
                                                <TableCell align="center">
                                                    <Button
                                                        className="btn btn-primary"
                                                        variant="contained"
                                                        onClick={() => this.selectParticipant(c)}
                                                    >
                                                        {this.props.t("certificate.select")}
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                        <br />
                        <Typography variant="h3" fontWeight={"bold"} align="center">
                            {this.props.t("certificate.participant_selected")}
                        </Typography>
                        <br />
                        <Box>
                            <TextField
                                fullWidth
                                label={this.props.t("certificate.modify_participant_full_name")}
                                variant="outlined"
                                size="medium"
                                onChange={(event) => this.setFullName(event.target.value)}
                                value={this.state.full_name}
                            />
                            <br />
                            <br />
                            <TextField
                                fullWidth
                                label={this.props.t("certificate.modify_the_name_of_the_training")}
                                variant="outlined"
                                size="medium"
                                onChange={(event) => this.setSurveyName(event.target.value)}
                                value={this.state.survey_name}
                            />
                            <br />
                            <br />
                            <TextField
                                fullWidth
                                label={this.props.t("certificate.modify_the_duration_of_the_training")}
                                variant="outlined"
                                size="medium"
                                onChange={(event) => this.setDuration(event.target.value)}
                                value={this.state.duration}
                            />
                            <br />
                            <br />
                            <TextField
                                fullWidth
                                label={this.props.t("certificate.modifys_session_dates")}
                                variant="outlined"
                                size="medium"
                                onChange={(event) => this.setSessionsDate(event.target.value)}
                                value={this.state.sessions_date}
                            />
                            <br />
                            <br />

                            <Typography variant="h4" fontWeight={"bold"} align="center">
                                {this.props.t("certificate.import_objectives")}
                            </Typography>

                            <Typography fontWeight={"bold"} align="center">
                                {this.props.t("certificate.helper_text_objectives")}
                            </Typography>
                            <br />
                            <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <CertificateCsvReader onSelect={(name, info) => this.loadObjectives(name, info)} />
                            </Box>

                            <br />
                            <br />
                            <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => this.toggleCollapseAllObjectives()}
                                >
                                    {this.state.objectivesCollapsed
                                        ? this.props.t("certificate.develop_objectives")
                                        : this.props.t("certificate.reduce_objectives")}
                                </Button>
                            </Box>

                            <br />
                            <br />
                            {this.state.objectivesCollapsed ? null : (
                                <Box>
                                    {this.state.objectives.map((objective, index) => {
                                        return (
                                            <Box>
                                                <TextField
                                                    fullWidth
                                                    label={this.props.t("certificate.modify_objectif") + (index + 1)}
                                                    variant="outlined"
                                                    size="medium"
                                                    onChange={(event) => this.setObjective(event.target.value, index)}
                                                    value={objective}
                                                />
                                                <br />
                                                <br />
                                                <Button
                                                    variant="contained"
                                                    color="error"
                                                    onClick={() => this.removeObjective(index)}
                                                >
                                                    {this.props.t("certificate.delete_objectif") + (index + 1)}
                                                </Button>
                                                <br />
                                                <br />
                                            </Box>
                                        );
                                    })}
                                    <br />
                                    <br />
                                    <Button
                                        variant="contained"
                                        color="success"
                                        onClick={() => this.addObjective()}
                                        disabled={this.state.objectives.length >= this.state.maxObjectivesNumber}
                                    >
                                        {this.props.t("certificate.add_objectif")}
                                    </Button>
                                </Box>
                            )}
                            <br />
                            <br />
                        </Box>
                        <br />
                        <br />

                        <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Button
                                className="btn btn-primary"
                                variant="contained"
                                color="warning"
                                onClick={() => this.setDefaultDatas()}
                            >
                                {this.props.t("certificate.default_values")}
                            </Button>
                        </Box>
                    </Box>
                )}
            </Box>
        );
    }
}
