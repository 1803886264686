import {
    ValdiateUuidv4,
    QuestionAnswerAbstract,
    QuestionAnswerTextModel,
    QuestionAnswerChoiceModel,
    QuestionAbstract,
    QuestionMultipleChoiceDisplayAttr,
    IAttribute,
    EMetaType,
    QuestionAnswerMultipleChoiceModel,
    LQuestionAbstract,
    ELQuestionType,
    LChoiceAbstract,
} from "4common-ts";
import { AnswerAbstract, AnswerChoice, AnswerText } from "Survey/answers/answer-wrapper";
import { findAttribute } from "Survey/Components/Layout/utils/attribute";

/**
 *
 */
abstract class UpdateAnswerAbstract {
    protected answerModel: QuestionAnswerAbstract;
    protected questionAttrs: Array<IAttribute>;
    protected questionModel: LQuestionAbstract<QuestionAbstract>;

    constructor(
        questionModel: LQuestionAbstract<QuestionAbstract>,
        questionAttrs: Array<IAttribute>,
        answerModel: QuestionAnswerAbstract
    ) {
        this.questionModel = questionModel;
        this.questionAttrs = questionAttrs;
        this.answerModel = answerModel;
    }

    /**
     *
     * @param value
     */
    abstract updateAnswer(answer: AnswerAbstract): QuestionAnswerAbstract | undefined;
}

class UpdateAnswerText extends UpdateAnswerAbstract {
    updateAnswer(answer: AnswerText): QuestionAnswerAbstract | undefined {
        const answerModelText = this.answerModel as QuestionAnswerTextModel;
        answerModelText.value = answer.value;
        return answerModelText;
    }
}

class UpdateAnswerChoice extends UpdateAnswerAbstract {
    updateAnswer(answer: AnswerChoice): QuestionAnswerAbstract | undefined {
        if (!ValdiateUuidv4(answer.choice)) return undefined;

        const answerModelChoice = this.answerModel as QuestionAnswerChoiceModel;
        answerModelChoice.choice_id = answer.choice;
        return answerModelChoice;
    }
}

class UpdateAnswerMultipleChoice extends UpdateAnswerAbstract {
    protected nbChoices: number; // max number of choices allowed

    constructor(
        questionModel: LQuestionAbstract<QuestionAbstract>,
        questionAttrs: Array<IAttribute>,
        answerModel: QuestionAnswerAbstract
    ) {
        super(questionModel, questionAttrs, answerModel);
        const attrMCT = findAttribute<QuestionMultipleChoiceDisplayAttr>(questionAttrs, EMetaType.DISPLAY);

        if (attrMCT === undefined) this.nbChoices = 1;
        else if (attrMCT.nbChoice === -1) {
            this.nbChoices = (this.questionModel.containers as Array<LChoiceAbstract>).length;
        } else this.nbChoices = attrMCT.nbChoice;
    }

    updateAnswer(answer: AnswerChoice): QuestionAnswerAbstract | undefined {
        const answerModelChoice = this.answerModel as QuestionAnswerMultipleChoiceModel;
        if (answerModelChoice.choice_ids.find((id) => id === answer.choice) !== undefined)
            answerModelChoice.choice_ids = answerModelChoice.choice_ids.filter((id) => id !== answer.choice);
        else {
            if (answerModelChoice.choice_ids.length < this.nbChoices) answerModelChoice.choice_ids.push(answer.choice);
            else return undefined;
        }
        return answerModelChoice;
    }
}

export class UpdateAnswerFactory {
    static Create(
        questionModel: LQuestionAbstract<QuestionAbstract>,
        questionAttrs: Array<IAttribute>,
        answerModel: QuestionAnswerAbstract
    ): UpdateAnswerAbstract {
        const questionType = questionModel.type;
        if (questionType === ELQuestionType.L_QUESTION_CHOICE)
            return new UpdateAnswerChoice(questionModel, questionAttrs, answerModel);
        else if (questionType === ELQuestionType.L_QUESTION_MULTIPLE_CHOICE)
            return new UpdateAnswerMultipleChoice(questionModel, questionAttrs, answerModel);
        else if (questionType === ELQuestionType.L_QUESTION_TEXT)
            return new UpdateAnswerText(questionModel, questionAttrs, answerModel);

        throw Error("Unknown type of answer: " + questionType);
    }
}
