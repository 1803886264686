import BaseTabAbstract, { IBaseTabProps, IBaseTabState } from "Common/Components/tabs/base-tab-abstract";

import Papa from "papaparse";
import DashboardManageTokensController from "User/Controllers/dashboard-token-manager-controller";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import {
    ESexes,
    ESupportedLanguage,
    ETokenType,
    UISurveyToken,
    UIToken,
    UITokenBuyToken,
    UITokenParent,
} from "4common-ts";
import { Trans } from "react-i18next";
import { EPrivillegeLevels } from "Common/privileges";
import { convertDateToUTC } from "Common/date";
import { TextField } from "@material-ui/core";
import Button from "@mui/material/Button";
import DashboardTokenController from "User/Controllers/dashboard-tokens-controller";
import { UIUserProfileStruct } from "4common-ts/build/interfaces";
import CircularProgress from "@mui/material/CircularProgress";

interface IDashboardTokenProps extends IBaseTabProps {}
interface IDashboardTokenState extends IBaseTabState {
    uiToken: UISurveyToken | undefined;
    tokenIdSelected: string | undefined;
    questionsAreVisible: boolean;
    formerName: string | undefined;
    generateReportSate: EGenerateReportState;
    searchTokenState: EGenerateReportState;
}

enum EGenerateReportState {
    NONE,
    LOADING,
    SUCCESSED,
    FAILED,
}

export default class DashboardToken extends BaseTabAbstract<
    DashboardTokenController,
    IDashboardTokenProps,
    IDashboardTokenState
> {
    static privillegeLevel = EPrivillegeLevels.ADMIN;
    static controllerType = DashboardTokenController;
    static title = "tokens";
    title = DashboardToken.title;

    constructor(props: IDashboardTokenProps) {
        super(props);

        this.state = {
            ...this.state,
            uiToken: undefined,
            tokenIdSelected: undefined,
            questionsAreVisible: false,
            formerName: undefined,
            generateReportSate: EGenerateReportState.NONE,
            searchTokenState: EGenerateReportState.NONE,
        };
    }

    private async getSurveyTokenInfos() {
        const token = this.state.tokenIdSelected;
        this.setState({
            searchTokenState: EGenerateReportState.LOADING,
        });

        if (token) {
            const tk = await this.controller.getSurveyTokenInfosSync(token);
            this.setState({ uiToken: tk });

            this.setState({
                searchTokenState: tk ? EGenerateReportState.SUCCESSED : EGenerateReportState.FAILED,
            });
        }
    }

    private async generateResult() {
        this.setState({
            generateReportSate: EGenerateReportState.LOADING,
        });

        const token = this.state.tokenIdSelected;
        const email = this.controller.userData.email;
        if (token && email) {
            const userFormer = {
                email,
                privilege: this.controller.userData.privilege,
                fullName: this.state.formerName,
                sex: ESexes.MALE,
                company: "Actualisation",
                prefered_lang: this.controller.userData.prefered_lang,
            } as UIUserProfileStruct;

            const result = await this.controller.generateResultAsync(token, userFormer);
            var generateReportSate = this.state.generateReportSate;
            if (result) generateReportSate = EGenerateReportState.SUCCESSED;
            else generateReportSate = EGenerateReportState.FAILED;

            this.setState({
                generateReportSate,
            });
        }
    }

    private reinitializeButtonState() {
        if (this.state.tokenIdSelected === "") {
            this.setState({
                generateReportSate: EGenerateReportState.NONE,
                searchTokenState: EGenerateReportState.NONE,
                uiToken: undefined,
                tokenIdSelected: undefined,
                questionsAreVisible: false,
                formerName: undefined,
            });
        }
    }

    private uuidIsValid(): boolean {
        if (this.state.tokenIdSelected === undefined) return false;
        else return this.state.tokenIdSelected.length === 36;
    }

    private canGenerateReport(): boolean {
        return this.uuidIsValid() && this.state.uiToken?.isStillValid === false && this.state.formerName !== undefined;
    }

    private updateButtonAfterSearchToken() {
        if (this.state.tokenIdSelected) {
            if (this.state.searchTokenState === EGenerateReportState.LOADING)
                return (
                    <CircularProgress
                        sx={{
                            color: "#7475b5",
                        }}
                    />
                );
            else if (this.state.searchTokenState === EGenerateReportState.SUCCESSED)
                return (
                    <Button variant="contained" color="success">
                        {this.props.t("dashboard.button_names.success")}
                    </Button>
                );
            else if (this.state.searchTokenState === EGenerateReportState.FAILED)
                return (
                    <Button variant="outlined" color="error">
                        Erreur
                    </Button>
                );
            else if (this.state.searchTokenState === EGenerateReportState.NONE) {
                return (
                    <Button
                        className="btn btn-primary"
                        variant="contained"
                        onClick={() => this.getSurveyTokenInfos()}
                        disabled={!this.uuidIsValid()}
                    >
                        chercher
                    </Button>
                );
            }
        }
    }

    private updateButtonAfterGenerateReport() {
        if (this.state.uiToken) {
            if (this.state.generateReportSate === EGenerateReportState.LOADING)
                return (
                    <CircularProgress
                        sx={{
                            color: "#7475b5",
                        }}
                    />
                );
            else if (this.state.generateReportSate === EGenerateReportState.SUCCESSED)
                return (
                    <Button variant="contained" color="success">
                        {this.props.t("dashboard.button_names.success")}
                    </Button>
                );
            else if (this.state.generateReportSate === EGenerateReportState.FAILED)
                return (
                    <Button variant="outlined" color="error">
                        Erreur
                    </Button>
                );
        }
    }

    private getTextStatusForGenerateReport() {
        var text = "";
        if (this.state.generateReportSate === EGenerateReportState.NONE && this.state.uiToken?.isStillValid === true) {
            text = "Le rapport ne peux être généré car le questionnaire n'a pas encore été complété";
        } else if (this.state.generateReportSate === EGenerateReportState.SUCCESSED)
            text = "Rapport généré avec succès, le rapport a été envoyé par courriel au participant et au formateur";
        else if (this.state.generateReportSate === EGenerateReportState.FAILED)
            text = "Une erreur est survenue lors de la génération du rapport";
        else if (this.state.generateReportSate === EGenerateReportState.LOADING)
            text = "Génération du rapport en cours";

        return text;
    }

    private getTextStatusForSearchToken() {
        var text = "";
        if (this.state.searchTokenState === EGenerateReportState.NONE && this.state.uiToken === undefined) {
            text = "";
        } else if (this.state.searchTokenState === EGenerateReportState.SUCCESSED)
            text = "Identifiant trouvé avec succès";
        else if (this.state.searchTokenState === EGenerateReportState.FAILED)
            text = "Identifiant non trouvé ou erreur lors de la recherche";
        else if (this.state.searchTokenState === EGenerateReportState.LOADING) text = "recherche en cours";

        return text;
    }

    private setQuestionsAreVisible() {
        this.setState({ questionsAreVisible: !this.state.questionsAreVisible });
    }

    private renderInfos() {
        return (
            <Box>
                <h3>Jeton</h3>
                <br />
                <div>
                    <strong>Identifiant : </strong>
                    {this.state.uiToken?.id}
                </div>
                <div>
                    <strong>Courriel : </strong>
                    {this.state.uiToken?.email}
                </div>
                <div>
                    <strong>Langue du participant : </strong>
                    {this.state.uiToken?.language}
                </div>
                <div>
                    <strong>Date de création : </strong>
                    {this.state.uiToken?.creationDate ? convertDateToUTC(this.state.uiToken?.creationDate) : null}
                </div>
                <div>
                    <strong>Date d'expiration : </strong>
                    {this.state.uiToken?.expirationDate ? convertDateToUTC(this.state.uiToken?.creationDate) : null}
                </div>
                <div>
                    <strong>Date de complétion : </strong>
                    {this.state.uiToken?.completionDate ? convertDateToUTC(this.state.uiToken?.completionDate) : null}
                </div>
                <div>
                    <strong>Taux de complétion des réponse : </strong>
                    {this.state.uiToken?.completionRate}%
                </div>
                <div>
                    <strong>Questionnaire complété : </strong>
                    {this.state.uiToken?.isStillValid ? "non" : "oui"}
                </div>
                {this.state.uiToken?.type === ETokenType.AUTO ? (
                    <Box>
                        <div>
                            <strong>Nombre d'observateurs</strong> {this.state.uiToken.observers.length}
                        </div>
                        <div>
                            <strong>Taux de complétion des observateurs</strong>{" "}
                            {`${
                                (this.state.uiToken.observers.filter((o) => !o.isStillValid).length /
                                    this.state.uiToken.observers.length) *
                                100
                            }%`}
                        </div>
                    </Box>
                ) : null}
                <br />
                <br />
                <h3>
                    <strong>Questionnaire</strong>
                </h3>
                <div>
                    <strong>Type de questionnaire : </strong>
                    {this.state.uiToken?.type}
                </div>
                <div>
                    <strong>Nom du questionnaire : </strong>
                    {this.state.uiToken?.surveyName}
                </div>
                <div>
                    <strong>Nom de code : </strong>
                    {this.state.uiToken?.surveyInternalName}
                </div>
                <div>
                    <strong>Acronyme : </strong>
                    {this.state.uiToken?.surveyAcronym}
                </div>
                <div>
                    <strong>Description du questionnaire : </strong>
                    {this.state.uiToken?.surveyDescription}
                </div>

                <div>
                    <strong>Identifiant du questionaire : </strong>
                    {this.state.uiToken?.surveyId}
                </div>
                <div>
                    <strong>Nombre de questions : </strong>
                    {this.state.uiToken?.questionsNb}
                </div>

                <br />
                <br />

                <div>
                    {this.state.uiToken && this.state.uiToken?.questionNamesUnanswered.length > 0 ? (
                        <Box>
                            <Button
                                className="btn btn-primary"
                                variant="contained"
                                onClick={() => this.setQuestionsAreVisible()}
                            >
                                {this.state.questionsAreVisible ? "cacher questions" : "afficher questions"}
                            </Button>

                            <br />
                            {this.state.questionsAreVisible ? (
                                <Box>
                                    <h3>
                                        <strong>Questions</strong>
                                    </h3>
                                    <br />

                                    <strong>Questions non répondu :</strong>

                                    <br />

                                    {this.state.uiToken?.questionNamesUnanswered.map((q, index) => {
                                        return (
                                            <Box marginTop={2}>
                                                <strong>Nom de la question : </strong>
                                                {q}
                                                <br />
                                                <strong>Description : </strong>
                                                <br />
                                                {this.state.uiToken?.questionDescriptionsUnanswered[index]}
                                            </Box>
                                        );
                                    })}
                                </Box>
                            ) : null}
                        </Box>
                    ) : null}
                </div>

                {this.state.uiToken?.type === ETokenType.AUTO && this.state.uiToken?.observers.length > 0 ? (
                    <Box>
                        {this.state.uiToken?.observers.map((o, index) => {
                            return (
                                <Box marginTop={2}>
                                    <h3>{`Observateur ${index + 1}`}</h3>
                                    <div>
                                        <strong>Identifiant :</strong> {o.id}
                                    </div>
                                    <div>
                                        <strong>Courriel :</strong> {o.email}
                                    </div>
                                    <div>
                                        <strong>Questionnaire complété:</strong> {o.isStillValid ? "non" : "oui"}
                                    </div>
                                </Box>
                            );
                        })}
                    </Box>
                ) : null}
            </Box>
        );
    }

    render() {
        this.reinitializeButtonState();

        return (
            <Box>
                Identifiant
                <Box>
                    <TextField
                        fullWidth
                        error={!this.uuidIsValid()}
                        helperText={!this.uuidIsValid() ? "Le format de l'identifiant est invalide" : undefined}
                        label={"ex: 1f9fb19e-5247-4ee7-8f7e-63473eba8b05"}
                        variant="outlined"
                        size="medium"
                        onChange={(e) => this.setState({ tokenIdSelected: e.target.value })}
                        value={this.state.tokenIdSelected}
                    />
                </Box>
                {this.updateButtonAfterSearchToken()}
                <br />
                {this.getTextStatusForSearchToken()}
                <br />
                <br />
                <TextField
                    fullWidth
                    error={this.state.formerName === undefined}
                    helperText={this.state.formerName === undefined ? "Nom du formateur obligatoire" : undefined}
                    label={"ex: Line Bourdeau"}
                    variant="outlined"
                    size="medium"
                    onChange={(e) => this.setState({ formerName: e.target.value })}
                    value={this.state.formerName}
                />
                {this.state.generateReportSate === EGenerateReportState.NONE ? (
                    <Button
                        className="btn btn-primary"
                        variant="contained"
                        onClick={() => this.generateResult()}
                        disabled={!this.canGenerateReport()}
                    >
                        {this.props.t("dashboard.survey_token_table_column_names.generate_report")}
                    </Button>
                ) : (
                    this.updateButtonAfterGenerateReport()
                )}
                <br />
                <br />
                {this.getTextStatusForGenerateReport()}
                <br />
                <br />
                {this.state.uiToken ? this.renderInfos() : null}
            </Box>
        );
    }
}
