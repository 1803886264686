import { ICommandResult } from "../ICommandResult";
import { ECommandResultType } from "../../utils/enum-constants";
import { ICommand } from "src/command/command-model/ICommand";
import { ESurveyType } from "src/model/survey/utils/enum-constants";

export interface ExtraArgsCommandResultEndOfSurvey {
    haveAReport: boolean;
    surveyType: ESurveyType;
    surveyTokenId: string;
    unAnsweredQuestionsIds: string[];
}

export class CommandResultEndOfSurvey implements ICommandResult {
    static readonly type = ECommandResultType.COMMAND_RESULT_END_OF_SURVEY;

    readonly type: ECommandResultType = CommandResultEndOfSurvey.type;
    readonly id: string;
    readonly timestamp: string;
    readonly command: ICommand;
    readonly status: number = 200;
    readonly surveyType: ESurveyType;
    readonly surveyTokenId: string;
    readonly unAnsweredQuestionsIds: string[];
    readonly haveAReport: boolean;

    cookies: { [key: string]: string } = {};

    constructor(id: string, timestamp: string, command: ICommand, surveyType: ESurveyType, surveyTokenId: string, unAnsweredQuestionsIds: string[], haveAReport: boolean) {
        this.id = id;
        this.timestamp = timestamp;
        this.command = command;
        this.surveyType = surveyType;
        this.surveyTokenId = surveyTokenId;
        this.unAnsweredQuestionsIds = unAnsweredQuestionsIds;
        this.haveAReport = haveAReport;
    }
}
