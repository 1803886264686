import { EServices, ICommand } from "../ICommand";
import { ECommandType } from "../../utils/enum-constants";

export interface ExtraArgsCommandNextQuestion {
    questionId: string;
    questionAnswer: string;
}

export class CommandNextQuestion implements ICommand {
    static readonly type = ECommandType.COMMAND_NEXT_QUESTION;
    static readonly service: EServices = EServices.SURVEY;

    readonly id: string;
    readonly timestamp: string;
    readonly type: ECommandType = CommandNextQuestion.type;
    readonly service: EServices = CommandNextQuestion.service;
    readonly token: string;
    readonly questionId: string;
    readonly questionAnswer: string;

    constructor(id: string, timestamp: string, token: string, questionId: string, questionAnswer: string) {
        this.id = id;
        this.timestamp = timestamp;
        this.token = token;
        this.questionId = questionId;
        this.questionAnswer = questionAnswer;
    }
}
