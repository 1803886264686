import { EServices, ICommand } from "../ICommand";
import { ECommandType } from "../../utils/enum-constants";

export interface ExtraArgsCommandCreateSession {
    survey_id: string;
    email: string;
    start_date: string;
    end_date: string;
    code_session: string;
}

export class CommandCreateSession implements ICommand {
    static readonly type = ECommandType.COMMAND_CREATE_SESSION;
    static readonly service: EServices = EServices.USER;

    readonly id: string;
    readonly timestamp: string;
    readonly type: ECommandType = CommandCreateSession.type;
    readonly service = CommandCreateSession.service;
    readonly token: string; //for now dont usefull for 4BeSession
    readonly email: string; //this is the email to link to the surveytoken
    readonly start_date: string;
    readonly end_date: string;
    readonly code_session: string;
    readonly survey_id: string;

    constructor(id: string, timestamp: string, survey_id: string, email: string, stard_date: string, end_date: string, code_session: string) {
        this.id = id;
        this.timestamp = timestamp;
        this.token = "";
        this.email = email.toLowerCase();
        this.survey_id = survey_id;
        this.start_date = stard_date;
        this.end_date = end_date;
        this.code_session = code_session;
    }
}
