import ActuComponentBase, { IBaseProps, IBaseState } from "Common/Components/actu-component-base";
import { ComponentWithPredefinedLang } from "Survey/langugae/interface";

import Container from "@material-ui/core/Container";
import BaseTabAbstract from "./base-tab-abstract";
import { TabHeaderAbstract } from "./tab-header-abstract";

interface ITabContainerProps extends IBaseProps, ComponentWithPredefinedLang {
    tabs: { [title: string]: BaseTabAbstract };
    tabCls: any[]; // typeof BaseTabAbstract []
    activeTabTitleOnStart: string;
    headerCls: typeof TabHeaderAbstract;
    onPageChanged: (title: string, lang: string) => void;
}
interface ITabContainerState extends IBaseState {
    activeTab: BaseTabAbstract;
    activeTabCls: any; // typeof BaseTabAbstract
}

export default class TabContainer extends ActuComponentBase<{}, ITabContainerProps, ITabContainerState> {
    constructor(props: ITabContainerProps) {
        super(props);

        this.state = {
            ...this.state,
            activeTab: this.props.tabs[this.props.activeTabTitleOnStart],
            activeTabCls: this.props.tabCls.find((t) => t.title == this.props.activeTabTitleOnStart),
        };
    }
    setActiveTab(title: string, context: TabContainer) {
        if (!this.props.tabs[title]) {
            window.location.href = "/404"; // TODO: should we redirect to dashboard home
            return;
        }
        this.setState({
            activeTab: this.props.tabs[title],
            activeTabCls: this.props.tabCls.find((t) => t.title == title),
        });
        this.props.onPageChanged(title, this.props.selectlang);
    }

    render() {
        return (
            <Container maxWidth="lg">
                <this.props.headerCls
                    setActiveTab={(title) => this.setActiveTab(title, this)}
                    tabs={this.props.tabCls}
                    activeTabTitle={this.state.activeTabCls.title}
                />
                {this.state.activeTab}
            </Container>
        );
    }
}
