import { HookAbstract } from "./hook-abstract";
import { EHookType } from "../utils/enum-constants";
import { EHLListenner, HookLayout } from "./hook-layout";
import { EHPListenner, HookPage } from "./hook-page";
import { EHQListenner, HookQuestion } from "./hook-question";

export class HookFactory {
    static create(type: EHookType, rawFunc: string, listener: string): HookFactoryResult {
        var result: HookFactoryResult = new HookFactoryResult();
        if (type === EHookType.H_LAYOUT) {
            result.hook = new HookLayout(EHLListenner[listener], rawFunc);
        } else if (type === EHookType.H_PAGE) {
            result.hook = new HookPage(EHPListenner[listener], rawFunc);
        } else if (type === EHookType.H_QUESTION) {
            result.hook = new HookQuestion(EHQListenner[listener], rawFunc);
        } else {
            result.error = `Unknown type of Hook [${type}]`;
            return result;
        }
        return result;
    }
}

// FIXME: Circular dependencies with HookAbstract
export class HookFactoryResult {
    hook: HookAbstract | undefined;
    error: string = "";
}
