import {
    CommandFactory,
    CommandCreateUser,
    CommandResultSuccess,
    ExtraArgsCommandCreateUser,
    ESupportedLanguage,
    ESexes,
} from "4common-ts";
import CommandController, { EEndPointSecure } from "Common/command-controller";
import { UserData } from "Common/user-data";
import ControllerAbstract from "../../Common/Controllers/controller-abstract";

export default class SingInController extends ControllerAbstract {
    static myName: string = "sig-in-controller";

    constructor(commandMediator: CommandController, userData: UserData, currentLang: ESupportedLanguage) {
        super(commandMediator, userData, SingInController.myName, currentLang);
    }

    async onSingInAsync(
        email: string,
        firstName: string,
        lastName: string,
        company: string,
        sex: ESexes,
        lang: ESupportedLanguage
    ): Promise<boolean> {
        return new Promise<boolean>(async (resolve, reject) => {
            const command = CommandFactory.create<ExtraArgsCommandCreateUser>(CommandCreateUser.type, this.token, {
                email,
                firstName,
                lastName,
                company,
                sex,
                lang,
            });

            const result = await this.sendCommandAsync(command, EEndPointSecure.PUBLIC);
            if (result === undefined) throw Error("Something went wrong while SingIn.");
            else if (result.type !== CommandResultSuccess.type) {
                resolve(false);
                return;
            }

            resolve(true);
        });
    }
}
