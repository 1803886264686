import { ModelAbstract } from "../model-abstract";
import { ESurveyModelType } from "./utils/enum-constants";

export const allQuestionTypes: Array<string> = [];

export abstract class QuestionAbstract extends ModelAbstract {
    // columns
    question_type: string;
    mandatory: boolean;
    expose_name: string;

    // relations
    survey_id: string;

    constructor(id: string, type: ESurveyModelType, survey_id: string, question_type: string, mandatory: boolean, expose_name: string) {
        super(id, type);

        this.question_type = question_type;

        this.survey_id = survey_id;
        this.mandatory = mandatory;
        this.expose_name = expose_name;
    }
}
