import BaseTabAbstract, { IBaseTabProps, IBaseTabState } from "Common/Components/tabs/base-tab-abstract";
import { EPrivillegeLevels } from "Common/privileges";

import DashboardHomeController from "User/Controllers/dashboard-home-controller";

interface IDashboardHomeProps extends IBaseTabProps {}
interface IDashboardHomeState extends IBaseTabState {}

export default class DashboardHome extends BaseTabAbstract<
    DashboardHomeController,
    IDashboardHomeProps,
    IDashboardHomeState
> {
    static privillegeLevel = EPrivillegeLevels.EMPLOYEE;
    static controllerType = DashboardHomeController;
    static title = "home";
    title = DashboardHome.title;

    render() {
        return <h1>This page is on developpement</h1>;
    }
}
