import { ESupportedLanguage } from "../../language";
import { EDescriptionType } from "../utils/enum-constants";
import { LDescriptionAbstract } from "./description-abstract";

export class LHtmlDescription extends LDescriptionAbstract {
    static type = EDescriptionType.L_HTML_DESCRIPTION;

    constructor(text: string, lang: string | ESupportedLanguage) {
        super(LHtmlDescription.type, text, lang);
    }
}
