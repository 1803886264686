import { ESexes, ESupportedLanguage } from "4common-ts";

import { Trans } from "react-i18next";
import * as EmailValidator from "email-validator";

import ActuComponentBase, { IBaseProps, IBaseState } from "Common/Components/actu-component-base";
import CommonPageFrame from "Common/Components/page-frame";
import { ComponentWithPredefinedLang } from "Survey/langugae/interface";
import SingInController from "../Controllers/sign-in-controller";

import Container from "@material-ui/core/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Button, TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { DEFAULT_LANG, DEFAULT_SEX } from "global";

//Disable copy-paste on textfield
const disableAction = (e) => {
    e.preventDefault();
};

interface ISingInProps extends IBaseProps, ComponentWithPredefinedLang {}
interface ISingInState extends IBaseState {
    email: string;
    emailValidation: string;
    firstName: string;
    lastName: string;
    company: string;
    sex: ESexes;
    lang: ESupportedLanguage;
    genErrorMsg?: string;
    signInFailed: boolean;
}

export default class SingIn extends ActuComponentBase<SingInController, ISingInProps, ISingInState> {
    constructor(props: ISingInProps) {
        super(props);

        this.controller.redirectIfConnect(this.props.selectlang);

        this.state = {
            ...this.state,
            signInFailed: false,
            email: "",
            emailValidation: "",
            firstName: "",
            lastName: "",
            company: "",
            sex: DEFAULT_SEX,
            lang: DEFAULT_LANG,
        };
    }

    private allValuesFilled() {
        const textFields = [
            this.state.email,
            this.state.emailValidation,
            this.state.firstName,
            this.state.lastName,
            this.state.company,
        ];
        return textFields.every((f) => f !== "");
    }

    private emailsAreEquals() {
        return this.state.email === this.state.emailValidation;
    }

    private onSingIn(context: SingIn) {
        return new Promise<void>(async (resolve) => {
            const result = await context.controller.onSingInAsync(
                context.state.email,
                context.state.firstName,
                context.state.lastName,
                context.state.company,
                context.state.sex,
                context.state.lang
            );

            if (result) {
                this.controller.changeLocation("after-registration", this.state.lang, false);
            } else this.setState({ signInFailed: true });

            resolve();
        });
    }

    private areEmailTextFielValid(): [boolean, string, boolean, string, boolean] {
        let emailErrorMsg = "";

        var emailHasError = false;

        if (!EmailValidator.validate(this.state.email)) {
            emailHasError = true;
            emailErrorMsg = this.props.t("sign-in.invalid_email");
        }

        let emailValidationErrorMsg = "";

        var emailValidationError = false;

        if (!EmailValidator.validate(this.state.emailValidation)) {
            emailValidationError = true;
            emailValidationErrorMsg = this.props.t("sign-in.invalid_email");
        } else if (!this.emailsAreEquals()) {
            emailValidationError = true;
            emailValidationErrorMsg = this.props.t("sign-in.email_not_equal");
        }

        const isButtonDisable = this.allValuesFilled() && this.emailsAreEquals();

        return [emailHasError, emailErrorMsg, emailValidationError, emailValidationErrorMsg, isButtonDisable];
    }

    render() {
        const [emailHasError, emailErrorMsg, emailValidationError, emailValidationErrorMsg, isButtonDisable] =
            this.areEmailTextFielValid();
        return (
            <Container maxWidth="md">
                <CommonPageFrame
                    availableLanguages={this.props.availableLanguages}
                    onselectlanguage={(lang) => this.props.onselectlanguage(lang)}
                    disconnectUser={() => this.controller.disconnectUser(this.props.selectlang)}
                    selectlang={this.props.selectlang}
                    userData={this.controller.userData}
                >
                    <Grid container direction="column" display="flex" justifyContent="center" alignItems="center">
                        <Typography
                            align="center"
                            variant="h2"
                            sx={{
                                marginTop: "10px",
                                marginBottom: "10px",
                            }}
                        >
                            <Trans i18nKey="sign-in.welcome" />
                        </Typography>
                        {this.state.genErrorMsg && (
                            <Typography align="center" color="red">
                                *{this.state.genErrorMsg}
                            </Typography>
                        )}
                        <form
                            style={{ width: "100%" }}
                            onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                    this.onSingIn(this);
                                }
                            }}
                        >
                            <TextField
                                fullWidth
                                error={emailHasError}
                                helperText={emailErrorMsg}
                                id={"email"}
                                label={`*${this.props.t("sign-in.email")}`}
                                variant="outlined"
                                onChange={(e) => this.setState({ email: e.target.value })}
                                value={this.state.email}
                                size="medium"
                                autoComplete="email"
                                sx={{
                                    marginTop: "10px",
                                }}
                            />
                            <TextField
                                fullWidth
                                error={emailValidationError}
                                helperText={emailValidationErrorMsg}
                                id={"emailValidation"}
                                label={`*${this.props.t("sign-in.email_validation")}`}
                                variant="outlined"
                                onChange={(e) => this.setState({ emailValidation: e.target.value })}
                                value={this.state.emailValidation}
                                size="medium"
                                autoComplete="emailValidation"
                                sx={{
                                    marginTop: "10px",
                                }}
                                onCut={disableAction}
                                onCopy={disableAction}
                                onPaste={disableAction}
                            />
                            <TextField
                                fullWidth
                                error={this.state.firstName === ""}
                                helperText={this.state.firstName === "" ? this.props.t("sign-in.mandatory") : ""}
                                id={"firstName"}
                                label={`*${this.props.t("sign-in.firstName")}`}
                                variant="outlined"
                                onChange={(e) => this.setState({ firstName: e.target.value })}
                                value={this.state.firstName}
                                size="medium"
                                autoComplete="firstName"
                                sx={{
                                    marginTop: "10px",
                                }}
                            />
                            <TextField
                                fullWidth
                                error={this.state.lastName === ""}
                                helperText={this.props.t(
                                    this.state.firstName === "" ? this.props.t("sign-in.mandatory") : ""
                                )}
                                id={"lastName"}
                                label={`*${this.props.t("sign-in.lastName")}`}
                                variant="outlined"
                                onChange={(e) => this.setState({ lastName: e.target.value })}
                                value={this.state.lastName}
                                size="medium"
                                autoComplete="lastName"
                                sx={{
                                    marginTop: "10px",
                                }}
                            />
                            <TextField
                                fullWidth
                                error={this.state.company === ""}
                                helperText={this.state.firstName === "" ? this.props.t("sign-in.mandatory") : ""}
                                id={"company"}
                                label={`*${this.props.t("sign-in.company")}`}
                                variant="outlined"
                                onChange={(e) => this.setState({ company: e.target.value })}
                                value={this.state.company}
                                size="medium"
                                autoComplete="company"
                                sx={{
                                    marginTop: "10px",
                                }}
                            />
                        </form>

                        <br />
                        <br />
                        <FormControl fullWidth>
                            <InputLabel id="sex-label">{this.props.t("sign-in.sex")}</InputLabel>
                            <Select
                                labelId="sex-label"
                                id="sex"
                                onChange={(e) => this.setState({ sex: e.target.value as ESexes })}
                                value={this.state.sex}
                            >
                                {Object.values(ESexes).map((sex) => {
                                    return <MenuItem value={sex}>{this.props.t(`sign-in.${sex}`)}</MenuItem>;
                                })}
                            </Select>
                        </FormControl>

                        <br />
                        <br />
                        <FormControl fullWidth>
                            <InputLabel id="lang-label">{this.props.t("sign-in.lang")}</InputLabel>
                            <Select
                                labelId="lang-label"
                                id="lang"
                                onChange={(e) => this.setState({ lang: e.target.value as ESupportedLanguage })}
                                value={this.state.lang}
                            >
                                {Object.values(ESupportedLanguage).map((lang) => {
                                    return <MenuItem value={lang}>{lang}</MenuItem>;
                                })}
                            </Select>
                        </FormControl>
                        <Button
                            fullWidth
                            onClick={() => this.onSingIn(this)}
                            className="btn btn-primary"
                            variant="contained"
                            sx={{
                                marginTop: "20px",
                            }}
                            disabled={!isButtonDisable}
                        >
                            <Trans i18nKey="sign-in.sign-in" />
                        </Button>

                        <br />
                        <br />

                        <Typography
                            align="center"
                            variant="h4"
                            sx={{
                                marginTop: "10px",
                                marginBottom: "10px",
                            }}
                        >
                            {this.state.signInFailed ? this.props.t("sign-in.sign_in_failed") : ""}
                        </Typography>
                    </Grid>
                </CommonPageFrame>
            </Container>
        );
    }
}
