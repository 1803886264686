import { QuestionAnswerAbstractModel, allQuestionAnswerTypes } from "./question-answer-abstract";
import { ESurveyModelType } from "./utils/enum-constants";

export class QuestionAnswerChoiceModel extends QuestionAnswerAbstractModel {
    static type: ESurveyModelType = ESurveyModelType.QUESTION_ANSWER_CHOICE;

    choice_id: string;

    constructor(id: string, question_type: string, question_id: string, survey_token_id: string, choice_id: string, extra?: string | undefined | null) {
        super(id, QuestionAnswerChoiceModel.type, question_type, question_id, survey_token_id, extra);

        this.choice_id = choice_id;
    }
}

allQuestionAnswerTypes.push(QuestionAnswerChoiceModel.type);
