import { Component } from "react";
import { Container } from "semantic-ui-react";

import { ESupportedLanguage } from "4common-ts";

import { UserData } from "Common/user-data";
import ActuHeader from "./Parts/header";
import ActuFooter from "./Parts/footer";
import Loading from "./Parts/loading";

interface ISurveyProps {
    availableLanguages: Array<ESupportedLanguage>;
    onselectlanguage: (lang: ESupportedLanguage | string) => void;
    disconnectUser: (lang: ESupportedLanguage | string) => void;
    selectlang: string;
    surveyTitle?: string;
    loading?: boolean;
    userData: UserData;
}

export default class CommonPageFrame extends Component<ISurveyProps, {}> {
    render() {
        let loading = this.props.loading;
        if (loading === undefined) loading = false;

        return (
            <Container justify="center">
                <ActuHeader
                    availableLanguages={this.props.availableLanguages}
                    onselectlanguage={this.props.onselectlanguage}
                    disconnectUser={this.props.disconnectUser}
                    selectlang={this.props.selectlang}
                    surveyTitle={this.props.surveyTitle}
                    userData={this.props.userData}
                />
                <Container
                    maxWidth="md"
                    style={{
                        display: "block",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "50px",
                    }}
                >
                    {loading ? <Loading /> : this.props.children}
                </Container>
                <ActuFooter />
            </Container>
        );
    }
}
