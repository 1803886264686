import { ICommand } from "../../../command-model/ICommand";
import { ICommandResult } from "../../ICommandResult";
import { ECommandResultType } from "../../../utils/enum-constants";

export interface ExtraArgsCommandResultAccessDenied {
    errorMessage: string;
}

export class CommandResultAccessDenied implements ICommandResult {
    static readonly type = ECommandResultType.COMMAND_RESULT_ACCESS_DENIED;

    readonly type: ECommandResultType = CommandResultAccessDenied.type;
    readonly id: string;
    readonly timestamp: string;
    readonly command: ICommand;
    readonly status: number = 401;

    readonly errorMessage: string;

    cookies: { [key: string]: string } = {};

    constructor(id: string, timestamp: string, command: ICommand) {
        this.id = id;
        this.timestamp = timestamp;
        this.command = command;

        this.errorMessage = "Access denied";
    }
}
