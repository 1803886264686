import ActuComponentBase, { IBaseProps, IBaseState } from "Common/Components/actu-component-base";
import { ComponentWithPredefinedLang } from "Survey/langugae/interface";

import ControllerAbstract from "Common/Controllers/controller-abstract";
import { EPrivillegeLevels } from "Common/privileges";

export interface IBaseTabProps extends IBaseProps, ComponentWithPredefinedLang {}
export interface IBaseTabState extends IBaseState, ComponentWithPredefinedLang {}

export default abstract class BaseTabAbstract<
    C extends ControllerAbstract = ControllerAbstract,
    P extends IBaseTabProps = IBaseTabProps,
    S extends IBaseTabState = IBaseTabState
> extends ActuComponentBase<C, P, S> {
    static title: string = "Abstract"; // Must be the same as in the url
    static controllerType: typeof ControllerAbstract = ControllerAbstract;
    static privillegeLevel: EPrivillegeLevels = -1; // Set max lvl y default
    abstract title: string;
}
