import { LLayoutAbstract } from "./layout-abstract";
import { LPageAbstract } from "./page/page-abstract";
import { IAttribute } from "./attribute/attribute";
import { ELLayoutType } from "./utils/enum-constants";
import { HookAbstract } from "./hook/hook-abstract";
import { ESupportedLanguage } from "src/language";
import { LDescriptionAbstract } from "./description/description-abstract";

export class LDefaultLayout extends LLayoutAbstract {
    static type: ELLayoutType = ELLayoutType.L_DEFAULT_LAYOUT;

    constructor(
        attributes: Array<IAttribute>,
        hooks: Array<HookAbstract>,
        containers: Array<LPageAbstract>,
        surveyId: string,
        descriptions: Array<LDescriptionAbstract>,
        langs: Array<ESupportedLanguage>
    ) {
        super(LDefaultLayout.type, attributes, hooks, containers, surveyId, descriptions, langs);
    }
}
