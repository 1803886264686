import { ICommandResult } from "../ICommandResult";
import { ECommandResultType } from "../../utils/enum-constants";
import { ICommand } from "src/command/command-model/ICommand";
import { UISurveyToken } from "src/interfaces";

export interface ExtraArgsCommandResultGetTokenInfos {
    token: UISurveyToken;
}

export class CommandResultGetTokenInfos implements ICommandResult {
    static readonly type = ECommandResultType.COMMAND_RESULT_GET_SURVEY_TOKEN_INFOS;

    readonly type: ECommandResultType = CommandResultGetTokenInfos.type;
    readonly id: string;
    readonly timestamp: string;
    readonly command: ICommand;
    readonly token: UISurveyToken;
    readonly status: number = 200;

    cookies: { [key: string]: string } = {};

    constructor(id: string, timestamp: string, command: ICommand, token: UISurveyToken) {
        this.id = id;
        this.timestamp = timestamp;
        this.command = command;
        this.token = token;
    }
}
