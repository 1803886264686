import { QuestionAnswerAbstractModel, allQuestionAnswerTypes } from "./question-answer-abstract";
import { ESurveyModelType } from "./utils/enum-constants";

export class QuestionAnswerTextModel extends QuestionAnswerAbstractModel {
    static type: ESurveyModelType = ESurveyModelType.QUESTION_ANSWER_TEXT;

    value: string;

    constructor(id: string, question_type: string, question_id: string, survey_token_id: string, value: string, extra?: string | undefined | null) {
        super(id, QuestionAnswerTextModel.type, question_type, question_id, survey_token_id, extra);

        this.value = value;
    }
}

allQuestionAnswerTypes.push(QuestionAnswerTextModel.type);
