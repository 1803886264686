import { EServices, ICommand } from "../ICommand";
import { ECommandType } from "../../utils/enum-constants";

export interface ExtraArgsCommandCreateObserversSurveyTokens {}

export class CommandCreateObserversSurveyTokens implements ICommand {
    static readonly type = ECommandType.COMMAND_CREATE_OBSERVER_SURVEY_TOKENS;
    static readonly service: EServices = EServices.SURVEY;

    readonly id: string;
    readonly timestamp: string;
    readonly type: ECommandType = CommandCreateObserversSurveyTokens.type;
    readonly service: EServices = CommandCreateObserversSurveyTokens.service;
    readonly token: string;

    constructor(id: string, timestamp: string, token: string) {
        this.id = id;
        this.timestamp = timestamp;
        this.token = token;
    }
}
