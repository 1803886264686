import { EServices, ICommand } from "../ICommand";
import { ECommandType } from "../../utils/enum-constants";
import { UIUserProfileStruct } from "src/interfaces";

export interface ExtraArgsCommandRetrieveSurveyResult {
    lang: string;
    userInfos?: UIUserProfileStruct;
    forceRetrieveSurveyResult?: boolean;
}

export class CommandRetrieveSurveyResult implements ICommand {
    static readonly type = ECommandType.COMMAND_RETRIEVE_SURVEY_RESULT;
    static readonly service: EServices = EServices.SURVEY;

    readonly id: string;
    readonly timestamp: string;
    readonly type: ECommandType = CommandRetrieveSurveyResult.type;
    readonly service: EServices = CommandRetrieveSurveyResult.service;
    readonly token: string;
    readonly lang: string;
    readonly userInfos?: UIUserProfileStruct;
    readonly forceRetrieveSurveyResult?: boolean;

    constructor(id: string, timestamp: string, token: string, lang: string, userInfos?: UIUserProfileStruct, forceRetrieveSurveyResult?: boolean) {
        this.id = id;
        this.timestamp = timestamp;
        this.token = token;
        this.lang = lang;
        this.userInfos = userInfos;
        this.forceRetrieveSurveyResult = forceRetrieveSurveyResult;
    }
}
