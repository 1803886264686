import { ModelAbstract } from "../model-abstract";
import { EConnectionResults, EUserModelType } from "./utils/enum-constants";

export class ConnectionAttemptedModel extends ModelAbstract {
    static type: EUserModelType = EUserModelType.CONNECTION_ATTEMPED;

    // Columns
    readonly timestamp: Date;
    readonly result: EConnectionResults;

    // relations
    readonly email: string;

    constructor(id: string, email: string, timestamp: Date, result: EConnectionResults) {
        super(id, ConnectionAttemptedModel.type);

        this.email = email;
        this.timestamp = timestamp;
        this.result = result;
    }
}
