import { EAkey, IAttribute } from "./attribute";
import { QuestionChoiceDisplayAttr } from "./question-choice-display";
import { QuestionMultipleChoiceDisplayAttr } from "./question-multiple-choice-display";
import { QuestionTextDisplayAttr } from "./question-text-display";

export class AttributeFactory {
    static create(key: string, args: { [key: string]: string }): AttributeFactoryResult {
        var result: AttributeFactoryResult = new AttributeFactoryResult();
        if (key === EAkey.A_QUESTION_CHOICE_DISPLAY) {
            result.attribute = new QuestionChoiceDisplayAttr(args);
        } else if (key === EAkey.A_QUESTION_MULTIPLE_CHOICE_DISPLAY) {
            result.attribute = new QuestionMultipleChoiceDisplayAttr(args);
        } else if (key === EAkey.A_QUESTION_TEXT_DISPLAY) {
            result.attribute = new QuestionTextDisplayAttr(args);
        } else {
            result.error = `Unknown type of Attribute [${key}]`;
            return result;
        }
        result.attribute.validate(result);
        return result;
    }
}

// FIXME: Circular dependencies with IAttribute
export class AttributeFactoryResult {
    attribute: IAttribute | undefined;
    error: string = "";
}
