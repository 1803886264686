import { ESupportedLanguage } from "4common-ts";
import CommandController from "Common/command-controller";
import { UserData } from "Common/user-data";
import ControllerAbstract from "../../Common/Controllers/controller-abstract";

export default class DashboardController extends ControllerAbstract {
    static myName: string = "dashboard-controller";

    constructor(commandMediator: CommandController, userData: UserData, currentLang: ESupportedLanguage) {
        super(commandMediator, userData, DashboardController.myName, currentLang);
    }
}
