import { Component } from "react";

import {
    LQuestionAbstract,
    ELQuestionType,
    QuestionAbstract,
    QuestionAnswerAbstract,
    EMetaType,
    QuestionMultipleChoiceDisplayAttr,
    QuestionChoiceDisplayAttr,
    QuestionTextDisplayAttr,
    EHQListenner,
    QuestionIdentifier,
    LChoiceAbstract,
} from "4common-ts";
import {
    TextQuestionComponent,
    ChoiceQuestionComponent,
    QuestionAbstractComponent,
    MultipleChoiceQuestionComponent,
} from "./questions";
import { findAttribute } from "../utils/attribute";
import { makeHook } from "Survey/utils/make_hook";

interface IPropsQuestionBuilder {
    // Common for all question
    onchangeDefault: (arg0: string, questionIdentifier: QuestionIdentifier) => void;
    question: LQuestionAbstract<QuestionAbstract>;
    answer: QuestionAnswerAbstract | undefined;
    addquestion: (id: string, questionComponent: QuestionAbstractComponent<any>) => void;

    // Optional
    choices?: Array<LChoiceAbstract>;
}

export class QuestionBuilder extends Component<IPropsQuestionBuilder> {
    private _render(): any {
        const display = findAttribute<
            QuestionChoiceDisplayAttr | QuestionTextDisplayAttr | QuestionMultipleChoiceDisplayAttr
        >(this.props.question.attributes, EMetaType.DISPLAY);
        if (display === undefined)
            throw Error(`There is no 'display' attribute for question [${this.props.question.question?.expose_name}]`);

        const questionIdentifier = new QuestionIdentifier(
            this.props.question.favorMethod,
            this.props.question.question.id,
            this.props.question.question?.expose_name
        );

        if (this.props.question.type === ELQuestionType.L_QUESTION_CHOICE) {
            return (
                <ChoiceQuestionComponent
                    question={this.props.question}
                    onchange={makeHook[0](
                        EHQListenner.ON_UPDATE_ANSWER,
                        this.props.question.hooks,
                        (choiceId: string) => this.props.onchangeDefault(choiceId, questionIdentifier),
                        1
                    )}
                    answer={this.props.answer}
                    addquestion={this.props.addquestion}
                    choices={this.props.choices!}
                    display={display}
                />
            );
        } else if (this.props.question.type === ELQuestionType.L_QUESTION_MULTIPLE_CHOICE) {
            let multipleChoiceAttr: QuestionMultipleChoiceDisplayAttr | undefined = undefined;
            if (this.props.question.type === ELQuestionType.L_QUESTION_MULTIPLE_CHOICE) {
                multipleChoiceAttr = findAttribute<QuestionMultipleChoiceDisplayAttr>(
                    this.props.question.attributes,
                    EMetaType.DISPLAY
                );
                if (multipleChoiceAttr === undefined) {
                    multipleChoiceAttr = { nbChoice: -1 } as QuestionMultipleChoiceDisplayAttr;
                    this.props.question.attributes.push(multipleChoiceAttr); // So we don't recreate it next time
                }
            }

            return (
                <MultipleChoiceQuestionComponent
                    question={this.props.question}
                    onchange={makeHook[0](
                        EHQListenner.ON_UPDATE_ANSWER,
                        this.props.question.hooks,
                        (choiceId: string) => this.props.onchangeDefault(choiceId, questionIdentifier),
                        1
                    )}
                    answer={this.props.answer}
                    addquestion={this.props.addquestion}
                    choices={this.props.choices!}
                    display={display}
                    multipleChoiceAttr={multipleChoiceAttr}
                />
            );
        } else if (this.props.question.type === ELQuestionType.L_QUESTION_TEXT) {
            return (
                <TextQuestionComponent
                    question={this.props.question}
                    onchange={makeHook[0](
                        EHQListenner.ON_UPDATE_ANSWER,
                        this.props.question.hooks,
                        (text: string) => this.props.onchangeDefault(text, questionIdentifier),
                        1
                    )}
                    answer={this.props.answer}
                    addquestion={this.props.addquestion}
                    display={display}
                />
            );
        } else {
            throw Error(`Unknow type of layout [${this.props.question.type}]`);
        }
    }

    render(): any {
        return this._render();
    }
}
