import { ICommand } from "../../command-model/ICommand";
import { ICommandResult } from "../ICommandResult";
import { ECommandResultType } from "../../utils/enum-constants";

export interface ExtraArgsCommandResultObserversTokensStillValid {
    nonCompletedTokenIds: Array<string>;
}

export class CommandResultObserversTokensStillValid implements ICommandResult {
    static readonly type = ECommandResultType.COMMAND_RESULT_OBSERVERS_TOKENS_STILL_VALID;

    readonly type: ECommandResultType = CommandResultObserversTokensStillValid.type;
    readonly id: string;
    readonly timestamp: string;
    readonly command: ICommand;
    readonly status: number = 200;

    readonly nonCompletedTokenIds: Array<string>;

    cookies: { [key: string]: string } = {};

    constructor(id: string, timestamp: string, command: ICommand, nonCompletedTokenIds: Array<string>) {
        this.id = id;
        this.timestamp = timestamp;
        this.command = command;
        this.nonCompletedTokenIds = nonCompletedTokenIds;
    }
}
