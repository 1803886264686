import React, { Fragment } from "react";
import { Trans, withTranslation } from "react-i18next";
import { ESupportedLanguage } from "4common-ts";
import { UserData } from "Common/user-data";

import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { Link } from "@mui/material";

import logo from "../../../image/Logo_Actualisation.png";

interface IHeaderProps {
    availableLanguages: Array<ESupportedLanguage>;
    onselectlanguage: (lang: ESupportedLanguage | string) => void;
    disconnectUser: (lang: ESupportedLanguage | string) => void;
    selectlang: string;
    surveyTitle?: string;
    userData: UserData;
}

// FIXME: transform this component into an Component object
const ActuHeader: React.FunctionComponent<IHeaderProps> = ({
    availableLanguages,
    onselectlanguage,
    disconnectUser,
    selectlang,
    surveyTitle,
    userData,
}) => {
    return (
        <Grid direction="column" maxWidth="md">
            <Grid container direction="row">
                <Grid item xs={4}>
                    <Box
                        marginTop={4}
                        component="img"
                        sx={{
                            height: "70%",
                            width: "70%",
                        }}
                        alt="logo"
                        src={logo}
                    />
                </Grid>
                <Grid item xs={8} textAlign="right">
                    <Box marginTop={2}>
                        <Typography align="right">
                            {userData.connected ? (
                                <Fragment>
                                    <Typography
                                        id="username"
                                        sx={{
                                            display: "inline-block",
                                            padding: "0 5px",
                                        }}
                                    >
                                        {userData.email}
                                    </Typography>
                                    |
                                    <Link
                                        href={`/dashboard/home/${selectlang}`}
                                        sx={{
                                            display: "inline-block",
                                            padding: "0 5px",
                                        }}
                                    >
                                        <Trans i18nKey="header.dashboard" />
                                    </Link>
                                    |
                                    <Link
                                        id="deconnection"
                                        href="#"
                                        onClick={() => disconnectUser(selectlang)}
                                        sx={{
                                            display: "inline-block",
                                            padding: "0 5px",
                                        }}
                                    >
                                        <Trans i18nKey="header.disconnection" />
                                    </Link>
                                </Fragment>
                            ) : (
                                <Fragment>
                                    <Link href={`/login/${selectlang}`}>
                                        <Trans i18nKey="header.connection" />
                                    </Link>
                                    |
                                    <Link
                                        href={`/sign-in/${selectlang}`}
                                        sx={{
                                            display: "inline-block",
                                            padding: "0 5px",
                                        }}
                                    >
                                        <Trans i18nKey="header.sign-in" />
                                    </Link>
                                </Fragment>
                            )}
                        </Typography>
                    </Box>
                    <Box marginTop={3}>
                        <FormControl
                            sx={{
                                width: 150,
                                maxWidth: "100%",
                                textAlign: "left",
                            }}
                        >
                            <InputLabel id="demo-simple-select-label">Langue</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectlang}
                                label="Langue"
                                onChange={(e) =>
                                    onselectlanguage((e.target as HTMLSelectElement).value as ESupportedLanguage)
                                }
                            >
                                {availableLanguages.map((lang: string) => (
                                    <MenuItem value={lang} key={"language-option-" + lang}>
                                        {lang} {/* TODO: */}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </Grid>
            </Grid>

            <Box
                marginTop={4}
                component="img"
                sx={{
                    height: "100%",
                    width: "100%",
                }}
                alt="photo header"
                src="https://cdn.sanity.io/images/i4kanko4/production/898aa95670e68a698968a1c3d471c4010a5075cc-1920x809.jpg?rect=0,0,1920,720&w=800&h=300&fit=max&auto=format"
            />

            {surveyTitle && (
                <Box marginTop={5}>
                    <Typography align="center" variant="h5" style={{ fontWeight: 500 }}>
                        {surveyTitle}
                    </Typography>
                </Box>
            )}

            <Box
                style={{
                    width: "100%",
                    margin: "10px 0px",
                    border: "5px solid #354189",
                    backgroundColor: "#354189",
                }}
            ></Box>
        </Grid>
    );
};

export default withTranslation()(ActuHeader);
