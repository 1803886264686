import { ModelAbstract } from "../model-abstract";
import { EUserModelType } from "./utils/enum-constants";

export class SurveyTokenUserModel extends ModelAbstract {
    static type: EUserModelType = EUserModelType.SURVEY_TOKEN_USER;

    // Columns
    readonly creation_date: Date;
    readonly expiration_date: Date;
    readonly is_completed: boolean;
    readonly is_disable: boolean;

    // relations
    readonly buy_token_id: string;
    readonly parent_token_id?: string | null;

    constructor(id: string, creation_date: Date, expiration_date: Date, is_completed: boolean, is_disable: boolean, buy_token_id: string, parent_token_id?: string | null) {
        super(id, SurveyTokenUserModel.type);

        this.creation_date = creation_date;
        this.expiration_date = expiration_date;
        this.is_completed = is_completed;
        this.is_disable = is_disable;
        this.buy_token_id = buy_token_id;
        this.parent_token_id = parent_token_id;
    }
}
