import { LQuestionAbstract } from "./question-abstract";
import { IAttribute } from "../../attribute/attribute";
import { QuestionTextModel } from "src/model/survey/question-text";
import { ELQuestionType } from "../../utils/enum-constants";
import { HookAbstract } from "src";
import { LDescriptionAbstract } from "../../description/description-abstract";

export class LTextQuestion extends LQuestionAbstract<QuestionTextModel> {
    static type = ELQuestionType.L_QUESTION_TEXT;

    constructor(attributes: Array<IAttribute>, hooks: Array<HookAbstract>, question: QuestionTextModel, descriptions: Array<LDescriptionAbstract>) {
        super(LTextQuestion.type, attributes, hooks, question, descriptions, undefined);
    }
}
