import { Component } from "react";
import Button from "@mui/material/Button";
import { Box } from "@mui/system";
import { EHPListenner, LLayoutAbstract } from "4common-ts";
import { nextOrPrevousEnum } from "./layout-builder";
import { makeHook } from "Survey/utils/make_hook";
import { Trans } from "react-i18next";

interface IState {}

interface IProps {
    layout: LLayoutAbstract;
    nbpages: number;
    currentpagepos: number;
    enableNextStep: boolean;
    onEndSurveyClick: () => void;
    onNextOrPreviousClickDefault: (n: number) => void;
    hasNextQuestion: (index: number, nbPages: number) => boolean;
    hasPreviousQuestion: (index: number) => boolean;
}

export abstract class LayoutAbstractComponent extends Component<IProps, IState> {
    abstract render(): any;
}

export class DefaultLayoutComponent extends LayoutAbstractComponent {
    render() {
        return (
            <div id={`layout-${this.props.layout.surveyId}`}>
                {this.props.children}
                <Box
                    textAlign="center"
                    style={{
                        width: "100%",
                    }}
                >
                    {this.props.hasPreviousQuestion(this.props.currentpagepos) && (
                        <Button
                            onClick={makeHook[0](EHPListenner.ON_PREVIOUS_PAGE_CLICK, this.props.layout.hooks, () =>
                                this.props.onNextOrPreviousClickDefault(nextOrPrevousEnum.previous)
                            )}
                            variant="outlined"
                            size="medium"
                            style={{
                                margin: "20px 50px",
                            }}
                        >
                            <Trans i18nKey="button_names.previous_page" />
                        </Button>
                    )}
                    {this.props.hasNextQuestion(this.props.currentpagepos, this.props.nbpages) ? (
                        <Button
                            onClick={makeHook[0](EHPListenner.ON_NEXT_PAGE_CLICK, this.props.layout.hooks, () =>
                                this.props.onNextOrPreviousClickDefault(nextOrPrevousEnum.next)
                            )}
                            variant="contained"
                            size="medium"
                            disabled={!this.props.enableNextStep}
                            style={{
                                margin: "20px 50px",
                            }}
                        >
                            <Trans i18nKey="button_names.next_page" />
                        </Button>
                    ) : (
                        <Button
                            onClick={() => {
                                this.props.onEndSurveyClick();
                            }}
                            // {makeHook[0](EHLListenner.ON_END_OF_SURVEY_CLICK, this.props.layout.hooks, () =>
                            //     this.props.onEndSurveyClickDefault()
                            // )}
                            variant="contained"
                            size="medium"
                            color="success"
                            disabled={!this.props.enableNextStep}
                            style={{
                                margin: "20px 50px",
                            }}
                        >
                            <Trans i18nKey="button_names.complete_the_survey" />
                        </Button>
                    )}
                </Box>
            </div>
        );
    }
}
