import { Component } from "react";
import BaseTabAbstract from "./base-tab-abstract";

export interface ITabHeaderAbsProps {
    setActiveTab: (title: string) => void;
    tabs: BaseTabAbstract[];
    activeTabTitle: string;
}

export interface ITabHeaderAbsState {
    activeTab: BaseTabAbstract;
}

export abstract class TabHeaderAbstract<P extends ITabHeaderAbsProps, S extends ITabHeaderAbsState> extends Component<
    P,
    S
> {
    static title = "";

    protected tabs: BaseTabAbstract[] = [];

    setActiveTab(title: string) {
        const activeTab = this.tabs.find((t) => t.title == title);
        if (!activeTab) console.error(`Tab with title [${title}] does not exist.`);
        this.props.setActiveTab(title);
        this.setState({ activeTab: activeTab! });
    }
}
