import {
    CommandFactory,
    CommandGetMyTokens,
    CommandGetTokenInfos,
    CommandResultError,
    CommandResultGetTokenInfos,
    CommandResultMyTokens,
    CommandResultSuccess,
    CommandRetrieveSurveyResult,
    ECommandError,
    EServices,
    ESupportedLanguage,
    ETokens,
    ExtraArgsCommandGetMyTokens,
    ExtraArgsCommandGetTokenInfos,
    ExtraArgsCommandRetrieveSurveyResult,
    UISurveyToken,
    UITokenBase,
} from "4common-ts";
import CommandController, { EEndPointSecure } from "Common/command-controller";
import { UserData } from "Common/user-data";
import ControllerAbstract from "../../Common/Controllers/controller-abstract";
import { UIUserProfileStruct } from "4common-ts/build/interfaces";

export default class DashboardTokenController extends ControllerAbstract {
    static myName: string = "dashboard-token-manager-controller";

    constructor(commandMediator: CommandController, userData: UserData, currentLang: ESupportedLanguage) {
        super(commandMediator, userData, DashboardTokenController.myName, currentLang);
    }

    async getSurveyTokenInfosSync(tokenId: string): Promise<UISurveyToken | undefined> {
        return new Promise<UISurveyToken | undefined>(async (resolve, reject) => {
            const command = CommandFactory.create<ExtraArgsCommandGetTokenInfos>(CommandGetTokenInfos.type, tokenId);

            const result = await this.sendCommandAsync(command, EEndPointSecure.PROTECTED);

            if (result === undefined) throw Error("Something went wrong while request Token to dashboard.");
            if (result.type === CommandResultError.type) {
                resolve(undefined);
                return;
            } else if (result.type !== CommandResultGetTokenInfos.type) {
                resolve(undefined);
                return;
            }

            const token = (result as CommandResultGetTokenInfos).token;

            resolve(token);
        });
    }

    async generateResultAsync(token: string, formerEmail: UIUserProfileStruct): Promise<boolean> {
        return new Promise<boolean>(async (resolve, reject) => {
            const command = CommandFactory.create<ExtraArgsCommandRetrieveSurveyResult>(
                CommandRetrieveSurveyResult.type,
                token,
                { lang: formerEmail.prefered_lang, forceRetrieveSurveyResult: true, userInfos: formerEmail }
            )!;
            const result = await this.sendCommandAsync(command, EEndPointSecure.PUBLIC);
            if (result === undefined) throw Error("Something went wrong while request Token to dashboard.");
            if (result.type !== CommandResultSuccess.type) {
                resolve(false);
            } else resolve(true);
        });
    }
}
