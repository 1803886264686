import { EServices, ICommand } from "../ICommand";
import { ECommandType } from "../../utils/enum-constants";

export interface ExtraArgsCommandGetSurveyStaticIdByName {
    surveyName: string;
}

export class CommandGetSurveyStaticIdByName implements ICommand {
    static readonly type = ECommandType.COMMAND_GET_SURVEY_STATIC_ID_BY_NAME;
    static readonly service: EServices = EServices.SURVEY;

    readonly id: string;
    readonly timestamp: string;
    readonly type: ECommandType = CommandGetSurveyStaticIdByName.type;
    readonly service: EServices = CommandGetSurveyStaticIdByName.service;
    readonly token: string;
    readonly surveyName: string;

    constructor(id: string, timestamp: string, surveyName: string) {
        this.id = id;
        this.timestamp = timestamp;
        this.token = "";
        this.surveyName = surveyName;
    }
}
