import { Component } from "react";

import { LPageAbstract } from "4common-ts";

interface IState {}

interface IProps {
    page: LPageAbstract;
}

export abstract class PageAbstractComponent<P extends IProps = IProps, S extends IState = IState> extends Component<
    P,
    S
> {
    abstract render(): any;
}

export class StaticPageComponent extends PageAbstractComponent {
    render() {
        return <div id={`page-${this.props.page.id}`}>{this.props.children}</div>;
    }
}
