import { v4 as uuidv4 } from "uuid";

import { ICommand } from "./ICommand";
import { CommandAnswerChoiceQuestion } from "./survey/command-answer-choice-question";
import { CommandAnswerMultipleChoiceQuestion } from "./survey/command-answer-multiple-choice-question";
import { CommandAnswerTextQuestion } from "./survey/command-answer-text-question";
import { CommandGetAnswers } from "./survey/command-get-answers";
import { CommandEndOfSurvey } from "./survey/command-end-of-survey";
import { CommandGetPage } from "./survey/command-get-page";
import { CommandRetrieveSurveyResult } from "./survey/command-retrieve-survey-result";
import { CommandGetDescriptionSurvey } from "./survey/command-get-description-survey";
import { CommandGetSurveyAvailableLanguages } from "./survey/command-get-survey-available-languages";
import { CommandLogIn } from "./user/command-log-in";
import { CommandSignIn } from "./user/command-sign-in";
import { CommandBuyToken } from "./user/command-buy-token";
import { CommandGetUserProfile } from "./user/command-get-user-profile";
import { CommandGetMyTokens } from "./user/command-get-my-tokens";
import { CommandGetSurvey } from "./survey/command-get-survey";
import { CommandGetQuestions } from "./survey/command-get-questions";
import { CommandGetAllSurveyNames } from "./survey/command-get-all-survey-names";

import { validateUtcDateString, validateUuidv4 } from "../utils/validate_type";
import { ECommandType } from "../utils/enum-constants";
import { CommandGetSurveyInfos } from "./survey/command-get-survey-infos";
import { CommandCreateUserSurveyToken } from "./user/command-create-user-survey-token";
import { CommandCreateSurveyToken } from "./survey/command-create-survey-token";
import { CommandModifyExpirationDate } from "./common/command-modify-expiration-date";
import { CommandCreateUser } from "./user/command-create-user";
import { CommandGenerateReport } from "./user/command-generate-report";
import { CommandGenerateDynamicToken } from "./user/command-generate-dynamic-token";
import { CommandGetSurveyStaticIdByName } from "./survey/command-get-survey-static-id-by-name";
import { CommandCreateObserversSurveyTokens } from "./survey/command-create-survey-observers-survey-tokens";
import { CommandSendEmailWithTokenToObserver } from "./survey/command-send-email-to-observer";
import { CommandSendEmailConfirmationToAuto } from "./survey/command-send-email-confirmation-to-auto";
import { CommandGetIndicators } from "./survey/command-get-indicators";
import { CommandCreateSession } from "./user/command-create-session";
import { CommandGetMySessions } from "./user/command-get-my-sessions";
import { CommandAssignBuyTokenToASession } from "./user/command-assign-buy-token-to-a-sesion";
import { CommandGetTokenInfos } from "./survey/command-get-token-infos";

export class CommandFactory {
    static recreate<E>(type: ECommandType, id: string, timestamp: string, token: string, extraArgs?: E): ICommand {
        return CommandFactory._create<E>(type, id, timestamp, token, extraArgs);
    }

    static create<E>(type: ECommandType, token: string, extraArgs?: E): ICommand {
        const id = uuidv4();
        const timestamp = new Date().toUTCString();

        return CommandFactory._create<E>(type, id, timestamp, token, extraArgs);
    }

    private static _create<E>(type: ECommandType, id: string, timestamp: string, token: string, extraArgs?: E): ICommand {
        if (!validateUuidv4(id)) throw Error("Invalide uuid format.");
        else if (!validateUtcDateString(timestamp)) throw Error("Invalide timestamp format.");

        switch (type) {
            /*COMMON*/
            case CommandModifyExpirationDate.type: {
                if (!extraArgs) throw Error("Missing extra args");
                return new CommandModifyExpirationDate(id, timestamp, token, extraArgs["expiration_date"], extraArgs["service"], extraArgs["parent_survey_token_id"]);
            }

            /*SURVEY*/
            case CommandGetSurvey.type: {
                if (!extraArgs) throw Error("Missing extra args");
                return new CommandGetSurvey(id, timestamp, token, extraArgs["surveyId"], extraArgs["surveyStatic"], extraArgs["lang"]);
            }
            case CommandRetrieveSurveyResult.type: {
                if (!extraArgs) throw Error("Missing extra args");
                return new CommandRetrieveSurveyResult(id, timestamp, token, extraArgs["lang"], extraArgs["userInfos"], extraArgs["forceRetrieveSurveyResult"]);
            }
            case CommandGetQuestions.type: {
                if (!extraArgs) throw Error("Missing extra args");
                return new CommandGetQuestions(id, timestamp, token, extraArgs["questionIds"], extraArgs["lang"]);
            }
            case CommandGetAnswers.type: {
                if (!extraArgs) throw Error("Missing extra args");
                return new CommandGetAnswers(id, timestamp, token, extraArgs["questionIds"]);
            }
            case CommandAnswerChoiceQuestion.type: {
                if (!extraArgs) throw Error("Missing extra args");
                return new CommandAnswerChoiceQuestion(id, timestamp, token, extraArgs["questionIdentifier"], extraArgs["answerId"]);
            }
            case CommandAnswerMultipleChoiceQuestion.type: {
                if (!extraArgs) throw Error("Missing extra args");
                return new CommandAnswerMultipleChoiceQuestion(id, timestamp, token, extraArgs["questionIdentifier"], extraArgs["answerIds"]);
            }
            case CommandAnswerTextQuestion.type: {
                if (!extraArgs) throw Error("Missing extra args");
                return new CommandAnswerTextQuestion(id, timestamp, token, extraArgs["questionIdentifier"], extraArgs["answerValue"]);
            }
            case CommandEndOfSurvey.type: {
                if (!extraArgs) throw Error("Missing extra args");
                return new CommandEndOfSurvey(id, timestamp, token, extraArgs["lang"]);
            }
            case CommandGetPage.type: {
                if (!extraArgs) throw Error("Missing extra args");
                return new CommandGetPage(id, timestamp, token, extraArgs["pageId"], extraArgs["lang"], extraArgs["randomPage"]);
            }
            case CommandGetDescriptionSurvey.type: {
                if (!extraArgs) throw Error("Missing extra args");
                return new CommandGetDescriptionSurvey(id, timestamp, token, extraArgs["surveyStatic"], extraArgs["lang"]);
            }
            case CommandGetSurveyAvailableLanguages.type: {
                return new CommandGetSurveyAvailableLanguages(id, timestamp, token);
            }
            case CommandGetSurveyInfos.type: {
                if (!extraArgs) throw Error("Missing extra args");
                return new CommandGetSurveyInfos(id, timestamp, extraArgs["surveyId"], extraArgs["infoTypes"], extraArgs["lang"], extraArgs["tokenId"]);
            }
            case CommandCreateSurveyToken.type: {
                if (!extraArgs) throw Error("Missing extra args");
                return new CommandCreateSurveyToken(id, timestamp, token, extraArgs["surveyId"], extraArgs["expiration_date"], extraArgs["parent_survey_token_id"]);
            }
            case CommandGetAllSurveyNames.type: {
                if (!extraArgs) throw Error("Missing extra args");
                return new CommandGetAllSurveyNames(id, timestamp, extraArgs["select"], extraArgs["lang"]);
            }
            case CommandGetSurveyStaticIdByName.type: {
                if (!extraArgs) throw Error("Missing extra args");
                return new CommandGetSurveyStaticIdByName(id, timestamp, extraArgs["surveyName"]);
            }
            case CommandCreateObserversSurveyTokens.type: {
                if (!extraArgs) throw Error("Missing extra args");
                return new CommandCreateObserversSurveyTokens(id, timestamp, token);
            }
            case CommandSendEmailWithTokenToObserver.type: {
                if (!extraArgs) throw Error("Missing extra args");
                return new CommandSendEmailWithTokenToObserver(id, timestamp, token, extraArgs["childTokenId"], extraArgs["lang"]);
            }
            case CommandSendEmailConfirmationToAuto.type: {
                if (!extraArgs) throw Error("Missing extra args");
                return new CommandSendEmailConfirmationToAuto(id, timestamp, token, extraArgs["lang"]);
            }
            case CommandGetIndicators.type: {
                if (!extraArgs) throw Error("Missing extra args");
                return new CommandGetIndicators(id, timestamp, extraArgs["survey_token_id"], extraArgs["lang"]);
            }
            case CommandGetTokenInfos.type: {
                return new CommandGetTokenInfos(id, timestamp, token);
            }

            /*USER*/
            case CommandLogIn.type: {
                if (!extraArgs) throw Error("Missing extra args");
                return new CommandLogIn(id, timestamp, extraArgs["email"], extraArgs["password"]);
            }
            case CommandSignIn.type: {
                if (!extraArgs) throw Error("Missing extra args");
                return new CommandSignIn(id, timestamp, extraArgs["fullName"], extraArgs["email"], extraArgs["password"]);
            }
            case CommandBuyToken.type: {
                if (!extraArgs) throw Error("Missing extra args");
                return new CommandBuyToken(id, timestamp, extraArgs["email"], extraArgs["survey_id"], extraArgs["nb_token_allowed"], extraArgs["description"]);
            }
            case CommandGetUserProfile.type: {
                if (!extraArgs) throw Error("Missing extra args");
                return new CommandGetUserProfile(id, timestamp, extraArgs["email"]);
            }
            case CommandGetMyTokens.type: {
                if (!extraArgs) throw Error("Missing extra args");
                return new CommandGetMyTokens(id, timestamp, extraArgs["email"], extraArgs["tokenType"], extraArgs["tokens"], extraArgs["service"], extraArgs["keySearch"]);
            }
            case CommandCreateUserSurveyToken.type: {
                if (!extraArgs) throw Error("Missing extra args");
                return new CommandCreateUserSurveyToken(
                    id,
                    timestamp,
                    extraArgs["buyTokenId"],
                    extraArgs["email"],
                    extraArgs["expiration_date"],
                    extraArgs["parent_survey_token_id"]
                );
            }
            case CommandCreateUser.type: {
                if (!extraArgs) throw Error("Missing extra args");
                return new CommandCreateUser(
                    id,
                    timestamp,
                    extraArgs["email"],
                    extraArgs["firstName"],
                    extraArgs["lastName"],
                    extraArgs["company"],
                    extraArgs["sex"],
                    extraArgs["lang"]
                );
            }
            case CommandGenerateReport.type: {
                if (!extraArgs) throw Error("Missing extra args");
                return new CommandGenerateReport(id, timestamp, extraArgs["surveyTokenId"]);
            }
            case CommandGenerateDynamicToken.type: {
                if (!extraArgs) throw Error("Missing extra args");
                return new CommandGenerateDynamicToken(id, timestamp, extraArgs["surveyName"]);
            }
            case CommandCreateSession.type: {
                if (!extraArgs) throw Error("Missing extra args");
                return new CommandCreateSession(
                    id,
                    timestamp,
                    extraArgs["survey_id"],
                    extraArgs["email"],
                    extraArgs["start_date"],
                    extraArgs["end_date"],
                    extraArgs["code_session"]
                );
            }
            case CommandGetMySessions.type: {
                if (!extraArgs) throw Error("Missing extra args");
                return new CommandGetMySessions(id, timestamp, extraArgs["email"]);
            }
            case CommandAssignBuyTokenToASession.type: {
                if (!extraArgs) throw Error("Missing extra args");
                return new CommandAssignBuyTokenToASession(id, timestamp, extraArgs["buy_token_id"], extraArgs["session_id"], extraArgs["code_session"]);
            }
            default:
                throw Error(`Unknow type of command [${type}]`);
        }
    }
}
