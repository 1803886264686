import { ICommandResult } from "../ICommandResult";
import { LPageAbstract } from "../../../layout/page/page-abstract";
import { ECommandResultType } from "../../utils/enum-constants";
import { ICommand } from "src/command/command-model/ICommand";

export interface ExtraArgsCommandResultGetPage {
    pageLayout: LPageAbstract;
}

export class CommandResultGetPage implements ICommandResult {
    static readonly type = ECommandResultType.COMMAND_RESULT_GET_PAGE;

    readonly type: ECommandResultType = CommandResultGetPage.type;
    readonly id: string;
    readonly timestamp: string;
    readonly command: ICommand;
    readonly status: number = 200;

    readonly pageLayout: LPageAbstract;

    cookies: { [key: string]: string } = {};

    constructor(id: string, timestamp: string, command: ICommand, pageLayout: LPageAbstract) {
        this.id = id;
        this.timestamp = timestamp;
        this.command = command;
        this.pageLayout = pageLayout;
    }
}
