import React from "react";

import { Progress } from "semantic-ui-react";
import { Box, LinearProgress, Typography } from "@mui/material";

interface ActuProgressBarProps {
    totalPages: number;
    currentPagePos: number;
}

const ActuProgressBar: React.FunctionComponent<ActuProgressBarProps> = ({ totalPages, currentPagePos }) => {
    return (
        <Box
            style={{
                margin: "50px 5%",
            }}
        >
            <Progress value={currentPagePos} total={totalPages} progress="ratio" color="blue" />
        </Box>
    );
};

export default ActuProgressBar;
