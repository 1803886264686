import { Component } from "react";
import Container from "@mui/material/Container";

import { ELLayoutType, LLayoutAbstract, LDefaultLayout, EHLListenner } from "4common-ts";
import { DefaultLayoutComponent } from "./layouts";
import { makeHook } from "Survey/utils/make_hook";

export enum nextOrPrevousEnum {
    previous = -1,
    next = 1,
}

interface IPropsLayoutBulder {
    layout: LLayoutAbstract;
    nbpages: number;
    currentpagepos: number;
    enableNextStep: boolean;
    onNextOrPreviousClickDefault: (n: number) => void;
    onEndSurveyClickDefault: () => void;
}

export class LayoutBuilder extends Component<IPropsLayoutBulder, {}> {
    private hasNextQuestion(index: number, nbPages: number): boolean {
        return index < nbPages;
    }

    private hasPreviousQuestion(index: number): boolean {
        return index > 1;
    }

    private _render(): any {
        if (
            this.props.layout.type === ELLayoutType.L_DEFAULT_LAYOUT ||
            this.props.layout.type === ELLayoutType.L_DISTRIBUTED
        ) {
            return (
                <DefaultLayoutComponent
                    layout={this.props.layout as LDefaultLayout}
                    currentpagepos={this.props.currentpagepos}
                    nbpages={this.props.nbpages}
                    hasNextQuestion={this.hasNextQuestion}
                    hasPreviousQuestion={this.hasPreviousQuestion}
                    onNextOrPreviousClickDefault={this.props.onNextOrPreviousClickDefault} // Must be handle one level above since both actions has the same callback
                    onEndSurveyClick={makeHook[0](EHLListenner.ON_END_OF_SURVEY_CLICK, this.props.layout.hooks, () =>
                        this.props.onEndSurveyClickDefault()
                    )}
                    enableNextStep={this.props.enableNextStep}
                >
                    {this.props.children /* Pages */}
                </DefaultLayoutComponent>
            );
        } else {
            throw Error(`Unknow type of layout this.props.[${this.props.layout.type}]`);
        }
    }

    render(): any {
        return (
            <Container
                maxWidth="md"
                style={{
                    backgroundColor: "white",
                }}
            >
                <div>{this._render()}</div>
            </Container>
        );
    }
}
