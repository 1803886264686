import { EServices, ICommand } from "../ICommand";
import { ECommandType } from "../../utils/enum-constants";

export interface ExtraArgsCommandCreateUserSurveyToken {
    buyTokenId: string;
    email: string;
    expiration_date?: string;
    parent_survey_token_id?: string;
}

export class CommandCreateUserSurveyToken implements ICommand {
    static readonly type = ECommandType.COMMAND_CREATE_USER_SURVEY_TOKEN;
    static readonly service: EServices = EServices.USER;

    readonly id: string;
    readonly timestamp: string;
    readonly type: ECommandType = CommandCreateUserSurveyToken.type;
    readonly service = CommandCreateUserSurveyToken.service;
    readonly token: string; //for now dont usefull for 4BeUser
    readonly buyTokenId: string;
    readonly email: string; //this is the email to link to the surveytoken
    readonly expiration_date: string | undefined;
    readonly parent_survey_token_id: string | undefined;

    constructor(id: string, timestamp: string, buyTokenId: string, email: string, expiration_date?: string, parent_survey_token_id?: string) {
        this.id = id;
        this.timestamp = timestamp;
        this.token = "";
        this.buyTokenId = buyTokenId;
        this.email = email.toLowerCase();
        this.expiration_date = expiration_date;
        this.parent_survey_token_id = parent_survey_token_id;
    }
}
