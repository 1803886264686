import { ICommandResult } from "../../ICommandResult";
import { ECommandResultType } from "../../../utils/enum-constants";
import { ICommand } from "src/command/command-model/ICommand";

export interface ExtraArgsCommandResultFieldsErrors {
    userFieldsErrors: { [key: string]: string };
}

export class CommandResultFieldsErrors implements ICommandResult {
    static readonly type = ECommandResultType.COMMAND_RESULT_FIELDS_ERRORS;

    readonly type: ECommandResultType = CommandResultFieldsErrors.type;
    readonly id: string;
    readonly timestamp: string;
    readonly command: ICommand;
    readonly status: number = 401;

    readonly userFieldsErrors: { [key: string]: string };

    cookies: { [key: string]: string } = {};

    constructor(id: string, timestamp: string, command: ICommand, userFieldsErrors: { [key: string]: string }) {
        this.id = id;
        this.timestamp = timestamp;
        this.command = command;
        this.userFieldsErrors = userFieldsErrors;
    }
}
