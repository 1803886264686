import ControllerAbstract from "../../Common/Controllers/controller-abstract";

import CommandController, { EEndPointSecure } from "../../Common/command-controller";
import { UserData } from "Common/user-data";
import {
    CommandBuyToken,
    CommandFactory,
    CommandGetAllSurveyNames,
    CommandResultError,
    CommandResultSuccess,
    CommandResultSurveyNames,
    ECommandError,
    ESupportedLanguage,
    ESurveyType,
    ExtraArgsCommandBuyToken,
    ExtraArgsCommandGetAllSurveyNames,
    ISurveyInfos,
} from "4common-ts";

export default class DashboardBuyTokenController extends ControllerAbstract {
    static myName: string = "buy-token-controller";

    constructor(commandMediator: CommandController, userData: UserData, currentLang: ESupportedLanguage) {
        super(commandMediator, userData, DashboardBuyTokenController.myName, currentLang);
    }

    fetchAllSurveysDetailsAsync = async (): Promise<ISurveyInfos[]> => {
        return new Promise<ISurveyInfos[]>(async (resolve, reject) => {
            const commandGetAllSurveyInfo = CommandFactory.create<ExtraArgsCommandGetAllSurveyNames>(
                CommandGetAllSurveyNames.type,
                "",
                {
                    select: [ESurveyType.AUTO, ESurveyType.STANDARD, ESurveyType.MULTI],
                    lang: this.currentLang,
                }
            );

            const result = await this.sendCommandAsync(commandGetAllSurveyInfo, EEndPointSecure.PUBLIC);
            if (result === undefined) throw Error("Something went wrong while requesting surveys details.");
            if (result.type === CommandResultError.type) {
                reject((result as CommandResultError).error);
                return;
            } else if (result.type !== CommandResultSurveyNames.type) {
                reject(ECommandError.UNKNOWN);
                return;
            }

            resolve((result as CommandResultSurveyNames).surveyInfos.sort());
        });
    };

    createBuyTokenAsync = async (
        survey_id: string,
        email: string,
        nb_token_allowed: number,
        description?: string
    ): Promise<void> => {
        return new Promise<void>(async (resolve, reject) => {
            const commandCreateBuyToken = CommandFactory.create<ExtraArgsCommandBuyToken>(CommandBuyToken.type, "", {
                email,
                survey_id,
                nb_token_allowed,
                description,
            });

            const result = await this.sendCommandAsync(commandCreateBuyToken, EEndPointSecure.PROTECTED);
            if (result === undefined) throw Error("Something went wrong while creating surveys details.");
            if (result.type === CommandResultError.type) {
                reject((result as CommandResultError).error);
                return;
            } else if (result.type !== CommandResultSuccess.type) {
                reject(ECommandError.UNKNOWN);
                return;
            }
            resolve();
        });
    };
}
