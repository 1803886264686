import {
    CommandFactory,
    ICommand,
    QuestionAnswerAbstract,
    QuestionAnswerChoiceModel,
    QuestionAnswerTextModel,
    QuestionIdentifier,
    QuestionAnswerMultipleChoiceModel,
    ELQuestionType,
    CommandAnswerTextQuestion,
    ExtraArgsCommandAnswerTextQuestion,
    CommandAnswerChoiceQuestion,
    ExtraArgsCommandAnswerChoiceQuestion,
    CommandAnswerMultipleChoiceQuestion,
    ExtraArgsCommandAnswerMultipleChoiceQuestion,
} from "4common-ts";

/**
 *
 */
export abstract class UpdateAnswerCommandAbstract {
    protected questionType: string;
    protected questionIdentifier: QuestionIdentifier;
    protected token: string;

    constructor(question_type: string, questionIdentifier: QuestionIdentifier, token: string) {
        this.questionType = question_type;
        this.questionIdentifier = questionIdentifier;
        this.token = token;
    }

    /**
     *
     * @param value
     */
    abstract generateUpdateAnswerCommand(answerUpdatedModel: QuestionAnswerAbstract): ICommand | undefined;
}

class UpdateAnswerTextCommand extends UpdateAnswerCommandAbstract {
    generateUpdateAnswerCommand(answerUpdatedModel: QuestionAnswerTextModel): ICommand | undefined {
        if (typeof (answerUpdatedModel as QuestionAnswerTextModel).value !== "string") return undefined;

        const command = CommandFactory.create<ExtraArgsCommandAnswerTextQuestion>(
            CommandAnswerTextQuestion.type,
            this.token,
            {
                questionIdentifier: this.questionIdentifier,
                answerValue: (answerUpdatedModel as QuestionAnswerTextModel).value,
            }
        );
        return command;
    }
}

class UpdateAnswerChoiceCommand extends UpdateAnswerCommandAbstract {
    /**
     * Prerequis: answerUpdatedModel.choices_ids is a Set<string>
     *
     * @param answerUpdatedModel
     * @returns
     */
    generateUpdateAnswerCommand(answerUpdatedModel: QuestionAnswerChoiceModel): ICommand | undefined {
        const answerId = (answerUpdatedModel as QuestionAnswerChoiceModel).choice_id;

        const command = CommandFactory.create<ExtraArgsCommandAnswerChoiceQuestion>(
            CommandAnswerChoiceQuestion.type,
            this.token,
            {
                questionIdentifier: this.questionIdentifier,
                answerId,
            }
        );

        return command;
    }
}

class UpdateAnswerMultipleChoiceCommand extends UpdateAnswerCommandAbstract {
    /**
     * Prerequis: answerUpdatedModel.choices_ids is a Set<string>
     *
     * @param answerUpdatedModel
     * @returns
     */
    generateUpdateAnswerCommand(answerUpdatedModel: QuestionAnswerMultipleChoiceModel): ICommand | undefined {
        const answerIds = (answerUpdatedModel as QuestionAnswerMultipleChoiceModel).choice_ids;

        const command = CommandFactory.create<ExtraArgsCommandAnswerMultipleChoiceQuestion>(
            CommandAnswerMultipleChoiceQuestion.type,
            this.token,
            {
                questionIdentifier: this.questionIdentifier,
                answerIds,
            }
        );

        return command;
    }
}

export class UpdateAnswerCommandFactory {
    static Create(
        question_type: ELQuestionType,
        questionIdentifier: QuestionIdentifier,
        token: string
    ): UpdateAnswerCommandAbstract | undefined {
        if (typeof token !== "string") throw Error(" token is undefined");

        if (question_type === ELQuestionType.L_QUESTION_CHOICE)
            return new UpdateAnswerChoiceCommand(question_type, questionIdentifier, token);
        else if (question_type === ELQuestionType.L_QUESTION_MULTIPLE_CHOICE)
            return new UpdateAnswerMultipleChoiceCommand(question_type, questionIdentifier, token);
        else if (question_type === ELQuestionType.L_QUESTION_TEXT)
            return new UpdateAnswerTextCommand(question_type, questionIdentifier, token);

        throw Error("Unknown type of answer: " + question_type);
    }
}
