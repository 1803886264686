import BaseTabAbstract, { IBaseTabProps, IBaseTabState } from "Common/Components/tabs/base-tab-abstract";
import { EPrivillegeLevels } from "Common/privileges";

import DashboardAttendanceListController from "User/Controllers/dashboard-attendance-list-controller";

import Grid from "@mui/material/Grid";
import Container from "@material-ui/core/Container";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import logo from "../../image/actualisation.png";
import eventail from "../../image/eventail.png";
import {
    IParticipantCsvFormat,
    IInformationCsvFormat,
    AttendanceListCsvReader,
    PARTICIPANT_COLUMNS,
} from "./Parts/attendance-list-csv-reader";
import { ESupportedLanguage } from "4common-ts";
import { borderBottomLeftRadius } from "html2canvas/dist/types/css/property-descriptors/border-radius";
import QRCode from "qrcode.react";

interface IDashboardAttendanceListProps extends IBaseTabProps {}
interface IDashboardAttendanceListState extends IBaseTabState {
    session_code: string;
    training_name: string;
    trainer_name: string;
    location: string;
    date: string;
    schedule: string;
    duration: string;
    collumns_attendees: string[];
    infos_1_collumns: string[];
    infos_2_collumns: string[];
    max_attendees: number;
    language: ESupportedLanguage;
    pages: IPage[];
    current_page: IPage | undefined;
    participant_selected: IParticipant | undefined;
    civility: ECivility;
}

interface IParticipant {
    full_name: string;
    email: string;
    signature: string;
}

interface IPage {
    index: number;
    attendees: IParticipant[];
}

enum ECivility {
    MR = "Monsieur",
    MME = "Madame",
}

export default class DashboardAttendanceList extends BaseTabAbstract<
    DashboardAttendanceListController,
    IDashboardAttendanceListProps,
    IDashboardAttendanceListState
> {
    static privillegeLevel = EPrivillegeLevels.ADMIN;
    static controllerType = DashboardAttendanceListController;
    static title = "attendance-list";
    title = DashboardAttendanceList.title;

    constructor(props: IDashboardAttendanceListProps) {
        super(props);
        this.state = {
            ...this.state,
            session_code: "ACTUALISATIONGIM20230713",
            training_name: "Gestionnaire d'impact",
            trainer_name: "Michel Di-Lillo",
            location: "Centre de formation de Montréal",
            duration: "7 heures",
            date: "13 juillet 2023",
            schedule: "9h00 à 16h00",
            infos_1_collumns: [
                this.props.t("attendance_list.session_code"),
                this.props.t("attendance_list.training"),
                this.props.t("attendance_list.trainer"),
            ],
            infos_2_collumns: [
                this.props.t("attendance_list.location"),
                this.props.t("attendance_list.duration"),
                this.props.t("attendance_list.date"),
                this.props.t("attendance_list.schedule"),
            ],
            collumns_attendees: [
                this.props.t("attendance_list.participant"),
                this.props.t("attendance_list.email"),
                this.props.t("attendance_list.signature"),
            ],

            language: ESupportedLanguage.fr,
            max_attendees: 10,
            pages: [],
            current_page: undefined,
            participant_selected: undefined,
            civility: ECivility.MR,
        };
    }

    private initializeAttendanceList() {
        const attendees: IParticipant[] = [];
        for (let i = 0; i < this.state.max_attendees; i++) {
            attendees.push({
                full_name: "Test",
                email: "test@test.ca",
                signature: "",
            });
        }
        const pages: IPage[] = [];
        pages.push({ index: 1, attendees });
        this.setState({ pages, current_page: pages[0] });
    }

    /**
     * The function convert the div to pdf, all the component inside the div div2PDF will be converted
     */
    downloadAttendanceList() {
        let input = window.document.getElementsByClassName("attendance-list")[0] as HTMLElement;
        html2canvas(input, { scale: 2 }).then((canvas) => {
            const img = canvas.toDataURL("image/png");
            const pdf = new jsPDF("portrait", "pt", "letter", true);
            pdf.addImage(img, "png", 10, 10, 590, 775);
            pdf.save(this.getAttendanceListNameFormat());
        });
    }

    getNameFormat(word: string) {
        var w = word
            .normalize("NFD") // Décomposer les caractères accentués
            .replace(/[\u0300-\u036f]/g, "") // Supprimer les diacritiques
            .replace(/\s+/g, ""); // Supprimer les espaces

        w = w.charAt(0).toUpperCase() + w.slice(1).toLowerCase();
        return w;
    }

    private getAttendanceListNameFormat() {
        var nameFormat = this.state.training_name;

        return (
            this.getNameFormat(nameFormat) +
            "_" +
            "P" +
            this.state.current_page?.index +
            "_" +
            this.getNameFormat(this.state.session_code) +
            ".pdf"
        );
    }

    downloadPorteNom() {
        let input = window.document.getElementsByClassName("porte-nom")[0] as HTMLElement;
        html2canvas(input, { scale: 2 }).then((canvas) => {
            const img = canvas.toDataURL("image/png");
            const pdf = new jsPDF("portrait", "pt", "letter", true);
            pdf.addImage(img, "png", 10, 10, 590, 775);
            pdf.save(this.getPorteNomNameFormat());
        });
    }

    private getPorteNomNameFormat() {
        var nameFormat = this.state.participant_selected ? this.state.participant_selected.full_name : "Test";

        return (
            "porte_nom" +
            "_" +
            this.getNameFormat(nameFormat) +
            "_" +
            this.getNameFormat(this.state.session_code) +
            ".pdf"
        );
    }

    private loadAttendanceList(_: string, participantCsvFormat: IParticipantCsvFormat[]) {
        //fill the list
        const attendees = participantCsvFormat.map((participant) => {
            const full_name = participant.firstname + " " + participant.name;
            const email = participant.email;
            const signature = "";
            const attendee: IParticipant = { full_name, email, signature };
            return attendee;
        });

        //split the list in pages 10 attendees per page
        const pages: IPage[] = [];

        attendees.forEach((attendee, index) => {
            const pageIndex = Math.floor(index / this.state.max_attendees);
            if (pages[pageIndex] === undefined) {
                pages.push({ index: pageIndex + 1, attendees: [] });
                pages[pageIndex].attendees.push(attendee);
            } else pages[pageIndex].attendees.push(attendee);
        });

        //fill the last page with empty values , total of 10 attendees per page
        const lastPage = pages[pages.length - 1];
        if (lastPage.attendees.length < this.state.max_attendees) {
            while (lastPage.attendees.length < this.state.max_attendees) {
                const full_name = "";
                const email = "";
                const signature = "";
                lastPage.attendees.push({ full_name, email, signature });
            }
            pages[pages.length - 1] = lastPage;
        }

        this.setState({ pages, current_page: pages[0] });
        this.setState({ participant_selected: pages[0].attendees[0] });
    }

    private loadSessionInformations(_: string, informationCsvFormat: IInformationCsvFormat[]) {
        this.setState({ session_code: informationCsvFormat[0].session_code });
        this.setState({ training_name: informationCsvFormat[0].training_name });
        this.setState({ trainer_name: informationCsvFormat[0].trainer_name });
        this.setState({ location: informationCsvFormat[0].location });
        this.setState({ date: informationCsvFormat[0].date });
        this.setState({ schedule: informationCsvFormat[0].schedule });
        this.setState({ duration: informationCsvFormat[0].duration });
    }

    private selectPage(index: number) {
        this.setState({ current_page: this.state.pages[index] });
        this.setState({ participant_selected: this.state.pages[index].attendees[0] });
    }

    private selectParticipant(participant: IParticipant) {
        this.setState({ participant_selected: participant });
    }

    private hideRowsForPorteNom(cellString: string, isSignatureCell: boolean) {
        if (isSignatureCell) return false;
        else if (cellString === "Test" || cellString === "" || cellString === "test@test.ca") {
            return true;
        } else return false;
    }

    private setNextPartipant() {
        if (this.state.current_page && this.state.participant_selected) {
            const index = this.state.current_page.attendees.indexOf(this.state.participant_selected);
            if (index < this.state.current_page.attendees.length - 1) {
                this.setState({ participant_selected: this.state.current_page.attendees[index + 1] });
                this.selectParticipant(this.state.current_page.attendees[index + 1]);
            }
        }
    }

    private setPreviousPartipant() {
        if (this.state.current_page && this.state.participant_selected) {
            const index = this.state.current_page.attendees.indexOf(this.state.participant_selected);
            if (index > 0) {
                this.setState({ participant_selected: this.state.current_page.attendees[index - 1] });
                this.selectParticipant(this.state.current_page.attendees[index - 1]);
            }
        }
    }

    private getParticipantIndex() {
        if (this.state.current_page && this.state.participant_selected) {
            const index = this.state.current_page.attendees.indexOf(this.state.participant_selected);
            return index;
        }
        return -1;
    }

    private getPageIndex() {
        if (this.state.current_page) {
            const index = this.state.pages.indexOf(this.state.current_page);
            return index;
        }
        return -1;
    }

    private changeCivility() {
        if (this.state.civility === ECivility.MR) this.setState({ civility: ECivility.MME });
        else this.setState({ civility: ECivility.MR });
    }

    private getTextCivility() {
        const text = this.props.t("porte_nom.description_3");
        if (this.state.civility === ECivility.MME) return text.replace("formateur", "formatrice");
        else return text;
    }

    render() {
        if (this.state.current_page === undefined) this.initializeAttendanceList();

        const textStyle = {
            transform: "rotate(180deg)", // Pour inverser horizontalement le texte
        };
        return (
            <Box>
                <Typography variant="h4" fontWeight={"bold"} align="center">
                    {this.props.t("attendance_list.import_session_infos")}
                </Typography>
                <Typography fontWeight={"bold"} align="center">
                    {this.props.t("attendance_list.helper_text_session_infos")}
                </Typography>
                <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <AttendanceListCsvReader onSelect={(name, info) => this.loadSessionInformations(name, info)} />
                </Box>
                <br />
                <Typography variant="h4" fontWeight={"bold"} align="center">
                    {this.props.t("attendance_list.import_participants")}
                </Typography>
                <Typography fontWeight={"bold"} align="center">
                    {this.props.t("attendance_list.helper_text_participants")}
                </Typography>
                <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <AttendanceListCsvReader onSelect={(name, info) => this.loadAttendanceList(name, info)} />
                </Box>
                <br />
                <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    {this.state.pages.map((page, index) => {
                        return (
                            <Button
                                className="btn btn-primary"
                                variant="contained"
                                color="success"
                                onClick={(e) => this.selectPage(index)}
                            >
                                {page.index}
                            </Button>
                        );
                    })}
                </Box>
                <br />
                <div className="attendance-list" style={{ boxShadow: "1px 1px 4px #1D1D35" }}>
                    <Box>
                        <Box
                            marginTop={4}
                            component="img"
                            sx={{
                                height: "40%",
                                width: "40%",
                            }}
                            alt="attendance-list"
                            src={logo}
                        />
                        <br />
                        <br />
                        <Box>
                            <Typography variant="h4" fontWeight={"bold"} align="center">
                                {this.props.t("attendance_list.title") +
                                    ` ( ${this.state.current_page?.index} / ${this.state.pages.length} )`}
                            </Typography>
                        </Box>
                        <br />
                        <Box>
                            <Grid container spacing={3}>
                                <Grid item xs={4} textAlign="center">
                                    <Typography variant="body1" fontWeight={700} fontSize="15px" fontStyle={"bold"}>
                                        {this.state.infos_1_collumns[0].toUpperCase()}
                                    </Typography>
                                    <Typography variant="body1" fontSize="15px">
                                        {this.state.session_code}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4} textAlign="center">
                                    <Typography variant="body1" fontWeight={700} fontSize="15px" fontStyle={"bold"}>
                                        {this.state.infos_1_collumns[1].toUpperCase()}
                                    </Typography>
                                    <Typography variant="body1" fontSize="15px">
                                        {this.state.training_name}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4} textAlign="center">
                                    <Typography variant="body1" fontWeight={700} fontSize="15px" fontStyle={"bold"}>
                                        {this.state.infos_1_collumns[2].toUpperCase()}
                                    </Typography>
                                    <Typography variant="body1" fontSize="15px">
                                        {this.state.trainer_name}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                        <br />
                        <Box>
                            <Grid container spacing={3}>
                                <Grid item xs={3} textAlign="center">
                                    <Typography variant="body1" fontWeight={700} fontSize="15px" fontStyle={"bold"}>
                                        {this.state.infos_2_collumns[0].toUpperCase()}
                                    </Typography>
                                    <Typography variant="body1" fontSize="15px">
                                        {this.state.location}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3} textAlign="center">
                                    <Typography variant="body1" fontWeight={700} fontSize="15px" fontStyle={"bold"}>
                                        {this.state.infos_2_collumns[1].toUpperCase()}
                                    </Typography>
                                    <Typography variant="body1" fontSize="15px">
                                        {this.state.duration}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3} textAlign="center">
                                    <Typography variant="body1" fontWeight={700} fontSize="15px" fontStyle={"bold"}>
                                        {this.state.infos_2_collumns[2].toUpperCase()}
                                    </Typography>
                                    <Typography variant="body1" fontSize="15px">
                                        {this.state.date}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3} textAlign="center">
                                    <Typography variant="body1" fontWeight={700} fontSize="15px" fontStyle={"bold"}>
                                        {this.state.infos_2_collumns[3].toUpperCase()}
                                    </Typography>
                                    <Typography variant="body1" fontSize="15px">
                                        {this.state.schedule}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                        <br />
                        <Box>
                            <Grid container spacing={3}>
                                {this.state.collumns_attendees.map((column_name) => {
                                    return (
                                        <Grid item xs={4} textAlign="center">
                                            <Typography
                                                variant="body1"
                                                fontWeight={700}
                                                fontSize="15px"
                                                fontStyle={"bold"}
                                            >
                                                {column_name.toUpperCase()}
                                            </Typography>
                                        </Grid>
                                    );
                                })}
                            </Grid>

                            {this.state.current_page?.attendees.map((attendee) => {
                                return (
                                    <Box borderBottom={2} padding={3}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={4} textAlign="center">
                                                <Typography variant="body1" fontSize="15px">
                                                    {attendee.full_name}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4} textAlign="center">
                                                <Typography variant="body1" fontSize="15px">
                                                    {attendee.email}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4} textAlign="center">
                                                <Typography variant="body1" fontSize="15px">
                                                    {attendee.signature}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                );
                            })}
                        </Box>
                    </Box>
                    <br />

                    <Typography fontWeight={700} fontSize="15px">
                        {this.props.t("attendance_list.trainer_signature").toUpperCase()}
                    </Typography>
                </div>
                <br />
                <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Button
                        className="btn btn-primary"
                        variant="contained"
                        color="success"
                        onClick={(e) => this.downloadAttendanceList()}
                    >
                        {this.props.t("attendance_list.download_attendance_list")}
                    </Button>
                </Box>
                <br />
                <br />

                <br />
                <br />
                <div className="porte-nom" style={{ boxShadow: "1px 1px 4px #1D1D35" }}>
                    <Box
                        sx={{
                            flexGrow: 1,
                            mt: 2,
                            borderRadius: 4,
                            p: 0,
                            marginRight: 0,
                        }}
                    >
                        <Box>
                            <Box padding={20} borderBottom={2}>
                                <Typography variant="h1" align="center" style={textStyle}>
                                    {this.state.participant_selected
                                        ? this.state.participant_selected.full_name
                                        : "Michel Di-Lillo"}
                                </Typography>
                            </Box>
                            <Box padding={2} borderBottom={2}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} textAlign="center"></Grid>
                                    <Grid item xs={12} textAlign="center">
                                        <Typography variant="h6">{this.props.t("porte_nom.description_1")}</Typography>
                                    </Grid>
                                    <Grid item xs={4} textAlign="left">
                                        <ul>
                                            <li>
                                                <Typography>{this.props.t("porte_nom.li1")}</Typography>
                                            </li>
                                            <li>
                                                <Typography>{this.props.t("porte_nom.li2")}</Typography>
                                            </li>
                                            <li>
                                                <Typography>{this.props.t("porte_nom.li3")}</Typography>
                                            </li>
                                        </ul>
                                    </Grid>
                                    <Grid item xs={4} textAlign="left">
                                        <ul>
                                            <li>
                                                <Typography>{this.props.t("porte_nom.li4")}</Typography>
                                            </li>
                                            <li>
                                                <Typography>{this.props.t("porte_nom.li5")}</Typography>
                                            </li>
                                            <li>
                                                <Typography>{this.props.t("porte_nom.li6")}</Typography>
                                            </li>
                                        </ul>
                                    </Grid>
                                    <Grid item xs={4} textAlign="left">
                                        <ul>
                                            <li>
                                                <Typography>{this.props.t("porte_nom.li7")}</Typography>
                                            </li>
                                            <li>
                                                <Typography>{this.props.t("porte_nom.li8")}</Typography>
                                            </li>
                                            <li>
                                                <Typography>{this.props.t("porte_nom.li9")}</Typography>
                                            </li>
                                        </ul>
                                    </Grid>
                                    <Grid item xs={12} textAlign="center">
                                        {MyQRCodeComponent()}
                                    </Grid>
                                    <Grid item xs={12} textAlign="center">
                                        <Typography variant="h6" fontWeight={"bold"}>
                                            {this.props.t("porte_nom.description_2")}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} textAlign="center">
                                        <Typography variant="h5" fontWeight={"bold"}>
                                            {this.getTextCivility() + this.state.trainer_name}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box padding={20}>
                                <Box
                                    marginTop={1}
                                    component="img"
                                    sx={{
                                        height: "100%",
                                        width: "100%",
                                    }}
                                    alt="certificate"
                                    src={logo}
                                />
                            </Box>
                        </Box>
                    </Box>
                </div>
                <br />
                <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Button
                        className="btn btn-primary"
                        variant="contained"
                        color="success"
                        onClick={(e) => this.changeCivility()}
                    >
                        {this.state.civility}
                    </Button>
                </Box>
                <br />
                <Typography variant="h4" align="center">
                    {"Page : " + (this.getPageIndex() + 1) + "/" + this.state.pages.length}
                </Typography>
                <Typography variant="h4" align="center">
                    {"Participant : " +
                        (this.getParticipantIndex() + 1) +
                        "/" +
                        this.state.current_page?.attendees.filter((partcipant) => partcipant.full_name !== "").length}
                </Typography>
                <br />
                <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Button
                        className="btn btn-primary"
                        variant="contained"
                        color="success"
                        onClick={(e) => this.setPreviousPartipant()}
                        disabled={this.getParticipantIndex() === -1 || this.getParticipantIndex() === 0}
                    >
                        {this.props.t("certificate.previous")}
                    </Button>
                    <Button
                        className="btn btn-primary"
                        variant="contained"
                        color="success"
                        onClick={(e) => this.setNextPartipant()}
                        disabled={
                            this.getParticipantIndex() === -1 ||
                            (this.state.current_page &&
                                this.getParticipantIndex() === this.state.current_page.attendees.length - 1)
                        }
                    >
                        {this.props.t("certificate.next")}
                    </Button>
                </Box>
                <br />
                <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Button
                        className="btn btn-primary"
                        variant="contained"
                        color="success"
                        onClick={(e) => this.downloadPorteNom()}
                    >
                        {this.props.t("attendance_list.download_porte_nom")}
                    </Button>
                </Box>
            </Box>
        );
    }
}

const MyQRCodeComponent = () => {
    const qrCodeData = "https://www.actualisation.com/minute-de-formation";

    return (
        <div>
            <QRCode value={qrCodeData} size={50} />
        </div>
    );
};
