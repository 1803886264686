import {
    CommandResultAccessDenied,
    CommandResultTokenNoMoreValid,
    ICommand,
    ICommandResult,
    ESupportedLanguage,
} from "4common-ts";
import { UserData } from "Common/user-data";
import Cookies from "universal-cookie";
import CommandController, { EEndPointSecure } from "../command-controller";
import config from "../../config/config.json";

export const DEFAULT_CONNECTED_PAGE = "dashboard/home";
export const DEFAULT_NOT_CONNECTED_PAGE = "login";

/**
 * Base class for every view Controller. The controller should handle all the logic
 * and requests to the backend and foward to information to the view/model.
 */
export default abstract class ControllerAbstract {
    protected bearerToken: string = ""; // TODO:
    protected token: string = "";

    public name: string;
    public commandMediator: CommandController;
    public userData: UserData;
    public currentLang: ESupportedLanguage;

    constructor(commandMediator: CommandController, userData: UserData, name: string, currentLang: ESupportedLanguage) {
        this.commandMediator = commandMediator;
        this.userData = userData;
        this.name = name;
        this.currentLang = currentLang;
    }

    getToken() {
        return this.token;
    }

    async sendCommandAsync(command: ICommand, secure: EEndPointSecure): Promise<ICommandResult> {
        return this.commandMediator.handleAsync(command, secure);
    }

    getUrlBasePath() {
        return config["frontend"].baseUrl;
    }

    changeLocation(
        path: string,
        lang: ESupportedLanguage | string,
        includeToken: boolean,
        args?: { [key: string]: string | number }
    ) {
        var newLocation = "";
        if (path.length > 0) newLocation += `/${path}`;
        if (includeToken) newLocation += `/${this.token}`;
        if (lang) newLocation += `/${lang}`;
        if (args && Object.keys(args).length > 0) {
            newLocation += "?";
            Object.keys(args).forEach((key: string) => {
                newLocation += `${key}=${args[key]}&`;
            });
            newLocation.slice(0, newLocation.length - 1); // remove the last &
        }
        window.location.href = newLocation;
    }

    isValideRequestResult(commandResult: ICommandResult, lang: ESupportedLanguage): boolean {
        if (commandResult.type === CommandResultAccessDenied.type) {
            this.redirectToAccessDenied(lang);
            return false;
        } else if (commandResult.type === CommandResultTokenNoMoreValid.type) {
            this.redirectToInvalidToken(lang);
            return false;
        }
        return true;
    }
    setSurveyToken(token: string): void {
        this.token = token;
    }

    // TODO: write to log file
    logError(message: string) {
        console.error(message);
    }

    onLangChange(path: string, lang: ESupportedLanguage | string, includeToken: boolean) {
        this.changeLocation(path, lang, includeToken);
    }

    isConnect(): boolean {
        return this.userData && this.userData.connected;
    }

    redirectIfNotConnected(lang: ESupportedLanguage | string) {
        if (!this.isConnect()) this.changeLocation(DEFAULT_NOT_CONNECTED_PAGE, lang, false);
    }

    redirectIfConnect(lang: ESupportedLanguage | string): void {
        if (this.userData.connected) this.changeLocation(DEFAULT_CONNECTED_PAGE, lang, false);
    }

    disconnectUser(selectlang: ESupportedLanguage | string): void {
        new Cookies().remove("token", { path: "/" });
        window.location.href = `/login/${selectlang}`;
    }

    private redirectToAccessDenied(lang: ESupportedLanguage) {
        this.changeLocation("error/accessDenied", lang, false);
    }

    private redirectToInvalidToken(lang: ESupportedLanguage) {
        this.changeLocation("error/invalidToken", lang, false);
    }
}
