import { EServices, ICommand } from "../ICommand";
import { ECommandType } from "../../utils/enum-constants";

export interface ExtraArgsCommandGetDescriptionSurvey {
    surveyStatic: boolean;
    lang: string;
}

export class CommandGetDescriptionSurvey implements ICommand {
    static readonly type = ECommandType.COMMAND_GET_DESCRIPTION_SURVEY;
    static readonly service: EServices = EServices.SURVEY;

    readonly id: string;
    readonly timestamp: string;
    readonly type: ECommandType = CommandGetDescriptionSurvey.type;
    readonly service: EServices = CommandGetDescriptionSurvey.service;
    readonly token: string;
    readonly surveyStatic: boolean;
    readonly lang: string;

    constructor(id: string, timestamp: string, token: string, surveyStatic: boolean, lang: string) {
        this.id = id;
        this.timestamp = timestamp;
        this.token = token;
        this.surveyStatic = surveyStatic;
        this.lang = lang;
    }
}
