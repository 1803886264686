import { ICommandResult } from "../ICommandResult";
import { ECommandResultType } from "../../utils/enum-constants";
import { ICommand } from "src/command/command-model/ICommand";
import { SessionModel } from "src/model/user/session";
import { BuyTokenModel } from "src/model/user/buy-token";

export interface ExtraArgsCommandResultGetMySessions {
    sessions: SessionModel[];
    buyTokens: BuyTokenModel[];
}

export class CommandResultGetMySessions implements ICommandResult {
    static readonly type = ECommandResultType.COMMAND_RESULT_CREATE_SESSION;

    readonly type: ECommandResultType = CommandResultGetMySessions.type;
    readonly id: string;
    readonly timestamp: string;
    readonly command: ICommand;
    readonly status: number = 200;
    readonly buyTokens: BuyTokenModel[];
    readonly sessions: SessionModel[];

    cookies: { [key: string]: string } = {};

    constructor(id: string, timestamp: string, command: ICommand, sessions: SessionModel[], buyTokens: BuyTokenModel[]) {
        this.id = id;
        this.timestamp = timestamp;
        this.command = command;
        this.sessions = sessions;
        this.buyTokens = buyTokens;
    }
}
