import { ICommandResult } from "../ICommandResult";
import { ECommandResultType } from "../../utils/enum-constants";
import { LDescriptionSurvey } from "src";
import { ICommand } from "src/command/command-model/ICommand";

export interface ExtraArgsCommandResultGetDescriptionSurvey {
    descriptionSurvey: LDescriptionSurvey;
}

export class CommandResultGetDescriptionSurvey implements ICommandResult {
    static readonly type = ECommandResultType.COMMAND_RESULT_GET_DESCRIPTION_SURVEY;

    readonly type: ECommandResultType = CommandResultGetDescriptionSurvey.type;
    readonly id: string;
    readonly timestamp: string;
    readonly command: ICommand;
    readonly status: number = 200;

    readonly descriptionSurvey: LDescriptionSurvey;

    cookies: { [key: string]: string } = {};

    constructor(id: string, timestamp: string, command: ICommand, descriptionSurvey: LDescriptionSurvey) {
        this.id = id;
        this.timestamp = timestamp;
        this.command = command;
        this.descriptionSurvey = descriptionSurvey;
    }
}
