import { Fragment } from "react";
import Container from "@material-ui/core/Container";
import {
    LQuestionAbstract,
    QuestionAnswerAbstract,
    QuestionAbstract,
    UIModelStruct,
    QuestionIdentifier,
    LChoiceAbstract,
    isQuestionWithChoices,
    ESupportedLanguage,
    LDescriptionSurvey,
    ESurveyType,
    CommandResultEndOfSurvey,
    ELLayoutType,
} from "4common-ts";

import ActuComponentBase, { IBaseProps, IBaseState } from "../../Common/Components/actu-component-base";
import SurveyController from "../Controllers/survey-controller";
import CommonPageFrame from "../../Common/Components/page-frame";
import ActuProgressBar from "../../Common/Components/Parts/progress-bar";
import Waiting from "../../Common/Components/Parts/waiting";
import { LayoutBuilder, nextOrPrevousEnum } from "./Layout/layout-builder";
import { PageBuilder } from "./Layout/page/page-builder";
import { QuestionBuilder } from "./Layout/questions/question-builder";
import { getQuestionFromPageById, getQuestionLayoutFromPageById } from "./Layout/utils/question";
import { updatePageLayout } from "./Layout/utils/layout";
import { QuestionAbstractComponent } from "./Layout/questions/questions";
import { InstaciateAnswerFactory } from "Survey/answers/instanciate-answer-model";
import { UpdateAnswerFactory } from "Survey/answers/update-answer-model";
import { validateAnswersLogic, validateAnswersWrapperComponent } from "Survey/answers/answer-validator";
import {
    getNextPageId,
    getPageById,
    getPagePosition,
    getPageQuestionIds,
    getPreviousPageId,
    includesRandomQuestions,
    isPagePriorTo,
} from "./Layout/utils/page";
import { initializeMakeHook } from "Survey/utils/make_hook";
import { AnswerAbstract } from "Survey/answers/answer-wrapper";
import Box from "@mui/material/Box";

interface ISurveyProps extends IBaseProps {
    controller: SurveyController;
}

interface ISurveyState extends IBaseState, UIModelStruct {}

export default class Survey extends ActuComponentBase<SurveyController, ISurveyProps, ISurveyState> {
    /**
     * Dict where the key is the question entity id.
     */
    private currentQuestionsComponents: { [id: string]: QuestionAbstractComponent };

    constructor(props: ISurveyProps) {
        super(props);

        this.state = {
            ...this.state,
            currentPageId: undefined,
            surveyModel: undefined,
            questionAnswerModels: {},
            hasError: false,
            surveyIsLoading: false,
        };

        this.currentQuestionsComponents = {}; // Needed to show

        initializeMakeHook(this);
    }

    /**
     *
     */
    private async requestSurveyById(): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            this.setState({ surveyIsLoading: true });
            const result = await this.controller.requestSurveyByIdAsync(this.props.selectlang);

            if (result === undefined) {
                this.setState({ hasError: true });
                reject();
                return;
            }

            this.setState({
                surveyModel: result[0],
                questionAnswerModels: result[1],
                currentPageId: result[2],
                layout: result[3],
                enableNextStep: this.validateCurrentQuestions(
                    getPageById(result[2], result[3].containers)?.containers || [],
                    result[1]
                ),
                surveyIsLoading: false,
            });

            resolve();
        });
    }

    async requestAllAnswers(): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            const missingAnswers: Array<string> = [];
            this.state.surveyModel?.questions.forEach((question) => {
                let questionId = "";
                if (typeof question === "string") questionId = question;
                else questionId = question.id;

                if (this.state.questionAnswerModels[questionId] === undefined) missingAnswers.push(questionId);
            });

            // TODO: request answer models
            // const result = await this.controller.

            // TODO: update state

            resolve();
        });
    }

    async requestQuestionsByIds(questionIds: Array<string>): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            // TODO: request question models
            // const result = await this.controller.

            // TODO: update state

            resolve();
        });
    }

    /**
     * Return true if all questions in the current page have been answer.
     *  This function should be call if the error are to be display.
     *
     * @returns
     */
    private validateCurrentQuestionsAndShow(): boolean {
        const result = validateAnswersWrapperComponent(
            Object.values(this.currentQuestionsComponents),
            this.state.questionAnswerModels
        );
        var valide = true;
        Object.keys(result).forEach((questionId) => {
            const errorList = result[questionId];
            if (errorList.length) {
                this.currentQuestionsComponents[questionId].showError(errorList);
                valide = false;
            }
        });
        return valide;
    }

    /**
     * Return true if all questions int eh current page have been answer.
     *  This function should be call if the error are not to be display.
     *
     * @returns
     */
    private validateCurrentQuestions(
        lquestion: Array<LQuestionAbstract<any>>,
        answer: { [key: string]: QuestionAnswerAbstract }
    ): boolean {
        const result = validateAnswersLogic(lquestion, answer, this.state.layout?.type, this.state.currentPageId);
        return Object.values(result).every((l) => l.length === 0);
    }

    /**
     *
     * @param action
     * @param context
     * @returns
     */
    private async onNextOrPreviousClick(action: nextOrPrevousEnum, context: Survey): Promise<void> {
        return new Promise(async (resolve, reject) => {
            if (context.state.layout === undefined || context.state.currentPageId === undefined) {
                reject();
                return;
            }

            if (action === nextOrPrevousEnum.next && !context.validateCurrentQuestionsAndShow()) {
                resolve();
                return;
            }

            let pageToDisplayId: string | undefined;
            let pages = context.state.layout.containers!;

            if (action === nextOrPrevousEnum.next) pageToDisplayId = getNextPageId(context.state.currentPageId, pages);
            else if (action === nextOrPrevousEnum.previous)
                pageToDisplayId = getPreviousPageId(context.state.currentPageId, pages);

            if (pageToDisplayId === undefined) {
                context.setState({ hasError: true });
                reject();
                return;
            }

            /*
                Remove outdated temporary pages.
                FIXME: this should be handle differently but until we don't have a better understanding
                of what made a temporary page outdated, leave it this way.
            */
            if (action === nextOrPrevousEnum.previous) {
                const currentPage = getPageById(context.state.currentPageId, pages)!;
                if (currentPage.isTemporary && isPagePriorTo(pageToDisplayId, context.state.currentPageId, pages)) {
                    // delete questions associate to temporary page
                    currentPage.containers!.forEach((questionLayout) => {
                        delete this.state.questionAnswerModels[questionLayout.question.id];
                    });
                    // delete pages
                    pages = pages.filter((p) => p.id !== context.state.currentPageId);
                    context.state.layout.containers = pages;
                }
            }

            const areRandomQuestions = includesRandomQuestions(this.state.layout);
            const randomPage = areRandomQuestions ? getPageById(pageToDisplayId, pages)! : undefined;

            const pageLayout = await context.controller.requestPageByIdAsync(
                pageToDisplayId,
                context.props.selectlang,
                randomPage
            );
            const nextPageQuestionIds = getPageQuestionIds(pageLayout);
            const pageAnswers = await context.controller.requestAnswersByQuestionIdsAsync(
                nextPageQuestionIds,
                this.props.selectlang
            );

            const layout = context.state.layout;
            const updatedLayout = updatePageLayout(pageLayout, layout);
            if (updatedLayout === undefined) {
                resolve();
                return;
            }

            context.currentQuestionsComponents = {}; // Reset the current questions
            context.setState({
                currentPageId: pageLayout.id,
                layout: updatedLayout,
                questionAnswerModels: { ...context.state.questionAnswerModels, ...pageAnswers },
                enableNextStep: context.validateCurrentQuestions(
                    getPageById(pageLayout.id, updatedLayout.containers)?.containers || [],
                    pageAnswers
                ),
            });
            // Scroll to top
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
            resolve();
        });
    }

    /**
     *
     * @param newValue
     * @param questionId
     * @param context
     * @returns
     */
    private onAnswerUpdate(answer: AnswerAbstract, questionIdentifier: QuestionIdentifier, context: Survey): void {
        const currentPage = getPageById(context.state.currentPageId!, context.state.layout?.containers);
        if (currentPage === undefined) return; // TODO: log error

        const questionLayout = getQuestionLayoutFromPageById(questionIdentifier.questonId, currentPage.containers);
        if (questionLayout === undefined) return; // TODO: log error

        const questionAnswerModels = context.state.questionAnswerModels;
        let answerToUpdate = questionAnswerModels[questionIdentifier.questonId];
        if (answerToUpdate === undefined) {
            answerToUpdate = InstaciateAnswerFactory.Create(questionLayout);
        }

        const attrs = questionLayout.attributes;
        const answerUpdatedModel = UpdateAnswerFactory.Create(questionLayout, attrs, answerToUpdate).updateAnswer(
            answer
        );
        if (answerUpdatedModel === undefined)
            // FIXME: handle error (if error)
            return;

        questionAnswerModels[questionIdentifier.questonId] = answerUpdatedModel;

        context.setState({
            questionAnswerModels,
            enableNextStep: this.validateCurrentQuestions(currentPage.containers!, questionAnswerModels), //TODO:
        });

        // We don't wait for the result
        // TODO: display (or log) if an error occured.
        context.controller.saveUserAnswerAsync(
            questionLayout,
            answerUpdatedModel,
            questionIdentifier,
            this.props.selectlang
        );
    }

    private onEndSurveyClick(context: Survey): Promise<void> {
        return new Promise<void>(async (resolve) => {
            const result = await context.controller.endSurveyAsync(this.props.selectlang);
            const surveyType = (result as CommandResultEndOfSurvey).surveyType;
            const haveAReport = (result as CommandResultEndOfSurvey).haveAReport;
            const unAnsweredQuestionsIds = (result as CommandResultEndOfSurvey).unAnsweredQuestionsIds;

            if (unAnsweredQuestionsIds.length !== 0) {
                // TODO: when answers missing Display message to user
            }

            if (surveyType === ESurveyType.AUTO) {
                const childrenTokenIds = await context.controller.createObserverSurveyTokensAsync(
                    this.props.selectlang
                );

                if (childrenTokenIds.length !== 0) {
                    childrenTokenIds.forEach(async (childTokenId) => {
                        await context.controller.sendEmailWithTokenToObserversAsync(
                            childTokenId,
                            this.props.selectlang
                        );
                    });

                    await context.controller.sendEmailConfirmationToAutoAsync(this.props.selectlang);
                }
            }

            try {
                await context.controller.generateResultAsync(this.props.selectlang);
                if (haveAReport) context.controller.changeLocation("thanks", this.props.selectlang, true);
                else context.controller.changeLocation("indicators", this.props.selectlang, true);
            } catch (e: any) {
                console.log(e.toString());
            }

            resolve();
        });
    }

    private addQuestion(questionId: string, questionComponent: QuestionAbstractComponent<any>, context: Survey): void {
        context.currentQuestionsComponents[questionId] = questionComponent;
    }

    /**
     * No validation check is done.
     *
     * @param questionId
     * @returns
     */
    private getChoices(questionId: string): Array<LChoiceAbstract> {
        const currentPage = getPageById(this.state.currentPageId!, this.state.layout?.containers)!;
        const question = getQuestionFromPageById(questionId, currentPage.containers)!;
        if (isQuestionWithChoices(question)) return question.containers as Array<LChoiceAbstract>;
        else {
            console.log(`question [${questionId}] is of type [${question.type}] and hasno choices`);
            return [];
        }
    }

    /**
     * No validation check is done.
     *
     * @param questionId
     * @returns
     */
    private getAnswer(questionId: string): QuestionAnswerAbstract | undefined {
        return this.state.questionAnswerModels[questionId];
    }

    private getSurveyName(lang: ESupportedLanguage) {
        const lDescription = this.state.layout?.descriptions.find((desc) => desc.lang === lang);
        let description = "";

        if (lDescription) {
            if (lDescription.type === "survey") description = (lDescription as LDescriptionSurvey).name;
        }
        return description;
    }

    /**
     *
     * @returns
     */
    render() {
        if (this.state.hasError) return "An error occured :'(";

        if (this.state.layout === undefined && !this.state.surveyIsLoading) {
            this.requestSurveyById();
        }

        const pages = this.state.layout?.containers;
        const currentPage = getPageById(this.state.currentPageId!, pages);
        const isLoading = pages === undefined || currentPage === undefined;

        return (
            <Container maxWidth="md">
                <CommonPageFrame
                    availableLanguages={
                        this.state.layout !== undefined
                            ? this.state.layout.langs
                            : [this.props.selectlang /* Set a default languages */]
                    }
                    onselectlanguage={(lang) => this.props.onselectlanguage(lang)}
                    disconnectUser={() => this.controller.disconnectUser(this.props.selectlang)}
                    selectlang={this.props.selectlang}
                    surveyTitle={isLoading ? "" : this.getSurveyName(this.props.selectlang)}
                    loading={isLoading}
                    userData={this.controller.userData}
                >
                    {isLoading ? (
                        <Fragment />
                    ) : (
                        <Fragment>
                            <ActuProgressBar
                                totalPages={pages.length}
                                currentPagePos={getPagePosition(this.state.currentPageId!, pages)}
                            />

                            <LayoutBuilder
                                layout={this.state.layout!}
                                nbpages={pages.length}
                                currentpagepos={getPagePosition(this.state.currentPageId!, pages)}
                                onNextOrPreviousClickDefault={(action: nextOrPrevousEnum) =>
                                    this.onNextOrPreviousClick(action, this)
                                }
                                onEndSurveyClickDefault={() => {
                                    this.onEndSurveyClick(this);
                                    this.setState({ surveyIsLoading: true });
                                }}
                                enableNextStep={this.state.surveyIsLoading ? false : this.state.enableNextStep}
                            >
                                <PageBuilder page={currentPage} lang={this.props.selectlang}>
                                    {currentPage.containers!.map((question: LQuestionAbstract<QuestionAbstract>) => (
                                        <QuestionBuilder
                                            question={question}
                                            answer={this.getAnswer(question.question.id)}
                                            choices={this.getChoices(question.question.id)}
                                            onchangeDefault={(
                                                newValue: string,
                                                questionIdentifier: QuestionIdentifier
                                            ) => this.onAnswerUpdate(newValue, questionIdentifier, this)}
                                            addquestion={(id, component) => this.addQuestion(id, component, this)}
                                        />
                                    ))}
                                </PageBuilder>
                            </LayoutBuilder>
                            <Box hidden={!this.state.surveyIsLoading} sx={{ width: "100%" }}>
                                <Waiting></Waiting>
                            </Box>
                        </Fragment>
                    )}
                </CommonPageFrame>
            </Container>
        );
    }
}
