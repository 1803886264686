import { IAttribute } from "./attribute/attribute";
import { LDescriptionAbstract } from "./description/description-abstract";
import { HookAbstract } from "./hook/hook-abstract";

export abstract class ContainerAbstract<container_type extends ContainerAbstract<any, any> | undefined, enum_type> {
    type: enum_type;
    attributes: Array<IAttribute>;
    containers: Array<container_type> | undefined;
    hooks: Array<HookAbstract<any>>;
    isTemporary: boolean;
    descriptions: Array<LDescriptionAbstract>;

    constructor(
        type: enum_type,
        attributes: Array<IAttribute>,
        hooks: Array<HookAbstract>,
        descriptions: Array<LDescriptionAbstract>,
        containers: Array<container_type> | undefined
    ) {
        this.type = type;
        this.attributes = attributes;
        this.hooks = hooks;
        this.descriptions = descriptions;
        this.containers = containers;
        this.isTemporary = false;
    }
}
