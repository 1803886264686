import { ETokenType, UIToken, UITokenBuyToken } from "4common-ts";
import { TextField } from "@material-ui/core";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Trans } from "react-i18next";
import Typography from "@mui/material/Typography";
import BaseTabAbstract, { IBaseTabProps, IBaseTabState } from "Common/Components/tabs/base-tab-abstract";
import { EPrivillegeLevels } from "Common/privileges";

import DashboardInviteCollaboratorController from "User/Controllers/dashboard-invite-collaborators-controller";
import { convertDateToUTC, isDateFormatValid } from "Common/date";
import { EMAIL_SPLIT_CHAR, validateMultipleEmail } from "Common/email";
import { IParticipantCsvFormat, ParticipantCsvReader } from "./Parts/participant-csv-reader";
import DynamicEmailsTextFields from "Common/Components/dynamic-emails-textfields";

const SURVEY_SELECT_COLOR = "#CCFFE5";
const MISSING_SURVEY_SELECT_COLOR = "#ff4d4d";

interface IDashboardInviteCollaboratorProps extends IBaseTabProps {
    controller: DashboardInviteCollaboratorController;
}
interface IDashboardInviteCollaboratorState extends IBaseTabState {
    surveyTokens: UIToken[];
    buyTokens: UITokenBuyToken[];
    surveyTokenId: string;
    emailUser: string;
    expirationDate: string;
    loadingToken: boolean;
    tokenFirstLoaded: boolean;
    isSurveyTokenCreated: boolean;
    hasUserEmailError: boolean;
    emailsToString: string;
    emails: string[];
}

export default class DashboardInviteCollaborator extends BaseTabAbstract<
    DashboardInviteCollaboratorController,
    IDashboardInviteCollaboratorProps,
    IDashboardInviteCollaboratorState
> {
    static privillegeLevel = EPrivillegeLevels.ADMIN;
    static controllerType = DashboardInviteCollaboratorController;
    static title = "invite-collaborator";
    title = DashboardInviteCollaborator.title;

    constructor(props: IDashboardInviteCollaboratorProps) {
        super(props);

        this.state = {
            ...this.state,
            surveyTokens: [],
            buyTokens: [],
            emails: [],
            surveyTokenId: this.props.t("dashboard.create_token.no_buy_token_selected"),
            emailUser: "",
            emailsToString: "",
            expirationDate: "",
            loadingToken: true,
            tokenFirstLoaded: false,
            isSurveyTokenCreated: false,
            hasUserEmailError: false,
        };
    }

    private fetchTokenData() {
        this.setState({ loadingToken: true, tokenFirstLoaded: true });
        return new Promise<void>(async (resolve) => {
            const tokens = await this.controller.getTokenAsync(this.controller.userData.email!);
            const surveyTokens = tokens.filter((uiToken) => uiToken.type === ETokenType.AUTO) as UIToken[];
            const buyTokens = tokens.filter((token) => token.type === ETokenType.BUY_TOKEN) as UITokenBuyToken[];
            this.setState({ surveyTokens, buyTokens, loadingToken: false, tokenFirstLoaded: true });
        });
    }

    private createSurveyToken() {
        return new Promise<void>(async (resolve) => {
            const expiration_date = this.state.expirationDate === "" ? undefined : this.state.expirationDate;

            const missingEmails = await this.controller.createSurveyTokenAsync(
                this.getSurveyToken()!.buyTokenId,
                this.state.emailUser.split(EMAIL_SPLIT_CHAR),
                this.state.surveyTokenId,
                expiration_date
            );
            this.setState({ isSurveyTokenCreated: !missingEmails, hasUserEmailError: !missingEmails });
            this.emptyFields();
            resolve();
        });
    }

    private setEmailUser(emailUser: string) {
        this.setState({ emailUser });
    }
    private setDate(date: string) {
        this.setState({ expirationDate: date });
    }

    private setSurveyTokenId = (surveyTokenId: string) => {
        this.setState({ surveyTokenId, isSurveyTokenCreated: false });
    };

    private emptyFields() {
        this.setState({
            surveyTokenId: this.props.t("dashboard.create_token.no_buy_token_selected"),
            emailUser: "",
            expirationDate: "",
        });
    }

    private getSurveyToken() {
        return this.state.surveyTokens.find((surveyToken) => surveyToken.id === this.state.surveyTokenId);
    }

    private surveyTokenSelectedTitle() {
        var title = this.props.t("dashboard.create_token.no_buy_token_selected");

        const surveyToken = this.state.surveyTokens.find((surveyToken) => surveyToken.id === this.state.surveyTokenId);
        if (surveyToken === undefined) return title;
        else {
            return `${surveyToken.surveyInternalName}(${surveyToken.type}): ${this.state.surveyTokenId}`;
        }
    }

    private getDateExpirationAlert() {
        if (this.state.surveyTokenId === this.props.t("dashboard.create_token.no_buy_token_selected")) {
            return "";
        } else
            return (
                this.props.t("dashboard.create_token.alert_date_expiration_existed_1") +
                convertDateToUTC(this.getSurveyToken()?.expiration_date) +
                this.props.t("dashboard.create_token.alert_date_expiration_existed_2")
            );
    }

    private setRowBackgroundColor(surveyTokenId: string) {
        if (
            this.state.surveyTokenId === surveyTokenId &&
            this.state.surveyTokenId !== this.props.t("dashboard.create_token.no_buy_token_selected")
        ) {
            return SURVEY_SELECT_COLOR;
        } else return "#E4E4F1";
    }

    private allTokenAreUsed(buyTokenId: string) {
        const nbTokenUsed = this.state.buyTokens.find((buyToken) => buyToken.id === buyTokenId);
        const nbTokenAllowed = this.state.buyTokens.find((buyToken) => buyToken.id === buyTokenId);
        if (nbTokenUsed && nbTokenAllowed) return nbTokenAllowed.nbTokenAllowed - nbTokenUsed.nbTokenUsed === 0;
        else return false;
    }

    private onCsvRead(_: string, emails: IParticipantCsvFormat[]) {
        const emailUser = emails.map((e) => e.email).join(EMAIL_SPLIT_CHAR);
        this.setState({ emailUser });
    }
    private onEmailUpdate(emailsToString: string) {
        this.setState({ emailsToString });
    }

    private isCreateTokenInfoValid(): [boolean, string, boolean, string, boolean, boolean] {
        var hasEmailError = false;
        var helperText = "";
        var hasDateFormatError = false;
        var dateHelperText = "";

        const noRowSelected = this.state.surveyTokenId !== "";

        if (this.state.emailUser !== "" && !validateMultipleEmail(this.state.emailUser)) {
            hasEmailError = true;
            helperText = this.props.t("dashboard.create_token.invalid_email");
        }

        if (!isDateFormatValid(this.state.expirationDate)) {
            hasDateFormatError = true;
            dateHelperText = this.props.t("dashboard.create_token.invalid_date_format");
        }

        const isButtonDisable = this.state.emailUser === "" || hasEmailError || hasDateFormatError || noRowSelected;

        return [hasEmailError, helperText, hasDateFormatError, dateHelperText, noRowSelected, isButtonDisable];
    }

    render() {
        if (!this.state.tokenFirstLoaded) this.fetchTokenData();

        const [hasEmailError, helperText, hasDateFormatError, dateHelperText, noRowSelected, isButtonDisable] =
            this.isCreateTokenInfoValid();

        return (
            <Box>
                <Box>
                    <br />
                    <br />
                    <Typography variant="h5">
                        <Trans i18nKey="dashboard.my-token.survey_token_table_title" />
                    </Typography>

                    <TableContainer component={Paper} style={{ backgroundColor: "#E4E4F1" }}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    {tableColumNames.surveyTokens.map((column_name) => {
                                        return (
                                            <TableCell align="center">
                                                <Typography fontWeight={700} fontSize="15px" fontStyle={"bold"}>
                                                    <Trans i18nKey={column_name} />
                                                </Typography>
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.surveyTokens.map((surveyToken) => (
                                    <TableRow
                                        key={surveyToken.id}
                                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                        style={{ backgroundColor: this.setRowBackgroundColor(surveyToken.id) }}
                                    >
                                        <TableCell
                                            align="center"
                                            component="th"
                                            scope="row"
                                            key={`id-${surveyToken.id}`}
                                        >
                                            {surveyToken.id}
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            component="th"
                                            scope="row"
                                            key={`type-${surveyToken.id}`}
                                        >
                                            {surveyToken.type}
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            component="th"
                                            scope="row"
                                            key={`description-${surveyToken.id}`}
                                        >
                                            {surveyToken.description}
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            component="th"
                                            scope="row"
                                            key={`internal-name-${surveyToken.id}`}
                                        >
                                            {surveyToken.surveyInternalName}
                                        </TableCell>
                                        <TableCell align="center" key={`creation-date-${surveyToken.id}`}>
                                            {convertDateToUTC(surveyToken.creation_date)}
                                        </TableCell>
                                        <TableCell align="center" key={`expiration-date-${surveyToken.id}`}>
                                            {convertDateToUTC(surveyToken.expiration_date)}
                                        </TableCell>
                                        <TableCell align="center" key={`is-completed-${surveyToken.id}`}>
                                            {surveyToken.isCompleted + ""}
                                        </TableCell>
                                        <TableCell align="center" key={`is-completed-${surveyToken.id}`}>
                                            {`${
                                                this.state.buyTokens.find((bt) => bt.id === surveyToken.buyTokenId)
                                                    ?.nbTokenUsed
                                            }/${
                                                this.state.buyTokens.find((bt) => bt.id === surveyToken.buyTokenId)
                                                    ?.nbTokenAllowed
                                            }`}
                                        </TableCell>
                                        <TableCell key={`action-${surveyToken.id}`}>
                                            <Button
                                                className="btn btn-primary"
                                                variant="contained"
                                                onClick={() => this.setSurveyTokenId(surveyToken.id)}
                                                disabled={this.allTokenAreUsed(surveyToken.buyTokenId)}
                                            >
                                                <Trans
                                                    i18nKey={
                                                        this.allTokenAreUsed(surveyToken.buyTokenId)
                                                            ? this.props.t(
                                                                  "dashboard.create_token.no_more_token_allowed_available"
                                                              )
                                                            : this.props.t("dashboard.button_names.select")
                                                    }
                                                />
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <br />
                    <br />
                    <Typography variant="h5">{this.props.t("dashboard.create_token.buyToken_selected")}</Typography>
                    <Box
                        component={Paper}
                        style={{ backgroundColor: noRowSelected ? MISSING_SURVEY_SELECT_COLOR : SURVEY_SELECT_COLOR }}
                    >
                        <Typography variant="h5">{this.surveyTokenSelectedTitle()}</Typography>
                    </Box>

                    <br />

                    <Typography variant="h5">{this.props.t("dashboard.create_token.invite_collaborator")}</Typography>

                    <br />
                    <TextField
                        fullWidth
                        error={hasEmailError}
                        helperText={helperText}
                        label={this.props.t("dashboard.create_token.email_collaborator")}
                        variant="outlined"
                        size="medium"
                        onChange={(event) => this.setEmailUser(event.target.value)}
                        value={this.state.emailUser}
                    />
                    <ParticipantCsvReader onSelect={(name, info) => this.onCsvRead(name, info)} />
                    <br />
                    <br />
                    <Typography variant="h5">{this.getDateExpirationAlert()}</Typography>
                    <br />
                    <Typography variant="h5">{this.props.t("dashboard.create_token.select_a_date")}</Typography>
                    <TextField
                        fullWidth
                        error={hasDateFormatError}
                        helperText={dateHelperText}
                        label={this.props.t("dashboard.create_token.date_exemple")}
                        variant="outlined"
                        size="medium"
                        onChange={(event) => this.setDate(event.target.value)}
                        value={this.state.expirationDate}
                    />
                    <br />
                    <br />
                    <Button
                        className="btn btn-primary"
                        variant="contained"
                        onClick={() => this.createSurveyToken()}
                        disabled={isButtonDisable}
                    >
                        {this.props.t("dashboard.button_names.send")}
                    </Button>
                    <br />
                    <br />

                    <Box>
                        <Typography variant="h5">
                            {this.state.isSurveyTokenCreated
                                ? this.props.t("dashboard.create_token.collaborator_invited")
                                : ""}
                            {this.state.hasUserEmailError ? this.props.t("dashboard.create_token.email_error") : ""}
                        </Typography>
                    </Box>
                    <DynamicEmailsTextFields
                        onChangeEmail={(emailsToString: string) => this.onEmailUpdate(emailsToString)}
                        emails={this.state.emailsToString.split(";")}
                        textfielsHaveLimitedNumber={false}
                        minTextfieldNumber={1}
                        maxTexfieldsNumber={10}
                    />
                </Box>
            </Box>
        );
    }
}

const tableColumNames = {
    surveyTokens: [
        "dashboard.survey_token_table_column_names.id",
        "dashboard.survey_token_table_column_names.type",
        "dashboard.survey_token_table_column_names.description",
        "dashboard.survey_token_table_column_names.survey_internal_name",
        "dashboard.survey_token_table_column_names.creation_date",
        "dashboard.survey_token_table_column_names.expiration_date",
        "dashboard.survey_token_table_column_names.is_completed",
        "dashboard.buy_token_table_column_names.NbTokenAllowed",
        "dashboard.survey_token_table_column_names.actions",
    ],
};
