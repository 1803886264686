import { ESupportedLanguage } from "../../language";
import { EDescriptionType } from "../utils/enum-constants";
import { LDescriptionAbstract } from "./description-abstract";

export class LDescriptionSurvey extends LDescriptionAbstract {
    static type = EDescriptionType.L_DESCRIPTION_SURVEY;
    readonly name: string;

    constructor(name: string, text: string, lang: string | ESupportedLanguage) {
        super(LDescriptionSurvey.type, text, lang);
        this.name = name;
    }
}
