import axios from "axios";
import Cookies from "universal-cookie";

import {
    ICommand,
    serializeCommand,
    CommandResultError,
    CommandResultFactory,
    ICommandResult,
    ECommandType,
    ECommandError,
    EServices,
} from "4common-ts";

export enum EEndPointSecure {
    PRIVATE = "private",
    PROTECTED = "protected",
    PUBLIC = "public",
}

export interface BackendConfigConnection {
    port: number | string | undefined;
    host: string;
    commandEndPoint: string;
}

export interface CommandControllerConfig {
    survey: BackendConfigConnection;
    user: BackendConfigConnection;
}

export default class CommandController {
    private surveyBackendConfig: BackendConfigConnection;
    private userBackendConfig: BackendConfigConnection;

    private cookies: Cookies = new Cookies();

    constructor(config: CommandControllerConfig) {
        // TODO: Read from config
        this.surveyBackendConfig = config.survey;
        this.userBackendConfig = config.user;

        this.initAxios();
    }

    private initAxios() {
        axios.interceptors.response.use(
            (response) => {
                return response;
            },
            (error) => {
                return error.response;
            }
        );
    }

    private getCompletUrl(service: EServices, secure: EEndPointSecure): string {
        const buildUrl = (config: BackendConfigConnection, endPointSecure: EEndPointSecure) => {
            let url: string;
            if (config.port) url = `${config.host}:${config.port}${config.commandEndPoint}`;
            else url = `${config.host}${config.commandEndPoint}`;
            return url.replace("public", endPointSecure);
        };
        let url: string = "";
        switch (service) {
            case EServices.SURVEY:
                return buildUrl(this.surveyBackendConfig, secure);
            case EServices.USER:
                return buildUrl(this.userBackendConfig, secure);
            case EServices.NONE:
            default:
                console.error(`Invalide service [${service}]`);
                break;
        }
        return url;
    }

    async handleAsync(command: ICommand, secure: EEndPointSecure): Promise<ICommandResult> {
        return new Promise<ICommandResult>(async (resolve, reject) => {
            if (Object.values(ECommandType).includes(command.type)) {
                resolve(this.sendAsync(command, secure));
                return;
            } else {
                reject(
                    CommandResultFactory.create(CommandResultError.type, command, {
                        error: ECommandError.COMMAND_TYPE_UNKNOWN,
                    })!
                );
                return;
            }
        });
    }

    private sendAsync(command: ICommand, secure: EEndPointSecure): Promise<ICommandResult> {
        return new Promise<ICommandResult>(async (resolve) => {
            const configs = {
                headers: {
                    "Content-Type": "text/plain",
                    // "Access-Control-Allow-Origin": "*", // FIXME: a configuration should be add to specify the backend url
                    // "Access-Control-Allow-Methods": "POST",
                },
            };

            if ([EEndPointSecure.PROTECTED, EEndPointSecure.PRIVATE].includes(secure)) {
                const bearerToken = this.cookies.get("token");
                configs["headers"]["Authorization"] = `Bearer ${bearerToken}`;
            }

            try {
                const response = await axios.post(
                    this.getCompletUrl(command.service, secure),
                    serializeCommand(command),
                    configs
                );

                if (response.status === 401) {
                    // In case there is no token or the token is invalid.
                    // If the user in the token is invalid, we will receive a CommandResultError
                    resolve(
                        CommandResultFactory.create(CommandResultError.type, command, {
                            error: ECommandError.INVALID_JWTOKEN,
                        })
                    );
                    return;
                }

                const commandResult = response.data as ICommandResult;
                if (commandResult === undefined) {
                    console.log("sendAsync received invalid data from backend.");
                    throw Error();
                }

                // set cookies
                Object.keys(commandResult.cookies).forEach((k) => {
                    this.cookies.remove(k);
                    this.cookies.set(k, commandResult.cookies[k], { path: "/" });
                });

                resolve(commandResult);
            } catch (e) {
                resolve(
                    CommandResultFactory.create(CommandResultError.type, command, {
                        error: ECommandError.UNKNOWN,
                    })
                );
            }
        });
    }
}
