import { ChoiceModel } from "./choice";
import { IdOrModelType } from "../model-abstract";
import { QuestionAbstract, allQuestionTypes } from "./question-abstract";
import { ESurveyModelType } from "./utils/enum-constants";

export class QuestionChoiceModel extends QuestionAbstract {
    static type: ESurveyModelType = ESurveyModelType.QUESTION_CHOICE;

    // relations
    choices: Array<IdOrModelType<ChoiceModel>>;

    constructor(id: string, survey_id: string, question_type: string, mandatory: boolean, choices: Array<IdOrModelType<ChoiceModel>>, exposed_name: string) {
        super(id, QuestionChoiceModel.type, survey_id, question_type, mandatory, exposed_name);

        this.choices = choices;
    }
}

allQuestionTypes.push(QuestionChoiceModel.type);
