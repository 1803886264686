import { ESurveyType, ISurveyInfos } from "4common-ts";
import { FormControl, InputLabel, Select, MenuItem, TextField, Button, Box, Grid, Typography } from "@mui/material";
import * as EmailValidator from "email-validator";

import ActuComponentBase, { IBaseProps, IBaseState } from "../../Common/Components/actu-component-base";
import BuyTokenController from "../Controllers/dashboard-buy-token-controller";
import { ComponentWithPredefinedLang } from "Survey/langugae/interface";
import DashboardBuyTokenController from "../Controllers/dashboard-buy-token-controller";
import { EPrivillegeLevels } from "Common/privileges";

enum ECreationResult {
    NONE,
    SUCCESS,
    ERROR,
}

interface IBuyTokenProps extends IBaseProps, ComponentWithPredefinedLang {}

interface IBuyTokenState extends IBaseState {
    surveysDetails: ISurveyInfos[];
    selectSurveyDetails: ISurveyInfos | undefined;
    nbTokenAllowed: string;
    description: string;
    email: string;
    surveyDetailsLoaded: boolean;
    creationResult: ECreationResult;
}

export default class DashboardBuyToken extends ActuComponentBase<BuyTokenController, IBuyTokenProps, IBuyTokenState> {
    static privillegeLevel = EPrivillegeLevels.ADMIN;
    static controllerType = DashboardBuyTokenController;
    static title = "buy-token";
    title = DashboardBuyToken.title;

    constructor(props: IBuyTokenProps) {
        super(props);
        this.state = {
            ...this.state,
            surveysDetails: [],
            selectSurveyDetails: undefined,
            nbTokenAllowed: "",
            description: "",
            email: "",
            surveyDetailsLoaded: false,
            creationResult: ECreationResult.NONE,
        };
    }

    private fetchSurveyDetails() {
        this.setState({ surveyDetailsLoaded: true });
        return new Promise<void>(async (_) => {
            const surveysDetails = await this.controller.fetchAllSurveysDetailsAsync();
            this.setState({ surveysDetails });
        });
    }

    private createBuyToken() {
        if (!this.state.selectSurveyDetails) return;

        if (!EmailValidator.validate(this.state.email) || parseInt(this.state.nbTokenAllowed!) <= 0) return;

        return new Promise<void>(async (_) => {
            try {
                await this.controller.createBuyTokenAsync(
                    this.state.selectSurveyDetails!.id,
                    this.state.email,
                    parseInt(this.state.nbTokenAllowed!),
                    this.state.description
                );
                this.setState({
                    selectSurveyDetails: undefined,
                    nbTokenAllowed: "",
                    description: "",
                    creationResult: ECreationResult.SUCCESS,
                });
            } catch (e) {
                console.log(e);
                this.setState({ creationResult: ECreationResult.ERROR });
            }
        });
    }

    private onChangeSelect(surveyId: string) {
        this.setState({ selectSurveyDetails: this.state.surveysDetails.find((sd) => sd.id === surveyId) });
    }

    private setEmail(email: string) {
        this.setState({ email });
    }

    private setDescription(description: string) {
        this.setState({ description });
    }

    private setNbTokenAllowed(nbTokenAllowed: string) {
        if (nbTokenAllowed === "") {
            this.setState({ nbTokenAllowed: "" });
            return;
        }

        const nb = parseInt(nbTokenAllowed);
        if (!Number.isNaN(nb)) this.setState({ nbTokenAllowed: nbTokenAllowed });
    }
    private getSelectedValue(): string {
        if (!this.state.selectSurveyDetails) return "";
        return this.state.selectSurveyDetails?.id;
    }

    private displayCreationMsg() {
        switch (this.state.creationResult) {
            case ECreationResult.NONE:
                return;
            case ECreationResult.SUCCESS:
                return <p style={{ color: "#7cfc00" }}>{this.props.t("dashboard.buy-token.creation-success")}</p>;
            case ECreationResult.ERROR:
                return <p style={{ color: "#ff0500" }}>{this.props.t("dashboard.buy-token.creation-error")}</p>;
        }
    }

    private displayBuyTokenOptions() {
        let nbTokenLabel: string;
        if (this.state.selectSurveyDetails?.type === ESurveyType.AUTO)
            nbTokenLabel = this.props.t("dashboard.buy-token.nb-token-allowed-auto");
        else nbTokenLabel = this.props.t("dashboard.buy-token.nb-token-allowed");

        let isButtonDisable: boolean = true;
        if (this.state.nbTokenAllowed && parseInt(this.state.nbTokenAllowed) > 0) isButtonDisable = false;

        let errorEmail: string | undefined = undefined;
        if (this.state.email && !EmailValidator.validate(this.state.email)) {
            errorEmail = this.props.t("dashboard.create_token.invalid_email");
        }

        return (
            <Box width="100%">
                <Typography variant="h5">{this.props.t("dashboard.buy-token.email")}</Typography>
                <TextField
                    fullWidth
                    key="buy-token-email"
                    variant="outlined"
                    onChange={(event) => this.setEmail(event.target.value)}
                    value={this.state.email}
                    helperText={errorEmail}
                    error={!!errorEmail}
                />
                <br />
                <Typography variant="h5">{nbTokenLabel}</Typography>
                <TextField
                    fullWidth
                    key="buy-token-allowed-token"
                    variant="outlined"
                    onChange={(event) => this.setNbTokenAllowed(event.target.value)}
                    value={this.state.nbTokenAllowed}
                />
                <br />
                <Typography variant="h5">{this.props.t("dashboard.buy-token.description")}</Typography>
                <TextField
                    fullWidth
                    key="buy-token-desc"
                    variant="outlined"
                    onChange={(event) => this.setDescription(event.target.value)}
                    value={this.state.description}
                />
                {/* TODO: add option to select csv to generate survey token */}
                <br />
                <Button
                    className="btn btn-primary"
                    variant="contained"
                    onClick={() => this.createBuyToken()}
                    disabled={isButtonDisable}
                >
                    {this.props.t("dashboard.buy-token.create")}
                </Button>
            </Box>
        );
    }

    render() {
        if (!this.state.surveyDetailsLoaded) this.fetchSurveyDetails();

        return (
            <Box>
                <Grid container direction="column" display="flex" justifyContent="center" alignItems="center">
                    {this.displayCreationMsg()}
                    <br />
                    <FormControl fullWidth>
                        <InputLabel id="survey-select-label">
                            {this.props.t("dashboard.buy-token.label-select")}
                        </InputLabel>
                        <Select
                            labelId="survey-select-label"
                            id="survey-select"
                            value={this.getSelectedValue()}
                            onChange={(e) => this.onChangeSelect(e.target.value)}
                        >
                            <MenuItem value="" key={`select-survey-default`} disabled>
                                -----
                            </MenuItem>
                            {this.state.surveysDetails.map((sd) => (
                                <MenuItem value={sd.id} key={`select-survey-${sd.internal_name}`}>
                                    {sd.name} ({sd.type})
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {this.state.selectSurveyDetails ? this.displayBuyTokenOptions() : undefined}
                </Grid>
            </Box>
        );
    }
}
