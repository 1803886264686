export interface AnswerAbstract {}

export class AnswerText implements AnswerAbstract {
    readonly value: string;

    constructor(value: string) {
        this.value = value;
    }
}

export class AnswerChoice implements AnswerAbstract {
    readonly choice: string;

    constructor(choice: string) {
        this.choice = choice;
    }
}
