import { EServices, ICommand } from "../ICommand";
import { ECommandType } from "../../utils/enum-constants";
import { ESupportedLanguage } from "src/language";

export interface ExtraArgsCommandEndOfSurvey {
    lang: ESupportedLanguage;
}

export class CommandEndOfSurvey implements ICommand {
    static readonly type = ECommandType.COMMAND_END_OF_SURVEY;
    static readonly service: EServices = EServices.SURVEY;

    readonly id: string;
    readonly timestamp: string;
    readonly type: ECommandType = CommandEndOfSurvey.type;
    readonly service: EServices = CommandEndOfSurvey.service;
    readonly token: string;
    readonly lang;

    constructor(id: string, timestamp: string, token: string, lang: ESupportedLanguage) {
        this.id = id;
        this.timestamp = timestamp;
        this.token = token;
        this.lang = lang;
    }
}
