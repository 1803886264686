import { EServices, ICommand } from "../ICommand";
import { ECommandType } from "../../utils/enum-constants";
import { ESurveyType } from "src/model/survey/utils/enum-constants";
import { ESupportedLanguage } from "src/language";

export interface ExtraArgsCommandGetAllSurveyNames {
    select: ESurveyType[] | "ALL";
    lang?: ESupportedLanguage;
}

export class CommandGetAllSurveyNames implements ICommand {
    static readonly type = ECommandType.COMMAND_GET_ALL_SURVEY_NAMES;
    static readonly service: EServices = EServices.SURVEY;

    readonly id: string;
    readonly timestamp: string;
    readonly type: ECommandType = CommandGetAllSurveyNames.type;
    readonly service: EServices = CommandGetAllSurveyNames.service;
    readonly token: string = "";
    readonly select: ESurveyType[] | "ALL";
    readonly lang?: ESupportedLanguage;

    constructor(id: string, timestamp: string, select: ESurveyType[] | "ALL", lang?: ESupportedLanguage) {
        this.id = id;
        this.timestamp = timestamp;
        this.select = select;
        this.lang = lang;
    }
}
