import { ModelAbstract } from "../model-abstract";
import { ESurveyModelType } from "./utils/enum-constants";

export class IndicatorModel extends ModelAbstract {
    static type: ESurveyModelType = ESurveyModelType.INDICATOR;

    // columns
    have_main_skill: boolean;
    main_skill_name: string;
    skill_name: string;
    score: number;
    pourcentage: number;
    description: string;

    // relations
    survey_token_id: string;

    constructor(
        id: string,
        survey_token_id: string,
        have_main_skill: boolean,
        main_skill_name: string,
        skill_name: string,
        score: number,
        pourcentage: number,
        description: string
    ) {
        super(id, IndicatorModel.type);
        this.survey_token_id = survey_token_id;
        this.have_main_skill = have_main_skill;
        this.main_skill_name = main_skill_name;
        this.skill_name = skill_name;
        this.score = score;
        this.pourcentage = pourcentage;
        this.description = description;
    }
}
