import { EServices, ICommand } from "../ICommand";
import { ECommandType } from "../../utils/enum-constants";

export interface ExtraArgsCommandGetMySessions {
    email: string;
}

export class CommandGetMySessions implements ICommand {
    static readonly type = ECommandType.COMMAND_GET_MY_SESSIONS;
    static readonly service: EServices = EServices.USER;

    readonly id: string;
    readonly timestamp: string;
    readonly type: ECommandType = CommandGetMySessions.type;
    readonly service = CommandGetMySessions.service;
    readonly token: string; //for now dont usefull for 4BeSession
    readonly email: string;

    constructor(id: string, timestamp: string, email: string) {
        this.id = id;
        this.timestamp = timestamp;
        this.token = "";
        this.email = email.toLowerCase();
    }
}
