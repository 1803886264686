import { ValdiateUuidv4 } from "4common-ts";

import HomeController from "../Controllers/instruction-controller";
import ActuComponentBase, { IBaseProps, IBaseState } from "../../Common/Components/actu-component-base";
import CommonPageFrame from "../../Common/Components/page-frame";
import { ComponentWithPredefinedLang } from "Survey/langugae/interface";

import Container from "@material-ui/core/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Trans } from "react-i18next";

interface IHomeProps extends IBaseProps, ComponentWithPredefinedLang {}

interface IHomeState extends IBaseState {
    idSurveySelect: string;
}

export default class Main extends ActuComponentBase<HomeController, IHomeProps, IHomeState> {
    constructor(props: IHomeProps) {
        super(props);

        this.state = {
            ...this.state,
            idSurveySelect: "11101cec-79ea-435e-81f4-ed26ae7ba222", //FIXME: this should be the surveyToken encrypted
            hasError: false,
        };
    }

    goToSurvey() {
        if (ValdiateUuidv4(this.state.idSurveySelect)) window.location.href = "/survey/" + this.state.idSurveySelect;
        else console.log("Invalide uuid for sruvey.");
    }

    render() {
        return (
            <Container maxWidth="md">
                <CommonPageFrame
                    availableLanguages={this.props.availableLanguages!}
                    onselectlanguage={(lang) => this.props.onselectlanguage(lang)}
                    disconnectUser={() => this.controller.disconnectUser(this.props.selectlang)}
                    selectlang={this.props.selectlang}
                    userData={this.controller.userData}
                >
                    <Box>
                        <Typography align="center" variant="h5">
                            <Trans i18nKey="main.bienvenue" />
                            <br />
                            <br />
                            <Trans i18nKey="main.message" />
                        </Typography>
                        <br />
                        <Typography align="center" variant="h5">
                            <Trans i18nKey="main.nous rejoindre" />
                        </Typography>
                        <br />
                        <Typography
                            align="center"
                            variant="h5"
                            dangerouslySetInnerHTML={{ __html: this.props.t("main.plus d'info") }}
                        ></Typography>
                    </Box>
                </CommonPageFrame>
            </Container>
        );
    }
}
