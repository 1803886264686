import { EHLListenner, EHPListenner, EHQListenner, HookAbstract } from "4common-ts";
import Survey from "Survey/Components/survey";

type LType = EHLListenner | EHPListenner | EHQListenner;

export const makeHook = [
    (lType: LType, hooks: Array<HookAbstract>, defaultAction: CallableFunction, nbExtraArgs: number = 0): any => {
        throw Error("makeHook is not initialisae. Call initializeMakeHook before.");
    },
];

export const initializeMakeHook = (surveyComponent: Survey) => {
    makeHook[0] = (
        lType: LType,
        hooks: Array<HookAbstract>,
        defaultAction: CallableFunction,
        nbExtraArgs: number = 0
    ): any => {
        const hook = hooks.find((h) => h.listener === lType);
        if (hook === undefined) return defaultAction;
        else {
            if (nbExtraArgs === 0)
                return () => {
                    new Function(
                        "__surveyComponent",
                        "__default_action",
                        `return (${hook.rawFunc}(__surveyComponent, __default_action))`
                    )(surveyComponent, defaultAction);
                };
            else if (nbExtraArgs === 1)
                return (arg1: any) => {
                    new Function(
                        "__surveyComponent",
                        "__default_action",
                        "__arg1",
                        `return (${hook.rawFunc}(__surveyComponent, __default_action, __arg1))`
                    )(surveyComponent, defaultAction, arg1);
                };
            else if (nbExtraArgs === 2)
                return (arg1: any, arg2: any) => {
                    new Function(
                        "__surveyComponent",
                        "__default_action",
                        "__arg1",
                        "__arg2",
                        `return (${hook.rawFunc}(__surveyComponent, __default_action, __arg1, __arg2))`
                    )(surveyComponent, defaultAction, arg1, arg2);
                };
            else throw new Error(`Hook with [${nbExtraArgs}] is not Implemented.`);
        }
    };
};
