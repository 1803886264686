import { ModelAbstract } from "../model-abstract";
import { ESurveyModelType } from "./utils/enum-constants";

export class ChoiceModel extends ModelAbstract {
    static type: ESurveyModelType = ESurveyModelType.CHOICE;

    // columns
    order: number;
    value: string;
    expose_name: string;

    // relations
    questionChoiceId: string;

    constructor(id: string, questionChoiceId: string, order: number, value: string, expose_name: string) {
        super(id, ChoiceModel.type);

        this.order = order;
        this.value = value;

        this.questionChoiceId = questionChoiceId;
        this.expose_name = expose_name;
    }
}
