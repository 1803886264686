import { QuestionAbstract } from "../../../model/survey/question-abstract";
import { ICommandResult } from "../ICommandResult";
import { ECommandResultType } from "../../utils/enum-constants";
import { ICommand } from "src/command/command-model/ICommand";

export interface ExtraArgsCommandResultGetQuestions {
    questionModels: Array<QuestionAbstract>;
}

export class CommandResultGetQuestions implements ICommandResult {
    static readonly type = ECommandResultType.COMMAND_RESULT_GET_QUESTIONS;

    readonly type: ECommandResultType = CommandResultGetQuestions.type;
    readonly id: string;
    readonly timestamp: string;
    readonly command: ICommand;
    readonly status: number = 200;

    readonly questionModels: Array<QuestionAbstract>;

    cookies: { [key: string]: string } = {};

    constructor(id: string, timestamp: string, command: ICommand, questionModels: Array<QuestionAbstract>) {
        this.id = id;
        this.timestamp = timestamp;
        this.command = command;
        this.questionModels = questionModels;
    }
}
