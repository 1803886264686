import { EServices, ICommand } from "../ICommand";
import { ECommandType } from "../../utils/enum-constants";
import { ESupportedLanguage } from "src/language";

export interface ExtraArgsCommandSendEmailConfirmationToAuto {
    lang: ESupportedLanguage;
}

export class CommandSendEmailConfirmationToAuto implements ICommand {
    static readonly type = ECommandType.COMMAND_SEND_EMAIL_CONFIRMATION_TO_AUTO;
    static readonly service: EServices = EServices.SURVEY;

    readonly id: string;
    readonly timestamp: string;
    readonly type: ECommandType = CommandSendEmailConfirmationToAuto.type;
    readonly service: EServices = CommandSendEmailConfirmationToAuto.service;
    readonly token: string;
    readonly lang: ESupportedLanguage;

    constructor(id: string, timestamp: string, token: string, lang: ESupportedLanguage) {
        this.id = id;
        this.timestamp = timestamp;
        this.token = token;
        this.lang = lang;
    }
}
