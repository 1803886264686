import { ModelAbstract } from "../model-abstract";
import { EPrivileges, EUserModelType } from "./utils/enum-constants";

export class UserModel extends ModelAbstract {
    static type: EUserModelType = EUserModelType.USER;

    // Columns
    readonly email: string; // PK
    readonly password: string;
    readonly privilege: EPrivileges;
    readonly is_disable: boolean;
    readonly creation_date: Date;

    constructor(email: string, password: string, privilege: EPrivileges, is_disable: boolean, creation_date: Date) {
        super("", UserModel.type); // FIXME: For now, UserModel will have an empty id. Otherwise, we will have problem with thetyping

        this.email = email;
        this.password = password;
        this.privilege = privilege;
        this.is_disable = is_disable;
        this.creation_date = creation_date;
    }
}
