import { LLayoutAbstract, LPageAbstract, LQuestionAbstract, QuestionAbstract } from "4common-ts";

export const getPagePosition = (pageId: string, pages: Array<LPageAbstract>): number => {
    const pagePos = pages.findIndex((page: LPageAbstract) => page.id === pageId) + 1; // Index start at 0
    return pagePos;
};

export const getPageById = (pageId: string, pages?: Array<LPageAbstract>): LPageAbstract | undefined => {
    if (pages === undefined) return undefined;
    return pages.find((p) => p.id === pageId);
};

export const getPageQuestionIds = (page: LPageAbstract): Array<string> => {
    const questions = page.containers;
    if (questions === undefined) return [];
    return questions.map((question: LQuestionAbstract<QuestionAbstract>) => {
        return question.question.id;
    });
};

export const getNextPageId = (pageId: string, pages: Array<LPageAbstract>): string | undefined => {
    const currentIndex = pages.findIndex((page: LPageAbstract, i: number) => page.id === pageId);
    const nextIndex = currentIndex + 1;
    if (nextIndex >= pages.length) return undefined;
    return pages[nextIndex].id;
};

export const getPreviousPageId = (pageId: string, pages: Array<LPageAbstract>): string | undefined => {
    const currentIndex = pages.findIndex((page: LPageAbstract, i: number) => page.id === pageId);
    const prevIndex = currentIndex - 1;
    if (prevIndex < 0) return undefined;
    return pages[prevIndex].id;
};

export const isPagePriorTo = (pageId: string, otherPageId: string, pages: Array<LPageAbstract>): boolean => {
    const pageIndex = pages.findIndex((page: LPageAbstract, i: number) => page.id === pageId);
    const otherPageIndex = pages.findIndex((page: LPageAbstract, i: number) => page.id === otherPageId);
    return pageIndex < otherPageIndex;
};

/**
 * Look if a pages contain a page with random questions
 * @param layoutAbstract
 * @returns boolean
 */
export function includesRandomQuestions(layoutAbstract: LLayoutAbstract | undefined): boolean {
    const pages = layoutAbstract?.containers;
    const page = pages?.find((page) => page.areTheQuestionsRandom && page.areTheQuestionsRandom + "" === "true");
    return page ? true : false;
}
