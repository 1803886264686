/**
 * The list need to be update each time we add survey in the database
 */
export enum ESurveyAcronyms {
    ADC = "ADC", // Ancres de carrière
    EDCEMH = "EDCEMH", // Évaluation des compétences en mode hybride
    LSI = "LSI", // Leadership situationnel
    VC = "VC", // Visibilité et crédibilité
    EDC = "EDC", // Évaluation des compétences
    IPL = "IPL", // Inventaire des pratiques du leadership
    COA = "COA", // Inventaire des compétences en coaching
    EPT = "EPT", // Profil d'efficacité personnelle au travail
    CDGEMH = "CDGEMH", // Compétence de gestion en mode hybride
    PEL = "PEL", //Profil d'efficacité du leader
    EECK = "EECK", //Expérience employé chez Kenworth
    BAT = "BAT", // Bonheur au travail
    GDS = "GDS", // Gestion du stress
    CAT = "CAT", // Civilité au travail
    NOC = "NOC", // Négociation
    CSC = "CSC", // Comportement en situation de conflit
    EECRMAAQ = "EECRMAAQ", //Expérience employé chez Régie des marchés agricoles et alimentaires du Québec
    GPT = "GPT", //Garder le plaisir au travail
    EQCAT = "EQCAT", //Évaluer la qualité de la communication au travail
    HP = "HP", // Habilité politique
    REE = "REE", // Réussir en équipe
    EC = "EC", // Éxpérience client
}

/**
 * The function take the survey name internal_name of Survey Entity
 * and return the survey acronym if he is in the list
 * @param internal_name
 * @returns ESurveyAcronyms | undefined
 */
export const findSurveyAcronym = (acronym?: string): ESurveyAcronyms | undefined => {
    if (!acronym) return undefined;
    const surveyAcronyms = Object.values(ESurveyAcronyms);
    return surveyAcronyms.find((a) => a === acronym);
};
