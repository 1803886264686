import React from "react";
import { Box, CircularProgress } from "@mui/material";
import { Trans, withTranslation } from "react-i18next";

const Loading: React.FunctionComponent = () => {
    return (
        <Box sx={{ display: "block", width: "100%", textAlign: "center" }}>
            <CircularProgress
                sx={{
                    color: "#7475b5",
                }}
            />
            <p style={{ color: "#7475b5" }}>
                <Trans i18nKey="waiting.loading" />
            </p>
        </Box>
    );
};

export default withTranslation()(Loading);
