import { ICommandResult } from "../ICommandResult";
import { ECommandResultType } from "../../utils/enum-constants";
import { ESurveyType } from "src/model/survey/utils/enum-constants";
import { ICommand } from "src/command/command-model/ICommand";

export interface ExtraArgsCommandResultSurveyNames {
    surveyInfos: ISurveyInfos[];
}

export interface ISurveyInfos {
    id: string;
    name: string;
    internal_name: string;
    type: ESurveyType;
}

export class CommandResultSurveyNames implements ICommandResult {
    static readonly type = ECommandResultType.COMMAND_RESULT_SURVEY_NAMES;

    readonly type: ECommandResultType = CommandResultSurveyNames.type;
    readonly id: string;
    readonly timestamp: string;
    readonly command: ICommand;
    readonly status: number = 200;
    cookies: { [key: string]: string } = {};
    readonly surveyInfos: ISurveyInfos[];

    constructor(id: string, timestamp: string, command: ICommand, surveyInfos: ISurveyInfos[]) {
        this.id = id;
        this.timestamp = timestamp;
        this.command = command;
        this.surveyInfos = surveyInfos;
    }
}
