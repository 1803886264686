import Papa from "papaparse";
import { Input } from "@mui/material";

export interface IParticipantCsvFormat {
    name: string;
    firstname: string;
    email: string;
    language: string;
    hours: number;
    dates: string;
    training: string;
}

export interface IObjectiveCsvFormat {
    description: string;
}

interface ICertificateCsvReaderProps {
    onSelect: (path: string, data: any) => void;
    onError?: (path: string, error: any) => boolean; // if return true, continue, Otherwise stop.
}

export const OBJECTIVE_COLUMNS = ["description"];
export const PARTICIPANT_COLUMNS = ["name", "firstname", "email", "language", "hours", "dates", "training"];

const readFile = (
    e: any,
    cb: (a: string, b: IParticipantCsvFormat[] | IObjectiveCsvFormat[]) => void,
    cbErr?: (path: string, error: any) => boolean
) => {
    var file = e.target.files[0];

    Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        delimiter: ";",
        complete: function (results) {
            const column_names = results.meta.fields || [];
            const allObjectivesColsPresent = OBJECTIVE_COLUMNS.map((k) => column_names.includes(k)).every((e) => e);
            const allParticipantsColsPresent = PARTICIPANT_COLUMNS.map((k) => column_names.includes(k)).every((e) => e);
            const allColsPresent = allObjectivesColsPresent || allParticipantsColsPresent;

            if (!allColsPresent) {
                if (!cbErr) return;
                else if (
                    !cbErr(
                        file.name,
                        `Missing columns. Got ${column_names}. Expected ${
                            allObjectivesColsPresent ? OBJECTIVE_COLUMNS : PARTICIPANT_COLUMNS
                        }`
                    )
                )
                    return;
            }

            // TODO: validate not empty col in row

            cb(file.name, results.data as IParticipantCsvFormat[] | IObjectiveCsvFormat[]);
        },
    });
};
export const CertificateCsvReader = (props: ICertificateCsvReaderProps) => {
    return (
        <div>
            <Input type="file" onChange={(e) => readFile(e, props.onSelect, props.onError)} />
        </div>
    );
};
