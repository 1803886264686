import { ESupportedLanguage } from "4common-ts";

import ActuComponentBase, { IBaseProps, IBaseState } from "../../Common/Components/actu-component-base";
import CommonPageFrame from "../../Common/Components/page-frame";
import AfterRegistrationController from "../Controllers/after-registration-controller";

import Grid from "@mui/material/Grid";
import Container from "@material-ui/core/Container";
import Typography from "@mui/material/Typography";
import { ComponentWithPredefinedLang } from "Survey/langugae/interface";

interface IAfterRegistrationProps extends IBaseProps, ComponentWithPredefinedLang {}

interface IAfterRegistrationState extends IBaseState {
    idSurveySelect: string;
    availableLanguages: Array<ESupportedLanguage>;
}

export default class AfterRegistration extends ActuComponentBase<
    AfterRegistrationController,
    IAfterRegistrationProps,
    IAfterRegistrationState
> {
    render() {
        return (
            <Container maxWidth="md">
                <CommonPageFrame
                    availableLanguages={this.props.availableLanguages}
                    onselectlanguage={(lang) => this.props.onselectlanguage(lang)}
                    disconnectUser={() => this.controller.disconnectUser(this.props.selectlang)}
                    selectlang={this.props.selectlang}
                    userData={this.controller.userData}
                >
                    <Grid container direction="column" display="flex" justifyContent="center" alignItems="center">
                        <Typography align="center" variant="h3">
                            {this.props.t("after-registration.success")}
                        </Typography>
                        <br />

                        <Typography align="center" variant="h5">
                            {this.props.t("after-registration.message")}
                        </Typography>
                    </Grid>
                </CommonPageFrame>
            </Container>
        );
    }
}
