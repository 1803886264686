import { ModelAbstract } from "../model-abstract";
import { EUserModelType } from "./utils/enum-constants";

export class BuyTokenModel extends ModelAbstract {
    static type: EUserModelType = EUserModelType.BUY_TOKEN;

    // Columns
    readonly survey_id: string;
    readonly nb_token_allowed: number;
    readonly creation_date: Date;
    readonly description: string;
    readonly session_id: string;
    readonly code_session: string;
    readonly title: string;
    readonly duration: number;

    // relations
    readonly email: string;

    constructor(
        id: string,
        email: string,
        survey_id: string,
        nb_token_allowed: number,
        creation_date: Date,
        session_id: string,
        code_session: string,
        title: string,
        description: string,
        duration: number
    ) {
        super(id, BuyTokenModel.type);

        this.email = email;
        this.survey_id = survey_id;
        this.nb_token_allowed = nb_token_allowed;
        this.creation_date = creation_date;
        this.description = description;
        this.code_session = code_session;
        this.title = title;
        this.duration = duration;
        this.session_id = session_id;
    }
}
