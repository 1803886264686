import { UIToken, UITokenBuyToken, UITokenBase, ETokenType, UITokenParent } from "4common-ts";

import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import LaunchIcon from "@mui/icons-material/Launch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import BaseTabAbstract, { IBaseTabProps, IBaseTabState } from "Common/Components/tabs/base-tab-abstract";

import DashboardMyTokensController from "User/Controllers/dashboard-my-tokens-controller";
import { Trans } from "react-i18next";
import { EPrivillegeLevels } from "Common/privileges";
import { convertDateToUTC } from "Common/date";
import DashboardMyTokenController from "User/Controllers/dashboard-my-tokens-controller";

interface IDashboardMyTokensProps extends IBaseTabProps {
    controller: DashboardMyTokensController;
}

interface IGenerateReportState {
    tokenId: string;
    generateReportSate: EGenerateReportState;
}
interface IDashboardMyTokensState extends IBaseTabState {
    tokens: UITokenBase[];
    loadingToken: boolean;
    tokenFirstLoaded: boolean;
    generateReportSates: IGenerateReportState[];
}
enum EGenerateReportState {
    NONE,
    LOADING,
    SUCCESSED,
    FAILED,
}

export default class DashboardMyTokens extends BaseTabAbstract<
    DashboardMyTokensController,
    IDashboardMyTokensProps,
    IDashboardMyTokensState
> {
    static privillegeLevel = EPrivillegeLevels.ADMIN;
    static controllerType = DashboardMyTokenController;
    static title = "my-tokens";
    title = DashboardMyTokens.title;

    constructor(props: IDashboardMyTokensProps) {
        super(props);

        this.state = {
            ...this.state,
            tokens: [],
            loadingToken: true,
            tokenFirstLoaded: false,
            generateReportSates: [],
        };
    }

    private async fetchTokenData() {
        this.setState({ loadingToken: true, tokenFirstLoaded: true });
        const [tokens, generateReportSates] = await new Promise<[UITokenBase[], IGenerateReportState[]]>(
            async (resolve) => {
                const surveyTokens = (await this.controller.getTokenAsync(
                    this.controller.userData.email!
                )) as UITokenBase[];

                const gsr = surveyTokens.map((token) => {
                    return {
                        tokenId: token.id,
                        generateReportSate: EGenerateReportState.NONE,
                    } as IGenerateReportState;
                });
                resolve([surveyTokens, gsr]);
            }
        );
        this.setState({ tokens, loadingToken: false, tokenFirstLoaded: true, generateReportSates });
    }
    private generateReport(surveyTokenId: string) {
        const index = this.state.generateReportSates.findIndex((token) => token.tokenId === surveyTokenId);
        const generateReportSates = this.state.generateReportSates;
        generateReportSates[index].generateReportSate = EGenerateReportState.LOADING;

        this.setState({
            generateReportSates,
        });
        return new Promise<void>(async () => {
            const result = await this.controller.generateReportAsync(surveyTokenId);

            if (result) generateReportSates[index].generateReportSate = EGenerateReportState.SUCCESSED;
            else generateReportSates[index].generateReportSate = EGenerateReportState.FAILED;

            this.setState({
                generateReportSates,
            });
        });
    }
    private canGenerateReport(tk: UITokenBase) {
        const tokenIscompleted = (tk as UIToken).isCompleted;
        const tokenIsParent = (tk as UIToken).tokenParentId === null && tk.type !== ETokenType.STANDARD;

        if (tokenIsParent) {
            return (tk as UITokenParent).nbTotalCollaboratorWhereTokenIsCompleted !== 0 && tokenIscompleted;
        } else return tokenIscompleted;
    }

    private getGenerateReportState(tk: UITokenBase) {
        return this.state.generateReportSates.find((token) => token.tokenId === tk.id);
    }

    private updateButtonAfterGenerateReport(tk: UITokenBase) {
        const getGenerateReportState = this.getGenerateReportState(tk);

        if (getGenerateReportState) {
            if (getGenerateReportState.generateReportSate === EGenerateReportState.LOADING)
                return (
                    <CircularProgress
                        sx={{
                            color: "#7475b5",
                        }}
                    />
                );
            else if (getGenerateReportState.generateReportSate === EGenerateReportState.SUCCESSED)
                return (
                    <Button variant="contained" color="success">
                        {this.props.t("dashboard.button_names.success")}
                    </Button>
                );
            else if (getGenerateReportState.generateReportSate === EGenerateReportState.FAILED)
                return (
                    <Button variant="outlined" color="error">
                        {this.props.t("dashboard.button_names.error")}
                    </Button>
                );
        }
    }

    render() {
        if (!this.state.tokenFirstLoaded) this.fetchTokenData();

        return (
            <Box>
                <Box>
                    <br />
                    <br />
                    <Typography variant="h5">
                        <Trans i18nKey="dashboard.my-token.buy_token_table_title" />
                    </Typography>

                    <TableContainer component={Paper} style={{ backgroundColor: "#E4E4F1" }}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    {tableColumNames.buyTokens.map((column_name) => {
                                        return (
                                            <TableCell align="center">
                                                <Typography fontWeight={700} fontSize="15px" fontStyle={"bold"}>
                                                    <Trans i18nKey={column_name} />
                                                </Typography>
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.tokens
                                    .filter((tk) => tk.type === ETokenType.BUY_TOKEN)
                                    .map((tk) => (
                                        <TableRow
                                            key={tk.id}
                                            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                        >
                                            <TableCell align="center" component="th" scope="row" key={`id-${tk.id}`}>
                                                {tk.id}
                                            </TableCell>
                                            <TableCell align="center" component="th" scope="row" key={`type-${tk.id}`}>
                                                {tk.type}
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                                component="th"
                                                scope="row"
                                                key={`survey-type-${tk.id}`}
                                            >
                                                {(tk as UITokenBuyToken).surveyType}
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                                component="th"
                                                scope="row"
                                                key={`description-${tk.id}`}
                                            >
                                                {tk.description}
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                                component="th"
                                                scope="row"
                                                key={`internal-name-${tk.id}`}
                                            >
                                                {tk.surveyInternalName}
                                            </TableCell>
                                            <TableCell align="center" key={`creation-date-${tk.id}`}>
                                                {convertDateToUTC(tk.creation_date)}
                                            </TableCell>
                                            <TableCell align="center">{`${(tk as UITokenBuyToken).nbTokenUsed}/${
                                                (tk as UITokenBuyToken).nbTokenAllowed
                                            }`}</TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>

                <Box>
                    <br />
                    <br />
                    <Typography variant="h5">
                        <Trans i18nKey="dashboard.my-token.survey_token_table_title" />
                    </Typography>

                    <TableContainer component={Paper} style={{ backgroundColor: "#E4E4F1" }}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    {tableColumNames.surveyTokens.map((column_name) => {
                                        return (
                                            <TableCell align="center">
                                                <Typography fontWeight={700} fontSize="15px" fontStyle={"bold"}>
                                                    <Trans i18nKey={column_name} />
                                                </Typography>
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.tokens
                                    .filter((tk) => tk.type !== ETokenType.BUY_TOKEN)
                                    .map((tk) => (
                                        <TableRow
                                            key={tk.id}
                                            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                        >
                                            <TableCell align="center" component="th" scope="row" key={`id-${tk.id}`}>
                                                {tk.id}
                                            </TableCell>
                                            <TableCell align="center" component="th" scope="row" key={`type-${tk.id}`}>
                                                {tk.type}
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                                component="th"
                                                scope="row"
                                                key={`description-${tk.id}`}
                                            >
                                                {tk.description}
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                                component="th"
                                                scope="row"
                                                key={`internal-name-${tk.id}`}
                                            >
                                                {(tk as UIToken).surveyInternalName}
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                                component="th"
                                                scope="row"
                                                key={`collaborators-${tk.id}`}
                                            >
                                                {(tk as UIToken).tokenParentId === null &&
                                                tk.type !== ETokenType.STANDARD
                                                    ? `${
                                                          (tk as UITokenParent).nbTotalCollaboratorWhereTokenIsCompleted
                                                      }/${(tk as UITokenParent).nbTotalCollaborator}`
                                                    : "N/A"}
                                            </TableCell>
                                            <TableCell align="center" key={`creation-date-${tk.id}`}>
                                                {convertDateToUTC(tk.creation_date)}
                                            </TableCell>
                                            <TableCell align="center" key={`expiration-date-${tk.id}`}>
                                                {convertDateToUTC((tk as UIToken).expiration_date)}
                                            </TableCell>
                                            <TableCell align="center" key={`is-completed-${tk.id}`}>
                                                {(tk as UIToken).isCompleted ? "True" : "False"}
                                            </TableCell>
                                            <TableCell align="center" key={`link-${tk.id}`}>
                                                {!(tk as UIToken).isCompleted && !(tk as UIToken).isDisable && (
                                                    <a
                                                        href={`${this.controller.getUrlBasePath()}/instructions/${
                                                            tk.id
                                                        }/${this.props.selectlang}`}
                                                    >
                                                        <LaunchIcon />
                                                    </a>
                                                )}
                                            </TableCell>
                                            <TableCell align="center" key={`link-${tk.id}`}>
                                                {this.getGenerateReportState(tk)?.generateReportSate ===
                                                EGenerateReportState.NONE ? (
                                                    <Button
                                                        className="btn btn-primary"
                                                        variant="contained"
                                                        onClick={() => this.generateReport(tk.id)}
                                                        disabled={!this.canGenerateReport(tk)}
                                                    >
                                                        {this.props.t(
                                                            "dashboard.survey_token_table_column_names.generate_report"
                                                        )}
                                                    </Button>
                                                ) : (
                                                    this.updateButtonAfterGenerateReport(tk)
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
        );
    }
}

const tableColumNames = {
    surveyTokens: [
        "dashboard.survey_token_table_column_names.id",
        "dashboard.survey_token_table_column_names.type",
        "dashboard.survey_token_table_column_names.description",
        "dashboard.survey_token_table_column_names.survey_internal_name",
        "dashboard.survey_token_table_column_names.collaborators",
        "dashboard.survey_token_table_column_names.creation_date",
        "dashboard.survey_token_table_column_names.expiration_date",
        "dashboard.survey_token_table_column_names.is_completed",
        "dashboard.survey_token_table_column_names.link_to_survey",
    ],
    buyTokens: [
        "dashboard.buy_token_table_column_names.id",
        "dashboard.buy_token_table_column_names.type",
        "dashboard.buy_token_table_column_names.survey_type",
        "dashboard.survey_token_table_column_names.description",
        "dashboard.buy_token_table_column_names.survey_internal_name",
        "dashboard.survey_token_table_column_names.creation_date",
        "dashboard.buy_token_table_column_names.NbTokenAllowed",
    ],
};
