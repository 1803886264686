import { ICommandResult } from "../ICommandResult";
import { ECommandResultType } from "../../utils/enum-constants";
import { ICommand } from "src/command/command-model/ICommand";


export interface ExtraArgsCommandResultSurveyId {
    surveyId: string;
}

export class CommandResultSurveyId implements ICommandResult {
    static readonly type = ECommandResultType.COMMAND_RESULT_SURVEY_ID;

    readonly type: ECommandResultType = CommandResultSurveyId.type;
    readonly id: string;
    readonly timestamp: string;
    readonly command: ICommand;
    readonly status: number = 200;
    readonly surveyId: string;
    cookies: { [key: string]: string } = {};

    constructor(id: string, timestamp: string, command: ICommand, surveyId: string) {
        this.id = id;
        this.timestamp = timestamp;
        this.command = command;
        this.surveyId = surveyId;
    }
}
