import AccessDeniedController from "../../Common/Controllers/access-denied-controller";
import ActuComponentBase, { IBaseProps, IBaseState } from "./actu-component-base";
import CommonPageFrame from "./page-frame";
import { ComponentWithPredefinedLang } from "Survey/langugae/interface";

import Container from "@material-ui/core/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { Trans } from "react-i18next";

interface IAccesDeniedProps extends IBaseProps, ComponentWithPredefinedLang {}

export default class AccesDenied extends ActuComponentBase<AccessDeniedController, IAccesDeniedProps, IBaseState> {
    goToMainMenu() {
        window.location.href = "/";
    }

    render() {
        return (
            <Container maxWidth="md">
                <CommonPageFrame
                    availableLanguages={this.props.availableLanguages}
                    onselectlanguage={(lang) => this.props.onselectlanguage(lang)}
                    disconnectUser={() => this.controller.disconnectUser(this.props.selectlang)}
                    selectlang={this.props.selectlang}
                    surveyTitle={""}
                    userData={this.controller.userData}
                >
                    <Grid
                        container
                        direction="column"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        maxWidth="md"
                    >
                        <Box>
                            <Typography align="center" variant="h2">
                                <Trans i18nKey="error.jeton invalide" />
                            </Typography>
                        </Box>

                        <Box
                            component="img"
                            sx={{
                                height: "50%",
                                width: "50%",
                            }}
                            alt="The house from the offer."
                            src="https://cdn.sanity.io/images/i4kanko4/production/e2476c9690dd10202befb2240360fb69e949e83b-700x700.png?fit=max&auto=format"
                        />

                        <Box marginTop={3}>
                            <Typography align="center" variant="h5">
                                <Trans i18nKey="error.acces refuse - message" />
                            </Typography>
                        </Box>
                        <Box marginTop={5}>
                            <Button onClick={() => this.goToMainMenu()} className="btn btn-primary" variant="contained">
                                <Trans i18nKey="error.Retour accueil" />
                            </Button>
                        </Box>
                    </Grid>
                </CommonPageFrame>
            </Container>
        );
    }
}
