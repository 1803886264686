import Cookies from "universal-cookie";

import {
    SurveyModel,
    QuestionAbstract,
    QuestionAnswerAbstract,
    CommandFactory,
    CommandGetAnswers,
    CommandGetQuestions,
    CommandGetSurvey,
    CommandResultError,
    CommandResultGetAnswers,
    CommandResultGetQuestions,
    CommandResultGetSurvey,
    CommandResultSuccess,
    LPageAbstract,
    LLayoutAbstract,
    ESupportedLanguage,
    CommandGetPage,
    CommandResultGetPage,
    CommandEndOfSurvey,
    CommandRetrieveSurveyResult,
    CommandResultSurveyNotCompleted,
    CommandResultObserversTokensStillValid,
    QuestionIdentifier,
    LQuestionAbstract,
    ExtraArgsCommandGetSurvey,
    ExtraArgsCommandGetPage,
    ExtraArgsCommandGetQuestions,
    ExtraArgsCommandGetAnswers,
    ExtraArgsCommandRetrieveSurveyResult,
    ExtraArgsCommandGenerateDynamicToken,
    CommandGenerateDynamicToken,
    CommandResultDynamicToken,
    CommandCreateObserversSurveyTokens,
    ExtraArgsCommandSendEmailWithTokenToObserver,
    CommandResultEndOfSurvey,
    ExtraArgsCommandCreateObserversSurveyTokens,
    CommandSendEmailWithTokenToObserver,
    CommandResultCreateObserverSurveyTokens,
    CommandSendEmailConfirmationToAuto,
    ExtraArgsCommandSendEmailConfirmationToAuto,
    ICommandResult,
    ExtraArgsCommandEndOfSurvey,
    CommandResultGetIndicators,
    IndicatorModel,
} from "4common-ts";

import ControllerAbstract from "../../Common/Controllers/controller-abstract";
import CommandController, { EEndPointSecure } from "../../Common/command-controller";
import { UpdateAnswerCommandFactory } from "../utils/models/commands/update-answer-command";
import { getPageQuestionIds, includesRandomQuestions } from "Survey/Components/Layout/utils/page";
import { updatePageLayout } from "Survey/Components/Layout/utils/layout";
import { UserData } from "Common/user-data";

type requestSurveyByIdAsyncReturnType = [
    SurveyModel,
    { [key: string]: QuestionAnswerAbstract },
    string,
    LLayoutAbstract
];

const cookies: Cookies = new Cookies();

export default class SurveyController extends ControllerAbstract {
    static myName: string = "survey-controller";

    private surveyStatic: boolean;
    private surveyName: string = "";

    constructor(
        commandMediator: CommandController,
        userData: UserData,
        currentLang: ESupportedLanguage,
        surveyStatic: boolean = false
    ) {
        super(commandMediator, userData, SurveyController.myName, currentLang);
        this.surveyStatic = surveyStatic;
    }

    /**
     * Request the different part of a survey (question, answers, user answers, ...) and
     * return a SurveyModel and a list QuestionAnswerModel to the survey view by calling
     * onSurveyReceived.
     *
     * This function should be call only one time when the survey is first display.
     *
     * @param surveyId id of the survey to display
     * @param lang select language
     * @param nextPageId index of the page to display (-1 to display the next unanswer question)
     */
    requestSurveyByIdAsync(lang: ESupportedLanguage): Promise<requestSurveyByIdAsyncReturnType> {
        return new Promise<requestSurveyByIdAsyncReturnType>(async (resolve, reject) => {
            try {
                if (this.surveyStatic) {
                    // Use cookie so we don't create a new token for every refresh
                    const ckie_token = cookies.get(`token_${this.surveyName}`);
                    if (ckie_token) {
                        this.setSurveyToken(ckie_token);
                    } else {
                        const commandGenerateToken = CommandFactory.create<ExtraArgsCommandGenerateDynamicToken>(
                            CommandGenerateDynamicToken.type,
                            "",
                            {
                                surveyName: this.surveyName,
                            }
                        );

                        const resultGenerateToken = await this.sendCommandAsync(
                            commandGenerateToken,
                            EEndPointSecure.PUBLIC
                        );

                        if (resultGenerateToken === undefined) {
                            throw Error("Something went wrong while requesting survey.");
                        } else if (resultGenerateToken.type === CommandResultError.type) {
                            throw Error((resultGenerateToken as CommandResultError).error.toString());
                        }

                        this.setSurveyToken((resultGenerateToken as CommandResultDynamicToken).token);
                        cookies.set(`token_${this.surveyName}`, this.token, { path: "/" });
                    }
                }

                const command = CommandFactory.create<ExtraArgsCommandGetSurvey>(CommandGetSurvey.type, this.token, {
                    surveyId: "", //FIXME: DEV4S-57
                    surveyStatic: this.surveyStatic,
                    lang,
                });

                const result = await this.sendCommandAsync(command, EEndPointSecure.PUBLIC);

                if (result === undefined) {
                    throw Error("Something went wrong while requesting survey.");
                } else if (result.type === CommandResultError.type) {
                    throw Error((result as CommandResultError).error.toString());
                }

                if (!this.isValideRequestResult(result, lang)) {
                    reject();
                    return;
                }

                const surveyModel = (result as CommandResultGetSurvey).surveyModel;
                const layoutSkeleton: LLayoutAbstract = (result as CommandResultGetSurvey).layoutSkeleton;
                const areRandomQuestions = includesRandomQuestions(layoutSkeleton);
                const randomPage = areRandomQuestions ? layoutSkeleton.containers![0] : undefined;
                const nextPage = await this.requestPageByIdAsync(layoutSkeleton.containers![0].id, lang, randomPage); // FIXME: this should be replace by requestNextPageAsync
                if (nextPage === undefined) throw Error("Something went wrong while requesting survey.");

                const nextPageQuestionIds = getPageQuestionIds(nextPage);
                const questionAnswers = await this.requestAnswersByQuestionIdsAsync(nextPageQuestionIds, lang);
                const layout = updatePageLayout(nextPage, layoutSkeleton)!;

                resolve([surveyModel, questionAnswers, nextPage.id, layout]);
            } catch (e: any) {
                this.logError(e);
                reject();
            }
        });
    }

    requestPageByIdAsync(pageId: string, lang: ESupportedLanguage, randomPage?: LPageAbstract): Promise<LPageAbstract> {
        return new Promise<LPageAbstract>(async (resolve, reject) => {
            try {
                const command = CommandFactory.create<ExtraArgsCommandGetPage>(CommandGetPage.type, this.token, {
                    pageId,
                    lang,
                    randomPage,
                });

                const result = await this.sendCommandAsync(command, EEndPointSecure.PUBLIC);
                if (result === undefined) {
                    throw Error("Something went wrong while requesting survey.");
                } else if (result.type === CommandResultError.type) {
                    throw Error((result as CommandResultError).error.toString());
                }

                if (!this.isValideRequestResult(result, lang)) {
                    reject();
                    return;
                }

                resolve((result as CommandResultGetPage).pageLayout);
            } catch (e: any) {
                this.logError(e);
                reject();
            }
        });
    }

    /**
     * Send command to request a Questions by Ids.
     *
     * @param questionId id of the question to display
     * @param lang select language
     */
    requestQuestionsByIdsAsync(questionIds: Array<string>, lang: ESupportedLanguage): Promise<Array<QuestionAbstract>> {
        return new Promise<Array<QuestionAbstract>>(async (resolve, reject) => {
            try {
                const command = CommandFactory.create<ExtraArgsCommandGetQuestions>(
                    CommandGetQuestions.type,
                    this.token,
                    {
                        questionIds,
                        lang: lang,
                    }
                );
                if (command === undefined) {
                    throw Error("Command 'GetQuestions' could not be created.");
                }

                const result = await this.sendCommandAsync(command, EEndPointSecure.PUBLIC);

                if (result === undefined) {
                    throw Error("Something went wrong while requesting questions.");
                } else if (result.type === CommandResultError.type) {
                    throw (result as CommandResultError).error.toString();
                }

                if (!this.isValideRequestResult(result, lang)) {
                    reject();
                    return;
                }

                resolve((result as CommandResultGetQuestions).questionModels);
            } catch (e: any) {
                this.logError(e);
                reject(undefined);
            }
        });
    }

    saveUserAnswerAsync(
        currentQuestionLayout: LQuestionAbstract<QuestionAbstract>,
        answerUpdatedModel: QuestionAnswerAbstract,
        questionIdentifier: QuestionIdentifier,
        lang: ESupportedLanguage
    ) {
        return new Promise<QuestionAnswerAbstract>(async (resolve, reject) => {
            try {
                if (answerUpdatedModel === undefined)
                    throw Error(`Could not update answer for question with id [${currentQuestionLayout.question.id}]`);
                const updateCommand = UpdateAnswerCommandFactory.Create(
                    currentQuestionLayout.type,
                    questionIdentifier,
                    this.token
                );

                if (updateCommand === undefined) {
                    throw Error("Command " + updateCommand + " could not be created.");
                }
                const command = updateCommand.generateUpdateAnswerCommand(answerUpdatedModel);

                if (command === undefined) {
                    throw Error("Command " + command + " could not be created.");
                }

                const result = await this.sendCommandAsync(command, EEndPointSecure.PUBLIC);

                if (result === undefined) {
                    throw Error("Something went wrong while requesting answer.");
                } else if (result.type === CommandResultError.type) {
                    throw (result as CommandResultError).error.toString();
                }

                if (!this.isValideRequestResult(result, lang)) {
                    reject();
                    return;
                }

                resolve(answerUpdatedModel);
            } catch (e: any) {
                this.logError(e);
                reject();
            }
        });
    }

    /**
     *
     * @param questionIds
     * @param bearerToken
     * @returns
     */
    requestAnswersByQuestionIdsAsync(
        questionIds: Array<string>,
        lang: ESupportedLanguage
    ): Promise<{ [key: string]: QuestionAnswerAbstract }> {
        return new Promise<{ [key: string]: QuestionAnswerAbstract }>(async (resolve, reject) => {
            const command = CommandFactory.create<ExtraArgsCommandGetAnswers>(CommandGetAnswers.type, this.token, {
                questionIds,
            });

            const result = await this.sendCommandAsync(command, EEndPointSecure.PUBLIC);

            if (result === undefined) {
                reject("Something went wrong while requesting answer.");
                return;
            } else if (result.type === CommandResultError.type) {
                reject((result as CommandResultError).error.toString());
                return;
            }

            if (!this.isValideRequestResult(result, lang)) {
                reject();
                return;
            }

            const questionAnswerModels = (result as CommandResultGetAnswers).questionAnswerModels;
            const answersDict: { [key: string]: QuestionAnswerAbstract } = {};
            questionAnswerModels.forEach((q) => (answersDict[q.question_id] = q));
            resolve(answersDict);
        });
    }

    /**
     *
     * @returns
     */
    endSurveyAsync(lang: ESupportedLanguage): Promise<ICommandResult> {
        return new Promise<ICommandResult>(async (resolve, reject) => {
            if (this.surveyStatic) {
                cookies.remove(`token_${this.surveyName}`, { path: "/" });
            }

            const command = CommandFactory.create<ExtraArgsCommandEndOfSurvey>(CommandEndOfSurvey.type, this.token, {
                lang,
            });
            if (command === undefined) {
                reject("Command 'CommandEndOfSurvey' could not be created.");
                return;
            }

            const result = await this.sendCommandAsync(command, EEndPointSecure.PUBLIC);
            // Errors
            if (result === undefined) {
                reject("Something went wrong while sending endSurvey.");
                return;
            } else if (result.type === CommandResultError.type) {
                reject((result as CommandResultError).error.toString());
                return;
            }

            if (!this.isValideRequestResult(result, lang)) {
                reject();
                return;
            }

            // Success
            if (result.type === CommandResultEndOfSurvey.type) {
                resolve(result);
            }
        });
    }

    /**
     *
     * @returns
     */
    createObserverSurveyTokensAsync(lang: ESupportedLanguage): Promise<Array<string>> {
        return new Promise<Array<string>>(async (resolve, reject) => {
            if (this.surveyStatic) {
                cookies.remove(`token_${this.surveyName}`, { path: "/" });
            }

            const command = CommandFactory.create<ExtraArgsCommandCreateObserversSurveyTokens>(
                CommandCreateObserversSurveyTokens.type,
                this.token,
                {}
            );
            if (command === undefined) {
                reject("Command 'CommandCreateObserversSurveyTokens' could not be created.");
                return;
            }

            const result = await this.sendCommandAsync(command, EEndPointSecure.PUBLIC);
            // Errors
            if (result === undefined) {
                reject("Something went wrong while sending endSurvey.");
                return;
            } else if (result.type === CommandResultError.type) {
                reject((result as CommandResultError).error.toString());
                return;
            }

            if (!this.isValideRequestResult(result, lang)) {
                reject();
                return;
            }

            // Success
            if (result.type === CommandResultCreateObserverSurveyTokens.type) {
                const childrenTokenIds = (result as CommandResultCreateObserverSurveyTokens).childrenTokenIds;
                resolve(childrenTokenIds);
            }
        });
    }

    /**
     *
     * @returns
     */
    sendEmailWithTokenToObserversAsync(childTokenId: string, lang: ESupportedLanguage): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            if (this.surveyStatic) {
                cookies.remove(`token_${this.surveyName}`, { path: "/" });
            }

            const command = CommandFactory.create<ExtraArgsCommandSendEmailWithTokenToObserver>(
                CommandSendEmailWithTokenToObserver.type,
                this.token,
                { childTokenId, lang: ESupportedLanguage[lang] }
            );
            if (command === undefined) {
                reject("Command 'CommandSendEmailWithTokenToObserver' could not be created.");
                return;
            }

            const result = await this.sendCommandAsync(command, EEndPointSecure.PUBLIC);
            // Errors
            if (result === undefined) {
                reject("Something went wrong while sending endSurvey.");
                return;
            } else if (result.type === CommandResultError.type) {
                reject((result as CommandResultError).error.toString());
                return;
            }

            if (!this.isValideRequestResult(result, lang)) {
                reject();
                return;
            }

            // Success
            if (result.type === CommandResultSuccess.type) {
                resolve();
                return;
            }
        });
    }

    /**
     *
     * @returns
     */
    sendEmailConfirmationToAutoAsync(lang: ESupportedLanguage): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            if (this.surveyStatic) {
                cookies.remove(`token_${this.surveyName}`, { path: "/" });
            }

            const command = CommandFactory.create<ExtraArgsCommandSendEmailConfirmationToAuto>(
                CommandSendEmailConfirmationToAuto.type,
                this.token,
                { lang: ESupportedLanguage[lang] }
            );

            if (command === undefined) {
                reject("Command 'CommandSendEmailConfirmationToAuto' could not be created.");
                return;
            }

            const result = await this.sendCommandAsync(command, EEndPointSecure.PUBLIC);
            // Errors
            if (result === undefined) {
                reject("Something went wrong while sending endSurvey.");
                return;
            } else if (result.type === CommandResultError.type) {
                reject((result as CommandResultError).error.toString());
                return;
            }

            if (!this.isValideRequestResult(result, lang)) {
                reject();
                return;
            }

            // Success
            if (result.type === CommandResultSuccess.type) {
                resolve();
                return;
            }
        });
    }

    async generateResultAsync(lang: ESupportedLanguage): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            const command = CommandFactory.create<ExtraArgsCommandRetrieveSurveyResult>(
                CommandRetrieveSurveyResult.type,
                this.token,
                { lang, forceRetrieveSurveyResult: false }
            )!;
            const result = await this.sendCommandAsync(command, EEndPointSecure.PUBLIC);
            if (
                result.type === CommandResultSuccess.type ||
                result.type === CommandResultObserversTokensStillValid.type
            ) {
                resolve();
            } else if (result.type === CommandResultSurveyNotCompleted.type) {
                reject("The survey is not complete yet.");
            } else if (result.type === CommandResultError.type) {
                reject((result as CommandResultError).error.toString());
            } else {
                reject("Unknown error.");
            }
        });
    }

    setSurveyName(surveyName: string) {
        this.surveyName = surveyName;
    }
}
