import {
    CommandFactory,
    CommandLogIn,
    CommandResultError,
    ECommandError,
    CommandResultSuccess,
    ExtraArgsCommandLogIn,
    ESupportedLanguage,
} from "4common-ts";
import CommandController, { EEndPointSecure } from "Common/command-controller";
import { UserData } from "Common/user-data";
import ControllerAbstract, { DEFAULT_CONNECTED_PAGE } from "../../Common/Controllers/controller-abstract";

export default class LoginController extends ControllerAbstract {
    static myName: string = "login-controller";

    constructor(commandMediator: CommandController, userData: UserData, currentLang: ESupportedLanguage) {
        super(commandMediator, userData, LoginController.myName, currentLang);
    }

    async onLoginAsync(email: string, password: string, lang: ESupportedLanguage): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            const command = CommandFactory.create<ExtraArgsCommandLogIn>(CommandLogIn.type, this.token, {
                email,
                password,
            });

            const result = await this.sendCommandAsync(command, EEndPointSecure.PUBLIC);
            if (result === undefined) throw Error("Something went wrong while login.");
            else if (result.type === CommandResultError.type) {
                reject((result as CommandResultError).error);
                return;
            } else if (result.type !== CommandResultSuccess.type) {
                reject(ECommandError.UNKNOWN);
                return;
            }

            this.changeLocation(DEFAULT_CONNECTED_PAGE, lang, false);

            resolve();
        });
    }
}
