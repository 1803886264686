import { IAttribute } from "src/layout/attribute/attribute";
import { LDescriptionAbstract } from "src/layout/description/description-abstract";
import { HookAbstract } from "src/layout/hook/hook-abstract";
import { ELChoiceType } from "src/layout/utils/enum-constants";
import { ChoiceModel } from "src/model/survey/choice";
import { LChoiceAbstract } from "./choice-abstract";

// TODO: add an abstract choice and make choice_type extends it if another type of choice is added
export class LSimpleChoice extends LChoiceAbstract<ChoiceModel> {
    static type: ELChoiceType = ELChoiceType.L_SIMPLE_CHOICE;

    constructor(attributes: Array<IAttribute>, hooks: Array<HookAbstract>, choice: ChoiceModel, descriptions: Array<LDescriptionAbstract>) {
        super(LSimpleChoice.type, attributes, hooks, choice, descriptions);
    }
}
