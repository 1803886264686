import { EServices, ICommand } from "../ICommand";
import { ECommandType } from "../../utils/enum-constants";
import { ESexes } from "src/model/user/utils/enum-constants";
import { ESupportedLanguage } from "src/language";

export interface ExtraArgsCommandCreateUser {
    email: string;
    firstName: string;
    lastName: string;
    company: string;
    sex: ESexes;
    lang: ESupportedLanguage;
}

export class CommandCreateUser implements ICommand {
    static readonly type = ECommandType.COMMAND_CREATE_USER;
    static readonly service: EServices = EServices.USER;

    readonly id: string;
    readonly timestamp: string;
    readonly type: ECommandType = CommandCreateUser.type;
    readonly service = CommandCreateUser.service;
    readonly token: string; //for now dont usefull for 4BeUser
    readonly email: string; //this is the email to link to the surveytoken
    readonly firstName: string;
    readonly lastName: string;
    readonly company: string;
    readonly sex: ESexes;
    readonly lang: ESupportedLanguage;

    constructor(id: string, timestamp: string, email: string, firstName: string, lastName: string, company: string, sex: ESexes, lang: ESupportedLanguage) {
        this.id = id;
        this.timestamp = timestamp;
        this.token = "";
        this.email = email.toLowerCase();
        this.firstName = firstName;
        this.lastName = lastName;
        this.company = company;
        this.sex = sex;
        this.lang = lang;
    }
}
