import { QuestionAbstract, allQuestionTypes } from "./question-abstract";
import { ESurveyModelType } from "./utils/enum-constants";

export class QuestionTextModel extends QuestionAbstract {
    static type: ESurveyModelType = ESurveyModelType.QUESTION_TEXT;

    constructor(id: string, survey_id: string, question_type: string, mandatory: boolean, exposed_name: string) {
        super(id, QuestionTextModel.type, survey_id, question_type, mandatory, exposed_name);
    }
}

allQuestionTypes.push(QuestionTextModel.type);
