import { IAttribute, EAkey, EMetaType } from "./attribute";
import { AttributeFactoryResult } from "./attribute-factory";

export class QuestionChoiceDisplayAttr implements IAttribute {
    readonly key: EAkey = EAkey.A_QUESTION_CHOICE_DISPLAY;
    readonly metaType: EMetaType = EMetaType.DISPLAY;
    readonly display: string;

    constructor(args: {}) {
        this.display = args["display"];
    }

    validate = (r: AttributeFactoryResult) => {
        if (findEADisplayChoiceMember(this.display) === undefined) r.error = `Invalid value [${this.display}] for argument 'display' for attribute [QuestionChoiceDisplayAttr]`;
    };
}

export enum EAQuestionChoiceDisplay {
    A_DEFAULT = "default",
    A_QUESTION_CHOICE_HORIZONTAL_BUTTON = "horizontal-button",
    A_QUESTION_CHOICE_VERTICAL_BUTTON = "vertical-button",
    A_QUESTION_CHOICE_SLIDER = "slider",
    A_QUESTION_CHOICE_DISTRIBUTED_SLIDER = "distributed-slider",
}
const findEADisplayChoiceMember = (display: string): EAQuestionChoiceDisplay | undefined => {
    return Object.values(EAQuestionChoiceDisplay).find((e) => e === display);
};
