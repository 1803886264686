import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import * as FR from "locales/fr/translation.json";
import * as EN from "locales/en/translation.json";

const resources = {
    fr: { translation: FR },
    en: { translation: EN },
};

i18n.use(initReactI18next).init({
    resources,
    fallbackLng: "fr",
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;
