import * as EmailValidator from "email-validator";
import {
    ELQuestionType,
    EMetaType,
    LQuestionAbstract,
    QuestionAbstract,
    QuestionAnswerAbstract,
    QuestionAnswerChoiceModel,
    QuestionAnswerMultipleChoiceModel,
    QuestionAnswerTextModel,
    QuestionMultipleChoiceDisplayAttr,
    QuestionTextDisplayAttr,
    EFormats,
    EAQuestionTextDisplay,
    LChoiceAbstract,
    ChoiceModel,
    ELLayoutType,
    QuestionChoiceDisplayAttr,
    EAQuestionChoiceDisplay,
} from "4common-ts";
import { QuestionAbstractComponent } from "Survey/Components/Layout/questions/questions";
import { EAnswerError } from "./error-type";
import { findAttribute } from "Survey/Components/Layout/utils/attribute";

export type validateFct = (
    question: LQuestionAbstract<QuestionAbstract>,
    answer?: QuestionAnswerAbstract
) => Array<EAnswerError>;

/**
 * This fonction iterate over a liste of question layout and
 * call the corresponding validation function depending on the type of the question.
 *
 * @param lquestions
 * @param answers
 */
export const validateAnswersLogic = (
    lquestions: Array<LQuestionAbstract<any>>,
    answers: { [key: string]: QuestionAnswerAbstract },
    layoutType?: ELLayoutType,
    currentPageId?: string
): { [id: string]: Array<EAnswerError> } => {
    const answerErrorMap: { [id: string]: Array<EAnswerError> } = {};

    lquestions.forEach((lquestion: LQuestionAbstract<any>): void => {
        var validator: validateFct = answerValidatorBase; // default

        if (
            lquestion.attributes.find(
                (attribute) =>
                    (attribute as QuestionTextDisplayAttr).format === EFormats.EMAIL ||
                    (attribute as QuestionTextDisplayAttr).format === EFormats.USER_EMAIL
            )
        ) {
            validator = emailValidator;
        } else if (
            lquestion.attributes.find(
                (attribute) => (attribute as QuestionTextDisplayAttr).display === EAQuestionTextDisplay.DYNAMIC_EMAILS
            )
        ) {
            validator = dynamicEmailsValidator;
        } else if (lquestion.type === ELQuestionType.L_QUESTION_TEXT) {
            validator = answerValidatorText;
        } else if (lquestion.type === ELQuestionType.L_QUESTION_CHOICE) {
            validator = answerValidatorChoice;
        } else if (lquestion.type === ELQuestionType.L_QUESTION_MULTIPLE_CHOICE) {
            validator = answerValidatorMultipleChoice;
        }
        const result = validator(lquestion, answers[lquestion.question.id]);
        answerErrorMap[lquestion.question.id] = result;
    });

    if (currentPageId && layoutType === ELLayoutType.L_DISTRIBUTED) {
        //assume its only for distributed questions choices
        var questionsAreDistributed = false;
        lquestions.forEach((lquestion: LQuestionAbstract<any>) => {
            const questionChoiceDisplay = lquestion.attributes.find(
                (attribute) =>
                    (attribute as QuestionChoiceDisplayAttr).display ===
                    EAQuestionChoiceDisplay.A_QUESTION_CHOICE_DISTRIBUTED_SLIDER
            );

            if (questionChoiceDisplay) questionsAreDistributed = true;
        });

        if (questionsAreDistributed) answerErrorMap[currentPageId] = distributedAnswersValidator(lquestions, answers);
    }

    return answerErrorMap;
};

export const validateAnswersWrapperComponent = (
    questionComponents: Array<QuestionAbstractComponent>,
    answers: { [key: string]: QuestionAnswerAbstract }
): { [id: string]: Array<EAnswerError> } => {
    return validateAnswersLogic(
        questionComponents.map((c) => c.props.question),
        answers
    );
};

/**
 * This is a default function in case the type of question is not handle.
 *
 * @param question
 * @param answer
 * @returns
 */
export const answerValidatorBase: validateFct = (question, answer) => {
    if (answer === undefined && !question.question?.mandatory) return [];
    else if (answer === undefined) return [EAnswerError.EMPTY];
    return [];
};

export const emailValidator: validateFct = (question, answer) => {
    if (answer === undefined && !question.question?.mandatory) return [];
    else if (answer === undefined) return [EAnswerError.EMPTY];

    const errorList: Array<EAnswerError> = [];
    const answerText = answer as QuestionAnswerTextModel;
    if (answerText.value.length !== 0) {
        if (!validateEmail(answerText.value)) errorList.push(EAnswerError.WRONG_EMAIL);
    }
    if (question.question?.mandatory && answerText.value.trim().length === 0) errorList.push(EAnswerError.EMPTY);
    return errorList;
};
export const dynamicEmailsValidator: validateFct = (question, answer) => {
    if (answer === undefined && !question.question?.mandatory) return [];
    else if (answer === undefined) return [EAnswerError.EMPTY];

    const errorList: Array<EAnswerError> = [];
    const emailsToOneString = (answer as QuestionAnswerTextModel).value;
    const emails = emailsToOneString.split(";").filter((email) => email !== "");
    const questionTextDisplayAttr = question.attributes.find(
        (attribute) => (attribute as QuestionTextDisplayAttr).display === EAQuestionTextDisplay.DYNAMIC_EMAILS
    ) as QuestionTextDisplayAttr;

    if (emailsToOneString.trim().length === 0) errorList.push(EAnswerError.EMPTY);
    else if (haveDuplicateEmail(emails)) {
        errorList.push(EAnswerError.TOO_MANY_SELECTION);
    } else if (questionTextDisplayAttr.min) {
        if (emails.length < questionTextDisplayAttr.min) {
            errorList.push(EAnswerError.MISSING_SELECTION);
        }
    }

    return errorList;
};

/**
 * this answer validation is only for distributed layout.
 * The questions on the same distribution need to be on the same page.
 * Assume its already done in the layout.
 * A distribution is a set of answers which the sum of the answers must 100%.
 * The scale used is a scale of 10. So on a set of answers, the sum of the answers must give 10.
 * Example, if there are 4 answers: we can have the following answers:
 * (7,1,1,1),(6,2,1,1),(5,3,1,1),(5,2,2,1),(4,4,1,1),(4,3,2,1),(4,2,2,2),(3,3,3,1) or (3,3,2,2)
 * @param lquestions
 * @param answers
 * @returns
 */

export const distributedAnswersValidator = (
    lquestions: Array<LQuestionAbstract<any>>,
    answers: { [key: string]: QuestionAnswerAbstract }
) => {
    const errorList: Array<EAnswerError> = [];

    const distributions: Array<number> = [];
    const totalScore = 10;

    lquestions.forEach((lquestion: LQuestionAbstract<any>) => {
        const questionChoiceDisplay = lquestion.attributes.find(
            (attribute) =>
                (attribute as QuestionChoiceDisplayAttr).display ===
                EAQuestionChoiceDisplay.A_QUESTION_CHOICE_DISTRIBUTED_SLIDER
        );

        if (lquestion.type === ELQuestionType.L_QUESTION_CHOICE && questionChoiceDisplay) {
            //answer can be undefined
            const answerChoice = answers[lquestion.question.id] as QuestionAnswerChoiceModel;
            const choices = lquestion.containers as Array<LChoiceAbstract>;

            if (answerChoice) {
                const choice = choices.find((choice) => choice.choice.id === answerChoice.choice_id);
                const choiceModel = choice?.choice as ChoiceModel;
                distributions.push(Number(choiceModel.value));
            } else return errorList;
        }
    });

    var score = 0;
    distributions.forEach((s) => {
        score = score + s;
    });

    if (score !== totalScore) {
        errorList.push(EAnswerError.WRONG_DISTRIBUTION);
    }
    return errorList;
};

export const answerValidatorText: validateFct = (question, answer) => {
    if (answer === undefined && !question.question?.mandatory) return [];
    else if (answer === undefined) return [EAnswerError.EMPTY];

    const errorList: Array<EAnswerError> = [];
    const answerText = answer as QuestionAnswerTextModel;
    if (question.question?.mandatory && answerText.value.trim().length === 0) errorList.push(EAnswerError.EMPTY);
    return errorList;
};

export const answerValidatorChoice: validateFct = (question, answer) => {
    if (answer === undefined) return [EAnswerError.EMPTY];

    const answerChoice = answer as QuestionAnswerChoiceModel;
    const errorList: Array<EAnswerError> = [];
    if ((answerChoice.choice_id === undefined || answerChoice.choice_id === "") && question.question?.mandatory)
        errorList.push(EAnswerError.NO_SELECTION);
    return errorList;
};

export const answerValidatorMultipleChoice: validateFct = (question, answers) => {
    if (answers === undefined && !question.question?.mandatory) return [];
    else if (answers === undefined) return [EAnswerError.EMPTY];

    const errorList: Array<EAnswerError> = [];
    if (question.question?.mandatory) {
        let nbAnswerExpected = findAttribute<QuestionMultipleChoiceDisplayAttr>(
            question.attributes,
            EMetaType.DISPLAY
        )?.nbChoice;
        if (nbAnswerExpected === undefined) nbAnswerExpected = -1;

        if (nbAnswerExpected === -1) {
            /* do nothing */
        } else if (nbAnswerExpected > (answers as QuestionAnswerMultipleChoiceModel).choice_ids.length)
            errorList.push(EAnswerError.MISSING_SELECTION);
        else if (nbAnswerExpected < (answers as QuestionAnswerMultipleChoiceModel).choice_ids.length)
            errorList.push(EAnswerError.TOO_MANY_SELECTION);
    }
    return errorList;
};

export function validateEmail(email: string): boolean {
    return EmailValidator.validate(email);
}

export function haveDuplicateEmail(emails: string[]) {
    var haveDuplicate = false;

    emails.forEach((email) => {
        if (emails.filter((duplicate) => duplicate === email).length > 1) haveDuplicate = true;
        if (haveDuplicate) return;
    });

    return haveDuplicate;
}
