import { ECommandType } from "../utils/enum-constants";

export enum EServices {
    NONE = "none",
    SURVEY = "4Be",
    USER = "4BeUser",
}

export interface ICommand {
    readonly id: string;
    readonly timestamp: string; // utc format (new Date().toUTCString())
    readonly type: ECommandType;

    readonly token: string;
    readonly service: EServices;
}

export const EmptyCommand = { id: "00000000-0000-0000-0000-000000000000", timestamp: "", type: ECommandType.EMPTY, token: "", service: EServices.NONE } as ICommand;
