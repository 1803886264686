import { EServices, ICommand } from "../ICommand";
import { ECommandType } from "../../utils/enum-constants";

export interface ExtraArgsCommandLogIn {
    email: string;
    password: string;
}

export class CommandLogIn implements ICommand {
    static readonly type = ECommandType.COMMAND_LOG_IN;
    static readonly service: EServices = EServices.USER;

    readonly id: string;
    readonly timestamp: string;
    readonly type: ECommandType = CommandLogIn.type;
    readonly service: EServices = CommandLogIn.service;
    readonly token: string; //for now dont usefull for 4BeUser
    readonly email: string;
    readonly password: string;

    constructor(id: string, timestamp: string, email: string, password: string) {
        this.id = id;
        this.timestamp = timestamp;
        this.token = "";
        this.email = email.toUpperCase();
        this.password = password;
    }
}
