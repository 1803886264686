import { EServices, ICommand } from "../ICommand";
import { ECommandType } from "../../utils/enum-constants";

export interface ExtraArgsCommandSignIn {
    fullName: string;
    email: string;
    password: string;
}

export class CommandSignIn implements ICommand {
    static readonly type = ECommandType.COMMAND_SIGN_IN;
    static readonly service: EServices = EServices.USER;

    readonly id: string;
    readonly timestamp: string;
    readonly type: ECommandType = CommandSignIn.type;
    readonly service: EServices = CommandSignIn.service;
    readonly token: string; //for now dont usefull for 4BeUser
    readonly fullName: string;
    readonly email: string;
    readonly password: string;
    constructor(id: string, timestamp: string, fullName: string, email: string, password: string) {
        this.id = id;
        this.timestamp = timestamp;
        this.token = "";
        this.fullName = fullName;
        this.email = email.toLowerCase();
        this.password = password;
    }
}
