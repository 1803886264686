import { EHookType } from "../utils/enum-constants";
import { HookAbstract } from "./hook-abstract";

export class HookLayout extends HookAbstract<EHLListenner> {
    static type: EHookType = EHookType.H_LAYOUT;
}

export enum EHLListenner {
    H_None = "None",
    ON_END_OF_SURVEY_CLICK = "ON_END_OF_SURVEY_CLICK",
}
