import { IAttribute, EAkey, EMetaType, EFormats } from "./attribute";
import { AttributeFactoryResult } from "./attribute-factory";

export class QuestionTextDisplayAttr implements IAttribute {
    readonly key: EAkey = EAkey.A_QUESTION_TEXT_DISPLAY;
    readonly metaType: EMetaType = EMetaType.DISPLAY;
    readonly display: string;
    readonly format: EFormats | undefined;
    readonly min: number | undefined;
    readonly max: number | undefined;

    constructor(args: {}) {
        this.display = args["display"];
        args["format"] === undefined ? this.format === EFormats.DEFAULT : (this.format = args["format"]);
        args["min"] === undefined ? (this.min = 1) : (this.min = Number(args["min"]));
        args["max"] === undefined ? (this.max = undefined) : (this.max = Number(args["max"]));
    }

    validate = (r: AttributeFactoryResult): void => {
        if (findEADisplayTextMember(this.display) === undefined) r.error = `Invalid value [${this.display}] for argument 'display' for attribute [QuestionTextDisplayAttr]`;
    };
}

export enum EAQuestionTextDisplay {
    A_DEFAULT = "default",
    A_QUESTION_TEXT_ONE_LINE = "text-one-line",
    DYNAMIC_EMAILS = "dynamic-emails",
}
const findEADisplayTextMember = (display: string): EAQuestionTextDisplay | undefined => {
    return Object.values(EAQuestionTextDisplay).find((e) => e === display);
};
