import { EPrivileges } from "4common-ts";

/**
un utilisateur avec un privillege peut voir tout les onglet avec un privillege suppérieur
admin niveau 1 est le plus haut niveau de privillege, il peut voir tous les onglets disponible,
le manager peut voir les page que voit un employé
 */
export enum EPrivillegeLevels {
    ADMIN = 1,
    FORMER = 2,
    MANAGER = 3,
    EMPLOYEE = 4,
    NONE = Number.MAX_SAFE_INTEGER,
}

export const privillegeLevels = new Map<EPrivileges, EPrivillegeLevels>();
privillegeLevels.set(EPrivileges.ADMIN, EPrivillegeLevels.ADMIN);
privillegeLevels.set(EPrivileges.FORMER, EPrivillegeLevels.FORMER);
privillegeLevels.set(EPrivileges.MANAGER, EPrivillegeLevels.MANAGER);
privillegeLevels.set(EPrivileges.EMPLOYEE, EPrivillegeLevels.EMPLOYEE);

export const getPrivilegeLevel = (privilege: EPrivileges | string | undefined): EPrivillegeLevels => {
    if (privilege === undefined) return EPrivillegeLevels.NONE;

    const userPriv = findPrivilegeMember(privilege);
    if (userPriv === undefined) return EPrivillegeLevels.NONE;

    const usrPrivLevel = privillegeLevels.get(userPriv);
    if (usrPrivLevel === undefined) return EPrivillegeLevels.EMPLOYEE;

    return usrPrivLevel;
};

export const findPrivilegeMember = (privilege: string): EPrivileges | undefined => {
    return Object.values(EPrivileges).find((epriv) => epriv === privilege);
};

export const hasPrivilleges = (
    usrLevelPrivillege: EPrivillegeLevels,
    minExpectedPrivillege: EPrivillegeLevels
): boolean => {
    return usrLevelPrivillege <= minExpectedPrivillege;
};
