import { ESurveyModelType } from "./utils/enum-constants";
import { ModelAbstract } from "../model-abstract";

export class ParticipantModel extends ModelAbstract {
    static type: ESurveyModelType = ESurveyModelType.PARTICIPANT;

    firstName: string;
    name: string;
    email: string;
    survey_token_id: string;

    constructor(id: string, firstName: string, name: string, email: string, survey_token_id: string) {
        super(id, ParticipantModel.type);
        this.firstName = firstName;
        this.name = name;
        this.email = email;
        this.survey_token_id = survey_token_id;
    }
}
