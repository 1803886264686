import { ESupportedLanguage } from "4common-ts";

import TokenUsedController from "../Controllers/token-used-controller";
import ActuComponentBase, { IBaseProps, IBaseState } from "../../Common/Components/actu-component-base";
import CommonPageFrame from "../../Common/Components/page-frame";
import { ComponentWithPredefinedLang } from "Survey/langugae/interface";

import Container from "@material-ui/core/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

interface ITokenUsedProps extends IBaseProps, ComponentWithPredefinedLang {
    availableLanguages: Array<ESupportedLanguage>;
}

export default class TokenUsed extends ActuComponentBase<TokenUsedController, ITokenUsedProps, IBaseState> {
    goToMainMenu() {
        window.location.href = "/";
    }

    render() {
        return (
            <Container maxWidth="md">
                <CommonPageFrame
                    availableLanguages={this.props.availableLanguages}
                    onselectlanguage={(lang) => this.props.onselectlanguage(lang)}
                    disconnectUser={() => this.controller.disconnectUser(this.props.selectlang)}
                    selectlang={this.props.selectlang}
                    surveyTitle={""}
                    userData={this.controller.userData}
                >
                    <Grid container direction="column" display="flex" justifyContent="center" alignItems="center">
                        <Box>
                            <Typography align="center" variant="h2">
                                {this.props.t("error.jeton invalide")}
                            </Typography>
                        </Box>

                        <Box
                            component="img"
                            sx={{
                                height: "50%",
                                width: "50%",
                            }}
                            alt="The house from the offer."
                            src="https://cdn.sanity.io/images/i4kanko4/production/4b774a50853179784ad4e8e58f6571a4993e6627-700x700.png?fit=max&auto=format"
                        />

                        <Box marginTop={3}>
                            <Typography variant="h5">
                                {this.props.t("error.acces refuse - message")}
                                <strong>1-877-688-0101</strong> {this.props.t("error.message")}{" "}
                                <strong>info@actualisation.com</strong>.
                            </Typography>
                        </Box>

                        <Box marginTop={5}>
                            <Button onClick={() => this.goToMainMenu()} className="btn btn-primary" variant="contained">
                                {this.props.t("error.Retour accueil")}
                            </Button>
                        </Box>
                    </Grid>
                </CommonPageFrame>
            </Container>
        );
    }
}
