import { EServices, ICommand } from "../ICommand";
import { ECommandType } from "../../utils/enum-constants";

export interface ExtraArgsCommandGetTokenInfos {}

export class CommandGetTokenInfos implements ICommand {
    static readonly type = ECommandType.COMMAND_GET_TOKEN_INFOS;
    static readonly service: EServices = EServices.SURVEY;

    readonly id: string;
    readonly timestamp: string;
    readonly type: ECommandType = CommandGetTokenInfos.type;
    readonly service: EServices = CommandGetTokenInfos.service;
    readonly token: string;

    constructor(id: string, timestamp: string, token: string) {
        this.id = id;
        this.timestamp = timestamp;
        this.token = token;
    }
}
