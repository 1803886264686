import { EServices, ICommand } from "../ICommand";
import { ECommandType } from "../../utils/enum-constants";

export interface ExtraArgsCommandGetSurveyAvailableLanguages {}

export class CommandGetSurveyAvailableLanguages implements ICommand {
    static readonly type = ECommandType.COMMAND_GET_SURVEY_AVAILABLE_LANGUAGES;
    static readonly service: EServices = EServices.SURVEY;

    readonly id: string;
    readonly timestamp: string;
    readonly type: ECommandType = CommandGetSurveyAvailableLanguages.type;
    readonly service: EServices = CommandGetSurveyAvailableLanguages.service;
    readonly token: string;

    constructor(id: string, timestamp: string, token: string) {
        this.id = id;
        this.timestamp = timestamp;
        this.token = token;
    }
}
