import { ContainerAbstract } from "../../container-abstract";
import { IAttribute } from "../../attribute/attribute";
import { ELQuestionType } from "../../utils/enum-constants";
import { QuestionAbstract } from "src/model/survey/question-abstract";
import { HookAbstract } from "src";
import { EQIFavorMethod } from "../../../command/utils/question-identifier";
import { LDescriptionAbstract } from "../../description/description-abstract";
import { LChoiceAbstract } from "./choice/choice-abstract";

export abstract class LQuestionAbstract<question_type extends QuestionAbstract, container_type extends LChoiceAbstract | undefined = any> extends ContainerAbstract<
    container_type,
    ELQuestionType
> {
    static type: ELQuestionType = ELQuestionType.L_ABSTRACT;

    question: question_type;
    favorMethod: EQIFavorMethod = EQIFavorMethod.QUESTION_ID;

    constructor(
        type: ELQuestionType,
        attributes: Array<IAttribute>,
        hooks: Array<HookAbstract>,
        question: question_type,
        descriptions: Array<LDescriptionAbstract>,
        containers: Array<container_type> | undefined
    ) {
        super(type, attributes, hooks, descriptions, containers);
        this.question = question;
    }
}
