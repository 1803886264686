import { EServices, ICommand } from "../ICommand";
import { ECommandType } from "../../utils/enum-constants";

export interface ExtraArgsCommandGenerateReport {
    surveyTokenId: string;
}

export class CommandGenerateReport implements ICommand {
    static readonly type = ECommandType.COMMAND_GENERATE_REPORT;
    static readonly service: EServices = EServices.USER;

    readonly id: string;
    readonly timestamp: string;
    readonly type: ECommandType = CommandGenerateReport.type;
    readonly service: EServices = CommandGenerateReport.service;
    readonly token: string;
    readonly surveyTokenId: string;

    constructor(id: string, timestamp: string, surveyTokenId: string) {
        this.id = id;
        this.token = "";
        this.timestamp = timestamp;
        this.surveyTokenId = surveyTokenId;
    }
}
