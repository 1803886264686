export enum EQuestionsType {
    QUESTION_CHOICE = "question-choice",
    QUESTION_TEXT = "question-text",
}
export const findEQuestionTypeMember = (questionType: string): EQuestionsType | undefined => {
    return Object.values(EQuestionsType).find((e) => e === questionType);
};

export enum ESurveyModelType {
    CHOICE = "choice",
    PARTICIPANT = "participant",
    QUESTION_ANSWER_MULTIPLE_CHOICE = "question-answer-multiple-choice",
    QUESTION_ANSWER_CHOICE = "question-answer-choice",
    QUESTION_ANSWER_TEXT = "question-answer-text",
    QUESTION_CHOICE = "question-choice",
    QUESTION_TEXT = "question-text",
    SURVEY_TOKEN = "survey-token",
    SURVEY = "survey",
    INDICATOR = "indicator",
}
export const findESurveyModelTypeMember = (modelType: string): ESurveyModelType | undefined => {
    return Object.values(ESurveyModelType).find((e) => e === modelType);
};

export enum ESurveyType {
    AUTO = "auto",
    OBSERVER = "observateur",
    STANDARD = "standard",
    MULTI = "multi",
}

export const findESurveyTypeMember = (surveyType: string | undefined): ESurveyType | undefined => {
    return Object.values(ESurveyType).find((e) => e === surveyType);
};

const _360Surveys = [ESurveyType.AUTO, ESurveyType.OBSERVER, ESurveyType.MULTI];

export const isType360Survey = (surveyType: ESurveyType | undefined): boolean => {
    if (surveyType === undefined) return false;
    else return _360Surveys.includes(surveyType);
};

export enum ESurveyParticipantInfos {
    EMAIL = "email",
    FIRSTNAME = "firstName",
    LASTNAME = "lastName",
    COMPANY = "company",
}

export const findESurveyParticipantInfosMember = (participantInfos: string | undefined): ESurveyParticipantInfos | undefined => {
    return Object.values(ESurveyParticipantInfos).find((e) => e === participantInfos);
};
