import Papa from "papaparse";
import { Input } from "@mui/material";

export interface IParticipantCsvFormat {
    name: string;
    firstname: string;
    email: string;
}

export interface IInformationCsvFormat {
    session_code: string;
    training_name: string;
    trainer_name: string;
    location: string;
    date: string;
    schedule: string;
    duration: string;
}

interface IAttendanceListCsvReaderProps {
    onSelect: (path: string, data: any) => void;
    onError?: (path: string, error: any) => boolean; // if return true, continue, Otherwise stop.
}

export const INFORMATIONS_COLUMNS = [
    "session_code",
    "training_name",
    "trainer_name",
    "location",
    "date",
    "schedule",
    "duration",
];
export const PARTICIPANT_COLUMNS = ["name", "firstname", "email"];

const readFile = (
    e: any,
    cb: (a: string, b: IParticipantCsvFormat[] | IInformationCsvFormat[]) => void,
    cbErr?: (path: string, error: any) => boolean
) => {
    var file = e.target.files[0];

    Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        delimiter: ";",
        complete: function (results) {
            const column_names = results.meta.fields || [];
            const allInformationsColsPresent = INFORMATIONS_COLUMNS.map((k) => column_names.includes(k)).every(
                (e) => e
            );
            const allParticipantsColsPresent = PARTICIPANT_COLUMNS.map((k) => column_names.includes(k)).every((e) => e);
            const allColsPresent = allInformationsColsPresent || allParticipantsColsPresent;

            if (!allColsPresent) {
                if (!cbErr) return;
                else if (
                    !cbErr(
                        file.name,
                        `Missing columns. Got ${column_names}. Expected ${
                            allInformationsColsPresent ? INFORMATIONS_COLUMNS : PARTICIPANT_COLUMNS
                        }`
                    )
                )
                    return;
            }

            // TODO: validate not empty col in row

            cb(file.name, results.data as IParticipantCsvFormat[] | IInformationCsvFormat[]);
        },
    });
};
export const AttendanceListCsvReader = (props: IAttendanceListCsvReaderProps) => {
    return (
        <div>
            <Input type="file" onChange={(e) => readFile(e, props.onSelect, props.onError)} />
        </div>
    );
};
