import { EDescriptionType } from "../utils/enum-constants";
import { LDescriptionAbstract } from "./description-abstract";
import { LDescriptionSurvey } from "./description-survey";
import { LHtmlDescription } from "./html-description";
import { LSimpleDescription } from "./simple-description";

export class DescriptionFactory {
    static create<E>(descriptionType: EDescriptionType, text: string, lang: string, extraArgs?: E): LDescriptionAbstract | undefined {
        if (descriptionType === EDescriptionType.L_SIMPLE_DESCRIPTION) {
            return new LSimpleDescription(text, lang);
        } else if (descriptionType === EDescriptionType.L_HTML_DESCRIPTION) {
            return new LHtmlDescription(text, lang);
        } else if (descriptionType === EDescriptionType.L_DESCRIPTION_SURVEY) {
            if (!extraArgs) throw Error("Missing extra args");
            return new LDescriptionSurvey(extraArgs["name"], text, lang);
        } else return undefined;
    }
}
