import { ESupportedLanguage, LDescriptionSurvey } from "4common-ts";

import ActuComponentBase, { IBaseProps, IBaseState } from "../../Common/Components/actu-component-base";
import InstructionController from "../Controllers/instruction-controller";
import CommonPageFrame from "../../Common/Components/page-frame";

import Container from "@material-ui/core/Container";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { Fragment } from "react";
import { Trans } from "react-i18next";

interface IInstructionProps extends IBaseProps {}

interface IInstructionState extends IBaseState {
    descriptionSurvey: LDescriptionSurvey | undefined;
    availableLanguages: Array<ESupportedLanguage>;
}

export default class Instructions extends ActuComponentBase<
    InstructionController,
    IInstructionProps,
    IInstructionState
> {
    private instructionsAreLoading: boolean;
    constructor(props: IInstructionProps) {
        super(props);

        this.instructionsAreLoading = false;

        this.state = {
            ...this.state,
            descriptionSurvey: undefined,
            availableLanguages: [],
            hasError: false,
        };
    }
    private async initInstructionsSurvey(): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            this.instructionsAreLoading = true;
            const descriptionSurvey = await this.controller.requestDescriptionSurveyAsync(this.props.selectlang);
            const availableLanguages = await this.controller.requestAvailableLanguageAsync(this.props.selectlang);
            this.setState({ descriptionSurvey, availableLanguages }, () => {
                this.instructionsAreLoading = false;
            });
            resolve();
        });
    }

    render() {
        if (this.state.descriptionSurvey === undefined && !this.instructionsAreLoading) {
            this.initInstructionsSurvey();
        }

        const isLoading = this.state.descriptionSurvey === undefined;

        return (
            <Container
                maxWidth="md"
                style={{ backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center" }}
            >
                <CommonPageFrame
                    availableLanguages={this.state.availableLanguages}
                    onselectlanguage={(lang) => this.props.onselectlanguage(lang)}
                    disconnectUser={() => this.controller.disconnectUser(this.props.selectlang)}
                    selectlang={this.props.selectlang}
                    surveyTitle={isLoading ? "" : this.state.descriptionSurvey!.name!}
                    loading={isLoading}
                    userData={this.controller.userData}
                >
                    {isLoading ? (
                        <Fragment />
                    ) : (
                        <Fragment>
                            <Box
                                marginTop={0}
                                style={{
                                    textAlign: "justify",
                                }}
                                dangerouslySetInnerHTML={{ __html: this.state.descriptionSurvey!.text }}
                            />
                            <style dangerouslySetInnerHTML={{ __html: "* { font-size: 16px !important; }" }} />
                            <br />

                            <Box
                                textAlign="center"
                                style={{
                                    width: "100%",
                                }}
                            >
                                <Button
                                    onClick={() => this.controller.toSurvey(this.props.selectlang)}
                                    className="btn btn-primary"
                                    variant="contained"
                                >
                                    <Trans i18nKey="instruction.Debuter questionnaire" />
                                </Button>
                            </Box>
                        </Fragment>
                    )}
                </CommonPageFrame>
            </Container>
        );
    }
}
