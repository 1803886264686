import { EModelType } from "./enum-constants";

export type IdOrModelType<E extends ModelAbstract> = E | string;

export abstract class ModelAbstract {
    readonly id: string;
    readonly type: EModelType;

    constructor(id: string, type: EModelType) {
        this.id = id;
        this.type = type;
    }
}
