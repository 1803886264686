import { ModelAbstract } from "../model-abstract";
import { ESurveyModelType } from "./utils/enum-constants";

export class SurveyTokenModel extends ModelAbstract {
    static type: ESurveyModelType = ESurveyModelType.SURVEY_TOKEN;

    // Columns
    value: string; // Secret value of the token // FIXME: not sur it useful
    is_still_valid: boolean;
    //format year-month-day
    expiration_date: Date | undefined | null;

    // relations
    survey_id: string;
    parent_token_id: string | undefined;

    constructor(id: string, survey_id: string, value: string, is_still_valid: boolean, parent_token_id: string | undefined, expiration_date?: Date | undefined | null) {
        super(id, SurveyTokenModel.type);

        this.value = value;
        this.is_still_valid = is_still_valid;

        this.survey_id = survey_id;
        this.parent_token_id = parent_token_id;
        this.expiration_date = expiration_date;
    }
}
