import { ICommandResult } from "../ICommandResult";
import { ECommandResultType } from "../../utils/enum-constants";
import { ICommand } from "src/command/command-model/ICommand";

export interface ExtraArgsCommandResultCreateObserverSurveyTokens {
    parentTokenId: string;
    childrenTokenIds: string[];
}

export class CommandResultCreateObserverSurveyTokens implements ICommandResult {
    static readonly type = ECommandResultType.COMMAND_RESULT_CREATE_OBSERVER_SURVEY_TOKENS;

    readonly type: ECommandResultType = CommandResultCreateObserverSurveyTokens.type;
    readonly id: string;
    readonly timestamp: string;
    readonly command: ICommand;
    readonly status: number = 200;
    readonly parentTokenId: string;
    readonly childrenTokenIds: string[];

    cookies: { [key: string]: string } = {};

    constructor(id: string, timestamp: string, command: ICommand, parentTokenId: string, childrenTokenIds: string[]) {
        this.id = id;
        this.timestamp = timestamp;
        this.command = command;
        this.parentTokenId = parentTokenId;
        this.childrenTokenIds = childrenTokenIds;
    }
}
