import { EServices, ICommand } from "../ICommand";
import { ECommandType } from "../../utils/enum-constants";

export interface ExtraArgsCommandGenerateDynamicToken {
    surveyName: string;
}

export class CommandGenerateDynamicToken implements ICommand {
    static readonly type = ECommandType.COMMAND_GENERATE_DYNAMIC_TOKEN;
    static readonly service: EServices = EServices.USER;

    readonly id: string;
    readonly timestamp: string;
    readonly type: ECommandType = CommandGenerateDynamicToken.type;
    readonly service: EServices = CommandGenerateDynamicToken.service;
    readonly token: string; //for now dont usefull for 4BeUser
    jwtToken: string = "";
    readonly surveyName: string;

    constructor(id: string, timestamp: string, surveyName: string) {
        this.id = id;
        this.timestamp = timestamp;
        this.token = "";
        this.surveyName = surveyName;
    }
}
