import { EServices, ICommand } from "../ICommand";
import { ECommandType } from "../../utils/enum-constants";
import { QuestionIdentifier } from "../../utils/question-identifier";

export interface ExtraArgsCommandAnswerTextQuestion {
    questionIdentifier: QuestionIdentifier;
    answerValue: string;
}

export class CommandAnswerTextQuestion implements ICommand {
    static readonly type = ECommandType.COMMAND_ANSWER_TEXT_QUESTION;
    static readonly service: EServices = EServices.SURVEY;

    readonly id: string;
    readonly timestamp: string;
    readonly type: ECommandType = CommandAnswerTextQuestion.type;
    readonly service: EServices = CommandAnswerTextQuestion.service;
    readonly token: string;
    readonly questionIdentifier: QuestionIdentifier;
    readonly answerValue: string;

    constructor(id: string, timestamp: string, token: string, questionIdentifier: QuestionIdentifier, answerValue: string) {
        this.id = id;
        this.timestamp = timestamp;
        this.token = token;
        this.questionIdentifier = questionIdentifier;
        this.answerValue = answerValue;
    }
}
