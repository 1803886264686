import { ESupportedLanguage, ESurveyType, IndicatorModel } from "4common-ts";

import ActuComponentBase, { IBaseProps, IBaseState } from "../../Common/Components/actu-component-base";
import CommonPageFrame from "../../Common/Components/page-frame";
import IndicatorsController from "../Controllers/indicators.controller";

import Grid from "@mui/material/Grid";
import Container from "@material-ui/core/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ComponentWithPredefinedLang } from "Survey/langugae/interface";
import SocialMedias from "Common/Components/social-medias";
import { ISurveyInfos } from "./thanks";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";

interface IndicatorsProps extends IBaseProps, ComponentWithPredefinedLang {}

interface IndicatorsState extends IBaseState {
    indicators: IndicatorModel[];
    surveyInfos: ISurveyInfos | undefined;
    hasError: boolean;
    indicatorsPerStackMaxNumber: number;
    stacks: IStack[];
}

interface IStack {
    id: number;
    indicators: IndicatorModel[];
}

export default class Indicators extends ActuComponentBase<IndicatorsController, IndicatorsProps, IndicatorsState> {
    constructor(props: IndicatorsProps) {
        super(props);

        this.state = {
            indicators: [],
            surveyInfos: undefined,
            hasError: false,
            indicatorsPerStackMaxNumber: 4,
            stacks: [],
        };
    }

    /**
     * This function return a number of stack that containt a limited number of indicator fixed in the state
     * The indicators in the same stack will appear in the same line.
     * @param indicators
     */
    private setStacks(indicators: IndicatorModel[]) {
        const stacks = new Array<IStack>();
        var temp_indicators = new Array<IndicatorModel>();
        var id = 0;

        indicators.forEach((indicator, index) => {
            if (index === indicators.length - 1) {
                //last indicator
                if (temp_indicators.length >= this.state.indicatorsPerStackMaxNumber) {
                    //when the limit is reached we push a new stack
                    stacks.push({
                        id,
                        indicators: temp_indicators,
                    });
                    //reinitialize and insert the last indicator
                    id++;
                    temp_indicators = new Array<IndicatorModel>();
                    temp_indicators.push(indicator);

                    //push the last stack
                    stacks.push({
                        id,
                        indicators: temp_indicators,
                    });
                } else {
                    temp_indicators.push(indicator);
                    //push the last stack
                    stacks.push({
                        id,
                        indicators: temp_indicators,
                    });
                }
            } else if (temp_indicators.length >= this.state.indicatorsPerStackMaxNumber) {
                //when the limit is reached we push a new stack
                stacks.push({
                    id,
                    indicators: temp_indicators,
                });
                //reinitialize the stack
                id++;
                temp_indicators = new Array<IndicatorModel>();
                temp_indicators.push(indicator);
            } else {
                //add an indicator
                temp_indicators.push(indicator);
            }
        });

        this.setState({
            stacks,
        });
    }

    private getIndicatorAsync() {
        return new Promise<void>(async (resolve) => {
            const indicators = await this.controller.getIndicatorsAsync();

            this.setState({
                indicators,
            });

            this.setStacks(this.getChildrenIndicators());

            resolve();
        });
    }

    private getSurveyInfosAsync() {
        return new Promise<void>(async (resolve) => {
            const surveyInfos = await this.controller.getSurveyInfosAsync();

            this.setState({
                surveyInfos,
            });

            resolve();
        });
    }
    private getChildrenIndicators() {
        return this.state.indicators.filter((indicator) => indicator.have_main_skill === true);
    }

    private getParentIndicators() {
        return this.state.indicators.filter((indicator) => indicator.have_main_skill === false);
    }

    private getNumberOfChildrenIndicators(): number {
        return this.state.indicators.filter((indicator) => indicator.have_main_skill === true).length;
    }

    private getChildrenIndicatorText() {
        if (this.getNumberOfChildrenIndicators() === 0) return "";
        else if (this.getNumberOfChildrenIndicators() === 1) return this.props.t("indicators.your_indicator");
        else return this.props.t("indicators.your_indicators");
    }

    private getNumberOfParentIndicators(): number {
        return this.state.indicators.filter((indicator) => indicator.have_main_skill === false).length;
    }

    private getParentIndicatorText() {
        if (this.getNumberOfParentIndicators() === 0) return "";
        else if (this.getNumberOfParentIndicators() === 1) return this.props.t("indicators.your_main_indicator");
        else return this.props.t("indicators.your_main_indicators");
    }

    render() {
        if (this.state.surveyInfos === undefined) {
            this.getSurveyInfosAsync();
            this.getIndicatorAsync();
        }

        return (
            <Container maxWidth="md">
                <CommonPageFrame
                    availableLanguages={this.props.availableLanguages}
                    onselectlanguage={(lang) => this.props.onselectlanguage(lang)}
                    disconnectUser={() => this.controller.disconnectUser(this.props.selectlang)}
                    selectlang={this.props.selectlang}
                    surveyTitle={""}
                    userData={this.controller.userData}
                >
                    <Box>
                        <Typography align="center" variant="h3">
                            {this.state.surveyInfos?.survey_name}
                        </Typography>
                        <br />
                        <Typography align="center" variant="h4">
                            {this.props.t("indicators.results")}
                        </Typography>
                    </Box>
                    <br />

                    <Grid container direction="column" display="flex" justifyContent="center" alignItems="center">
                        <Box>
                            <Stack spacing={5} direction="row">
                                {this.getParentIndicators().map((indicator) => {
                                    return (
                                        <Box
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <Box sx={{ position: "relative", display: "inline-flex" }}>
                                                <CircularProgress
                                                    size="5rem"
                                                    variant="determinate"
                                                    value={indicator.pourcentage}
                                                />
                                                <Box
                                                    sx={{
                                                        top: 0,
                                                        left: 0,
                                                        bottom: 0,
                                                        right: 0,
                                                        position: "absolute",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                    }}
                                                >
                                                    <Typography variant="h6" component="div">
                                                        <b>{`${Math.round(indicator.pourcentage)}%`}</b>
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <Box marginLeft={1}>
                                                <Typography align="center" variant="h6">
                                                    <b>{indicator.skill_name}</b>
                                                </Typography>
                                            </Box>
                                        </Box>
                                    );
                                })}
                            </Stack>
                        </Box>
                    </Grid>

                    <br />
                    <Box>
                        {this.getParentIndicators().map((indicator) => {
                            return (
                                <Typography align="center" variant="h6">
                                    {indicator.description}
                                </Typography>
                            );
                        })}
                    </Box>
                    <br />

                    <Box>
                        {this.state.stacks.map((stack) => {
                            return (
                                <Box>
                                    <Grid
                                        container
                                        direction="column"
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Stack spacing={5} direction="row">
                                            {stack.indicators.map((indicator) => {
                                                return (
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                        }}
                                                    >
                                                        <Box sx={{ position: "relative", display: "inline-flex" }}>
                                                            <CircularProgress
                                                                size="5rem"
                                                                variant="determinate"
                                                                value={indicator.pourcentage}
                                                            />
                                                            <Box
                                                                sx={{
                                                                    top: 0,
                                                                    left: 0,
                                                                    bottom: 0,
                                                                    right: 0,
                                                                    position: "absolute",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                }}
                                                            >
                                                                <Typography variant="h6" component="div">
                                                                    <b>{`${Math.round(indicator.pourcentage)}%`}</b>
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                        <Box marginLeft={1}>
                                                            <Typography align="center" variant="h6">
                                                                <b>{indicator.skill_name}</b>
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                );
                                            })}
                                        </Stack>
                                    </Grid>

                                    <br />
                                    <br />
                                </Box>
                            );
                        })}

                        <br />
                    </Box>
                    <br />
                    <Box>
                        {this.state.surveyInfos?.surveyType === ESurveyType.AUTO ||
                        this.state.surveyInfos?.surveyType === ESurveyType.STANDARD ? (
                            <SocialMedias
                                surveyLink={`${this.controller.getUrlBasePath()}${"/indicators/"}${this.controller.getToken()}`}
                                surveyInfos={this.state.surveyInfos}
                            />
                        ) : (
                            ""
                        )}
                    </Box>

                    <br />
                    <br />

                    <Typography align="center" variant="h5">
                        {this.props.t("indicators.questions")}
                    </Typography>
                    <Typography align="center" variant="h6">
                        {this.props.t("indicators.invitation")}
                    </Typography>
                </CommonPageFrame>
            </Container>
        );
    }
}
