import { EServices, ICommand } from "../ICommand";
import { ECommandType } from "../../utils/enum-constants";

export interface ExtraArgsCommandAssignBuyTokenToASession {
    buy_token_id: string;
    session_id: string;
    code_session: string;
}

export class CommandAssignBuyTokenToASession implements ICommand {
    static readonly type = ECommandType.COMMAND_ASSIGN_A_BUY_TOKEN_TO_A_SESSION;
    static readonly service: EServices = EServices.USER;

    readonly id: string;
    readonly timestamp: string;
    readonly type: ECommandType = CommandAssignBuyTokenToASession.type;
    readonly service = CommandAssignBuyTokenToASession.service;
    readonly token: string; //for now dont usefull for 4BeSession
    readonly buy_token_id: string;
    readonly session_id: string;
    readonly code_session: string;

    constructor(id: string, timestamp: string, buy_token_id: string, session_id: string, code_session: string) {
        this.id = id;
        this.timestamp = timestamp;
        this.token = "";
        this.buy_token_id = buy_token_id;
        this.session_id = session_id;
        this.code_session = code_session;
    }
}
