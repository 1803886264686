import { ICommandResult } from "../ICommandResult";
import { ECommandResultType } from "../../utils/enum-constants";
import { ICommand } from "src/command/command-model/ICommand";

export interface ExtraArgsCommandResultTokenGenerated {
    token: string;
}

export class CommandResultTokenGenerated implements ICommandResult {
    static readonly type = ECommandResultType.COMMAND_RESUTL_TOKEN_GENERATED;

    readonly type: ECommandResultType = CommandResultTokenGenerated.type;
    readonly id: string;
    readonly timestamp: string;
    readonly command: ICommand;
    readonly status: number = 200;

    readonly token: string;

    cookies: { [key: string]: string } = {};

    constructor(id: string, timestamp: string, command: ICommand, token: string) {
        this.id = id;
        this.timestamp = timestamp;
        this.command = command;
        this.token = token;
    }
}
