import { EHookType } from "../utils/enum-constants";
import { HookAbstract } from "./hook-abstract";

export class HookPage extends HookAbstract<EHPListenner> {
    static type: EHookType = EHookType.H_PAGE;
}

export enum EHPListenner {
    H_None = "None",
    ON_NEXT_PAGE_CLICK = "ON_NEXT_PAGE_CLICK",
    ON_PREVIOUS_PAGE_CLICK = "ON_PREVIOUS_PAGE_CLICK",
}
