import { SurveyModel } from "../../../model/survey/survey";
import { ICommandResult } from "../ICommandResult";
import { ECommandResultType } from "../../utils/enum-constants";
import { LLayoutAbstract } from "../../../layout/layout-abstract";
import { ICommand } from "src/command/command-model/ICommand";

export interface ExtraArgsCommandResultGetSurvey {
    surveyModel: SurveyModel;
    layoutSkeleton: LLayoutAbstract;
}

export class CommandResultGetSurvey implements ICommandResult {
    static readonly type = ECommandResultType.COMMAND_RESULT_GET_SURVEY;

    readonly type: ECommandResultType = CommandResultGetSurvey.type;
    readonly id: string;
    readonly timestamp: string;
    readonly command: ICommand;
    readonly status: number = 200;

    readonly surveyModel: SurveyModel;
    readonly layoutSkeleton: LLayoutAbstract;

    cookies: { [key: string]: string } = {};

    constructor(id: string, timestamp: string, command: ICommand, surveyModel: SurveyModel, layoutSkeleton: LLayoutAbstract) {
        this.id = id;
        this.timestamp = timestamp;
        this.command = command;
        this.surveyModel = surveyModel;
        this.layoutSkeleton = layoutSkeleton;
    }
}
