import { ModelAbstract } from "../model-abstract";
import { ESurveyModelType } from "./utils/enum-constants";

export const allQuestionAnswerTypes: Array<string> = [];

export abstract class QuestionAnswerAbstractModel extends ModelAbstract {
    question_type: string;

    // relations
    question_id: string;
    survey_token_id: string;
    extra: string | undefined | null;

    constructor(id: string, type: ESurveyModelType, question_type: string, question_id: string, survey_token_id: string, extra?: string | undefined | null) {
        super(id, type);

        this.question_type = question_type;
        this.question_id = question_id;
        this.survey_token_id = survey_token_id;
        this.extra = extra;
    }
}
