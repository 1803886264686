import * as React from "react";
import ActuComponentBase, { IBaseProps, IBaseState } from "Common/Components/actu-component-base";
import CommonPageFrame from "Common/Components/page-frame";
import { ComponentWithPredefinedLang } from "Survey/langugae/interface";

import Container from "@material-ui/core/Container";
import Grid from "@mui/material/Grid";
import DashboardController from "User/Controllers/dashboard-controller";
import TabContainer from "Common/Components/tabs/tab-container";
import DashboardHome from "./dashboard-home";
import TabHeader from "./Parts/tab-header";
import DashboardMyTokens from "./dashboard-my-token";
import DashboardTokenManager from "./dashboard-token-manager";
import { getPrivilegeLevel, hasPrivilleges } from "Common/privileges";
import DashboardAssignTokenToAnUser from "./dashboard-assign-token-to-an-user";
import DashboardInviteCollaborator from "./dashboard-invite-collaborators";
import DashboardBuyToken from "./dashboard-buy-token";
import DashboardTrainingSession from "./dashboard-training-session";
import DashboardCertificates from "./dashboard-certificates";
import DashboardAttendanceList from "./dashboard-attendance-list";
import DashboardToken from "./dashboard-tokens";

interface IDashboardProps extends IBaseProps, ComponentWithPredefinedLang {
    activeTabTitleOnStart: string;
}
interface IDashboardState extends IBaseState {}

const orderedTabs = [
    DashboardHome,
    DashboardTrainingSession,
    DashboardCertificates,
    DashboardAttendanceList,
    DashboardToken,
    DashboardMyTokens,
    DashboardTokenManager,
    DashboardAssignTokenToAnUser,
    DashboardInviteCollaborator,
    DashboardBuyToken,
];

export default class Dashboard extends ActuComponentBase<DashboardController, IDashboardProps, IDashboardState, true> {
    readonly tabs: { [title: string]: any } = {};
    readonly headerTabs: any[] = [];

    constructor(props: IDashboardProps) {
        super(props);

        this.controller.redirectIfNotConnected(this.props.selectlang);

        this.initTabs();
    }

    private async initTabs() {
        const userPrivillegeLevel = getPrivilegeLevel(this.controller.userData.privilege);
        orderedTabs.forEach((tab) => {
            if (hasPrivilleges(userPrivillegeLevel, tab.privillegeLevel)) {
                this.headerTabs.push(tab);
                this.tabs[tab.title] = React.createElement(
                    tab,
                    {
                        ...this.props,
                        controller: new tab.controllerType(
                            this.controller.commandMediator,
                            this.controller.userData,
                            this.props.selectlang
                        ),
                        key: tab.title,
                    },
                    null
                );
            }
        });
    }

    private onPageChanged(title: string, lang: string) {
        window.history.pushState({}, "", `/dashboard/${title}/${lang}`);
    }

    render() {
        return (
            <Container maxWidth="md">
                <CommonPageFrame
                    availableLanguages={this.props.availableLanguages}
                    onselectlanguage={(lang) => this.props.onselectlanguage(lang)}
                    disconnectUser={() => this.controller.disconnectUser(this.props.selectlang)}
                    selectlang={this.props.selectlang}
                    userData={this.controller.userData}
                >
                    <Grid container direction="column" display="flex" justifyContent="center" alignItems="center">
                        <TabContainer
                            headerCls={TabHeader}
                            onselectlanguage={this.props.onselectlanguage}
                            selectlang={this.props.selectlang}
                            t={this.props.t}
                            availableLanguages={this.props.availableLanguages}
                            controller={null}
                            tabs={this.tabs}
                            tabCls={this.headerTabs}
                            activeTabTitleOnStart={this.props.activeTabTitleOnStart}
                            onPageChanged={this.onPageChanged}
                        />
                    </Grid>
                </CommonPageFrame>
            </Container>
        );
    }
}
