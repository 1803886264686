import { ICommand } from "../../../command-model/ICommand";
import { ICommandResult } from "../../ICommandResult";

import { ECommandResultType } from "../../../utils/enum-constants";

export interface ExtraArgsCommandResultError {
    error: ECommandError;
}

export class CommandResultError implements ICommandResult {
    static readonly type = ECommandResultType.COMMAND_RESULT_ERROR;

    readonly type: ECommandResultType = CommandResultError.type;
    readonly id: string;
    readonly timestamp: string;
    readonly command: ICommand;
    readonly status: number;

    readonly error: ECommandError;

    cookies: { [key: string]: string } = {};

    constructor(id: string, timestamp: string, command: ICommand, error: ECommandError, status: number = 500) {
        this.id = id;
        this.timestamp = timestamp;
        this.command = command;
        this.status = status;

        this.error = error;
    }
}

export enum ECommandError {
    UNKNOWN = "unknown",
    ERROR_WITH_4BE = "error-with-4be",
    ACCESS_DENIED = "access-denied",
    COMMAND_TYPE_UNKNOWN = "command-type-unknown",
    COMMAND_RESULT_TYPE_UNKNOWN = "command-result-type-unknown",
    COMMAND_MALFROMED = "command-malformed",
    COMMAND_NOT_SUPPORTED = "command-not-supported",
    UNAVAILABLE_4RESULT = "unavailable-4result",
    MISSING_PARTICIPANT_INFO = "missing-participant-info",
    CANNOT_FIND_SURVEY = "cannot-find-surey",
    CANNOT_FIND_QUESTION = "cannot-find-question",
    CANNOT_FIND_BUYTOKEN = "cannot-find-buytoken",
    CANNOT_FIND_USER = "cannot-find-user",
    CANNOT_FIND_CHOICE = "cannot-find-choice",
    CANNOT_FIND_SURVEY_TOKEN = "cannot-find-survey-token",
    CANNOT_FIND_USER_INFORMATION = "cannot-find-user-information",
    IMPLEMENTATION_ERROR = "implementation-error",
    LAYOUT_ERROR = "layout-error",
    INVALID_JWTOKEN = "invalid-jwttoken",
    COMMAND_RESULT_MALFORMED = "command-result-malformed",
    TOO_MANY_TOKENS = "too-many-tokens",
    BUY_TOKEN_NOT_ACTIVATED = "buy-token-not-activated",
    MISSING_TOKENINFO_AUTO = "missing-tokeninfo-auto",
    BUYTOKEN_INCOMPATIBLE_WITH_SURVEY_TYPE = "buytoken-incompatible-with-survey-type",
    NO_MORE_TOKEN_ALLOWED = "no-more-token-allowed",
    INVALID_EMAIL = "invalid-email",
    INVALID_PASSWORD = "invalid-password",
    USER_ALREADY_EXIST = "user-already-exist",
    USER_DISABLED = "user-disabled",
    CANNOT_FIND_USER_SURVEY_TOKEN = "cannot-find-user-survey-token",
    TOKEN_STILL_VALID = "token-still-valid",
}
