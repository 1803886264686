import { ESupportedLanguage } from "../../language";
import { EDescriptionType } from "../utils/enum-constants";

export abstract class LDescriptionAbstract {
    static type = EDescriptionType.L_ABSTRACT;

    readonly type: EDescriptionType;
    readonly text: string;
    readonly lang: ESupportedLanguage;

    constructor(type: EDescriptionType, text: string, lang: string | ESupportedLanguage) {
        this.type = type;
        this.text = text;
        if (typeof lang === "string") this.lang = ESupportedLanguage[lang];
        else this.lang = lang;
    }
}
