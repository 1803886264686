import { ESupportedLanguage, ESurveyType } from "4common-ts";

import ActuComponentBase, { IBaseProps, IBaseState } from "../../Common/Components/actu-component-base";
import CommonPageFrame from "../../Common/Components/page-frame";
import ThanksController from "../Controllers/thanks-controller";

import Grid from "@mui/material/Grid";
import Container from "@material-ui/core/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ComponentWithPredefinedLang } from "Survey/langugae/interface";

export interface ISurveyInfos {
    internal_name: string;
    surveyType: ESurveyType;
    survey_name: string;
    survey_description: string;
    have_indicators: boolean;
}

interface IThanksProps extends IBaseProps, ComponentWithPredefinedLang {}

interface IThanksState extends IBaseState {
    surveyInfos: ISurveyInfos | undefined;
    idSurveySelect: string;
    availableLanguages: Array<ESupportedLanguage>;
    hasError: boolean;
}

export default class Thanks extends ActuComponentBase<ThanksController, IThanksProps, IThanksState> {
    constructor(props: IThanksProps) {
        super(props);

        this.state = {
            surveyInfos: undefined,
            idSurveySelect: "",
            availableLanguages: [],
            hasError: false,
        };
    }

    private getSurveyInfosAsync() {
        return new Promise<void>(async (resolve) => {
            const surveyInfos = await this.controller.getSurveyInfosAsync();

            this.setState({
                surveyInfos,
            });

            resolve();
        });
    }

    render() {
        if (this.state.surveyInfos === undefined) {
            this.getSurveyInfosAsync();
        }

        return (
            <Container maxWidth="md">
                <CommonPageFrame
                    availableLanguages={this.props.availableLanguages}
                    onselectlanguage={(lang) => this.props.onselectlanguage(lang)}
                    disconnectUser={() => this.controller.disconnectUser(this.props.selectlang)}
                    selectlang={this.props.selectlang}
                    surveyTitle={""}
                    userData={this.controller.userData}
                >
                    <Grid container direction="column" display="flex" justifyContent="center" alignItems="center">
                        <Box>
                            <Box>
                                <Typography align="center" variant="h3">
                                    {this.props.t("thanks.greetings")}
                                </Typography>
                                <br />
                            </Box>
                            {this.state.surveyInfos?.surveyType === ESurveyType.AUTO ? (
                                <Box>
                                    <Typography align="center" variant="h3">
                                        {this.props.t("thanks.email_confirmation")}
                                    </Typography>
                                    <br />
                                </Box>
                            ) : (
                                ""
                            )}
                            {this.state.surveyInfos?.surveyType === ESurveyType.STANDARD ? (
                                <Box>
                                    <Typography align="center" variant="h3">
                                        {this.props.t("thanks.email")}
                                    </Typography>
                                    <br />
                                </Box>
                            ) : (
                                ""
                            )}
                        </Box>
                        {this.state.surveyInfos?.have_indicators ? (
                            <Typography align="center" variant="h5">
                                <a
                                    href={`${this.controller.getUrlBasePath()}${"/indicators/"}${this.controller.getToken()}`}
                                >
                                    {this.props.t("thanks.indicators")}
                                </a>
                            </Typography>
                        ) : (
                            ""
                        )}

                        <Box
                            component="img"
                            sx={{
                                height: "100%",
                                width: "100%",
                            }}
                            alt="The house from the offer."
                            src="https://cdn.sanity.io/images/i4kanko4/production/93c25c34b2f3a7b3d207c8278922bdc3ce56556e-700x700.png?fit=max&auto=format"
                        />

                        <Box marginTop={3}>
                            <br />
                            <Typography align="center" variant="h3">
                                {this.props.t("thanks.about_actualisation")}
                            </Typography>
                            <Typography align="center" variant="h5">
                                {this.props.t("thanks.about_actualisation_1")}
                                <a href="https://www.actualisation.com/fr/"> www.actualisation.com</a>{" "}
                                {this.props.t("thanks.about_actualisation_2")}
                            </Typography>
                        </Box>
                    </Grid>
                </CommonPageFrame>
            </Container>
        );
    }
}
