import { ContainerAbstract } from "./container-abstract";
import { IAttribute } from "./attribute/attribute";
import { LPageAbstract } from "./page/page-abstract";
import { ELLayoutType } from "./utils/enum-constants";
import { HookAbstract } from "./hook/hook-abstract";
import { ESupportedLanguage } from "src/language";
import { LDescriptionAbstract } from "./description/description-abstract";

export abstract class LLayoutAbstract extends ContainerAbstract<LPageAbstract, ELLayoutType> {
    static type = ELLayoutType.L_ABSTRACT;

    surveyId: string;
    langs: Array<ESupportedLanguage>;
    descriptions: Array<LDescriptionAbstract>;

    constructor(
        type: ELLayoutType,
        attributes: Array<IAttribute>,
        hooks: Array<HookAbstract>,
        containers: Array<LPageAbstract>,
        surveyId: string,
        descriptions: Array<LDescriptionAbstract>,
        langs: Array<ESupportedLanguage>
    ) {
        super(type, attributes, hooks, [], containers);
        this.surveyId = surveyId;
        this.descriptions = descriptions;
        this.langs = langs;
    }
}
