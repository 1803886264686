import BaseTabAbstract, { IBaseTabProps, IBaseTabState } from "Common/Components/tabs/base-tab-abstract";

import Papa from "papaparse";
import DashboardManageTokensController from "User/Controllers/dashboard-token-manager-controller";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { ETokenType, UIToken, UITokenBuyToken, UITokenParent } from "4common-ts";
import { Trans } from "react-i18next";
import { EPrivillegeLevels } from "Common/privileges";
import { convertDateToUTC } from "Common/date";
import { TextField } from "@material-ui/core";
import Button from "@mui/material/Button";
import DashboardTokenManagerController from "User/Controllers/dashboard-token-manager-controller";

// FIXME: should be in 4Common-Ts
export enum EKeySearch {
    EMAIL = "email",
    BUY_TOKEN_ID = "buy_token_id",
    SURVEY_TOKEN_ID = "survey_token_id",
    PARENT_TOKEN_ID = "parent_token_id",
}

interface TokensStruct {
    buyTokens: UITokenBuyToken[];
    autoTokens: UIToken[];
    observerTokens: UIToken[];
    standardTokens: UIToken[];
}

interface IDashboardTokenManagerProps extends IBaseTabProps {}
interface IDashboardTokenManagerState extends IBaseTabState, TokensStruct {
    loadingToken: boolean;
    tokenFirstLoaded: boolean;
    buyTokenIdSelected: string | undefined;
    buyTokenSelected: UITokenBuyToken | undefined;
}

interface IDataToExport {
    id: string;
    type: ETokenType;
    description: string;
    email?: string;
    creation_date?: Date;
    surveyInternalName?: string;
    buyTokenId: string;
    expiration_date?: Date;
    tokenParentId?: string;
    isCompleted: false;
    isDisable: false;
    nbTotalCollaborator: number;
    nbTotalCollaboratorWhereTokenIsCompleted: number;
}

export default class DashboardTokenManager extends BaseTabAbstract<
    DashboardManageTokensController,
    IDashboardTokenManagerProps,
    IDashboardTokenManagerState
> {
    static privillegeLevel = EPrivillegeLevels.ADMIN;
    static controllerType = DashboardTokenManagerController;
    static title = "token-manager";
    title = DashboardTokenManager.title;

    constructor(props: IDashboardTokenManagerProps) {
        super(props);

        this.state = {
            ...this.state,
            buyTokens: [],
            autoTokens: [],
            observerTokens: [],
            standardTokens: [],
            loadingToken: true,
            tokenFirstLoaded: false,
            buyTokenIdSelected: undefined,
            buyTokenSelected: undefined,
        };
    }

    private async fetchTokenData() {
        this.setState({ loadingToken: true, tokenFirstLoaded: true });
        const tks = await new Promise<TokensStruct>(async (resolve) => {
            const tokens = await this.controller.getTokenAsync("*");
            const filterTokens = {
                buyTokens: tokens.filter((token) => token.type === ETokenType.BUY_TOKEN) as UITokenBuyToken[],
                autoTokens: tokens.filter((token) => token.type === ETokenType.AUTO) as UIToken[],
                observerTokens: tokens.filter((token) => token.type === ETokenType.OBSERVER) as UIToken[],
                standardTokens: tokens.filter((token) => token.type === ETokenType.STANDARD) as UIToken[],
            };
            resolve(filterTokens);
        });
        this.setState({ ...tks, loadingToken: false });
    }

    private regroupObserversWithTheSameParentToken(parent_token_id: string) {
        return this.state.observerTokens.filter((uitoken) => uitoken.tokenParentId === parent_token_id);
    }

    private selectBuytokenId(buyTokenId: string) {
        const buyTokenSelected = this.state.buyTokens.find((buyToken) => buyToken.id === buyTokenId);

        this.setState({ buyTokenIdSelected: buyTokenSelected?.id });
        this.setState({ buyTokenSelected: buyTokenSelected });
    }

    private getSurveyTokenFromBuyToken(buyToken: UITokenBuyToken | undefined) {
        if (!buyToken) return [];

        return [...this.state.autoTokens, ...this.state.standardTokens].filter(
            (surveyToken) => surveyToken.buyTokenId === buyToken.id
        );
    }

    private convertToCSV() {
        const uitokens = this.getSurveyTokenFromBuyToken(this.state.buyTokenSelected);
        const data: IDataToExport[] = uitokens.map((uitoken) => {
            return {
                id: uitoken.id,
                link: `${this.controller.getUrlBasePath()}/instructions/${uitoken.id}/${this.props.selectlang}`,
                type: uitoken.type,
                description: uitoken.description,
                email: uitoken.email,
                creation_date: uitoken.creation_date,
                surveyInternalName: uitoken.surveyInternalName,
                buyTokenId: uitoken.buyTokenId,
                expiration_date: uitoken.expiration_date,
                tokenParentId: uitoken.tokenParentId,
                isCompleted: uitoken.isCompleted,
                isDisable: uitoken.isDisable,
                nbTotalCollaborator:
                    uitoken.type === ETokenType.STANDARD ? 0 : (uitoken as UITokenParent).nbTotalCollaborator,
                nbTotalCollaboratorWhereTokenIsCompleted:
                    uitoken.type === ETokenType.STANDARD
                        ? 0
                        : (uitoken as UITokenParent).nbTotalCollaboratorWhereTokenIsCompleted,
            };
        });
        const csv = Papa.unparse(data);
        return csv;
    }
    private csvDownloader() {
        const handleDownloadCSV = () => {
            const csvData = this.convertToCSV();
            const blob = new Blob([csvData], { type: "text/csv" });
            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            const fileName = this.state.buyTokenSelected
                ? this.state.buyTokenSelected.id + "-" + this.state.buyTokenSelected?.surveyInternalName
                : "no-datas";
            a.href = url;
            a.download = fileName + ".csv";
            a.click();
            URL.revokeObjectURL(url);
        };

        return (
            <div>
                <button onClick={handleDownloadCSV}>Télécharger en CSV</button>
            </div>
        );
    }

    render() {
        if (!this.state.tokenFirstLoaded) this.fetchTokenData();

        return (
            <Box>
                <Box>
                    <br />
                    <br />
                    <Typography variant="h5">
                        <Trans i18nKey="dashboard.token-manager.buy_token_table_title" />
                    </Typography>

                    <TableContainer component={Paper} style={{ backgroundColor: "#E4E4F1" }}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    {tableColumnNames.buyTokens.map((column_name) => {
                                        return (
                                            <TableCell align="center">
                                                <Typography fontWeight={700} fontSize="15px" fontStyle={"bold"}>
                                                    <Trans i18nKey={column_name} />
                                                </Typography>
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.buyTokens.map((buyToken) => (
                                    <TableRow
                                        key={buyToken.id}
                                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                    >
                                        <TableCell align="center" component="th" scope="row">
                                            {buyToken.id}
                                        </TableCell>
                                        <TableCell align="center" component="th" scope="row">
                                            {buyToken.type}
                                        </TableCell>
                                        <TableCell align="center" component="th" scope="row">
                                            {buyToken.description}
                                        </TableCell>
                                        <TableCell align="center" component="th" scope="row">
                                            {buyToken.surveyType}
                                        </TableCell>
                                        <TableCell align="center">{buyToken.surveyInternalName}</TableCell>
                                        <TableCell align="center">{buyToken.email}</TableCell>
                                        <TableCell align="center">{buyToken.creation_date}</TableCell>
                                        <TableCell align="center">{`${buyToken.nbTokenUsed}/${buyToken.nbTokenAllowed}`}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                <br />
                <br />
                <Box>
                    <br />
                    <br />
                    <Typography variant="h5">
                        <Trans i18nKey="dashboard.token-manager.auto_and_standard_survey_token_table_title" />
                    </Typography>

                    <TableContainer component={Paper} style={{ backgroundColor: "#E4E4F1" }}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    {tableColumnNames.surveyTokens.map((column_name) => {
                                        return (
                                            <TableCell align="center">
                                                <Typography fontWeight={700} fontSize="15px" fontStyle={"bold"}>
                                                    <Trans i18nKey={column_name} />
                                                </Typography>
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {[...this.state.autoTokens, ...this.state.standardTokens].map((surveyToken) => (
                                    <TableRow
                                        key={surveyToken.id}
                                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                    >
                                        <TableCell
                                            align="center"
                                            component="th"
                                            scope="row"
                                            key={`id-${surveyToken.id}`}
                                        >
                                            {surveyToken.id}
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            component="th"
                                            scope="row"
                                            key={`type-${surveyToken.id}`}
                                        >
                                            {surveyToken.type}
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            component="th"
                                            scope="row"
                                            key={`description-${surveyToken.id}`}
                                        >
                                            {surveyToken.description}
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            component="th"
                                            scope="row"
                                            key={`internal-name-${surveyToken.id}`}
                                        >
                                            {surveyToken.surveyInternalName}
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            component="th"
                                            scope="row"
                                            key={`email-${surveyToken.id}`}
                                        >
                                            {surveyToken.email}
                                        </TableCell>
                                        <TableCell align="center" key={`buy-token-${surveyToken.id}`}>
                                            {surveyToken.buyTokenId}
                                        </TableCell>
                                        <TableCell align="center" key={`creation-date-${surveyToken.id}`}>
                                            {convertDateToUTC(surveyToken.creation_date)}
                                        </TableCell>
                                        <TableCell align="center" key={`expiration-date-${surveyToken.id}`}>
                                            {convertDateToUTC(surveyToken.expiration_date)}
                                        </TableCell>
                                        <TableCell align="center" key={`is-actif-${surveyToken.id}`}>
                                            {surveyToken.isCompleted ? "True" : "False"}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                <br />
                <TextField
                    fullWidth
                    label={this.props.t("dashboard.create_token.buy_token_id")}
                    variant="outlined"
                    size="medium"
                    onChange={(event) => this.selectBuytokenId(event.target.value)}
                    value={this.state.buyTokenIdSelected}
                />
                {this.getSurveyTokenFromBuyToken(this.state.buyTokenSelected).length +
                    this.props.t("dashboard.create_token.tokens_finded")}

                {this.csvDownloader()}
                <br />
                <Box>
                    <br />
                    <br />
                    <Box>
                        <Typography variant="h5">
                            <Trans i18nKey="dashboard.token-manager.observers_survey_token_table_title" />
                        </Typography>
                    </Box>

                    {this.state.autoTokens.map((parent_token) => {
                        return (
                            <Accordion style={{ backgroundColor: "#E4E4F1" }}>
                                <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                                    <Typography>
                                        {this.regroupObserversWithTheSameParentToken(parent_token.id).length + " "}
                                        <Trans i18nKey="dashboard.token-manager.observers_linked_to_parent_token_id" />
                                        {parent_token.id}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <TableContainer component={Paper} style={{ backgroundColor: "#E4E4F1" }}>
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    {tableColumnNames.surveyTokensObservers.map((column_name) => {
                                                        return (
                                                            <TableCell align="center">
                                                                <Typography
                                                                    fontWeight={700}
                                                                    fontSize="15px"
                                                                    fontStyle={"bold"}
                                                                >
                                                                    <Trans i18nKey={column_name} />
                                                                </Typography>
                                                            </TableCell>
                                                        );
                                                    })}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.regroupObserversWithTheSameParentToken(parent_token.id).map(
                                                    (surveyToken) => (
                                                        <TableRow
                                                            key={surveyToken.id}
                                                            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                                        >
                                                            <TableCell
                                                                align="center"
                                                                component="th"
                                                                scope="row"
                                                                key={`auto-std-id-${surveyToken.id}`}
                                                            >
                                                                {surveyToken.id}
                                                            </TableCell>
                                                            <TableCell
                                                                align="center"
                                                                component="th"
                                                                scope="row"
                                                                key={`auto-std-description-${surveyToken.id}`}
                                                            >
                                                                {surveyToken.description}
                                                            </TableCell>
                                                            <TableCell
                                                                align="center"
                                                                component="th"
                                                                scope="row"
                                                                key={`auto-std-survey-name-${surveyToken.id}`}
                                                            >
                                                                {surveyToken.surveyInternalName}
                                                            </TableCell>
                                                            <TableCell
                                                                align="center"
                                                                component="th"
                                                                scope="row"
                                                                key={`auto-std-enail-${surveyToken.id}`}
                                                            >
                                                                {surveyToken.email}
                                                            </TableCell>
                                                            <TableCell
                                                                align="center"
                                                                component="th"
                                                                scope="row"
                                                                key={`auto-std-parent-${surveyToken.id}`}
                                                            >
                                                                {surveyToken.tokenParentId}
                                                            </TableCell>
                                                            <TableCell
                                                                align="center"
                                                                key={`auto-std-creation-date-${surveyToken.id}`}
                                                            >
                                                                {convertDateToUTC(surveyToken.creation_date)}
                                                            </TableCell>
                                                            <TableCell
                                                                align="center"
                                                                key={`auto-std-expiration-date-${surveyToken.id}`}
                                                            >
                                                                {convertDateToUTC(surveyToken.expiration_date)}
                                                            </TableCell>
                                                            <TableCell
                                                                align="center"
                                                                key={`auto-std-is-complete-${surveyToken.id}`}
                                                            >
                                                                {surveyToken.isCompleted ? "False" : "True"}
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </AccordionDetails>
                            </Accordion>
                        );
                    })}
                </Box>
            </Box>
        );
    }
}

const tableColumnNames = {
    surveyTokens: [
        "dashboard.survey_token_table_column_names.id",
        "dashboard.survey_token_table_column_names.type",
        "dashboard.survey_token_table_column_names.description",
        "dashboard.survey_token_table_column_names.survey_internal_name",
        "dashboard.survey_token_table_column_names.email",
        "dashboard.survey_token_table_column_names.buyTokenId",
        "dashboard.survey_token_table_column_names.creation_date",
        "dashboard.survey_token_table_column_names.expiration_date",
        "dashboard.survey_token_table_column_names.is_completed",
    ],
    surveyTokensObservers: [
        "dashboard.survey_token_table_column_names.id",
        "dashboard.survey_token_table_column_names.description",
        "dashboard.survey_token_table_column_names.survey_internal_name",
        "dashboard.survey_token_table_column_names.email",
        "dashboard.survey_token_table_column_names.parent_token_id",
        "dashboard.survey_token_table_column_names.creation_date",
        "dashboard.survey_token_table_column_names.expiration_date",
        "dashboard.survey_token_table_column_names.is_completed",
    ],
    buyTokens: [
        "dashboard.buy_token_table_column_names.id",
        "dashboard.buy_token_table_column_names.type",
        "dashboard.survey_token_table_column_names.description",
        "dashboard.buy_token_table_column_names.survey_type",
        "dashboard.buy_token_table_column_names.survey_internal_name",
        "dashboard.buy_token_table_column_names.email",
        "dashboard.buy_token_table_column_names.creation_date",
        "dashboard.buy_token_table_column_names.NbTokenAllowed",
    ],
};
