import {
    QuestionAnswerAbstract,
    QuestionAnswerTextModel,
    QuestionAnswerChoiceModel,
    QuestionAbstract,
    QuestionAnswerMultipleChoiceModel,
    ELQuestionType,
    LQuestionAbstract,
} from "4common-ts";

export class InstaciateAnswerFactory {
    static Create(questionLayout: LQuestionAbstract<QuestionAbstract>): QuestionAnswerAbstract {
        const questionType = questionLayout.type;
        if (questionType === ELQuestionType.L_QUESTION_CHOICE)
            return new QuestionAnswerChoiceModel("", questionType, questionLayout.question.id, "", "");
        else if (questionType === ELQuestionType.L_QUESTION_MULTIPLE_CHOICE)
            return new QuestionAnswerMultipleChoiceModel("", questionType, questionLayout.question.id, "", []);
        else if (questionType === ELQuestionType.L_QUESTION_TEXT)
            return new QuestionAnswerTextModel("", questionType, questionLayout.question.id, "", "");

        throw Error("Unknown type of answer: " + questionType);
    }
}
