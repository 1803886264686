import { ModelAbstract } from "../model-abstract";
import { ESexes, EUserModelType } from "./utils/enum-constants";

export class UserInformationsModel extends ModelAbstract {
    static type: EUserModelType = EUserModelType.USER_INFORMATION;

    // Columns
    readonly full_name: string;
    readonly company: string;
    readonly sex: ESexes;

    // relations
    readonly email: string;

    constructor(id: string, email: string, full_name: string, company: string, sex: ESexes) {
        super(id, UserInformationsModel.type);

        this.email = email;
        this.full_name = full_name;
        this.company = company;
        this.sex = sex;
    }
}
