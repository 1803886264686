import { IAttribute } from "src/layout/attribute/attribute";
import { ContainerAbstract } from "src/layout/container-abstract";
import { LDescriptionAbstract } from "src/layout/description/description-abstract";
import { HookAbstract } from "src/layout/hook/hook-abstract";
import { ELChoiceType } from "src/layout/utils/enum-constants";

// TODO: add an abstract choice and make choice_type extends it if another type of choice is added
export abstract class LChoiceAbstract<choice_type = any> extends ContainerAbstract<undefined, ELChoiceType> {
    static type: ELChoiceType = ELChoiceType.L_ABSTRACT;
    choice: choice_type;

    constructor(type: ELChoiceType, attributes: Array<IAttribute>, hooks: Array<HookAbstract>, choice: choice_type, descriptions: Array<LDescriptionAbstract>) {
        super(type, attributes, hooks, descriptions, undefined);
        this.choice = choice;
    }
}
