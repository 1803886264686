import ControllerAbstract from "./controller-abstract";

import CommandController from "../command-controller";
import { UserData } from "Common/user-data";
import { ESupportedLanguage } from "4common-ts";

export default class ErrorPage404Controller extends ControllerAbstract {
    static myName: string = "error-page-404-controller";

    constructor(commandMediator: CommandController, userData: UserData, currentLang: ESupportedLanguage) {
        super(commandMediator, userData, ErrorPage404Controller.myName, currentLang);
    }
}
