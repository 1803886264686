import { LLayoutAbstract, LPageAbstract } from "4common-ts";

export const updatePageLayout = (newPage: LPageAbstract, layout: LLayoutAbstract): LLayoutAbstract | undefined => {
    const pages = layout.containers;
    if (pages === undefined) return undefined;

    const updatePages: Array<LPageAbstract> = [];
    pages.forEach((page) => {
        if (page.id === newPage.id) updatePages.push(newPage);
        else updatePages.push(page);
    });

    return { ...layout, containers: updatePages } as LLayoutAbstract; // return a clone with updated pages
};
