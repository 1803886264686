import {
    ESupportedLanguage,
    CommandFactory,
    ECommandError,
    CommandGetSurveyInfos,
    ExtraArgsCommandGetSurveyInfos,
    ESurveyInfoType,
    CommandResultSurveyInfos,
    CommandGetIndicators,
    ExtraArgsCommandGetIndicators,
    CommandResultGetIndicators,
    IndicatorModel,
} from "4common-ts";

import ControllerAbstract from "../../Common/Controllers/controller-abstract";

import CommandController, { EEndPointSecure } from "../../Common/command-controller";
import { UserData } from "Common/user-data";
import { ISurveyInfos } from "Survey/Components/thanks";

export default class IndicatorsController extends ControllerAbstract {
    static myName: string = "thanks-standard-controller";

    constructor(commandMediator: CommandController, userData: UserData, currentLang: ESupportedLanguage) {
        super(commandMediator, userData, IndicatorsController.myName, currentLang);
    }

    async getSurveyInfosAsync(): Promise<ISurveyInfos> {
        return new Promise<ISurveyInfos>(async (resolve, reject) => {
            const tokenId = this.token;

            const commandSurveyInfos = CommandFactory.create<ExtraArgsCommandGetSurveyInfos>(
                CommandGetSurveyInfos.type,
                this.token,
                {
                    surveyId: "",
                    infoTypes: [
                        ESurveyInfoType.INTERNAL_NAME,
                        ESurveyInfoType.TYPE,
                        ESurveyInfoType.SURVEY_NAME,
                        ESurveyInfoType.SURVEY_DESCRIPTION,
                        ESurveyInfoType.HAVE_INDICATORS,
                    ],
                    lang: this.currentLang,
                    tokenId,
                }
            );

            const result = await this.sendCommandAsync(commandSurveyInfos, EEndPointSecure.PUBLIC);

            if (result === undefined) throw Error("Something went wrong while request commandSurveyInfo");
            if (result.type !== CommandResultSurveyInfos.type) {
                reject(ECommandError.UNKNOWN);
            }

            const surveyInfos = (result as CommandResultSurveyInfos).surveyInfos;
            const surveyType = surveyInfos[ESurveyInfoType.TYPE];
            const internal_name = surveyInfos[ESurveyInfoType.INTERNAL_NAME];
            const survey_name = surveyInfos[ESurveyInfoType.SURVEY_NAME];
            const survey_description = surveyInfos[ESurveyInfoType.SURVEY_DESCRIPTION];
            const have_indicators = surveyInfos[ESurveyInfoType.HAVE_INDICATORS];

            const surveyInfo = {
                internal_name,
                surveyType,
                survey_name,
                survey_description,
                have_indicators,
            } as ISurveyInfos;

            resolve(surveyInfo);
        });
    }

    async getIndicatorsAsync(): Promise<IndicatorModel[]> {
        return new Promise<IndicatorModel[]>(async (resolve, reject) => {
            const commandGetIndicators = CommandFactory.create<ExtraArgsCommandGetIndicators>(
                CommandGetIndicators.type,
                this.token,
                {
                    survey_token_id: this.token,
                    lang: this.currentLang,
                }
            );

            const result = await this.sendCommandAsync(commandGetIndicators, EEndPointSecure.PUBLIC);

            if (result === undefined) throw Error("Something went wrong while request CommandGetIndicators");
            if (result.type !== CommandResultGetIndicators.type) {
                reject(ECommandError.UNKNOWN);
            }

            const indicators = (result as CommandResultGetIndicators).indicators;

            resolve(indicators);
        });
    }
}
