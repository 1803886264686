import * as React from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import i18n from "i18next";
import { withTranslation } from "react-i18next";
import Cookies from "universal-cookie";

import config from "./config/config.json";

import { ESupportedLanguage, ESurveyType } from "4common-ts";

import CommandController from "./Common/command-controller";
import ErrorPage404Controller from "./Common/Controllers/error-page-404-controller";
import AccessDeniedController from "Common/Controllers/access-denied-controller";
import MainController from "./Survey/Controllers/main-controller";
import InstructionController from "./Survey/Controllers/instruction-controller";
import SurveyController from "./Survey/Controllers/survey-controller";
import TokenUsedController from "./Survey/Controllers/token-used-controller";
import LoginController from "User/Controllers/login-controller";

import AccessDenied from "./Common/Components/access-denied";
import ErrorPage404 from "./Common/Components/error-page-404";
import MainMenue from "./Survey/Components/main";
import Instructions from "./Survey/Components/instruction";
import Survey from "./Survey/Components/survey";
import Thanks from "./Survey/Components/thanks";
import TokenUsed from "./Survey/Components/token-used";
import Login from "User/Components/login";
import { UserData } from "Common/user-data";
import Dashboard from "User/Components/dashboard";
import SingIn from "User/Components/sign-in";
import DashboardController from "User/Controllers/dashboard-controller";
import SingInController from "User/Controllers/sign-in-controller";
import AfterRegistration from "User/Components/after-registration";
import AfterRegistrationController from "User/Controllers/after-registration-controller";
import { DEFAULT_LANG } from "global";
import ThanksController from "Survey/Controllers/thanks-controller";
import IndicatorsController from "Survey/Controllers/indicators.controller";
import Indicators from "Survey/Components/indicators";

const cookies = new Cookies();

interface IAppPros {}

interface IAppState {}

class App extends React.Component<IAppPros, IAppState> {
    private commandMediator: CommandController;

    private supportedLanguages: Array<ESupportedLanguage> = Object.keys(ESupportedLanguage).map(
        (l) => ESupportedLanguage[l]
    );

    constructor(props: any) {
        super(props);

        this.commandMediator = new CommandController({
            //@ts-ignore
            ...config["backend"],
        });

        this.state = {
            selectLang: DEFAULT_LANG, // need cookies
        };
    }
    getMainComponent = ({
        history,
        match: {
            params: { lang },
        },
    }: any) => {
        lang = validateLangAndRewriteUrl(history, { lang });
        i18n.changeLanguage(lang);
        const LangComponent = withTranslation()(MainMenue);
        const controller = new MainController(this.commandMediator, getUserData(), lang);
        return (
            <LangComponent
                controller={controller}
                selectlang={ESupportedLanguage[lang]}
                onselectlanguage={(l) => controller.onLangChange("", l, false)}
                availableLanguages={this.supportedLanguages}
                key={"home-component"}
            />
        );
    };

    getErrorPage404Component = ({
        history,
        match: {
            params: { lang },
        },
    }: any) => {
        const basepath = "404";
        lang = validateLangAndRewriteUrl(history, { lang, basepath });
        i18n.changeLanguage(lang);
        const LangComponent = withTranslation()(ErrorPage404);
        const controller = new ErrorPage404Controller(this.commandMediator, getUserData(), lang);
        return (
            <LangComponent
                controller={controller}
                selectlang={ESupportedLanguage[lang]}
                onselectlanguage={(l) => controller.onLangChange(basepath, l, false)}
                availableLanguages={this.supportedLanguages}
                key={"error-page-404-component"}
            />
        );
    };

    getErrorComponent = ({
        history,
        match: {
            params: { type, lang },
        },
    }: any) => {
        const basepath = "error";
        lang = validateLangAndRewriteUrl(history, { lang, type, basepath });
        i18n.changeLanguage(lang);
        if (type === "accessDenied") {
            const LangComponent = withTranslation()(AccessDenied);
            const controller = new AccessDeniedController(this.commandMediator, getUserData(), lang);
            return (
                <LangComponent
                    controller={controller}
                    selectlang={ESupportedLanguage[lang]}
                    onselectlanguage={(l) => controller.onLangChange(`${basepath}/accessDenied`, l, false)}
                    availableLanguages={this.supportedLanguages}
                    key={"access-denied-component"}
                />
            );
        } else if (type === "invalidToken") {
            const LangComponent = withTranslation()(TokenUsed);
            const controller = new TokenUsedController(this.commandMediator, getUserData(), lang);
            return (
                <LangComponent
                    controller={controller}
                    selectlang={ESupportedLanguage[lang]}
                    onselectlanguage={(l) => controller.onLangChange(`${basepath}/invalidToken`, l, false)}
                    availableLanguages={this.supportedLanguages}
                    key={"invalid-token-component"}
                />
            );
        } else return <Redirect to={`/404/${lang}`} />;
    };

    getInstructionsComponent = ({
        history,
        match: {
            params: { surveytoken, lang },
        },
    }: any) => {
        const basepath = "instructions";
        lang = validateLangAndRewriteUrl(history, { lang, surveytoken, basepath });
        i18n.changeLanguage(lang);
        const LangComponent = withTranslation()(Instructions);
        const controller = new InstructionController(this.commandMediator, getUserData(), lang);
        controller.setSurveyToken(surveytoken);
        return (
            <LangComponent
                controller={controller}
                selectlang={ESupportedLanguage[lang]}
                onselectlanguage={(l) => controller.onLangChange(basepath, l, true)}
                key={"instructions-component"}
            />
        );
    };

    getInstructionsComponentForStatic = ({
        history,
        match: {
            params: { surveyname, lang },
        },
    }: any) => {
        const basepath = `instructions/static/${surveyname}`;
        lang = validateLangAndRewriteUrl(history, { lang, basepath });
        i18n.changeLanguage(lang);
        const LangComponent = withTranslation()(Instructions);
        const controller = new InstructionController(this.commandMediator, getUserData(), lang, true);
        controller.setSurveyName(surveyname);
        return (
            <LangComponent
                controller={controller}
                selectlang={ESupportedLanguage[lang]}
                onselectlanguage={(l) => controller.onLangChange(basepath, l, true)}
                key={"instructions-component"}
            />
        );
    };

    getSurveyComponent = ({
        history,
        match: {
            params: { surveytoken, lang },
        },
    }: any) => {
        const basepath = "survey";
        lang = validateLangAndRewriteUrl(history, { lang, surveytoken, basepath });
        i18n.changeLanguage(lang);
        const LangComponent = withTranslation()(Survey);
        const controller = new SurveyController(this.commandMediator, getUserData(), lang);
        controller.setSurveyToken(surveytoken);
        return (
            <LangComponent
                controller={controller}
                selectlang={ESupportedLanguage[lang]}
                onselectlanguage={(l) => controller.onLangChange(basepath, l, true)}
                key={"survey-component"}
            />
        );
    };

    getSurveyComponentForStatic = ({
        history,
        match: {
            params: { surveyname, lang },
        },
    }: any) => {
        const basepath = `survey/static/${surveyname}`;
        lang = validateLangAndRewriteUrl(history, { lang, basepath });
        i18n.changeLanguage(lang);
        const LangComponent = withTranslation()(Survey);
        const controller = new SurveyController(this.commandMediator, getUserData(), lang, true);
        controller.setSurveyName(surveyname);
        return (
            <LangComponent
                controller={controller}
                selectlang={ESupportedLanguage[lang]}
                onselectlanguage={(l) => controller.onLangChange(basepath, l, true)}
                key={"survey-component"}
            />
        );
    };

    getThanksComponent = ({
        history,
        match: {
            params: { surveytoken, lang },
        },
    }: any) => {
        const basepath = "thanks";
        lang = validateLangAndRewriteUrl(history, { lang, surveytoken, basepath });
        i18n.changeLanguage(lang);
        const LangComponent = withTranslation()(Thanks);
        const controller = new ThanksController(this.commandMediator, getUserData(), lang);
        controller.setSurveyToken(surveytoken);
        return (
            <LangComponent
                controller={controller}
                selectlang={ESupportedLanguage[lang]}
                onselectlanguage={(l) => controller.onLangChange(basepath, l, true)}
                availableLanguages={this.supportedLanguages}
                key={"thanks-component"}
            />
        );
    };

    getIndicatorsComponent = ({
        history,
        match: {
            params: { surveytoken, lang },
        },
    }: any) => {
        const basepath = "indicators";
        lang = validateLangAndRewriteUrl(history, { lang, surveytoken, basepath });
        i18n.changeLanguage(lang);
        const LangComponent = withTranslation()(Indicators);
        const controller = new IndicatorsController(this.commandMediator, getUserData(), lang);
        controller.setSurveyToken(surveytoken);
        return (
            <LangComponent
                controller={controller}
                selectlang={ESupportedLanguage[lang]}
                onselectlanguage={(l) => controller.onLangChange(basepath, l, true)}
                availableLanguages={this.supportedLanguages}
                key={"indicators-component"}
            />
        );
    };

    getLoginComponent = ({
        history,
        match: {
            params: { lang },
        },
    }: any) => {
        const basepath = "login";
        lang = validateLangAndRewriteUrl(history, { lang, basepath });
        i18n.changeLanguage(lang);
        const LangComponent = withTranslation()(Login);
        const controller = new LoginController(this.commandMediator, getUserData(), lang);
        return (
            <LangComponent
                controller={controller}
                selectlang={ESupportedLanguage[lang]}
                onselectlanguage={(l) => controller.onLangChange(basepath, l, false)}
                availableLanguages={this.supportedLanguages}
                key={"login-component"}
            />
        );
    };

    getDashboardComponent = ({
        history,
        match: {
            params: { tab, lang },
        },
    }: any) => {
        const basepath = `dashboard/${tab}`;
        lang = validateLangAndRewriteUrl(history, { lang, basepath });
        i18n.changeLanguage(lang);
        const LangComponent = withTranslation()(Dashboard);
        const controller = new DashboardController(this.commandMediator, getUserData(), lang);
        return (
            <LangComponent
                controller={controller}
                selectlang={ESupportedLanguage[lang]}
                onselectlanguage={(l) => controller.onLangChange(basepath, l, false)}
                availableLanguages={this.supportedLanguages}
                activeTabTitleOnStart={tab}
                key={"login-component"}
            />
        );
    };

    getSignInComponent = ({
        history,
        match: {
            params: { lang },
        },
    }: any) => {
        const basepath = "sign-in";
        lang = validateLangAndRewriteUrl(history, { lang, basepath });
        i18n.changeLanguage(lang);
        const LangComponent = withTranslation()(SingIn);
        const controller = new SingInController(this.commandMediator, getUserData(), lang);
        return (
            <LangComponent
                controller={controller}
                selectlang={ESupportedLanguage[lang]}
                onselectlanguage={(l) => controller.onLangChange(basepath, l, false)}
                availableLanguages={this.supportedLanguages}
                key={"sign-in-component"}
            />
        );
    };

    getAfterRegistrationComponent = ({
        history,
        match: {
            params: { lang },
        },
    }: any) => {
        const basepath = "after-registration";
        lang = validateLangAndRewriteUrl(history, { lang, basepath });
        i18n.changeLanguage(lang);
        const LangComponent = withTranslation()(AfterRegistration);
        const controller = new AfterRegistrationController(this.commandMediator, getUserData(), lang);
        return (
            <LangComponent
                controller={controller}
                selectlang={ESupportedLanguage[lang]}
                onselectlanguage={(l) => controller.onLangChange(basepath, l, false)}
                availableLanguages={this.supportedLanguages}
                key={"after-registration-component"}
            />
        );
    };

    render() {
        return (
            <Router>
                <div className="App">
                    <Switch>
                        <Route
                            path="/after-registration/:lang?"
                            exact
                            component={(props: any) => this.getAfterRegistrationComponent(props)}
                        />
                        <Route
                            path="/sign-in/:lang?"
                            exact
                            component={(props: any) => this.getSignInComponent(props)}
                        />
                        <Route path="/login/:lang?" exact component={(props: any) => this.getLoginComponent(props)} />
                        <Route
                            path="/dashboard/:tab/:lang?"
                            exact
                            component={(props: any) => this.getDashboardComponent(props)}
                        />
                        <Route
                            path="/instructions/static/:surveyname/:lang?"
                            exact
                            component={(props: any) => this.getInstructionsComponentForStatic(props)}
                        />
                        {/* FIXME: change path for something like /instruction/id/:surveytoken/:lang
                                    and keep old path for back compatibility */}
                        <Route
                            path="/instructions/:surveytoken/:lang?"
                            exact
                            component={(props: any) => this.getInstructionsComponent(props)}
                        />
                        <Route
                            path="/survey/static/:surveyname/:lang?"
                            component={(props: any) => this.getSurveyComponentForStatic(props)}
                        />
                        {/* FIXME: change path for something like /survey/id/:surveytoken/:lang
                                    and keep old path for back compatibility */}
                        <Route
                            path="/survey/:surveytoken/:lang?"
                            component={(props: any) => this.getSurveyComponent(props)}
                        />
                        <Route
                            path="/thanks/:surveytoken/:lang?"
                            exact
                            component={(props: any) => this.getThanksComponent(props)}
                        />
                        <Route
                            path="/indicators/:surveytoken/:lang?"
                            exact
                            component={(props: any) => this.getIndicatorsComponent(props)}
                        />
                        <Route
                            path="/404/:lang?"
                            exact
                            component={(props: any) => this.getErrorPage404Component(props)}
                        />
                        <Route
                            path="/error/:type/:lang?"
                            exact
                            component={(props: any) => this.getErrorComponent(props)}
                        />
                        {/* Main component must be last */}
                        <Route path="/:lang?" exact component={(props: any) => this.getMainComponent(props)} />

                        <Redirect to="/404/:lang?" />
                    </Switch>
                </div>
            </Router>
        );
    }
}

interface Params {
    type?: string;
    basepath?: string;
    surveytoken?: string;
    lang: string;
}

const validateLangAndRewriteUrl = (history, params: Params): ESupportedLanguage => {
    if (ESupportedLanguage[params.lang]) {
        return ESupportedLanguage[params.lang];
    }

    let default_lang = getUserData().prefered_lang;
    default_lang = default_lang === undefined ? DEFAULT_LANG : default_lang;

    // If no language is defined, generate url with default lang
    let url = "";
    if (params.basepath) url += `/${params.basepath}`;
    if (params.type) url += `/${params.type}`;
    if (params.surveytoken) url += `/${params.surveytoken}`;
    url += `/${default_lang}`;

    history.push(url);

    return default_lang;
};

const getUserData = (): UserData => {
    const userData: string | undefined = cookies.get("token");
    if (!userData) return { connected: false };

    const decodedUserData = JSON.parse(window.atob(userData.split(".")[1])); // 0: algo, 1: payload, 2: secret
    return {
        email: decodedUserData["email"],
        privilege: decodedUserData["privilege"],
        prefered_lang: decodedUserData["prefered_lang"],
        connected: true,
    };
};

export default App;
