import React from "react";
import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
    LinkedinShareButton,
    LinkedinIcon,
    EmailShareButton,
    EmailIcon,
    FacebookMessengerShareButton,
    FacebookMessengerIcon,
} from "react-share";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { ISurveyInfos } from "Survey/Components/thanks";
import Typography from "@mui/material/Typography";
import { Trans } from "react-i18next";

export interface ISocialMediasProps {
    surveyLink: string;
    surveyInfos: ISurveyInfos;
}

interface ISocialMediasState {
    surveyLink: string;
    surveyInfos: ISurveyInfos;
}

class SocialMedias extends React.Component<ISocialMediasProps, ISocialMediasState> {
    constructor(props: ISocialMediasProps) {
        super(props);
        this.state = {
            surveyLink: this.props.surveyLink,
            surveyInfos: this.props.surveyInfos,
        };
    }

    render() {
        return (
            <Grid container direction="column" display="flex" justifyContent="center" alignItems="center">
                <Box maxWidth="md">
                    <Box>
                        <Typography align="center" variant="h5">
                            <Trans i18nKey="social_medias.share" />
                        </Typography>
                    </Box>
                    <br />
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <EmailShareButton
                            url={this.state.surveyLink}
                            subject={this.state.surveyInfos.survey_name}
                            body={this.state.surveyInfos.survey_description}
                        >
                            <EmailIcon size={32} round />
                        </EmailShareButton>
                        <FacebookShareButton
                            url={this.state.surveyLink}
                            quote={this.state.surveyInfos.survey_description}
                            hashtag="#Actualisation"
                        >
                            <FacebookIcon size={32} round />
                        </FacebookShareButton>
                        <FacebookMessengerShareButton url={this.state.surveyLink} appId="Actualisation">
                            <FacebookMessengerIcon size={32} round />
                        </FacebookMessengerShareButton>
                        <TwitterShareButton url={this.state.surveyLink}>
                            <TwitterIcon size={32} round />
                        </TwitterShareButton>
                        <LinkedinShareButton
                            url={this.state.surveyLink}
                            title={this.state.surveyInfos.survey_name}
                            summary={this.state.surveyInfos.survey_description}
                            source={this.state.surveyLink}
                        >
                            <LinkedinIcon size={32} round />
                        </LinkedinShareButton>
                    </Box>
                </Box>
            </Grid>
        );
    }
}

export default SocialMedias;
