import { ICommandResult } from "../ICommandResult";
import { ECommandResultType } from "../../utils/enum-constants";
import { ICommand } from "src/command/command-model/ICommand";

export interface ExtraArgsCommandResultGetSurveyAvailableLanguages {
    availableLanguages: Array<string>;
}

export class CommandResultGetSurveyAvailableLanguages implements ICommandResult {
    static readonly type = ECommandResultType.COMMAND_RESULT_GET_SURVEY_AVAILABLE_LANGUAGES;

    readonly type: ECommandResultType = CommandResultGetSurveyAvailableLanguages.type;
    readonly id: string;
    readonly timestamp: string;
    readonly command: ICommand;
    readonly status: number = 200;

    readonly availableLanguages: Array<string>;

    cookies: { [key: string]: string } = {};

    constructor(id: string, timestamp: string, command: ICommand, availableLanguages: Array<string>) {
        this.id = id;
        this.timestamp = timestamp;
        this.command = command;
        this.availableLanguages = availableLanguages;
    }
}
