import { ModelAbstract, IdOrModelType } from "../model-abstract";
import { QuestionAbstract } from "./question-abstract";
import { ESurveyModelType, ESurveyType } from "./utils/enum-constants";

export class SurveyModel extends ModelAbstract {
    static type: ESurveyModelType = ESurveyModelType.SURVEY;

    readonly internal_name: string | undefined;

    readonly survey_type: ESurveyType | null | undefined;

    //for 360 survey
    readonly survey_parent_id: string | null | undefined;

    // Relations
    questions: Array<IdOrModelType<QuestionAbstract>>;

    // properties
    nb_questions: number;

    constructor(id: string, questions: Array<IdOrModelType<QuestionAbstract>>, internal_name?: string, survey_type?: ESurveyType | null, survey_parent_id?: string | null) {
        super(id, SurveyModel.type);

        this.questions = questions;

        this.nb_questions = questions.length;

        this.internal_name = internal_name;
        this.survey_type = survey_type;
        this.survey_parent_id = survey_parent_id;
    }
}
