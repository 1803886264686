import { ICommandResult } from "../ICommandResult";
import { ECommandResultType } from "../../utils/enum-constants";
import { ICommand } from "src/command/command-model/ICommand";

export type TSurveyInfos = { [key: string]: any }; // key msut be in ESurveyInfoType

export interface ExtraArgsCommandResultSurveyInfos {
    surveyInfos: TSurveyInfos;
}

export class CommandResultSurveyInfos implements ICommandResult {
    static readonly type = ECommandResultType.COMMAND_RESULT_SURVEY_INFOS;

    readonly type: ECommandResultType = CommandResultSurveyInfos.type;
    readonly id: string;
    readonly timestamp: string;
    readonly command: ICommand;
    readonly status: number = 200;
    readonly surveyInfos: TSurveyInfos;
    cookies: { [key: string]: string } = {};

    constructor(id: string, timestamp: string, command: ICommand, surveyInfos: TSurveyInfos) {
        this.id = id;
        this.timestamp = timestamp;
        this.command = command;
        this.surveyInfos = surveyInfos;
    }
}
