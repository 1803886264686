import {
    CommandFactory,
    CommandResultError,
    CommandResultMyTokens,
    ECommandError,
    CommandGetMyTokens,
    ExtraArgsCommandGetMyTokens,
    CommandCreateUserSurveyToken,
    ExtraArgsCommandCreateUserSurveyToken,
    CommandResultSuccess,
    ETokens,
    EServices,
    UITokenBuyToken,
    ETokenType,
    CommandGetSurveyInfos,
    ExtraArgsCommandGetSurveyInfos,
    ESurveyInfoType,
    CommandResultSurveyInfos,
    UIToken,
    ESupportedLanguage,
} from "4common-ts";
import CommandController, { EEndPointSecure } from "Common/command-controller";
import { UserData } from "Common/user-data";
import ControllerAbstract from "../../Common/Controllers/controller-abstract";

export interface BuyTokenWithParentId extends UITokenBuyToken {
    parentTokenId: string | undefined;
}

export default class DashboardAssignTokenToAnUserController extends ControllerAbstract {
    static myName: string = "dashboard-home-controller";

    constructor(commandMediator: CommandController, userData: UserData, currentLang: ESupportedLanguage) {
        super(commandMediator, userData, DashboardAssignTokenToAnUserController.myName, currentLang);
    }

    async getTokenAsync(email: string): Promise<Array<BuyTokenWithParentId>> {
        return new Promise<Array<BuyTokenWithParentId>>(async (resolve, reject) => {
            const command = CommandFactory.create<ExtraArgsCommandGetMyTokens>(CommandGetMyTokens.type, this.token, {
                tokenType: [ETokens.BUY_TOKEN, ETokens.AUTO, ETokens.MULTI], // Query AUTO and MULTI to get the parent id of the observator
                tokens: [""], //TODO: A way to get all token and another to have specific tokens list
                email,
                service: EServices.USER,
                keySeach: "", //TODO: A way to search a token by a keySearch like email or surveyId
            });

            const result = await this.sendCommandAsync(command, EEndPointSecure.PROTECTED);
            if (result === undefined) throw Error("Something went wrong while request Token to dashboard.");
            if (result.type === CommandResultError.type) {
                reject((result as CommandResultError).error);
                return;
            } else if (result.type !== CommandResultMyTokens.type) {
                reject(ECommandError.UNKNOWN);
                return;
            }

            const otherTokens: UIToken[] = (result as CommandResultMyTokens).tokens.filter(
                (t) => t.type !== ETokenType.BUY_TOKEN
            );
            const buyTokens: UITokenBuyToken[] = (result as CommandResultMyTokens).tokens.filter(
                (t) => t.type === ETokenType.BUY_TOKEN
            );

            let buyTokensUI: BuyTokenWithParentId[] = [];
            await Promise.all(
                buyTokens.map(async (bt: UITokenBuyToken) => {
                    let parentTokenId: string = "";
                    let tokenType: string = "";
                    let surveyInternalName: string = "";
                    // If the token is AUTO (or MULTI), it's mean we want to create child tokens
                    if (bt.surveyType === ETokenType.AUTO || bt.surveyType === ETokenType.MULTI) {
                        const commandSurveyInfo = CommandFactory.create<ExtraArgsCommandGetSurveyInfos>(
                            CommandGetSurveyInfos.type,
                            this.token,
                            {
                                surveyId: bt.surveyId,
                                infoTypes: [ESurveyInfoType.CHILD_SURVEY_NAME, ESurveyInfoType.CHILD_TYPE],
                                lang: this.currentLang,
                            }
                        );

                        const result = await this.sendCommandAsync(commandSurveyInfo, EEndPointSecure.PUBLIC);
                        if (result.type === CommandResultSurveyInfos.type) {
                            tokenType = (result as CommandResultSurveyInfos).surveyInfos[
                                ESurveyInfoType.CHILD_TYPE
                            ] as ETokenType;
                            surveyInternalName = (result as CommandResultSurveyInfos).surveyInfos[
                                ESurveyInfoType.CHILD_SURVEY_NAME
                            ];
                        }

                        parentTokenId = otherTokens.find((ot) => ot.buyTokenId === bt.id)!.id; // Should always exist
                    } else {
                        const commandSurveyInfo = CommandFactory.create<ExtraArgsCommandGetSurveyInfos>(
                            CommandGetSurveyInfos.type,
                            this.token,
                            {
                                surveyId: bt.surveyId,
                                infoTypes: [ESurveyInfoType.SURVEY_NAME, ESurveyInfoType.TYPE],
                            }
                        );

                        const result = await this.sendCommandAsync(commandSurveyInfo, EEndPointSecure.PUBLIC);
                        if (result.type === CommandResultSurveyInfos.type) {
                            tokenType = (result as CommandResultSurveyInfos).surveyInfos[
                                ESurveyInfoType.TYPE
                            ] as ETokenType;
                            surveyInternalName = (result as CommandResultSurveyInfos).surveyInfos[
                                ESurveyInfoType.SURVEY_NAME
                            ];
                        }
                    }

                    buyTokensUI.push({
                        ...bt,
                        type: tokenType,
                        surveyInternalName,
                        parentTokenId,
                    } as BuyTokenWithParentId);
                })
            );

            resolve(buyTokensUI);
        });
    }

    async createSurveyTokenAsync(
        buyTokenId: string,
        emails: string[],
        expiration_date: string | undefined,
        parent_survey_token_id: string | undefined
    ): Promise<string[]> {
        return new Promise<string[]>(async (resolve, reject) => {
            resolve(
                (
                    await Promise.all(
                        emails.map(async (email) => {
                            const command = CommandFactory.create<ExtraArgsCommandCreateUserSurveyToken>(
                                CommandCreateUserSurveyToken.type,
                                this.token,
                                {
                                    buyTokenId,
                                    email,
                                    expiration_date,
                                    parent_survey_token_id,
                                }
                            );
                            const result = await this.sendCommandAsync(command, EEndPointSecure.PROTECTED);
                            if (result === undefined) return email;

                            if (result.type !== CommandResultSuccess.type) {
                                return email;
                            }

                            return "";
                        })
                    )
                ).filter((r) => r !== "")
            );
        });
    }
}
