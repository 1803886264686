import React, { Fragment } from "react";

import {
    EAQuestionChoiceDisplay,
    EAQuestionTextDisplay,
    LQuestionAbstract,
    QuestionAbstract,
    QuestionAnswerAbstract,
    QuestionAnswerChoiceModel,
    QuestionAnswerTextModel,
    QuestionChoiceDisplayAttr,
    QuestionTextDisplayAttr,
    QuestionMultipleChoiceDisplayAttr,
    ELQuestionType,
    QuestionAnswerMultipleChoiceModel,
    EFormats,
    LChoiceAbstract,
    LSimpleDescription,
    ChoiceModel,
    EAQuestionMultipleChoiceDisplay,
    LDescriptionAbstract,
} from "4common-ts";

import * as EmailValidator from "email-validator";
import { TextareaAutosize, TextField } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import Stack from "@mui/material/Stack";
import DynamicEmailsTextFields from "Common/Components/dynamic-emails-textfields";
import { AnswerAbstract, AnswerChoice, AnswerText } from "Survey/answers/answer-wrapper";
import { EAnswerError } from "Survey/answers/error-type";
import { displayDescription } from "../display-description";
import Button from "@mui/material/Button";
import { Trans } from "react-i18next";

interface IPropsBaseQuestion {
    question: LQuestionAbstract<QuestionAbstract>;
    answer: QuestionAnswerAbstract | undefined;
    display: QuestionChoiceDisplayAttr;
    onchange: (answer: AnswerAbstract) => void;
    addquestion: (id: string, questionComponent: QuestionAbstractComponent<any>) => void; // Must be call in every constructor
}

interface IStateBaseQuestion {
    email: string;
    emailValidation: string;
    isEmailValidate: boolean;
}

export abstract class QuestionAbstractComponent<
    P extends IPropsBaseQuestion = IPropsBaseQuestion
> extends React.Component<P, IStateBaseQuestion> {
    /**
     * Display error to user and returns false if the answer is not correct.
     */
    abstract showError(errorList: Array<EAnswerError>): void;
    abstract render(): any;
}

interface IPropsChoiceQuestion extends IPropsBaseQuestion {
    choices: Array<LChoiceAbstract>;
}

export class ChoiceQuestionComponent extends QuestionAbstractComponent<IPropsChoiceQuestion> {
    constructor(props: any) {
        super(props);
        this.props.addquestion(this.props.question.question.id, this);
    }

    showError(errorList: Array<EAnswerError>): void {}

    private getSelectedValue(): string | undefined {
        if (this.props.answer === undefined) return undefined;
        return (this.props.answer as QuestionAnswerChoiceModel).choice_id;
    }

    render() {
        const display = this.props.display.display;

        if (display === EAQuestionChoiceDisplay.A_QUESTION_CHOICE_DISTRIBUTED_SLIDER) {
            //max value of the slider  in pourcentage
            const MAX_VALUE = 100;
            //min value of the slider in pourcentage
            const MIN_VALUE = (1 / this.props.choices.length) * MAX_VALUE;
            //contain data and labels for the slider
            const marks = this.props.choices.map((choice, index) => {
                return {
                    value: ((index + 1) / this.props.choices.length) * MAX_VALUE,
                    label: "",
                    text: (choice as any).descriptions[0].text as LSimpleDescription,
                    id: choice.choice.id,
                };
            });

            const selected_value_choice_id = this.getSelectedValue(); // We assume that type slider has only one answer. Validation should have been done before
            var choice_description_text: string = "";
            //If the question is not answered yet, the default value is set to the first element
            var default_value: number[] = [0];
            var value: number = 0;

            //The default value correspond to the actual selected value,
            if (selected_value_choice_id !== undefined) {
                const choice_description = (
                    this.props.choices.find((choice) => choice.choice.id === selected_value_choice_id) as any
                ).descriptions[0] as LSimpleDescription;

                choice_description_text = choice_description.text;
                default_value = marks.map((mark) => {
                    if (mark.id === selected_value_choice_id) {
                        return mark.value;
                    }
                    return 1;
                });

                value = default_value[marks.findIndex((mark) => mark.id === selected_value_choice_id)];
            }

            return (
                <Box>
                    {questionDescriptionComponent(this.props.question.descriptions[0])}
                    <br />
                    <Slider
                        color={this.props.answer === undefined ? "secondary" : "primary"}
                        aria-label="Always visible"
                        size="medium"
                        min={MIN_VALUE}
                        max={MAX_VALUE}
                        value={value}
                        valueLabelFormat={(value: number) => {
                            return marks.findIndex((mark) => mark.value === value) + 1;
                        }}
                        step={10}
                        valueLabelDisplay="on"
                        marks={marks}
                        onChangeCommitted={(e, val) => {
                            const choice = marks.find((element) => element.value === val);

                            if (choice !== undefined) {
                                this.props.onchange(new AnswerChoice(choice.id));
                            }
                        }}
                    />
                </Box>
            );
        } else if (display === EAQuestionChoiceDisplay.A_QUESTION_CHOICE_SLIDER) {
            //max value of the slider  in pourcentage
            const MAX_VALUE = 100;
            //min value of the slider in pourcentage
            const MIN_VALUE = (1 / this.props.choices.length) * MAX_VALUE;
            //contain data and labels for the slider
            const marks = this.props.choices.map((choice, index) => {
                return {
                    value: ((index + 1) / this.props.choices.length) * MAX_VALUE,
                    label: "",
                    text: (choice as any).descriptions[0].text as LSimpleDescription,
                    id: choice.choice.id,
                };
            });

            const selected_value_choice_id = this.getSelectedValue(); // We assume that type slider has only one answer. Validation should have been done before
            var choice_description_text: string = "";
            //If the question is not answered yet, the default value is set to the first element
            var default_value: number[] = [0];
            var value: number = 0;

            //The default value correspond to the actual selected value,
            if (selected_value_choice_id !== undefined) {
                const choice_description = (
                    this.props.choices.find((choice) => choice.choice.id === selected_value_choice_id) as any
                ).descriptions[0] as LSimpleDescription;

                choice_description_text = choice_description.text;
                default_value = marks.map((mark) => {
                    if (mark.id === selected_value_choice_id) {
                        return mark.value;
                    }
                    return 1;
                });

                value = default_value[marks.findIndex((mark) => mark.id === selected_value_choice_id)];
            }

            return (
                <Box>
                    {questionDescriptionComponent(this.props.question.descriptions[0])}
                    <Slider
                        color={this.props.answer === undefined ? "secondary" : "primary"}
                        aria-label="Restricted values"
                        size="medium"
                        min={MIN_VALUE}
                        max={MAX_VALUE}
                        value={value}
                        valueLabelFormat={(value: number) => {
                            return marks.findIndex((mark) => mark.value === value) + 1;
                        }}
                        step={null}
                        valueLabelDisplay="auto"
                        marks={marks}
                        onChangeCommitted={(e, val) => {
                            const choice = marks.find((element) => element.value === val);

                            if (choice !== undefined) {
                                this.props.onchange(new AnswerChoice(choice.id));
                            }
                        }}
                    />
                    <div
                        style={{
                            width: "100%",
                            padding: "0 10px",
                            margin: "10px 0px",
                            border: "0.2px solid #BBB8CD",
                            borderRadius: "5px",
                            backgroundColor: "#E4E4F1",
                            textAlign: "center",
                        }}
                    >
                        {choice_description_text}
                    </div>
                </Box>
            );
        } else if (display === EAQuestionChoiceDisplay.A_QUESTION_CHOICE_HORIZONTAL_BUTTON) {
            return (
                <Box marginTop={5} boxShadow={"1px 1px 4px #1D1D35"}>
                    {questionDescriptionComponent(this.props.question.descriptions[0])}

                    <FormControl component="fieldset">
                        <RadioGroup
                            row
                            aria-label="gender"
                            name="row-radio-buttons-group"
                            value={this.getSelectedValue()}
                        >
                            {this.props.choices.map((choice: LChoiceAbstract, index) => {
                                const description = (choice as any).descriptions[0];
                                //max value to the size of the grid
                                const BOX_SIZE_MAX_VALUE = 12;
                                //2 answer-choice per line
                                const XS = BOX_SIZE_MAX_VALUE / 2;
                                //3 answer-choice per line
                                const SM = BOX_SIZE_MAX_VALUE / 3;
                                //all answer-choices in one line
                                const MD = BOX_SIZE_MAX_VALUE / this.props.choices.length;
                                const RATIO = 100 / this.props.choices.length;

                                //if is non pair answer-choice like 3-5-7 etc, the last element need to take all the line
                                const isLastAnswerChoiceAndNonPair =
                                    this.props.choices.length % 2 !== 0 && index === this.props.choices.length - 1;
                                return (
                                    <Grid
                                        xs={isLastAnswerChoiceAndNonPair ? BOX_SIZE_MAX_VALUE : XS}
                                        sm={isLastAnswerChoiceAndNonPair ? BOX_SIZE_MAX_VALUE : SM}
                                        md={MD}
                                        style={{
                                            width: RATIO + "%",
                                            padding: "0px",
                                            margin: "0px 0px",
                                            border: "0.2px solid #BBB8CD",
                                            textAlign: "center",
                                            display: "inline-block",
                                            backgroundColor:
                                                this.getSelectedValue() === choice.choice.id ? "#E4E4F1" : undefined,
                                        }}
                                    >
                                        <FormControlLabel
                                            value={choice.choice.id}
                                            onClick={() => this.props.onchange(new AnswerChoice(choice.choice.id))}
                                            control={<Radio />}
                                            label={choice.choice.order}
                                        />
                                        <br />

                                        <Typography
                                            sx={{
                                                fontSize: {
                                                    md: "1.5rem",
                                                    sm: "1.15rem",
                                                    xs: "1rem",
                                                },
                                                wordWrap: "break-word",
                                                width: "100%",

                                                textAlign: "center",
                                            }}
                                        >
                                            {description.text}
                                        </Typography>
                                    </Grid>
                                );
                            })}
                        </RadioGroup>
                    </FormControl>
                </Box>
            );
        } else if (display === EAQuestionChoiceDisplay.A_QUESTION_CHOICE_VERTICAL_BUTTON) {
            return (
                <Box marginTop={5}>
                    {questionDescriptionComponent(this.props.question.descriptions[0])}

                    <FormControl
                        style={{
                            padding: "10px 20px",
                            width: "100%",
                            boxShadow: "1px 1px 4px #1D1D35",
                        }}
                    >
                        {this.props.question.type === ELQuestionType.L_QUESTION_CHOICE ? (
                            <RadioGroup
                                name="radio-buttons-group"
                                value={this.getSelectedValue() /* Only one answer is assume */}
                            >
                                {this.props.choices.map((choice: LChoiceAbstract) => {
                                    const description = (choice as any).descriptions[0];
                                    return (
                                        <div
                                            style={{
                                                width: "100%",
                                                padding: "0 10px",
                                                margin: "10px 0px",
                                                border: "0.2px solid #BBB8CD",
                                                borderRadius: "5px",
                                                backgroundColor:
                                                    this.getSelectedValue() /* idem */ === choice.choice.id
                                                        ? "#E4E4F1"
                                                        : undefined,
                                            }}
                                        >
                                            <FormControlLabel
                                                value={choice.choice.id}
                                                key={description.id}
                                                onClick={() => this.props.onchange(new AnswerChoice(choice.choice.id))}
                                                control={<Radio />}
                                                label={description.text}
                                            />
                                        </div>
                                    );
                                })}
                            </RadioGroup>
                        ) : (
                            ""
                        )}
                    </FormControl>
                </Box>
            );
        } else return "ERROR LAYOUT, DISPLAY OR QUESTION TYPE";
    }
}

interface IPropsTextQuestion extends IPropsBaseQuestion {}

//Disable copy-paste on textfield
const disableAction = (e) => {
    e.preventDefault();
};

/**
 * Classe use for textfields, emails and user informations
 */
export class TextQuestionComponent extends QuestionAbstractComponent<IPropsTextQuestion> {
    constructor(props: any) {
        super(props);
        this.props.addquestion(this.props.question.question.id, this);
        this.state = {
            email: this.props.answer ? (this.props.answer as QuestionAnswerTextModel).value : "",
            emailValidation: "",
            isEmailValidate: false,
        };
    }

    emailsAreEquals() {
        return this.state.email === this.state.emailValidation;
    }

    emailOnchange(email: string) {
        this.setState({ email });
    }

    emailValidationOnchange(emailValidation: string) {
        this.setState({ emailValidation });
    }

    areEmailsValidate() {
        return (
            EmailValidator.validate(this.state.email) &&
            EmailValidator.validate(this.state.emailValidation) &&
            this.emailsAreEquals()
        );
    }

    showError(errorList: Array<EAnswerError>): void {}

    render() {
        const display = (this.props.display as QuestionTextDisplayAttr).display;
        if (display === EAQuestionTextDisplay.A_QUESTION_TEXT_ONE_LINE) {
            var hasError = false;
            var hasEmailValidator = false;

            if (
                this.props.question.question !== undefined &&
                this.props.question.attributes.find(
                    (attribute) =>
                        (attribute as QuestionTextDisplayAttr).format === EFormats.EMAIL || //textfield to enter email
                        (attribute as QuestionTextDisplayAttr).format === EFormats.USER_EMAIL //the email used by the user to receive the repport
                )
            ) {
                hasEmailValidator =
                    this.props.question.attributes.find(
                        (attribute) => (attribute as QuestionTextDisplayAttr).format === EFormats.USER_EMAIL //the email used by the user to receive the repport
                    ) !== undefined;

                const isEmailMandatory = this.props.question?.question.mandatory;
                const isEmailNotMandatoryAndNotEmpty =
                    !this.props.question.question.mandatory && this.state.email.length !== 0;
                hasError =
                    (!EmailValidator.validate(this.state.email) && isEmailMandatory) ||
                    (this.props.answer === undefined && isEmailNotMandatoryAndNotEmpty
                        ? false
                        : !EmailValidator.validate(this.state.email) && isEmailNotMandatoryAndNotEmpty);
            }

            return (
                <Grid container direction="column" justifyContent="space-between" alignItems="center" padding={3}>
                    <Box
                        component="form"
                        sx={{
                            width: 500,
                            maxWidth: "100%",
                        }}
                    >
                        {this.state.isEmailValidate ? (
                            <Box>
                                <TextField
                                    fullWidth
                                    error={hasError}
                                    id={`text-question-${this.props.question.question.id}`}
                                    label={displayDescription(
                                        this.props.question.descriptions[0],
                                        this.props.question.question.mandatory
                                    )}
                                    variant="outlined"
                                    disabled={true}
                                    value={this.state.email}
                                    size="medium"
                                />
                                <Box marginTop={3}>
                                    <Button
                                        className="btn btn-primary"
                                        variant="contained"
                                        onClick={() => {
                                            this.setState({ isEmailValidate: false });
                                        }}
                                        disabled={!this.emailsAreEquals()}
                                    >
                                        <Trans i18nKey="questions.modify" />
                                    </Button>
                                </Box>
                            </Box>
                        ) : (
                            <Fragment>
                                <TextField
                                    fullWidth
                                    error={hasError}
                                    id={`text-question-${this.props.question.question.id}`}
                                    label={displayDescription(
                                        this.props.question.descriptions[0],
                                        this.props.question.question.mandatory
                                    )}
                                    variant="outlined"
                                    onChange={(event) => {
                                        hasEmailValidator
                                            ? this.emailOnchange(event.target.value)
                                            : this.props.onchange(new AnswerText(event.target.value));
                                    }}
                                    value={
                                        hasEmailValidator
                                            ? this.state.email
                                            : (this.props.answer as QuestionAnswerTextModel)?.value
                                    }
                                    size="medium"
                                />
                                {hasError ? (
                                    <Typography color={"red"}>
                                        <Trans i18nKey="questions.invalid_email" />
                                    </Typography>
                                ) : (
                                    ""
                                )}
                                {hasEmailValidator ? (
                                    <Box marginTop={3}>
                                        <TextField
                                            fullWidth
                                            error={!this.emailsAreEquals()}
                                            label={<Trans i18nKey="questions.email_validation" />}
                                            helperText={
                                                !this.emailsAreEquals() ? (
                                                    <Trans i18nKey="questions.emails_are_not_equals" />
                                                ) : (
                                                    ""
                                                )
                                            }
                                            id={"emailValidation"}
                                            variant="outlined"
                                            onChange={(event) => {
                                                this.emailValidationOnchange(event.target.value);
                                            }}
                                            value={this.state.emailValidation}
                                            size="medium"
                                            autoComplete="emailValidation"
                                            onCut={disableAction}
                                            onCopy={disableAction}
                                            onPaste={disableAction}
                                        />
                                        <br />

                                        <Box marginTop={3}>
                                            <Button
                                                className="btn btn-primary"
                                                variant="contained"
                                                onClick={() => {
                                                    this.setState({ isEmailValidate: true });
                                                    this.props.onchange(new AnswerText(this.state.email));
                                                }}
                                                disabled={!this.areEmailsValidate()}
                                            >
                                                <Trans i18nKey="questions.confirm" />
                                            </Button>
                                        </Box>
                                    </Box>
                                ) : (
                                    ""
                                )}
                            </Fragment>
                        )}
                    </Box>
                </Grid>
            );
        } else if (display === EAQuestionTextDisplay.A_DEFAULT)
            return (
                <div
                    style={{
                        display: "block",
                    }}
                >
                    <h3>{displayDescription(this.props.question.descriptions[0])}</h3>
                    <TextareaAutosize
                        id={`text-question-${this.props.question.question.id}`}
                        key={`text-question-${this.props.question.question.id}`}
                        value={(this.props.answer as QuestionAnswerTextModel)?.value}
                        onChange={(event) => this.props.onchange(event.target.value)}
                        rowsMin={5}
                        style={{ resize: "none", height: "100%", width: "100%" }}
                        placeholder="Écrivez ici (250 caractères maximum)"
                        maxLength={250}
                    />
                </div>
            );
        else if (display === EAQuestionTextDisplay.DYNAMIC_EMAILS) {
            const minTextfieldNumber = (this.props.display as QuestionTextDisplayAttr).min;
            const maxTexfieldsNumber = (this.props.display as QuestionTextDisplayAttr).max;
            const answer = this.props.answer;
            const emailsToOneString = answer ? (this.props.answer as QuestionAnswerTextModel).value : "";
            const emails = emailsToOneString.split(";").filter((email) => email !== "");

            return (
                <Box>
                    <DynamicEmailsTextFields
                        onChangeEmail={(emailsToString: string) => this.props.onchange(new AnswerText(emailsToString))}
                        emails={emails}
                        textfielsHaveLimitedNumber={maxTexfieldsNumber ? true : false}
                        minTextfieldNumber={minTextfieldNumber ? minTextfieldNumber : 1}
                        maxTexfieldsNumber={maxTexfieldsNumber ? maxTexfieldsNumber : 10}
                    />
                </Box>
            );
        } else return "ERROR LAYOUT, DISPLAY OR QUESTION TYPE";
    }
}

interface IPropsMultipleChoiceQuestion extends IPropsBaseQuestion {
    choices: Array<LChoiceAbstract>;
    multipleChoiceAttr: QuestionMultipleChoiceDisplayAttr | undefined;
}
export class MultipleChoiceQuestionComponent extends QuestionAbstractComponent<IPropsMultipleChoiceQuestion> {
    constructor(props: any) {
        super(props);
        this.props.addquestion(this.props.question.question.id, this);
    }

    private getSelectedValues(): Array<string> {
        if (this.props.answer === undefined) return [];
        return (this.props.answer as QuestionAnswerMultipleChoiceModel).choice_ids;
    }

    /**
     * Verry Special case for ADC survey
     * @param choiceExposeName
     * @returns
     */
    private wasChoiceAlreadyChecked(choiceExposeName: string) {
        if (this.props.multipleChoiceAttr) {
            return this.props.multipleChoiceAttr.choicesExposeNamesChecked.includes(choiceExposeName);
        } else return false;
    }

    showError(errorList: Array<EAnswerError>): void {}

    render() {
        const display = (this.props.display as QuestionMultipleChoiceDisplayAttr).display;
        if (display === EAQuestionMultipleChoiceDisplay.VERTICAL_CHECK_BOXES) {
            return (
                <Box marginTop={5} boxShadow={"1px 1px 4px #1D1D35"}>
                    {questionDescriptionComponent(this.props.question.descriptions[0])}
                    <FormControl
                        component="legend"
                        style={{
                            padding: "10px 20px",
                            width: "100%",
                            boxShadow: "1px 1px 4px #1D1D35",
                        }}
                    >
                        <FormLabel component="legend">
                            {this.props.multipleChoiceAttr!.nbChoice === -1 ? (
                                <Typography textAlign={"center"}>
                                    <Trans i18nKey="questions.select_method" />
                                </Typography>
                            ) : (
                                <Typography textAlign={"center"}>
                                    <Trans i18nKey="questions.number_of_selection" />
                                    {`${this.props.multipleChoiceAttr!.nbChoice} choix`}
                                </Typography>
                            )}
                        </FormLabel>
                        <FormGroup>
                            {this.props.choices.map((choice: LChoiceAbstract, index) => {
                                const description = (choice as any).descriptions[0];
                                const isChoiceAlreadyChecked = this.wasChoiceAlreadyChecked(choice.choice.expose_name);
                                const checked =
                                    this.getSelectedValues().find((id) => id === choice.choice.id) !== undefined;

                                const color = isChoiceAlreadyChecked ? "#1ED024" : "#E4E4F1";
                                return (
                                    <Box>
                                        {isChoiceAlreadyChecked ? (
                                            <Typography textAlign={"center"}>
                                                <Trans i18nKey="questions.green_section" />
                                            </Typography>
                                        ) : (
                                            ""
                                        )}

                                        <div
                                            style={{
                                                width: "100%",
                                                padding: "0 10px",
                                                margin: "10px 0px",
                                                border: "0.2px solid #BBB8CD",
                                                borderRadius: "5px",
                                                backgroundColor: checked || isChoiceAlreadyChecked ? color : undefined,
                                            }}
                                        >
                                            <FormControlLabel
                                                value={choice.choice.id}
                                                key={description.id}
                                                onClick={() => {
                                                    this.props.onchange(new AnswerChoice(choice.choice.id));
                                                }}
                                                control={<Checkbox checked={checked} />}
                                                label={description.text}
                                            />
                                        </div>
                                    </Box>
                                );
                            })}
                        </FormGroup>
                    </FormControl>
                </Box>
            );
        } else if (display === EAQuestionMultipleChoiceDisplay.HORIZONTAL_CHECK_BOXES)
            return (
                <Box marginTop={5} boxShadow={"1px 1px 4px #1D1D35"}>
                    {questionDescriptionComponent(this.props.question.descriptions[0])}

                    <FormControl
                        style={{
                            padding: "10px 20px",
                            width: "100%",
                            boxShadow: "1px 1px 4px #1D1D35",
                        }}
                    >
                        <FormLabel component="legend">
                            {this.props.multipleChoiceAttr!.nbChoice === -1 ? (
                                <Typography textAlign={"center"}>
                                    <Trans i18nKey="questions.select_method" />
                                </Typography>
                            ) : (
                                <Typography textAlign={"center"}>
                                    <Trans i18nKey="questions.number_of_selection" />
                                    {`${this.props.multipleChoiceAttr!.nbChoice} choix`}
                                </Typography>
                            )}
                        </FormLabel>
                        <FormGroup>
                            <Grid>
                                <Box textAlign={"center"}>
                                    <Stack spacing={50} direction="row">
                                        {this.props.choices.map((choice: LChoiceAbstract) => {
                                            const description = (choice as any).descriptions[0];
                                            const checked =
                                                this.getSelectedValues().find((id) => id === choice.choice.id) !==
                                                undefined;
                                            return (
                                                <div
                                                    style={{
                                                        padding: "0 10px",
                                                        margin: "10px 0px",
                                                        border: "0.2px solid #BBB8CD",
                                                        borderRadius: "5px",
                                                        backgroundColor: checked ? "#E4E4F1" : undefined,
                                                    }}
                                                >
                                                    <FormControlLabel
                                                        value={choice.choice.id}
                                                        key={description.id}
                                                        onClick={() =>
                                                            this.props.onchange(new AnswerChoice(choice.choice.id))
                                                        }
                                                        control={<Checkbox checked={checked} />}
                                                        label={description.text}
                                                        labelPlacement="bottom"
                                                    />
                                                </div>
                                            );
                                        })}
                                    </Stack>
                                </Box>
                            </Grid>
                        </FormGroup>
                    </FormControl>
                </Box>
            );
        else return "ERROR LAYOUT, DISPLAY OR QUESTION TYPE";
    }
}

/**
 *  All question descriptions are uniform
 * @param description
 * @returns component
 */
const questionDescriptionComponent = (description: LDescriptionAbstract) => {
    return (
        <Typography
            fontWeight={400}
            fontSize="15px"
            align="justify"
            color="white"
            sx={{
                fontFamily: "Segoe UI",
                padding: "10px 20px",
                width: "100%",
                boxShadow: "1px 1px 4px #1D1D35",
                backgroundColor: "#1D1D35",
                textAlign: "center",
                fontSize: {
                    md: "1.5rem",
                    sm: "1.15rem",
                    xs: "1rem",
                },
            }}
        >
            {displayDescription(description)}
        </Typography>
    );
};
