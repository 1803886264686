import { ICommand } from "../command-model/ICommand";
import { ICommandResult } from "../command-result/ICommandResult";

export function serializeCommand(command: ICommand): string {
    return JSON.stringify(command);
}

export function serializeCommandResult(commandResult: ICommandResult): string {
    return JSON.stringify(commandResult);
}
