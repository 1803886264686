///// Layout Type
export enum ELLayoutType {
    L_ABSTRACT = "abstract",
    L_DEFAULT_LAYOUT = "default",
    L_DISTRIBUTED = "distributed",
}

export enum ELPageType {
    L_ABSTRACT = "abstract",
    L_STATIC_PAGE = "static",
}

export enum ELQuestionType {
    L_ABSTRACT = "abstract",
    L_QUESTION_TEXT = "text",
    L_QUESTION_CHOICE = "choice",
    L_QUESTION_MULTIPLE_CHOICE = "multiple-choice",
}

export enum ELChoiceType {
    L_ABSTRACT = "abstract",
    L_SIMPLE_CHOICE = "simple",
}

// Description
export enum EDescriptionType {
    L_ABSTRACT = "abstract",
    L_SIMPLE_DESCRIPTION = "simple",
    L_HTML_DESCRIPTION = "html",
    L_DESCRIPTION_SURVEY = "survey",
}

export const findEDescriptionMember = (display: string): EDescriptionType | undefined => {
    return Object.values(EDescriptionType).find((e) => e === display);
};

// Hook
export enum EHookType {
    H_LAYOUT = "layout",
    H_PAGE = "page",
    H_QUESTION = "question",
}
