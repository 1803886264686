import React from "react";
import { Box, CircularProgress, Typography, LinearProgress } from "@mui/material";
import { Trans, withTranslation } from "react-i18next";

const Waiting: React.FunctionComponent = () => {
    return (
        <Box sx={{ width: "100%" }}>
            <Box display="flex" justifyContent="center" alignItems="center">
                <CircularProgress />
            </Box>
            <br />
            <br />
            <Typography align="center" variant="h5">
                <b>
                    <Trans i18nKey="waiting.waiting" />
                </b>
            </Typography>
            <LinearProgress />
        </Box>
    );
};

export default withTranslation()(Waiting);
