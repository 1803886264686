import { v4 as uuidv4 } from "uuid";

import { ICommand } from "../command-model/ICommand";
import { ICommandResult } from "./ICommandResult";

import { CommandResultGetAnswers } from "./survey/command-result-get-answers";
import { CommandResultGetQuestions } from "./survey/command-result-get-questions";
import { CommandResultGetSurvey } from "./survey/command-result-get-survey";
import { CommandResultAccessDenied } from "./common/error/command-result-access-denied";
import { CommandResultError } from "./common/error/command-result-error";
import { CommandResultSuccess } from "./common/command-result-success";
import { CommandResultTokenGenerated } from "./survey/command-result-token-generated";
import { ECommandResultType } from "../utils/enum-constants";
import { CommandResultGetPage } from "./survey/command-result-get-page";
import { CommandResultSurveyRetrieved } from "./survey/command-result-survey-retrieved";
import { CommandResultTokenNoMoreValid } from "./survey/command-result-token-no-more-valid";
import { CommandResultSurveyNotCompleted } from "./survey/command-result-survey-not-completed";
import { CommandResultGetDescriptionSurvey } from "./survey/command-result-get-description-survey";

import { validateUtcDateString, validateUuidv4 } from "../utils/validate_type";
import { CommandResultObserversTokensStillValid } from "./survey/Command-result-observers-tokens-still-valid";
import { CommandResultGetSurveyAvailableLanguages } from "./survey/command-result-get-survey-available-languages";
import { CommandResultFieldsErrors } from "./user/error/command-result-fields-errors";
import { CommandResultUserProfile } from "./user/command-result-user-profile";
import { CommandResultMyTokens } from "./user/command-result-my-tokens";
import { CommandResultSurveyInfos } from "./survey/command-result-survey-infos";
import { CommandResultSurveyNames } from "./survey/command-result-survey-names";
import { CommandResultDynamicToken } from "./user/command-result-dynamic-token";
import { CommandResultSurveyId } from "./survey/command-result-survey-id";
import { CommandResultEndOfSurvey } from "./survey/command-result-end-of-survey";
import { CommandResultCreateObserverSurveyTokens } from "./survey/command-result-create-observer-survey-tokens";
import { CommandResultGetIndicators } from "./survey/command-result-get-indicators";
import { CommandResultGetMySessions } from "./user/command-result-get-my-sessions";
import { CommandResultGetTokenInfos } from "./survey/command-result-get-token-infos";

export class CommandResultFactory {
    static recreate<E>(type: ECommandResultType, id: string, timestamp: string, command: ICommand, extraArgs?: E): ICommandResult {
        return CommandResultFactory._create<E>(type, id, timestamp, command, extraArgs);
    }

    static create<E>(type: ECommandResultType, command: ICommand, extraArgs?: E): ICommandResult {
        const id = uuidv4();
        const timestamp = new Date().toUTCString();

        return CommandResultFactory._create<E>(type, id, timestamp, command, extraArgs);
    }

    private static _create<E>(type: ECommandResultType, id: string, timestamp: string, command: ICommand, extraArgs?: E): ICommandResult {
        if (!validateUuidv4(id)) throw Error("Invalide uuid format.");
        if (!validateUtcDateString(timestamp)) throw Error("Invalide timestamp format.");

        switch (type) {
            /*SURVEY*/
            case CommandResultSuccess.type: {
                return new CommandResultSuccess(id, timestamp, command);
            }
            case CommandResultTokenNoMoreValid.type: {
                return new CommandResultTokenNoMoreValid(id, timestamp, command);
            }
            case CommandResultObserversTokensStillValid.type: {
                if (!extraArgs) throw Error("Missing extra args");
                return new CommandResultObserversTokensStillValid(id, timestamp, command, extraArgs["nonCompletedTokenIds"]);
            }
            case CommandResultGetSurvey.type: {
                if (!extraArgs) throw Error("Missing extra args");
                return new CommandResultGetSurvey(id, timestamp, command, extraArgs["surveyModel"], extraArgs["layoutSkeleton"]);
            }
            case CommandResultGetQuestions.type: {
                if (!extraArgs) throw Error("Missing extra args");
                return new CommandResultGetQuestions(id, timestamp, command, extraArgs["questionModels"]);
            }
            case CommandResultGetPage.type: {
                if (!extraArgs) throw Error("Missing extra args");
                return new CommandResultGetPage(id, timestamp, command, extraArgs["pageLayout"]);
            }
            case CommandResultSurveyRetrieved.type: {
                if (!extraArgs) throw Error("Missing extra args");
                return new CommandResultSurveyRetrieved(id, timestamp, command, extraArgs["participant"]);
            }
            case CommandResultSurveyNotCompleted.type: {
                if (!extraArgs) throw Error("Missing extra args");
                return new CommandResultSurveyNotCompleted(id, timestamp, command);
            }
            case CommandResultGetAnswers.type: {
                if (!extraArgs) throw Error("Missing extra args");
                return new CommandResultGetAnswers(id, timestamp, command, extraArgs["questionAnswerModels"]);
            }
            case CommandResultTokenGenerated.type: {
                if (!extraArgs) throw Error("Missing extra args");
                return new CommandResultTokenGenerated(id, timestamp, command, extraArgs["token"]);
            }
            case CommandResultGetDescriptionSurvey.type: {
                if (!extraArgs) throw Error("Missing extra args");
                return new CommandResultGetDescriptionSurvey(id, timestamp, command, extraArgs["descriptionSurvey"]);
            }
            case CommandResultGetSurveyAvailableLanguages.type: {
                if (!extraArgs) throw Error("Missing extra args");
                return new CommandResultGetSurveyAvailableLanguages(id, timestamp, command, extraArgs["availableLanguages"]);
            }
            case CommandResultSurveyInfos.type: {
                if (!extraArgs) throw Error("Missing extra args");
                return new CommandResultSurveyInfos(id, timestamp, command, extraArgs["surveyInfos"]);
            }
            case CommandResultEndOfSurvey.type: {
                if (!extraArgs) throw Error("Missing extra args");
                return new CommandResultEndOfSurvey(
                    id,
                    timestamp,
                    command,
                    extraArgs["surveyType"],
                    extraArgs["surveyTokenId"],
                    extraArgs["unAnsweredQuestionsIds"],
                    extraArgs["haveAReport"]
                );
            }
            case CommandResultCreateObserverSurveyTokens.type: {
                if (!extraArgs) throw Error("Missing extra args");
                return new CommandResultCreateObserverSurveyTokens(id, timestamp, command, extraArgs["parentTokenId"], extraArgs["childrenTokenIds"]);
            }
            case CommandResultGetIndicators.type: {
                if (!extraArgs) throw Error("Missing extra args");
                return new CommandResultGetIndicators(id, timestamp, command, extraArgs["indicators"]);
            }
            case CommandResultGetTokenInfos.type: {
                if (!extraArgs) throw Error("Missing extra args");
                return new CommandResultGetTokenInfos(id, timestamp, command, extraArgs["token"]);
            }

            /*USER*/
            case CommandResultUserProfile.type: {
                if (!extraArgs) throw Error("Missing extra args");
                return new CommandResultUserProfile(id, timestamp, command, extraArgs["userProfile"]);
            }
            case CommandResultMyTokens.type: {
                if (!extraArgs) throw Error("Missing extra args");
                return new CommandResultMyTokens(id, timestamp, command, extraArgs["tokens"]);
            }
            case CommandResultSurveyNames.type: {
                if (!extraArgs) throw Error("Missing extra args");
                return new CommandResultSurveyNames(id, timestamp, command, extraArgs["surveyInfos"]);
            }
            case CommandResultDynamicToken.type: {
                if (!extraArgs) throw Error("Missing extra args");
                return new CommandResultDynamicToken(id, timestamp, command, extraArgs["token"]);
            }
            case CommandResultSurveyId.type: {
                if (!extraArgs) throw Error("Missing extra args");
                return new CommandResultSurveyId(id, timestamp, command, extraArgs["surveyId"]);
            }
            case CommandResultGetMySessions.type: {
                if (!extraArgs) throw Error("Missing extra args");
                return new CommandResultGetMySessions(id, timestamp, command, extraArgs["sessions"], extraArgs["buyTokens"]);
            }

            // ERRORS
            case CommandResultFieldsErrors.type: {
                if (!extraArgs) throw Error("Missing extra args");
                return new CommandResultFieldsErrors(id, timestamp, command, extraArgs["userFieldsErrors"]);
            }
            case CommandResultError.type: {
                if (!extraArgs) throw Error("Missing extra args");
                return new CommandResultError(id, timestamp, command, extraArgs["error"]);
            }
            case CommandResultAccessDenied.type: {
                if (!extraArgs) throw Error("Missing extra args");
                else return new CommandResultAccessDenied(id, timestamp, command);
            }
            default:
                throw Error(`Unknow type of command result [${type}]`);
        }
    }
}
