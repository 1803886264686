import { EServices, ICommand } from "../ICommand";
import { ECommandType } from "../../utils/enum-constants";
import { LPageAbstract } from "src/layout/page/page-abstract";

export interface ExtraArgsCommandGetPage {
    pageId: string;
    lang: string;
    randomPage?: LPageAbstract;
}

export class CommandGetPage implements ICommand {
    static readonly type = ECommandType.COMMAND_GET_PAGE;
    static readonly service: EServices = EServices.SURVEY;

    readonly id: string;
    readonly timestamp: string;
    readonly type: ECommandType = CommandGetPage.type;
    readonly service: EServices = CommandGetPage.service;
    readonly token: string;

    readonly pageId: string;
    readonly lang: string;
    readonly randomPage?: LPageAbstract;

    constructor(id: string, timestamp: string, token: string, pageId: string, lang: string, randomPage?: LPageAbstract) {
        this.id = id;
        this.timestamp = timestamp;
        this.token = token;
        this.pageId = pageId;
        this.lang = lang;
        this.randomPage = randomPage;
    }
}
