export const convertDateToUTC = (date: Date | string | undefined): string => {
    if (date === undefined || date === null) return "";
    return new Date(date).toUTCString();
};

//date format aaaa-mm-dd ex: 2022-10-05
export const dateRegex = /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/;
//hour format hh:min ex: 14h20
export const hourMinRegex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;

export const isDateFormatValid = (date: string): boolean => {
    if (date !== "") {
        const dates = date.split(" ");

        if (dates.length !== 2) return false;
        else {
            const yyyymmdd = dates[0];
            const hhmm = dates[1];
            return dateRegex.test(yyyymmdd) && hourMinRegex.test(hhmm);
        }
    } else return true;
};
