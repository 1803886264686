import { ICommandResult } from "../ICommandResult";
import { ECommandResultType } from "../../utils/enum-constants";
import { UIUserProfileStruct } from "src/interfaces";
import { ICommand } from "src/command/command-model/ICommand";

export interface ExtraArgsCommandResultUserProfile {
    userProfile: UIUserProfileStruct;
}

export class CommandResultUserProfile implements ICommandResult {
    static readonly type = ECommandResultType.COMMAND_RESULT_USER_PROFILE;

    readonly type: ECommandResultType = CommandResultUserProfile.type;
    readonly id: string;
    readonly timestamp: string;
    readonly command: ICommand;
    readonly status: number = 200;
    readonly userProfile: UIUserProfileStruct;

    cookies: { [key: string]: string } = {};

    constructor(id: string, timestamp: string, command: ICommand, userProfile: UIUserProfileStruct) {
        this.id = id;
        this.timestamp = timestamp;
        this.command = command;
        this.userProfile = userProfile;
    }
}
